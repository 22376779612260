import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import styled, { css } from "styled-components";
import { observer, inject } from "mobx-react";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import UserResultList from "components/searchResult/userResultList";
import InfiniteScroll from "react-infinite-scroller";
import _ from "lodash";
import * as searchAPI from "axios/searchAPI";
import debounce from "debounce";

const TabContentWrap = styled.div`
  width: 100%;
  max-width: 1024px;
  padding: 0px 16px;
  padding-bottom: 24px;
  padding-top: 80px;
`;
const SearchWrapper = styled.div`
  position: fixed;
  z-index: 99999;
  background: white;
  width: 100%;
  height: auto;
  padding: 16px;
  padding-top: 12px;
  display: flex;
  align-items: center;
`;
const SearchInput = styled.input`
  z-index: 2;
  padding-left: 16px;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  background-color: #eeeeee;
  border: none;
`;
const IconWrapper = styled.i`
  z-index: 1000;
  position: absolute;
  left: 28px;
  top: 25px;
  span {
    margin-left: 11px;
    vertical-align: text-bottom;
    font-style: normal;
    font-weight: 500;
    color: rgb(172, 172, 172);
    font-family: "Noto Sans CJK KR";
    font-size: 14px;
  }
`;

@inject("userStore")
@inject("postStore")
@observer
class CommentModal extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      searchVal: "",
      page: 0,
    };
    this.drawer = React.createRef();
  }
  componentDidUpdate(nextProps) {
    if (this.drawer.current) {
      this.drawer.current.scrollTop = 0;
    }
  }
  loadData = async ({ page, tab }) => {
    try {
      const headers = this.props.userStore.auth_token
        ? {
            Authorization: `Bearer ${this.props.userStore.auth_token}`,
          }
        : undefined;
      let result = {};

      const { searchVal } = this.state;

      result = await searchAPI
        .getUserList({ name: searchVal, page, headers })
        .then((res) => {
          return res.data;
        });

      let data = result.data;

      // console.log(page, result, searchVal);
      // let data = (this.state.data || []).concat(result.data);

      // // DELETE DUPLICATE
      // data = data.reduce((acc, val) => {
      //   if (!acc.find((o) => o.id === val.id)) acc.push(val);
      //   return acc;
      // }, []);

      this.setState({
        data,
        count: result.count,
        page,
        next: result.next,
      });

      return data;
    } catch (e) {
      console.error(e);
    }
  };
  resetResults = () => {
    if (this.props.mode == "following")
      this.props.setFollowing(this.props.data);
    else if (this.props.mode == "follower")
      this.props.setFollower(this.props.data);
    else if (this.props.mode == "posts")
      this.props.setPostData(this.props.data);
    else if (this.props.mode == "items")
      this.props.setItemData(this.props.data);
    this.setState({ isLoading: false, results: this.props.data, value: "" });
  };
  handleSearchChange = (e) => {
    this.setState({
      isLoading: true,
      searchVal: e.target.value,
      searching: true,
      data: [],
      count: 0,
      page: 0,
      next: null,
    });
    if (e.target.value == "") return this.resetResults();

    const re = new RegExp(_.escapeRegExp(e.target.value), "i");
    const isMatch = (result) => re.test(result.profile.nickname);
    console.log(e.target.value, this.props);
    this.setState({
      isLoading: false,
      results: _.filter(this.props.data, isMatch),
    });
    if (this.props.mode == "following")
      this.props.setFollowing(_.filter(this.props.data, isMatch));
    else if (this.props.mode == "follower")
      this.props.setFollower(_.filter(this.props.data, isMatch));
    else if (this.props.mode == "posts")
      this.props.setPostData(_.filter(this.props.data, isMatch));
    else if (this.props.mode == "items")
      this.props.setItemData(_.filter(this.props.data, isMatch));
  };
  _iconOnClick = () => {
    this.searchBar.focus();
  };
  _onFocus = () => {
    this.setState({ searching: true });
  };
  _onBlur = () => {
    if (this.state.searchVal == "") this.setState({ searching: false });
  };
  loadMore = async () => {
    const data = await this.loadData({
      page: this.state.page + 1,
    });
    return data;
  };
  render() {
    const { open, onClose, onSelect } = this.props;

    const hasMore =
      this.state.searchVal !== "" &&
      (this.state.page === 0 || Boolean(this.state.next));

    return (
      <>
        <Drawer
          ref={this.drawer}
          anchor="bottom"
          open={open}
          onClose={onClose}
          classes={{
            paper: this.props.classes.drawer,
            root: this.props.classes.root,
          }}
        >
          <SearchWrapper>
            {!this.state.searching ? (
              <IconWrapper onClick={this._iconOnClick}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 17 17"
                >
                  <path
                    fill="rgb(190,190,190)"
                    d="M6.5 11C4.01 11 2 8.99 2 6.5S4.01 2 6.5 2 11 4.01 11 6.5 8.99 11 6.5 11zm6 0h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34A6.505 6.505 0 0 0 .05 7.32c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49z"
                  />
                </svg>
                <span>
                  {this.props.placeholder ? this.props.placeholder : "검색"}
                </span>
              </IconWrapper>
            ) : (
              <Fragment />
            )}
            <SearchInput
              ref={(ref) => {
                this.searchBar = ref;
              }}
              onFocus={this._onFocus}
              onBlur={this._onBlur}
              loading={this.state.isLoading}
              onChange={this.handleSearchChange}
              value={this.state.searchVal}
              autoFocus
            />
            <IconButton
              onClick={() => {
                this.setState({
                  searchVal: "",
                  page: 0,
                });
                onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </SearchWrapper>
          <InfiniteScroll
            pageStart={this.state.page}
            loadMore={debounce(this.loadMore, 200)}
            hasMore={hasMore}
            loader={
              <div className="loader" style={{ textAlign: "center" }} key={0} />
            }
            useWindow={false}
          >
            <TabContentWrap>
              <UserResultList
                itemData={this.state.data}
                hideOnBlank
                searchVal={this.state.searchVal}
                type="select"
                onClick={onSelect}
              />
            </TabContentWrap>
          </InfiniteScroll>
        </Drawer>
      </>
    );
  }
}

export default withStyles({
  drawer: {
    height: "100%",
  },
  root: {
    zIndex: "9999999 !important",
  },
})(CommentModal);
