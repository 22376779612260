import axios from 'axios';
import { ROOT_URL } from './config';

export function getMainTags() {
  return axios.get(ROOT_URL + '/api/v1/tag/?ordering=main');
}

export function getCrtTags() {
  return axios.get(ROOT_URL + '/api/v1/tag/?ordering=popular&page=1');
}
export function getPost(req) {
  if (req.headers) {
    return axios.get(ROOT_URL + '/api/v1/post/' + req.id + '/', {
      headers: req.headers,
    });
  }
  return axios.get(ROOT_URL + '/api/v1/post/' + req.id + '/');
}
export function getFirstPost(req) {
  const defaults = {
    ordering: '',
    tag: '',
    page: '',
    event: '',
    profile: '',
    like: '',
    key: '',
    id_of_sum: null,
    following: false,
    page: 1,
    pageSize: 1,
  };
  for (var prop in defaults) {
    req[prop] = typeof req[prop] !== 'undefined' ? req[prop] : defaults[prop];
  }
  let filter =
    '?ordering=' +
    req.ordering +
    '&tag=' +
    req.tag +
    '&event=' +
    req.event +
    '&profile=' +
    req.profile +
    '&like=' +
    req.like +
    '&key=' +
    req.key +
    '&following=' +
    req.following +
    '&page=' +
    req.page +
    '&page_size=' +
    req.pageSize;
  if (req.id_of_sum) {
    filter += '&id_of_sum=';
    filter += req.id_of_sum;
  }

  if ('onlySelected' in req) {
    filter += `&onlySelected=${req.onlySelected}`;
  }

  if (req.category) {
    filter += `&category=${req.category}`;
  }

  const url = req.next || ROOT_URL + '/api/v1/post/' + filter;
  if (req.headers) {
    return axios.get(url, {
      headers: req.headers,
    });
  }
  return axios.get(url);
}
export function getPostList(req) {
  console.log('getPostList', req);
  const defaults = {
    ordering: '',
    tag: '',
    page: '',
    event: '',
    profile: '',
    like: '',
    key: '',
    id_of_sum: null,
    following: false,
    page: 1,
    pageSize: 5,
    hotThreshold: 0,
  };
  for (var prop in defaults) {
    req[prop] = typeof req[prop] !== 'undefined' ? req[prop] : defaults[prop];
  }
  let filter =
    '?ordering=' +
    req.ordering +
    '&tag=' +
    req.tag +
    '&event=' +
    req.event +
    '&profile=' +
    req.profile +
    '&like=' +
    req.like +
    '&key=' +
    req.key +
    '&following=' +
    req.following +
    '&page=' +
    req.page +
    '&page_size=' +
    req.pageSize;
  if (req.hotThreshold) {
    filter += '&hotThreshold=';
    filter += req.hotThreshold;
  }
  if (req.id_of_sum) {
    filter += '&id_of_sum=';
    if (typeof req.id_of_sum === 'string') {
      filter += req.id_of_sum;
    } else {
      filter += req.id_of_sum.join(',');
    }
  }
  if ('onlySelected' in req) {
    filter += `&onlySelected=${req.onlySelected ? 'true' : 'false'}`;
  }
  if ('recommended' in req) {
    filter += `&recommended=${req.recommended}`;
  }

  if (req.category) {
    filter += `&category=${req.category}`;
  }

  const url = req.next || ROOT_URL + '/api/v1/post/' + filter;
  if (req.headers) {
    return axios.get(url, {
      headers: req.headers,
    });
  }
  return axios.get(url);
}

export function getWelcomeMsg() {
  return axios.get(ROOT_URL + '/api/v1/message/welcome/?type=1');
}

export function patchMyLike(req) {
  return axios.patch(
    ROOT_URL + `/api/v1/like/click/?object=${req.object}&id=${req.id}`,
    '',
    { headers: req.headers }
  );
}

export function getPostComments(req) {
  const defaults = {
    post: '',
    parent: '',
    page: 1,
    profile: '',
  };
  for (var prop in defaults) {
    req[prop] = typeof req[prop] !== 'undefined' ? req[prop] : defaults[prop];
  }
  if (req.headers) {
    return axios.get(
      ROOT_URL +
        `/api/v1/comment/?post=${req.post}&parent=${req.parent}&profile=${req.profile}&page=${req.page}`,
      { headers: req.headers }
    );
  }
  return axios.get(
    ROOT_URL +
      `/api/v1/comment/?post=${req.post}&parent=${req.parent}&profile=${req.profile}&page=${req.page}`
  );
}

export function createPostComment(req) {
  const defaults = {
    post: false,
    parent: false,
  };
  for (var prop in defaults) {
    req[prop] = typeof req[prop] !== 'undefined' ? req[prop] : defaults[prop];
  }
  let filter;
  if (req.post) filter = '?post=' + req.post;
  if (req.parent) filter = '?parent=' + req.parent;
  return axios.post(ROOT_URL + `/api/v1/comment/${filter}`, req.data, {
    headers: req.headers,
  });
}

export function checkItemLink(link) {
  const options = {
    method: 'GET',
    params: { link: link },
    url: ROOT_URL + `/api/v1/item/check/`,
  };
  return axios(options);
}

export function createPost(req) {
  return axios.post(ROOT_URL + `/api/v1/post/basic/`, req.data, {
    headers: req.headers,
  });
}

export function createPostApp(req) {
  return axios.post(ROOT_URL + `/api/v1/post/`, req.data, {
    headers: req.headers,
  });
}

export async function deletePost(req) {
  try {
    const found = await axios.get(
      `https://db.lay-er.me/topics?postId=${req.id}`
    );
    let id = found.id || found.data[0].id;
    if (found.data && found.data.length) {
      await axios.delete(`https://db.lay-er.me/topics/${id}`);
    }
  } catch (e) {
    console.error(e);
  }
  return axios.delete(ROOT_URL + `/api/v1/post/${req.id}/`, {
    headers: req.headers,
  });
}

export function patchPost(req) {
  return axios.patch(ROOT_URL + `/api/v1/post/basic/${req.id}/`, req.data, {
    headers: req.headers,
  });
}
export function patchPostApp(req) {
  return axios.patch(ROOT_URL + `/api/v1/post/${req.id}/`, req.data, {
    headers: req.headers,
  });
}
