import React from 'react';
import { Modal } from 'antd';

class _Modal extends React.Component {
	render() {
		return (
			<Modal
				visible={this.props.open}
				onCancel={this.props.onClose}
				closable={false}
				className="_Modal"
				bodyStyle={{
					background: 'red',
				}}
				maskStyle={{
					background: 'red',
				}}
				style={{
					position: 'fixed',
					padding: 0,
					margin: 0,
					width: '100% !important',
					height: '100vh',
					left: 0,
					right: 0,
					top: 0,
					bottom: 0,
					maxWidth: 'none',
				}}
				bodyStyle={{
					padding: 0,
					margin: 0,
					width: '100%',
				}}
				footer={null}
				mask={null}
				afterClose={this.props.afterClose}
				destroyOnClose
			>
				{this.props.children}
			</Modal>
		);
	}
}
export default _Modal;
