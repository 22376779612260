import React from 'react';
import axios from 'axios';
import { observer, inject } from 'mobx-react';
import SearchIcon from '@material-ui/icons/Search';
import styled, { css } from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Sum from 'components/smallSum';
import { Wrap } from 'components/style/custom-styled-components';
import UpperDeco from 'components/upperDeco';
import ProductCategoryModal from 'components/popModal/productCategoryModal';
import ReportModal from 'components/popModal/reportModal';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Footer from 'components/footer';
import Swiper from 'react-id-swiper';
import history from 'utils/history';
import sail from 'images/sail.png';
import PostMoreInfoModal from 'components/popModal/postMoreInfoModal';
import LoginRequiredModal from 'components/popModal/loginRequiredModal';
import getUserOrCreate from 'utils/getUserOrCreate';
import * as postAPI from 'axios/postAPI';
import ProductList from 'components/ProductList';
import List from 'containers/mainListInline';
import cookie from 'js-cookie';
import { v1 } from 'uuid';
import MissionAvailable from '../components/MissionAvailable';
import getAvailableMissions from '../utils/getAvailableMissions';
import { url } from '../utils/starApi';

const base = 'https://star-api.lay-er.me';

@inject('userStore')
@observer
class Sums extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: null,
      selectedSum: null,
      sums: [],
      availableMissions: [],
      availableMissionsClosed: false,
    };
  }
  async componentDidMount() {
    const sums = (await axios.get(`${base}/sums?_sort=priority:ASC`)).data;
    if (this.props.userStore.auth_token) {
      this.setState({ loading: true });

      const sumIdReg = /sumId=(\d+)/.exec(window.location.href);

      const sumId = sumIdReg ? sumIdReg[1] : null;

      try {
        const profile = await getUserOrCreate(this.props.userStore.auth_token);
        if (profile) {
          profile.sums = profile.sums.reverse();
          console.log(profile);
          this.setState({ profile });
          const availableMissions = await getAvailableMissions(profile.userId);
          console.log(availableMissions);
          this.setState({ availableMissions });
        }
      } catch (e) {}

      const selectedSum = sums.find(({ id }) => String(id) === String(sumId));

      this.setState({
        loading: false,
        sums,
        selectedSum,
      });
    } else {
      this.setState({
        sums,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    console.log(this.props.sumId, nextProps.sumId);
    if (this.props.sumId !== nextProps.sumId) {
      const selectedSum = this.state.sums.find(
        ({ id }) => String(id) === String(nextProps.sumId)
      );
      console.log(selectedSum);
      this.setState({
        selectedSum,
      });
    }
  }

  handleClickAdd = () => {
    if (this.props.userStore.getTokenOrToggleLogin())
      history.push(`/new-post?sum=${this.props.match.params.id}`);
  };
  loadAd = (sumId) => {
    axios(`${url}/ad/${sumId}`).then(({ data }) => {
      this.setState({
        ad: data,
      });
    });
  };
  onClickAd = async () => {
    try {
      const post = this.state.postNow;
      const body = {
        userId: null,
        adId: this.state.ad.id,
        postUserId: post.profile.id,
        postUserName: post.profile.nickname,
      };
      if (this.state.profile) {
        body.userId = this.state.profile.id;
      } else {
        let userId = cookie.get('userId');
        if (!userId) {
          cookie.set('userId', v1(), { expires: 1000 });
          userId = cookie.get('userId');
        }
        body.userId = userId;
      }
      await axios(`${url}/ad`, {
        method: 'post',
        data: body,
      });
    } catch (e) {
      alert(JSON.stringify(e));
    }
    // alert(this.state.ad.url);
    window.location.href = this.state.ad.url;
    // window.open(this.state.ad.url);
  };
  loadSum = async (sumId) => {
    const selectedSum = this.state.sums.find(
      ({ id }) => String(id) === String(sumId)
    );
    this.setState({
      selectedSum,
    });
  };
  render() {
    const { classes } = this.props;
    // if (this.state.loading) return <div></div>;

    const isAllSum = !this.state.onlyEntered && !this.state.selectedSum;

    let sumId;
    if (typeof window !== 'undefined') {
      const searchParams = new URLSearchParams(window.location.search);
      sumId = searchParams.get('sumId');
    }
    console.log(this.state.profile);
    return (
      <Wrap
        style={{
          paddingBottom: 0,
          backgroundColor: 'rgb(248,248,248)',
        }}
      >
        <LoginRequiredModal bottom={'0px'} />
        <div
          style={{
            // position: 'fixed',
            // top: 0,
            width: '100%',
            // zIndex: 20000,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              background: 'white',
              paddingTop: 14,
            }}
          >
            <span
              onClick={() => {
                window.location.href =
                  'https://blog.naver.com/pdbly/222165572927';
              }}
              role="button"
              style={{ marginLeft: 14, opacity: 0.94, color: '#afafaf' }}
            >
              가이드 ⓘ
            </span>
            <SearchIcon
              style={{ fontSize: 24, color: '#cbcbcb', marginRight: 14 }}
              onClick={() => history.push('/search')}
            />
          </div>
          {this.state.loading ? (
            <div style={{ height: 140 }} />
          ) : (
            <div className={classes.swiper}>
              <Swiper slidesPerView={4.8} freeMode>
                <div>
                  <Sum
                    name={'마이피드'}
                    onClick={() => {
                      history.push('/');
                      this.setState({
                        onlyEntered: false,
                        selectedSum: null,
                      });
                    }}
                    selected={isAllSum}
                    defaultImg
                  />
                </div>

                {this.state.profile ? (
                  this.state.profile.sums.map(
                    ({ id, name, image, lastUploaded }) => (
                      <div key={id}>
                        <Sum
                          key={name}
                          sumId={id}
                          name={name}
                          onClick={() => {
                            history.push(`/?sumId=${id}`);
                            this.setState({
                              onlyEntered: false,
                            });
                          }}
                          lastUploaded={
                            lastUploaded ? moment(lastUploaded).fromNow() : ''
                          }
                          selected={
                            this.state.selectedSum &&
                            this.state.selectedSum.id === id
                          }
                          image={image}
                        />
                      </div>
                    )
                  )
                ) : (
                  <div></div>
                )}
              </Swiper>
            </div>
          )}
        </div>
        {/* 입주섬 전체 && (프로필 없거나 || (프로필 있는데 섬이 없거나)) */}
        {this.state.onlyEntered &&
        (!this.state.profile ||
          (this.state.profile && this.state.profile.sums.length === 0)) ? (
          <div
            style={{
              marginTop: 0,
              margin: 20,
              background: 'white',
              borderRadius: 24,
              width: 'calc(100% - 40px)',
              textAlign: 'center',
              paddingTop: 50,
              paddingBottom: 36,
            }}
          >
            <img
              src={sail}
              style={{ width: 100, height: 100, marginBottom: 30 }}
            />
            <h2 style={{ marginBottom: 14, fontSize: 22 }}>
              망망대해에서 표류중이세요!
            </h2>
            <p style={{ fontSize: 16, marginBottom: '0.2em' }}>
              마음에 드는 섬을 찾아
            </p>
            <p style={{ fontSize: 16, marginBottom: '0.2em' }}>
              입주해 보세요!
            </p>
            <p style={{ fontSize: 16, marginBottom: '0.2em' }}>
              (상어가 쫓아와요)!
            </p>
          </div>
        ) : (
          <div style={{ marginTop: 0 }}>
            <List
              isAllSum={isAllSum}
              sums={this.state.sums}
              sumId={
                sumId ||
                (this.state.selectedSum
                  ? this.state.selectedSum.id
                  : this.state.profile &&
                    this.state.onlyEntered &&
                    this.state.profile.sums.map(({ id }) => id)) ||
                null
              }
              onClickPostMore={(post) => {
                this.setState({
                  postMoreInfoOpen: true,
                  postNow: post,
                  sumId: Number(post.id_of_sum),
                });
              }}
              onClickProducts={(post) => {
                if (post.item.length) {
                  this.loadAd(post.id_of_sum);
                  this.setState({
                    productsOpen: true,
                    postNow: post,
                  });
                }
              }}
            />
          </div>
        )}

        <ProductCategoryModal
          open={this.state.productCategoryModalOpen}
          handleClose={() =>
            this.setState({
              productCategoryModalOpen: false,
            })
          }
          onClick={(selected) => {
            if (this.props.userStore.getTokenOrToggleLogin())
              history.push(`/new-post?selected=${selected}`);
          }}
          bottom={'0px'}
        />
        <PostMoreInfoModal
          open={this.state.postMoreInfoOpen}
          sumId={this.state.postNow && this.state.postNow.id_of_sum}
          handleClose={() =>
            this.setState({
              postMoreInfoOpen: false,
            })
          }
          post={this.state.postNow}
          bottom={'0px'}
          isMine={
            this.state.postNow &&
            this.state.profile &&
            this.state.postNow.profile.id === this.state.profile.userId
          }
        />
        <div>
          <ProductList
            on={this.state.productsOpen}
            onClose={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.setState({ productsOpen: false });
            }}
            items={this.state.postNow ? this.state.postNow.item : []}
            ad={this.state.ad}
            onClickAd={this.onClickAd}
          />
        </div>
        <ReportModal
          open={this.state.reportModalOn}
          handleClose={() =>
            this.setState({
              reportModalOn: false,
            })
          }
          bottom={'0px'}
        />
        {this.state.availableMissions &&
        this.state.availableMissions.length &&
        !this.state.availableMissionsClosed ? (
          <MissionAvailable
            items={this.state.availableMissions}
            onClose={() => {
              this.setState({ availableMissionsClosed: true });
            }}
          />
        ) : null}
      </Wrap>
    );
  }
}

export default withStyles({
  swiper: {
    '& .swiper-container': {
      overflow: 'hidden',
    },
    padding: '8px 0px 16px',

    marginBottom: 12,
    background: 'white',
    '& .swiper-wrapper': {
      marginLeft: 8,
      // paddingLeft: 8,
      // paddingLeft: 4,
      // paddingRight: 4,
    },
  },
})(Sums);
