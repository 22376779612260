import React, { Component } from 'react';
import styled, { css } from 'styled-components';

const IconWrap = styled.i`
	svg {
		vertical-align: middle;
	}
`;

class MutedIcon extends Component {
	constructor() {
		super();
	}

	render() {
		return (
			<IconWrap>
				{this.props.muted ? (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="23"
						height="22"
						viewBox="0 0 23 22"
					>
						<g>
							<g>
								<g />
								<g>
									<g>
										<path
											fill="#fff"
											d="M11.193 19.875c-2.032-1.713-4.977-4.201-5.216-4.422a1.297 1.297 0 0 0-.919-.379H2.076v-7.98H5.06c.334 0 .667-.14.908-.368.236-.227 3.18-2.801 5.226-4.586v17.735zm.373-19.29l-.095-.003c-.215 0-.462.065-.744.237 0 0-5.432 4.737-5.667 4.972H2.076c-.72 0-1.303.583-1.303 1.303v7.98c0 .719.583 1.303 1.303 1.303H5.06c.235.234 5.667 4.805 5.667 4.805.282.172.529.24.744.24l.096-.004c.481-.047.825-.449.929-.877V1.462c-.102-.427-.448-.83-.93-.877z"
										/>
										<path
											fill="none"
											stroke="#fff"
											strokeMiterlimit="50"
											strokeWidth=".6"
											d="M11.193 19.875c-2.032-1.713-4.977-4.201-5.216-4.422a1.297 1.297 0 0 0-.919-.379H2.076v-7.98H5.06c.334 0 .667-.14.908-.368.236-.227 3.18-2.801 5.226-4.586v17.735zm.373-19.29l-.095-.003c-.215 0-.462.065-.744.237 0 0-5.432 4.737-5.667 4.972H2.076c-.72 0-1.303.583-1.303 1.303v7.98c0 .719.583 1.303 1.303 1.303H5.06c.235.234 5.667 4.805 5.667 4.805.282.172.529.24.744.24l.096-.004c.481-.047.825-.449.929-.877V1.462c-.102-.427-.448-.83-.93-.877z"
										/>
									</g>
									<g>
										<path
											fill="#fff"
											d="M19.072 11.015l2.159-2.177a.772.772 0 0 0 0-1.088.756.756 0 0 0-1.079 0l-2.158 2.176-2.16-2.176a.755.755 0 0 0-1.077 0 .778.778 0 0 0 0 1.088l2.16 2.177-2.16 2.178a.778.778 0 0 0 0 1.087c.297.3.782.3 1.078 0l2.16-2.175 2.157 2.176c.298.3.781.3 1.079 0a.778.778 0 0 0 0-1.087z"
										/>
										<path
											fill="none"
											stroke="#fff"
											strokeMiterlimit="50"
											strokeWidth=".4"
											d="M19.072 11.015v0l2.159-2.177a.772.772 0 0 0 0-1.088.756.756 0 0 0-1.079 0l-2.158 2.176v0l-2.16-2.176a.755.755 0 0 0-1.077 0 .778.778 0 0 0 0 1.088l2.16 2.177v0l-2.16 2.178a.778.778 0 0 0 0 1.087c.297.3.782.3 1.078 0l2.16-2.175v0l2.157 2.176c.298.3.781.3 1.079 0a.778.778 0 0 0 0-1.087z"
										/>
									</g>
								</g>
							</g>
						</g>
					</svg>
				) : (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="22"
						height="22"
						viewBox="0 0 22 22"
					>
						<g>
							<g>
								<g />
								<g>
									<g>
										<path
											fill="#fff"
											d="M11.193 19.875c-2.032-1.713-4.977-4.201-5.216-4.422a1.297 1.297 0 0 0-.919-.379H2.076v-7.98H5.06c.334 0 .667-.14.908-.368.236-.227 3.18-2.801 5.226-4.586v17.735zm.373-19.29l-.095-.003c-.215 0-.462.065-.744.237 0 0-5.432 4.737-5.667 4.972H2.076c-.72 0-1.303.583-1.303 1.303v7.98c0 .719.583 1.303 1.303 1.303H5.06c.235.234 5.667 4.805 5.667 4.805.282.172.529.24.744.24l.096-.004c.481-.047.825-.449.929-.877V1.462c-.102-.427-.448-.83-.93-.877z"
										/>
										<path
											fill="none"
											stroke="#fff"
											strokeMiterlimit="50"
											strokeWidth=".6"
											d="M11.193 19.875c-2.032-1.713-4.977-4.201-5.216-4.422a1.297 1.297 0 0 0-.919-.379H2.076v-7.98H5.06c.334 0 .667-.14.908-.368.236-.227 3.18-2.801 5.226-4.586v17.735zm.373-19.29l-.095-.003c-.215 0-.462.065-.744.237 0 0-5.432 4.737-5.667 4.972H2.076c-.72 0-1.303.583-1.303 1.303v7.98c0 .719.583 1.303 1.303 1.303H5.06c.235.234 5.667 4.805 5.667 4.805.282.172.529.24.744.24l.096-.004c.481-.047.825-.449.929-.877V1.462c-.102-.427-.448-.83-.93-.877z"
										/>
									</g>
									<g>
										<path
											fill="#fff"
											d="M15.101 13.194a.628.628 0 1 0 .563 1.12c1.27-.64 1.962-1.908 1.962-3.315 0-1.406-.692-2.673-1.962-3.313a.627.627 0 1 0-.563 1.121c.842.424 1.27 1.264 1.27 2.192-.001.931-.429 1.77-1.27 2.195z"
										/>
										<path
											fill="none"
											stroke="#fff"
											strokeMiterlimit="50"
											strokeWidth=".6"
											d="M15.101 13.194a.628.628 0 1 0 .563 1.12c1.27-.64 1.962-1.908 1.962-3.315 0-1.406-.692-2.673-1.962-3.313a.627.627 0 1 0-.563 1.121c.842.424 1.27 1.264 1.27 2.192-.001.931-.429 1.77-1.27 2.195z"
										/>
									</g>
									<g>
										<path
											fill="#fff"
											d="M14.972 3.306a.625.625 0 0 0-.734.496.623.623 0 0 0 .494.736c3.093.604 5.24 3.323 5.24 6.466 0 3.146-2.147 5.862-5.24 6.467a.623.623 0 0 0-.494.735c.058.3.321.505.612.505l.122-.014c3.681-.715 6.255-3.951 6.255-7.695 0-3.742-2.575-6.979-6.255-7.696z"
										/>
										<path
											fill="none"
											stroke="#fff"
											strokeMiterlimit="50"
											strokeWidth=".6"
											d="M14.972 3.306a.625.625 0 0 0-.734.496.623.623 0 0 0 .494.736c3.093.604 5.24 3.323 5.24 6.466 0 3.146-2.147 5.862-5.24 6.467a.623.623 0 0 0-.494.735c.058.3.321.505.612.505l.122-.014c3.681-.715 6.255-3.951 6.255-7.695 0-3.742-2.575-6.979-6.255-7.696z"
										/>
									</g>
								</g>
							</g>
						</g>
					</svg>
				)}
			</IconWrap>
		);
	}
}

export default MutedIcon;
