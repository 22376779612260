import React, { Component } from 'react';

class HomeIcon extends Component {
	constructor() {
		super();
	}

	render() {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="26"
				height="18"
				viewBox="0 0 26 18"
				{...this.props}
			>
				<path
					d="M-180.353,170.925l-4.331,8.454-1.273-2.118a1.176,1.176,0,0,0-2.109,0l-5.652,9.4a.925.925,0,0,0,0,.925h0a.6.6,0,0,0,.5.312h24.362a.535.535,0,0,0,.469-.582h0a.678.678,0,0,0-.093-.348l-9.709-16.142A1.185,1.185,0,0,0-180.353,170.925Z"
					transform="translate(193.843 -170.169)"
				/>
			</svg>
		);
	}
}

export default HomeIcon;
