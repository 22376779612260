import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { Tabs } from "antd-mobile";
import { observer, inject } from "mobx-react";
import axios from "axios";

import { Wrap, IndexWrap } from "components/style/custom-styled-components";
import LoadingSpinner from "components/loadingSpinner/loadingSpinner";
import LoginRequiredModal from "components/popModal/loginRequiredModal";
import FollowBtn from "components/followBtn";
import SearchBar from "components/searchBar";

import * as userAPI from "axios/userAPI";
import history from "utils/history";
import back_ico from "images/back_ico_b.svg";
import default_profile from "images/default_profile.png";

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  line-height: 56px;
  height: 56px;
  width: 100%;
  max-width: 1024px;
  z-index: 3;
  top: 0;
  background: rgb(248, 248, 248);

  div.center-title {
    color: #333333;
    font-family: "Noto Sans CJK KR";
    font-size: 15px;
    font-weight: 700;
  }
`;
const IconWrap = styled.div`
  position: absolute;
  top: 18px;
  right: 18px;
`;

const ProfileWrap = styled.div`
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  p.profile-name {
    color: #333333;
    font-family: "Noto Sans CJK KR";
    font-size: 16px;
    font-weight: 700;
  }
  p.profile-intro {
    color: #999999;
    font-family: "Noto Sans CJK KR";
    font-size: 14px;
    font-weight: 500;
  }
  span.profile-btn {
    display: inline-block;
    text-align: center;
    width: 64px;
    height: 28px;
    border-radius: 20px;
    vertical-align: middle;
    font-family: "Noto Sans CJK KR";
    font-size: 13px;
    font-weight: 700;
    line-height: 28px;
    cursor: pointer;
    hover: {
      opacity: 0.8;
    }
  }
  span.profile-follow-btn {
    background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
    color: #ffffff;
  }
  span.profile-following-btn {
    background-color: rgba(130, 143, 247, 0.2);
    color: #828ff7;
  }
`;
const ProfileImage = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  display: flex;
  margin-right: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  ${(props) =>
    props.isDefaultProfileImg &&
    css`
      background-color: white;
    `}
  div {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    ${(props) =>
      props.isDefaultProfileImg &&
      css`
        margin: auto;
        width: 32px;
        height: 32px;
        border-radius: 0;
      `}
  }
`;
const InfoCard = styled.div`
  width: 100%;
  height: 140px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;

  p {
    width: 100%;
    height: 47px;
    padding: 14px 16px;
    border-bottom: solid 1px #eeeeee;
    margin: 0;
    span.profile-grade {
      color: #bebebe;
      font-family: "Noto Sans CJK KR";
      font-size: 14px;
      font-weight: 500;
      line-height: 30px;
    }
    span.profile-grade-data {
      margin-left: 16px;
      color: #828ff7;
      font-family: "Noto Sans CJK KR";
      font-size: 14px;
      font-weight: 700;
      line-height: 30px;
      text-transform: uppercase;
    }
    span.profile-grade-icon {
      float: right;
    }
  }
`;

@inject("userStore")
@observer
class UserFollow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResult: [],
      data: [],
      search_mode: true,
    };
  }
  async componentDidMount() {
    const req = {
      id: this.props.match.params.id,
      headers: this.props.userStore.auth_token
        ? { Authorization: `Bearer ${this.props.userStore.auth_token}` }
        : false,
    };
    const result = await userAPI.getLikeUser(req);
    let myId = undefined;
    if (this.props.userStore.auth_token) {
      myId = await userAPI.getMyUserProfile(req).then((res) => {
        return res.data.id;
      });
    }
    this.setState({ data: result.data, searchResult: result.data, myId });
    // const data = await Promise.all([
    // 	userAPI.getUserFollowing(req).then(res => {
    // 		return res.data;
    // 	}),
    // ]);
    // const followerData = await Promise.all([
    // 	userAPI.getUserFollower(req).then(res => {
    // 		return res.data;
    // 	}),
    // ]);
    // this.setState({
    // 	data: data[0],
    // 	followingResults: data[0],
    // 	followerData: followerData[0],
    // 	followerResults: followerData[0],
    // 	myId: myId,
    // });
  }

  _setFollowingSearchResults = (results) => {
    this.setState({
      searchResult: results,
    });
  };
  _setFollowerSearchResults = (results) => {
    this.setState({
      followerResults: results,
    });
  };
  userClick = async (id) => {
    if (this.props.userStore.auth_token) {
      if (this.state.myId == id) {
        history.push(`/my-page`);
      } else {
        history.push(`/profile/${id}`);
      }
    } else {
      history.push(`/profile/${id}`);
    }
  };
  render() {
    const {
      data,
      followerData,
      followingResults,
      followerResults,
    } = this.state;

    return (
      <Wrap style={{ paddingBottom: 0, backgroundColor: "rgb(248,248,248)" }}>
        <LoginRequiredModal bottom={"0px"} />
        <IndexWrap style={{ paddingTop: 56, backgroundColor: "#f8f8f8" }}>
          <NavBar>
            <div style={{ width: "33.3333%" }}>
              <img
                src={back_ico}
                onClick={() => {
                  history.goBack();
                }}
                style={{
                  marginLeft: 19,
                  width: 19,
                  verticalAlign: "middle",
                  cursor: "pointer",
                }}
              />
            </div>
            <div
              className="center-title"
              style={{ width: "33.3333%", textAlign: "center" }}
            ></div>
            <div style={{ width: "33.3333%", textAlign: "right" }}>
              {
                //<img src={search_ico} onClick={this._searchOnClick} style={{marginRight:19, width:17, verticalAlign:'middle', cursor:'pointer'}}/>
              }
            </div>
          </NavBar>
          <div>
            {this.state.search_mode ? (
              <SearchBar
                mode="following"
                data={data}
                setFollowing={this._setFollowingSearchResults}
                setFollower={this._setFollowerSearchResults}
                placeholder="아이디 검색"
              />
            ) : (
              <Fragment />
            )}
            {this.state.searchResult ? (
              <Fragment>
                {this.state.searchResult.map((user, index) => (
                  <ProfileWrap key={index}>
                    <ProfileImage
                      isDefaultProfileImg={user.profile.image ? false : true}
                      onClick={() => {
                        this.userClick(user.profile.id);
                      }}
                    >
                      <div
                        style={{
                          backgroundImage: `url('${
                            user.profile.image
                              ? user.profile.image
                              : default_profile
                          }')`,
                        }}
                      />
                    </ProfileImage>
                    <div style={{ flex: "1 1 auto", overflow: "hidden" }}>
                      <p className="profile-name"> {user.profile.nickname} </p>
                      <p className="profile-intro">
                        {" "}
                        {user.profile.introduction}{" "}
                      </p>
                    </div>
                    {this.state.myId != user.profile.id ? (
                      <FollowBtn
                        accessToken={this.state.accessToken}
                        profile={user.profile}
                        is_followed={user.profile.is_followed}
                      />
                    ) : (
                      <Fragment />
                    )}
                  </ProfileWrap>
                ))}
              </Fragment>
            ) : (
              <LoadingSpinner />
            )}
          </div>
        </IndexWrap>
      </Wrap>
    );
  }
}
export default UserFollow;
