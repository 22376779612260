import React, { Component, Fragment } from "react";
import styled, { css } from "styled-components";
import { observer, inject } from "mobx-react";
import history from "utils/history";

const Wrapper = styled.div`
  width: 100%;
  max-width: 480px;
  height: 100vh;
  top: 0px;
  z-index: -1;
  position: fixed;
  transition: 0.2s background-color ease, z-index 0.2s ease;

  ${props =>
    props.active &&
    css`
      z-index: 1002;
      background-color: rgba(0, 0, 0, 0.5);
      transition: 0.2s background-color ease;
    `}
`;
const Modal = styled.div`
  max-width: 480px;
  padding: 16px;
  position: fixed;
  bottom: -400px;
  width: 100%;
  height: 323px;
  background: #ffffff;
  transition: 0.2s ease;
  z-index: 1002;
  ${props =>
    props.active &&
    css`
      bottom: ${props.bottom ? props.bottom : "48px"};
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.4);
      border-radius: 20px 20px 0 0;
    `}
`;
const OutSideWrap = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0px;
  position: fixed;
  background: transparent;
`;
const IconWrapper = styled.div`
  width: 104px;
  height: 104px;
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 24px;
  background-color: rgba(130, 143, 247, 0.1);
  border-radius: 50%;
  text-align: center;
  i {
    vertical-align: middle;
    width: 100%;
    height: 64px;
  }
  svg {
    margin: auto;
    vertical-align: text-top;
  }
`;
const BigTextWrap = styled.p`
  margin-top: 16px;
  margin-bottom: 0px;
  text-align: center;
  width: 100%;
  color: #333333;
  font-family: "Noto Sans CJK KR";
  font-size: 18px;
  font-weight: 700;
`;
const SmallTextWrap = styled.p`
  margin-top: 3px;
  margin-bottom: 31px;
  text-align: center;
  width: 100%;
  color: #bebebe;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 500;
`;
const Btn = styled.div`
  width: 100%;
  height: 44px;
  box-shadow: 0 2px 10px rgba(176, 141, 247, 0.5);
  border-radius: 68px;
  background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
  color: #ffffff;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  line-height: 44px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

@inject("userStore")
@observer
class NotOnServiceModal extends Component {
  constructor() {
    super();
    this.state = {};
  }
  _toggle = () => {
    window.location.reload();
  };
  render() {
    return (
      <Wrapper active={this.props.userStore.notOnServiceToggle}>
        <OutSideWrap onClick={this._toggle} />
        <Modal
          active={this.props.userStore.notOnServiceToggle}
          bottom={this.props.bottom}
        >
          <IconWrapper>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="67"
              height="60"
              viewBox="0 0 67 60"
            >
              <defs>
                <mask id="sq9ab" width="2" height="2" x="-1" y="-1">
                  <path fill="#fff" d="M14 7h18v26H14z" />
                  <path d="M17.036 27.108c-.715 1.723-1.497 3.418-2.302 5.234h17.023c-.805-1.816-1.588-3.511-2.302-5.234a4.741 4.741 0 0 1-.373-1.738c-.003-.447.004-12.045-.03-15.947h1.059c.686 0 1.243-.543 1.243-1.212 0-.668-.557-1.21-1.243-1.21H16.37c-.686 0-1.242.542-1.242 1.21 0 .67.556 1.212 1.242 1.212h1.067c-.033 3.902-.026 15.5-.029 15.947a4.75 4.75 0 0 1-.373 1.738z" />
                </mask>
                <filter
                  id="sq9aa"
                  width="58"
                  height="68"
                  x="-6"
                  y="-13"
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset
                    dy="2"
                    in="SourceGraphic"
                    result="FeOffset1029Out"
                  />
                  <feGaussianBlur
                    in="FeOffset1029Out"
                    result="FeGaussianBlur1030Out"
                    stdDeviation="4 4"
                  />
                </filter>
                <mask id="sq9ad" width="2" height="2" x="-1" y="-1">
                  <path fill="#fff" d="M8 34h31v15H8z" />
                  <path d="M32.618 34.84h-18.75S8.582 46.053 8.273 46.767c-.628 1.452-.173 2.146 1.384 2.147 4.504.004 9.009.001 13.513.001H23.315c4.505 0 9.01.003 13.515-.001 1.556-.001 2.011-.695 1.384-2.147-.31-.714-5.596-11.929-5.596-11.929z" />
                </mask>
                <filter
                  id="sq9ac"
                  width="71"
                  height="57"
                  x="-12"
                  y="14"
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset
                    dy="2"
                    in="SourceGraphic"
                    result="FeOffset1045Out"
                  />
                  <feGaussianBlur
                    in="FeOffset1045Out"
                    result="FeGaussianBlur1046Out"
                    stdDeviation="4 4"
                  />
                </filter>
                <linearGradient
                  id="sq9ae"
                  x1="14.07"
                  x2="38.49"
                  y1="34.84"
                  y2="48.92"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stopColor="#828ff7" />
                  <stop offset="1" stopColor="#b08df7" />
                </linearGradient>
                <mask id="sq9ag" width="2" height="2" x="-1" y="-1">
                  <path fill="#fff" d="M36 9h23v40H36z" />
                  <path d="M50.867 9.868l-13.271 3.556c-.663.178-1.06.846-.887 1.492.173.646.851 1.026 1.514.848l.956-.256 7.651 28.555.012.05a5.881 5.881 0 0 0 11.362-3.045l-.014-.045-7.652-28.559.956-.256c.663-.178 1.06-.846.887-1.492-.173-.646-.851-1.026-1.514-.848z" />
                </mask>
                <filter
                  id="sq9af"
                  width="63"
                  height="82"
                  x="16"
                  y="-11"
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset
                    dy="2"
                    in="SourceGraphic"
                    result="FeOffset1064Out"
                  />
                  <feGaussianBlur
                    in="FeOffset1064Out"
                    result="FeGaussianBlur1065Out"
                    stdDeviation="4 4"
                  />
                </filter>
                <linearGradient
                  id="sq9ah"
                  x1="39.25"
                  x2="61.62"
                  y1="13.3"
                  y2="46.56"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stopColor="#828ff7" />
                  <stop offset="1" stopColor="#b08df7" />
                </linearGradient>
                <mask id="sq9aj" width="2" height="2" x="-1" y="-1">
                  <path fill="#fff" d="M20 26h3v3h-3z" />
                  <path d="M20 27.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0z" />
                </mask>
                <filter
                  id="sq9ai"
                  width="43"
                  height="45"
                  x="0"
                  y="6"
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset
                    dy="2"
                    in="SourceGraphic"
                    result="FeOffset1117Out"
                  />
                  <feGaussianBlur
                    in="FeOffset1117Out"
                    result="FeGaussianBlur1118Out"
                    stdDeviation="4 4"
                  />
                </filter>
                <linearGradient
                  id="sq9ak"
                  x1="20.6"
                  x2="23"
                  y1="26"
                  y2="29"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stopColor="#828ff7" />
                  <stop offset="1" stopColor="#b08df7" />
                </linearGradient>
                <mask id="sq9am" width="2" height="2" x="-1" y="-1">
                  <path fill="#fff" d="M24 21h2v2h-2z" />
                  <path d="M24 22a1 1 0 1 1 2 0 1 1 0 0 1-2 0z" />
                </mask>
                <filter
                  id="sq9al"
                  width="42"
                  height="44"
                  x="4"
                  y="1"
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset
                    dy="2"
                    in="SourceGraphic"
                    result="FeOffset1136Out"
                  />
                  <feGaussianBlur
                    in="FeOffset1136Out"
                    result="FeGaussianBlur1137Out"
                    stdDeviation="4 4"
                  />
                </filter>
                <linearGradient
                  id="sq9an"
                  x1="24.4"
                  x2="26"
                  y1="21"
                  y2="23"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stopColor="#828ff7" />
                  <stop offset="1" stopColor="#b08df7" />
                </linearGradient>
                <mask id="sq9ap" width="2" height="2" x="-1" y="-1">
                  <path fill="#fff" d="M22 17h2v2h-2z" />
                  <path d="M22 18a1 1 0 1 1 2 0 1 1 0 0 1-2 0z" />
                </mask>
                <filter
                  id="sq9ao"
                  width="42"
                  height="44"
                  x="2"
                  y="-3"
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset
                    dy="2"
                    in="SourceGraphic"
                    result="FeOffset1155Out"
                  />
                  <feGaussianBlur
                    in="FeOffset1155Out"
                    result="FeGaussianBlur1156Out"
                    stdDeviation="4 4"
                  />
                </filter>
                <linearGradient
                  id="sq9aq"
                  x1="22.4"
                  x2="24"
                  y1="17"
                  y2="19"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stopColor="#828ff7" />
                  <stop offset="1" stopColor="#b08df7" />
                </linearGradient>
              </defs>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g filter="url(#sq9aa)">
                          <path
                            fill="none"
                            d="M17.036 27.108c-.715 1.723-1.497 3.418-2.302 5.234h17.023c-.805-1.816-1.588-3.511-2.302-5.234a4.741 4.741 0 0 1-.373-1.738c-.003-.447.004-12.045-.03-15.947h1.059c.686 0 1.243-.543 1.243-1.212 0-.668-.557-1.21-1.243-1.21H16.37c-.686 0-1.242.542-1.242 1.21 0 .67.556 1.212 1.242 1.212h1.067c-.033 3.902-.026 15.5-.029 15.947a4.75 4.75 0 0 1-.373 1.738z"
                            mask='url("#sq9ab")'
                          />
                          <path
                            fill="#938ef7"
                            fillOpacity=".5"
                            d="M17.036 27.108c-.715 1.723-1.497 3.418-2.302 5.234h17.023c-.805-1.816-1.588-3.511-2.302-5.234a4.741 4.741 0 0 1-.373-1.738c-.003-.447.004-12.045-.03-15.947h1.059c.686 0 1.243-.543 1.243-1.212 0-.668-.557-1.21-1.243-1.21H16.37c-.686 0-1.242.542-1.242 1.21 0 .67.556 1.212 1.242 1.212h1.067c-.033 3.902-.026 15.5-.029 15.947a4.75 4.75 0 0 1-.373 1.738z"
                          />
                        </g>
                        <path
                          fill="#fff"
                          d="M17.036 27.108c-.715 1.723-1.497 3.418-2.302 5.234h17.023c-.805-1.816-1.588-3.511-2.302-5.234a4.741 4.741 0 0 1-.373-1.738c-.003-.447.004-12.045-.03-15.947h1.059c.686 0 1.243-.543 1.243-1.212 0-.668-.557-1.21-1.243-1.21H16.37c-.686 0-1.242.542-1.242 1.21 0 .67.556 1.212 1.242 1.212h1.067c-.033 3.902-.026 15.5-.029 15.947a4.75 4.75 0 0 1-.373 1.738z"
                        />
                      </g>
                      <g>
                        <g filter="url(#sq9ac)">
                          <path
                            fill="none"
                            d="M32.618 34.84h-18.75S8.582 46.053 8.273 46.767c-.628 1.452-.173 2.146 1.384 2.147 4.504.004 9.009.001 13.513.001H23.315c4.505 0 9.01.003 13.515-.001 1.556-.001 2.011-.695 1.384-2.147-.31-.714-5.596-11.929-5.596-11.929z"
                            mask='url("#sq9ad")'
                          />
                          <path
                            fill="#938ef7"
                            fillOpacity=".5"
                            d="M32.618 34.84h-18.75S8.582 46.053 8.273 46.767c-.628 1.452-.173 2.146 1.384 2.147 4.504.004 9.009.001 13.513.001H23.315c4.505 0 9.01.003 13.515-.001 1.556-.001 2.011-.695 1.384-2.147-.31-.714-5.596-11.929-5.596-11.929z"
                          />
                        </g>
                        <path
                          fill="url(#sq9ae)"
                          d="M32.618 34.84h-18.75S8.582 46.053 8.273 46.767c-.628 1.452-.173 2.146 1.384 2.147 4.504.004 9.009.001 13.513.001H23.315c4.505 0 9.01.003 13.515-.001 1.556-.001 2.011-.695 1.384-2.147-.31-.714-5.596-11.929-5.596-11.929z"
                        />
                      </g>
                      <g>
                        <g filter="url(#sq9af)">
                          <path
                            fill="none"
                            d="M50.867 9.868l-13.271 3.556c-.663.178-1.06.846-.887 1.492.173.646.851 1.026 1.514.848l.956-.256 7.651 28.555.012.05a5.881 5.881 0 0 0 11.362-3.045l-.014-.045-7.652-28.559.956-.256c.663-.178 1.06-.846.887-1.492-.173-.646-.851-1.026-1.514-.848z"
                            mask='url("#sq9ag")'
                          />
                          <path
                            fill="#938ef7"
                            fillOpacity=".5"
                            d="M50.867 9.868l-13.271 3.556c-.663.178-1.06.846-.887 1.492.173.646.851 1.026 1.514.848l.956-.256 7.651 28.555.012.05a5.881 5.881 0 0 0 11.362-3.045l-.014-.045-7.652-28.559.956-.256c.663-.178 1.06-.846.887-1.492-.173-.646-.851-1.026-1.514-.848z"
                          />
                        </g>
                        <path
                          fill="url(#sq9ah)"
                          d="M50.867 9.868l-13.271 3.556c-.663.178-1.06.846-.887 1.492.173.646.851 1.026 1.514.848l.956-.256 7.651 28.555.012.05a5.881 5.881 0 0 0 11.362-3.045l-.014-.045-7.652-28.559.956-.256c.663-.178 1.06-.846.887-1.492-.173-.646-.851-1.026-1.514-.848z"
                        />
                      </g>
                    </g>
                    <g>
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g>
                        <path
                          fill="#fff"
                          d="M47.735 20.168a.99.99 0 0 0-.695 1.216.99.99 0 0 0 1.21.707l4.547-1.219-.515-1.923z"
                        />
                      </g>
                      <g>
                        <path
                          fill="#fff"
                          d="M49.241 25.79a.99.99 0 0 0-.694 1.217.99.99 0 0 0 1.21.707l4.547-1.218-.516-1.924z"
                        />
                      </g>
                      <g>
                        <path
                          fill="#fff"
                          d="M50.748 31.414a.99.99 0 0 0-.694 1.216.99.99 0 0 0 1.21.707l4.546-1.219-.515-1.923z"
                        />
                      </g>
                      <g>
                        <path
                          fill="#fff"
                          d="M52.255 37.036a.99.99 0 0 0-.694 1.217.99.99 0 0 0 1.209.707l4.547-1.219-.515-1.923z"
                        />
                      </g>
                    </g>
                  </g>
                  <g>
                    <g filter="url(#sq9ai)">
                      <path
                        fill="none"
                        d="M20 27.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0z"
                        mask='url("#sq9aj")'
                      />
                      <path
                        fill="#938ef7"
                        fillOpacity=".5"
                        d="M20 27.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0z"
                      />
                    </g>
                    <path
                      fill="url(#sq9ak)"
                      d="M20 27.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0z"
                    />
                  </g>
                  <g>
                    <g filter="url(#sq9al)">
                      <path
                        fill="none"
                        d="M24 22a1 1 0 1 1 2 0 1 1 0 0 1-2 0z"
                        mask='url("#sq9am")'
                      />
                      <path
                        fill="#938ef7"
                        fillOpacity=".5"
                        d="M24 22a1 1 0 1 1 2 0 1 1 0 0 1-2 0z"
                      />
                    </g>
                    <path
                      fill="url(#sq9an)"
                      d="M24 22a1 1 0 1 1 2 0 1 1 0 0 1-2 0z"
                    />
                  </g>
                  <g>
                    <g filter="url(#sq9ao)">
                      <path
                        fill="none"
                        d="M22 18a1 1 0 1 1 2 0 1 1 0 0 1-2 0z"
                        mask='url("#sq9ap")'
                      />
                      <path
                        fill="#938ef7"
                        fillOpacity=".5"
                        d="M22 18a1 1 0 1 1 2 0 1 1 0 0 1-2 0z"
                      />
                    </g>
                    <path
                      fill="url(#sq9aq)"
                      d="M22 18a1 1 0 1 1 2 0 1 1 0 0 1-2 0z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </IconWrapper>
          <BigTextWrap>이 기능은 준비중이에요!</BigTextWrap>
          <SmallTextWrap>
            빠른 시일 내로 업데이트할 테니
            <br />
            조금만 더 기다려주세요.
          </SmallTextWrap>
          <Btn onClick={this._toggle}>기다릴게요</Btn>
        </Modal>
      </Wrapper>
    );
  }
}

export default NotOnServiceModal;
