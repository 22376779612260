import axios from "axios";
import { ROOT_URL } from "./config";

export function postConvertToken(req) {
  return axios.post(ROOT_URL + "/api/v1/login/", req);
}
export function postConvertTokenNaver(req) {
  return axios.post(ROOT_URL + `/api/v1/naver/login/`, req);
}
export function patchUserProfile(req) {
  /* req = {
			headers: {
				Authorization: ...
			},
			data: {
				...
			}
		} */
  return axios.patch(ROOT_URL + "/api/v1/profile/me/", req.data, {
    headers: req.headers,
  });
}
export function getLikeUser(req) {
  return axios.get(ROOT_URL + `/api/v1/social/favoritedBy/?post=${req.id}`, {
    headers: req.headers,
  });
}
export function getMyUserProfile(req) {
  return axios.get(ROOT_URL + "/api/v1/profile/me/", { headers: req.headers });
}
export function getUserItemList(req) {
  return axios.get(ROOT_URL + `/api/v1/item/?profile=${req.profile}`, {
    headers: req.headers,
  });
}
export function getUserLikeItemList(req) {
  return axios.get(ROOT_URL + `/api/v1/item/?like=${req.like}`, {
    headers: req.headers,
  });
}
export function getStyleList() {
  return axios.get(ROOT_URL + "/api/v1/style/");
}

export function getUserFollowing(req) {
  if (req.headers) {
    return axios.get(
      ROOT_URL + `/api/v1/social/following/?profile=${req.profile}`,
      { headers: req.headers }
    );
  }
  return axios.get(
    ROOT_URL + `/api/v1/social/following/?profile=${req.profile}`
  );
}

export function getUserFollower(req) {
  if (req.headers) {
    return axios.get(
      ROOT_URL + `/api/v1/social/follower/?profile=${req.profile}`,
      { headers: req.headers }
    );
  }
  return axios.get(
    ROOT_URL + `/api/v1/social/follower/?profile=${req.profile}`
  );
}

export function patchFollowUpdate(req) {
  return axios.patch(ROOT_URL + `/api/v1/social/${req.profile}/`, "", {
    headers: req.headers,
  });
}

export function getNotify(req) {
  return axios.get(ROOT_URL + "/api/v1/notify/", { headers: req.headers });
}

export function getLikableItems(req) {
  return axios.get(
    ROOT_URL + `/api/v1/item/?likable=${req.likable}&page=${req.page}`,
    { headers: req.headers }
  );
}
export function getLikableProfiles(req) {
  return axios.get(
    ROOT_URL + `/api/v1/profile/?page=${req.page}&likable=${req.likable}`,
    { headers: req.headers }
  );
}

export function getUserProfile(req) {
  if (req.headers) {
    return axios.get(ROOT_URL + `/api/v1/profile/${req.profile}/`, {
      headers: req.headers,
    });
  }
  return axios.get(ROOT_URL + `/api/v1/profile/${req.profile}/`);
}

export function report(req) {
  return axios.post(ROOT_URL + `/api/v1/report/`, req.data, {
    headers: req.headers,
  });
}

export function getIsNewNoti(req) {
  return axios.get(ROOT_URL + `/api/v1/notify/new/`, { headers: req.headers });
}
