import React from 'react';
import axios from 'axios';
import { observer, inject } from 'mobx-react';
import styled, { css } from 'styled-components';
import Sum from 'components/sumWithPic';
import { Wrap } from 'components/style/custom-styled-components';
import Button from '@material-ui/core/Button';
import Footer from 'components/footer';
import Swiper from 'react-id-swiper';
import history from 'utils/history';
import right from 'images/right_light.png';
import * as postAPI from 'axios/postAPI';
import Loader from 'components/loadingSpinner/loadingSpinner';

const base = 'https://star-api.lay-er.me';

@inject('userStore')
@observer
class Sums extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      worldList: [],
    };
  }
  async componentDidMount() {
    this.setState({ loading: true });

    const worlds = await axios.get(
      `${base}/worlds?_sort=priority:ASC&hidden=false`
    );
    worlds.data.forEach((world) => {
      world.sums.sort((a, b) => a.priority - b.priority);
    });
    const sumIds = worlds.data
      .reduce((acc, val) => [...acc, ...val.sums], [])
      .map(({ id }) => id);

    const thumbs = (
      await Promise.all(
        sumIds.map((id) => postAPI.getFirstPost({ id_of_sum: id }))
      )
    ).map(({ data }) => {
      return data.data.length > 0 ? data.data[0].thumbnail_image : null;
    });

    const worldList = worlds.data;
    // 이벤트
    try {
      const events = (await axios.get(`${base}/events?hidden=false`)).data;
      if (events && events.length) {
        events.forEach((event) => {
          if (event.sum) {
            const sumId = event.sum.id;
            const worldIdx = worldList.findIndex(({ sums }) =>
              sums.find(({ id }) => id === sumId)
            );
            if (Array.isArray(worldList[worldIdx].events)) {
              worldList[worldIdx].events.push(event);
            } else {
              worldList[worldIdx].events = [event];
            }
          }
        });
      }
    } catch (e) {
      console.error(e);
    }

    this.setState({
      worldList,
      thumbs: thumbs.map((thumb, i) => ({ sumId: sumIds[i], thumb })),
      loading: false,
    });
  }
  render() {
    const { worldList } = this.state;

    if (this.state.loading)
      return (
        <Wrap
          style={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader />
        </Wrap>
      );

    const sumIdReg =
      typeof window !== 'undefined' && /sumId=(\d+)/.exec(window.location.href);

    const sumId = sumIdReg ? sumIdReg[1] : null;

    return (
      <Wrap
        style={{
          paddingTop: 16,
          paddingBottom: 0,
          backgroundColor: 'rgb(248,248,248)',
        }}
      >
        <Footer
          sumId={sumId}
          refreshOnlyContents={() => {
            window.location.href = '/';
          }}
          onClickAdd={() => {
            if (this.props.userStore.getTokenOrToggleLogin())
              history.push(`/new-post?sum=${sumId}`);
          }}
        />{' '}
        <div
          style={{ paddingBottom: 76, paddingRight: 16, overflowX: 'hidden' }}
        >
          {worldList.map((world) => (
            <div key={world.id} style={{ marginBottom: 20 }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: 24,
                  marginBottom: 4,
                }}
              >
                <div style={{ width: 44, height: 44 }}>
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '100%',
                      objectFit: 'cover',
                      border: '1px solid #e3e3e3',
                    }}
                    src={world.image ? `${base}${world.image.url}` : null}
                    role="button"
                    onClick={() =>
                      (window.location.href = `/world/${world.id}`)
                    }
                  />
                </div>
                <div
                  style={{
                    flex: 1,
                    marginLeft: 8,
                  }}
                  role="button"
                  onClick={() => (window.location.href = `/world/${world.id}`)}
                >
                  <div
                    style={{
                      fontWeight: 'bold',
                      fontSize: 14,
                    }}
                  >
                    {world.name}
                  </div>
                  {world.desc && world.desc !== '' && (
                    <div style={{ color: '#939393', fontSize: 12 }}>
                      {world.desc}
                    </div>
                  )}
                </div>
                <Button
                  style={{
                    fontSize: 14,
                  }}
                  onClick={() =>
                    (window.location.href = `/world/${world.id}?hot=true`)
                  }
                >
                  추천보기 <img src={right} style={{ marginLeft: 8 }} />
                </Button>
              </div>
              <Swiper slidesPerView={3.1} rebuildOnUpdate>
                {world.sums.map(({ id, name, image }) => {
                  const found = this.state.thumbs.find(
                    ({ sumId }) => sumId === id
                  );
                  return (
                    <div key={id}>
                      <Sum
                        name={name}
                        onClick={() => (window.location.href = `/sum/${id}`)}
                        img={found && found.thumb}
                        style={{
                          marginLeft: 16,
                          width: '100%',
                        }}
                      />
                    </div>
                  );
                })}
              </Swiper>
              {world.events ? (
                <div style={{ marginTop: 8, marginLeft: 8 }}>
                  <Swiper slidesPerView={1} rebuildOnUpdate>
                    {world.events.map((event, idx, array) => (
                      <div key={event.id}>
                        <div
                          role="button"
                          onClick={() => {
                            window.location.href = event.link;
                          }}
                          style={{
                            background: '#4890ff',
                            borderRadius: 12,
                            padding: '10px 14px',
                            fontSize: 14,
                            color: 'white',
                            marginLeft: 12,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <div style={{ flex: 1, fontWeight: 'bold' }}>
                            {event.name}
                          </div>
                          {array.length > 1 ? (
                            <span
                              style={{
                                background: 'rgba(0,0,0,0.2)',
                                borderRadius: 14,
                                padding: '2px 10px',
                              }}
                            >
                              {idx + 1}/{array.length}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </Swiper>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </Wrap>
    );
  }
}

export default Sums;
