/* global Swiper */
import React from 'react';
import { observer, inject } from 'mobx-react';
import styled, { css } from 'styled-components';
import VideoSwiper from 'components/VideoSwiper';
import PostInfo from 'components/PostInfo';
import PostModalHeader from 'components/PostModalHeader';
import PostMoreInfoModal from 'components/popModal/postMoreInfoModal';
import LoginRequiredModal from 'components/popModal/loginRequiredModal';
import Footer from 'components/footer';
import SpinnerCurtain from 'components/loadingSpinner/spinnerCurtain';
import './posts.css';
import * as postAPI from 'axios/postAPI';
import * as userAPI from 'axios/userAPI';

const ThumbDiv = styled.div`
	background: black;
	position: absolute;
	height: 100vh;
	width: 100%;
	z-index: 10000;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	img {
		width: 100%;
	}
`;
@inject('userStore')
@observer
class Posts extends React.Component {
	static defaultProps = {
		swiperClassName: 'swiper-container-home',
		onChange: () => {},
	};
	constructor(props) {
		super(props);
		const { isFollowing } = this.props;
		this.state = {
			inited: false,
			isFollowing,
			// 리스트 정보와 단일 포스트의 상세 정보는 별도의 API로 구성되어 있다.
			// 단일 포스트의 상세 정보가 리스트에 불러와져야 프로필 표시가 가능하다.
			// postsMap으로 단일 포스트의 상세 정보를 저장한다.
			playing: false,

			myProfile: null,

			posts: [],
			postIndex: -1,
			// post List Api 호출용
			next: null,
			muted: true,
			// 제자리를 찾아가r고 있는가
			founding: true,
			// post swiper
			postSwiper: null,

			// Modal
			postMoreInfoOpen: false,
			commentModalOpen: null,
		};
	}
	async init() {
		let posts = await this.getPostList({});
		this.setState({
			posts,
			postIndex: posts.length > 0 ? 0 : -1,
		});

		const initData = await userAPI
			.getMyUserProfile({
				headers: {
					Authorization: `Bearer ${this.props.userStore.auth_token}`,
				},
			})
			.then((res) => {
				return res.data;
			});

		this.setState({ myProfile: initData });
	}
	componentDidMount() {
		this.init();
	}
	async componentDidUpdate(_, prevState) {
		// 이전 상태 : 스와이퍼가 없거나, 포스트 길이가 0
		// 스와이퍼 준비 + 포스트 로딩이 완료되었을 때 1회만 호출
		if (
			(!prevState.postSwiper || prevState.posts.length === 0) &&
			this.state.postSwiper &&
			this.state.posts.length > 0
		) {
			const { posts, postSwiper } = this.state;
			let { id } = this.props;
			if (id) {
				id = parseInt(id);
				const found = posts.findIndex((post) => post.id === id);
				if (found > -1) {
					postSwiper.slideTo(found, 0);
					this.props.onChange(posts[found]);
				} else {
					this.setState({ founding: true });
					// 현재 불러온 posts에 찾는 post가 없을 경우,
					// 불러와서 다시 페이지를 만들어야 해.
					let found = -1;
					while (found < 0) {
						const result = await this.loadMore();
						found = result.findIndex((post) => post.id === id);
					}
					postSwiper.slideTo(found, 0);
				}
			} else {
				// id 없으면(최상단일 때) slideTo가 호출되지 않아 home에 지금 slide 정보가 가지 않는다.
				// 그래서 home에 최상단 slide를 직접 보내준다.
				this.props.onChange(posts[0]);
			}
			this.setState({ founding: false });
		}
	}
	getPostList = async () => {
		const { userStore } = this.props;
		const headers = userStore.auth_token
			? { Authorization: `Bearer ${userStore.auth_token}` }
			: undefined;
		let posts;
		// 위에서 불러온 게 있을경우
		posts = this.props.posts
			? { data: this.props.posts }
			: await postAPI
					.getPostList({
						following: this.props.isFollowing,
						profile: this.props.profileId,
						headers,
						next: this.state.next,
					})
					.then((res) => res.data);
		if (posts) {
			if (posts.next) {
				this.setState({ next: posts.next });
			}
			return posts.data || [];
		}
		return null;
	};

	onPostSwiperReady = async (postSwiper) => {
		this.setState({
			postSwiper,
		});
	};
	loadMore = async () => {
		let posts = this.props.loadMore
			? await this.props.loadMore()
			: await this.getPostList({});
		// 기존에 로드 된 것은 무시
		posts = posts.filter(
			(o) => this.state.posts.findIndex((j) => j.id === o.id) < 0,
		);
		const concated = this.state.posts.concat(posts);
		this.setState({
			posts: concated,
		});
		return concated;
	};
	onRefresh = () => {
		// if (this.props.activeRefresh) {
		// 	window.location.href = '/';
		// }
	};
	refreshOnlyContents = () => {
		window.location.href = '/';
	};
	onSwipe = (postIndex) => {
		this.setState({
			postIndex,
		});
		const postNow = this.state.posts[postIndex];

		this.props.onChange(postNow, postIndex);
	};
	handleSound = () => {
		const postNow = this.state.posts[this.state.postIndex];
		const nextValue = !this.state.muted;
		this.setState({
			muted: nextValue,
		});

		// id 구하기
		const videoId = this.makeVideoId(postNow.id);

		// DOM Element 찾기
		const videoEle = videoId && document.getElementById(videoId);

		if (videoEle) {
			videoEle.muted = nextValue;
		}
	};
	onClickPriceTag = () => {
		alert('onClickPriceTag');
	};
	makeVideoId = (id) => {
		return `main-video-${id}`;
	};
	handleClickPostModalHeader = (name) => {
		switch (name) {
			case 'detailMore': {
				this.setState({
					postMoreInfoOpen: true,
				});
				break;
			}
		}
	};

	render() {
		const postNow = this.state.posts[this.state.postIndex];
		const isMine =
			postNow &&
			this.props.userStore.auth_token &&
			postNow.profile &&
			this.state.myProfile &&
			postNow.profile.id === this.state.myProfile.id;
		return (
			<>
				<VideoSwiper
					posts={this.state.posts}
					loadMore={this.loadMore}
					onRefresh={this.onRefresh}
					isFollowing={this.state.isFollowing}
					onSwipe={this.onSwipe}
					onPlay={() => {
						if (!this.state.inited) {
							this.handleSound();
						}
						this.setState({ playing: true, inited: true });
					}}
					makeVideoId={this.makeVideoId}
					muted={this.state.muted}
					onReady={this.onPostSwiperReady}
					onChange={this.props.onChange}
					stopVideo={this.props.stopVideo}
					swiperClassName={`${this.props.swiperClassName}-swiper`}
				/>
				{!this.props.disableFooter && (
					<Footer
						location={window.location.pathname}
						refresh={this.refreshOnlyContents}
						onClickAdd={this.props.onClickAdd}
					/>
				)}
				{postNow && (
					<PostInfo
						post={postNow}
						handleSound={this.handleSound}
						handleClickProfileImg={this.props.handleClickProfileImg}
						disableLike={isMine}
						onClickPriceTag={this.onClickPriceTag}
						muted={this.state.muted}
						ad={this.props.ad}
						onClickAd={this.props.onClickAd}
					/>
				)}
				<PostModalHeader
					onClick={this.handleClickPostModalHeader}
					onClose={this.props.onClose}
				/>
				<PostMoreInfoModal
					open={this.state.postMoreInfoOpen}
					handleClose={() =>
						this.setState({
							postMoreInfoOpen: false,
						})
					}
					post={postNow}
					bottom={'0px'}
					isMine={isMine}
				/>
				<LoginRequiredModal bottom={'0px'} />
				{this.props.thumbnail && !this.state.playing ? (
					<ThumbDiv>
						<img src={this.props.thumbnail} />
					</ThumbDiv>
				) : !this.props.thumbnail && !this.state.playing ? (
					<SpinnerCurtain />
				) : null}
			</>
		);
	}
}
export default Posts;
