import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from 'antd';
import { Grid, Tabs, WhiteSpace } from 'antd-mobile';
import { observer, inject } from 'mobx-react';

import { Wrap, IndexWrap } from 'components/style/custom-styled-components';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import FollowBtn from 'components/followBtn';
import SearchBar from 'components/searchBar';

import * as userAPI from 'axios/userAPI';
import * as eventAPI from 'axios/eventAPI';
import history from 'utils/history';
import back_ico from 'images/back_ico_b.svg';
import search_ico from 'images/search_ico.svg';
import default_profile from 'images/default_profile.png';

const NavBar = styled.div`
	display: flex;
	flex-direction: row;
	position: fixed;
	line-height: 44px;
	height: 44px;
	width: 100%;
	max-width: 1024px;
	z-index: 3;
	top: 0;
	background: rgb(248, 248, 248);
`;
const IconWrap = styled.div`
	position: absolute;
	top: 18px;
	right: 18px;
`;

const ProfileWrap = styled.div`
	padding: 8px 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	p {
		margin: 0px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	p.profile-name {
		color: #333333;
		font-family: 'Noto Sans CJK KR';
		font-size: 16px;
		font-weight: 700;
	}
	p.profile-intro {
		color: #999999;
		font-family: 'Noto Sans CJK KR';
		font-size: 14px;
		font-weight: 500;
	}
	span.profile-btn {
		display: inline-block;
		text-align: center;
		width: 64px;
		height: 28px;
		border-radius: 20px;
		vertical-align: middle;
		font-family: 'Noto Sans CJK KR';
		font-size: 13px;
		font-weight: 700;
		line-height: 28px;
		cursor: pointer;
		hover: {
			opacity: 0.8;
		}
	}
	span.profile-follow-btn {
		background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
		color: #ffffff;
	}
	span.profile-following-btn {
		background-color: rgba(130, 143, 247, 0.2);
		color: #828ff7;
	}
`;
const ProfileImage = styled.div`
	width: 56px;
	height: 56px;
	border-radius: 50%;
	background-size: cover;
	background-position: center;
	display: flex;
	margin-right: 12px;
	cursor: pointer;
	:hover {
		opacity: 0.8;
	}
	${props =>
		props.isDefaultProfileImg &&
		css`
			background-color: white;
		`}
	div {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-size: cover;
		background-position: center;
		${props =>
			props.isDefaultProfileImg &&
			css`
				margin: auto;
				width: 32px;
				height: 32px;
				border-radius: 0;
			`}
	}
`;
const InfoCard = styled.div`
	width: 100%;
	height: 140px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	background-color: #ffffff;

	p {
		width: 100%;
		height: 47px;
		padding: 14px 16px;
		border-bottom: solid 1px #eeeeee;
		margin: 0;
		span.profile-grade {
			color: #bebebe;
			font-family: 'Noto Sans CJK KR';
			font-size: 14px;
			font-weight: 500;
			line-height: 30px;
		}
		span.profile-grade-data {
			margin-left: 16px;
			color: #828ff7;
			font-family: 'Noto Sans CJK KR';
			font-size: 14px;
			font-weight: 700;
			line-height: 30px;
			text-transform: uppercase;
		}
		span.profile-grade-icon {
			float: right;
		}
	}
`;
const InfoCardInnerWrap = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: center;
	padding: 16px;
	div {
		text-align: center;
		width: 25%;
		span {
			display: block;
		}
		span.profile-info-title {
			color: #bebebe;
			font-family: 'Noto Sans CJK KR';
			font-size: 14px;
			font-weight: 500;
		}
		span.profile-info-data {
			color: #828ff7;
			font-family: 'Noto Sans CJK KR';
			color: #828ff7;
			font-family: 'Noto Sans CJK KR';
			font-size: 24px;
			font-weight: 700;
		}
	}
`;
const MainEventBanner = styled.div`
	padding: 0px 16px;
	margin-top: 1px;
	margin-bottom: 40px;
	margin-right: 0px;
	margin-left: 0px;
	cursor: pointer;
	width: 100%;
	max-width: 380px;
	height: 80px;
`;

@inject('userStore')
@observer
class MyPageFollow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			followingData: false,
			followerData: false,
			accessToken: this.props.userStore.auth_token,
			tab: this.props.match.params.follow,
			search_mode: true,
		};
		this.tabs = [{ title: '팔로잉' }, { title: '팔로우' }];
	}
	componentWillMount() {
		if (!this.props.userStore.auth_token) {
			history.push('/login');
		} else {
			if (
				this.props.match.params.follow != 'following' &&
				this.props.match.params.follow != 'follower'
			) {
				history.push('/my-page');
			}
		}
	}
	async componentDidMount() {
		const headers = {
			Authorization: `Bearer ${this.state.accessToken}`,
		};
		const myProfile = await Promise.all([
			userAPI.getMyUserProfile({ headers: headers }).then(res => {
				return res.data;
			}),
		]);
		const req = {
			profile: myProfile[0].id,
			headers: headers,
		};
		const followingData = await Promise.all([
			userAPI.getUserFollowing(req).then(res => {
				return res.data;
			}),
		]);
		const followerData = await Promise.all([
			userAPI.getUserFollower(req).then(res => {
				return res.data;
			}),
		]);
		this.setState({
			followingData: followingData[0],
			followingResults: followingData[0],
			followerData: followerData[0],
			followerResults: followerData[0],
		});
	}
	_searchOnClick = () => {
		this.setState({
			search_mode: !this.state.search_mode,
		});
	};
	_setFollowingSearchResults = results => {
		this.setState({
			followingResults: results,
		});
	};
	_setFollowerSearchResults = results => {
		this.setState({
			followerResults: results,
		});
	};
	userClick = id => {
		history.push(`/profile/${id}`);
	};
	render() {
		const {
			followingData,
			followerData,
			followingResults,
			followerResults,
		} = this.state;
		return (
			<Wrap
				style={{
					paddingBottom: 0,
					height: '-webkit-fill-available',
					backgroundColor: 'rgb(248,248,248)',
				}}
			>
				<IndexWrap style={{ paddingTop: 44, backgroundColor: '#f8f8f8' }}>
					<NavBar>
						<div style={{ width: '50%' }}>
							<img
								src={back_ico}
								onClick={() => {
									history.goBack();
								}}
								style={{
									marginLeft: 19,
									width: 19,
									verticalAlign: 'middle',
									cursor: 'pointer',
								}}
							/>
						</div>
						{/*
            <div style={{width:'50%',textAlign:'right'}}>
              <img src={search_ico} onClick={this._searchOnClick} style={{marginRight:19, width:17, verticalAlign:'middle', cursor:'pointer'}}/>
            </div>*/}
					</NavBar>
					<Tabs
						tabs={this.tabs}
						initialPage={this.props.match.params.follow == 'following' ? 0 : 1}
						animated={false}
						swipeable={false}
						useOnPan={false}
						tabBarTextStyle={{
							fontWeight: 700,
							fontFamily: 'Noto Sans CJK KR',
							cursor: 'pointer',
						}}
						tabBarActiveTextColor={'#828ff7'}
						tabBarInactiveTextColor={'#bebebe'}
						onChange={this._onChange}
					>
						<div>
							{this.state.search_mode ? (
								<SearchBar
									mode="following"
									data={followingData}
									setFollowing={this._setFollowingSearchResults}
									setFollower={this._setFollowerSearchResults}
									placeholder="아이디 검색"
								/>
							) : (
								<Fragment />
							)}
							{followingData ? (
								<Fragment>
									{followingResults.map((data, index) => (
										<ProfileWrap key={index}>
											<ProfileImage
												isDefaultProfileImg={data.profile.image ? false : true}
												onClick={() => {
													this.userClick(data.profile.id);
												}}
											>
												<div
													style={{
														backgroundImage: `url('${
															data.profile.image
																? data.profile.image
																: default_profile
														}')`,
													}}
												/>
											</ProfileImage>
											<div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
												<p className="profile-name">
													{' '}
													{data.profile.nickname}{' '}
												</p>
												<p className="profile-intro">
													{' '}
													{data.profile.introduction}{' '}
												</p>
											</div>
											<FollowBtn
												accessToken={this.state.accessToken}
												profile={data.profile}
												is_followed={data.is_followed}
											/>
										</ProfileWrap>
									))}
								</Fragment>
							) : (
								<LoadingSpinner />
							)}
						</div>
						<div>
							{this.state.search_mode ? (
								<SearchBar
									mode="follower"
									data={followerData}
									setFollowing={this._setFollowingSearchResults}
									setFollower={this._setFollowerSearchResults}
									placeholder="아이디 검색"
								/>
							) : (
								<Fragment />
							)}
							{followerData ? (
								<Fragment>
									{followerResults.map((data, index) => (
										<ProfileWrap key={index}>
											<ProfileImage
												isDefaultProfileImg={data.profile.image ? false : true}
												onClick={() => {
													this.userClick(data.profile.id);
												}}
											>
												<div
													style={{
														backgroundImage: `url('${
															data.profile.image
																? data.profile.image
																: default_profile
														}')`,
													}}
												/>
											</ProfileImage>
											<div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
												<p className="profile-name">
													{' '}
													{data.profile.nickname}{' '}
												</p>
												<p className="profile-intro">
													{' '}
													{data.profile.introduction}{' '}
												</p>
											</div>
											<FollowBtn
												accessToken={this.state.accessToken}
												profile={data.profile}
												is_followed={data.is_followed}
											/>
										</ProfileWrap>
									))}
								</Fragment>
							) : (
								<LoadingSpinner />
							)}
						</div>
					</Tabs>
				</IndexWrap>
			</Wrap>
		);
	}
}
export default MyPageFollow;
