import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  root: {
    background: "white",
    width: "100%",
    maxWidth: "90%",
    borderRadius: 8,
  },
  title: {
    fontSize: 16,
    fontWeight: 900,
    textAlign: "center",
    marginTop: 20,
    marginBottom: 20,
  },
  textField: {
    paddingTop: 4,
    borderRadius: 12,
    background: "rgba(120, 132, 158, 0.08)",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
    "& input": {
      paddingTop: 12,
      paddingBottom: 18,
    },
  },
  body: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 32,
  },
  buttonNegative: {
    color: "black",
    background: "#efefef",
    height: 48,
    width: "50%",
    borderRadius: 0,
  },
  button: {
    color: "white",
    background: "#1f75fe",
    height: 48,
    width: "50%",
    borderRadius: 0,
    borderRadius: 0,
    "&:hover": {
      background: "#1f75fe",
    },
  },
});

const Modal = ({ open, onClose, value, onChange, onSubmit, onRemove }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.root }}>
      <div>
        <div className={classes.title}>Q&A</div>
        <div className={classes.body}>
          <TextField
            className={classes.textField}
            value={value}
            fullWidth
            variant="outlined"
            placeholder="Q&A 입력 (최대 20자)"
            onChange={(e) =>
              e.target.value.length > 20 ? () => {} : onChange(e)
            }
          />
        </div>

        <Button
          className={classes.buttonNegative}
          onClick={() => {
            onRemove();
          }}
        >
          삭제하기
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => onSubmit(value)}
        >
          저장하기
        </Button>
      </div>
    </Dialog>
  );
};
export default Modal;
