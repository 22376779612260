import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';
import history from 'utils/history';
import Backdrop from './backdrop';

const Wrapper = styled.div`
	width: 100%;
	// max-width: 480px;
	height: 100vh;
	top: 0px;
	z-index: -1;
	position: fixed;
	transition: 0.2s background-color ease, z-index 0.2s ease;

	${props =>
		props.active &&
		css`
			z-index: 1000000 !important;
			background-color: rgba(0, 0, 0, 0.5);
			transition: 0.2s background-color ease;
		`}
`;
const Modal = styled.div`
	max-width: 480px;
	padding: 16px;
	position: fixed;
	right: 0px;
	left: 0px;
	margin: auto;
	bottom: -400px;
	width: 100%;
	height: 323px;
	background: #ffffff;
	transition: 0.2s ease;
	z-index: 1002;
	${props =>
		props.active &&
		css`
			bottom: ${props.bottom ? props.bottom : '48px'};
			box-shadow: 0 2px 20px rgba(0, 0, 0, 0.4);
			border-radius: 20px 20px 0 0;
		`}
`;
const IconWrapper = styled.div`
	width: 104px;
	height: 104px;
	display: flex;
	align-items: center;
	margin: auto;
	margin-top: 24px;
	background-color: rgba(130, 143, 247, 0.1);
	border-radius: 50%;
	text-align: center;
	i {
		vertical-align: middle;
		width: 100%;
		height: 64px;
	}
	svg {
		vertical-align: text-top;
	}
`;
const BigTextWrap = styled.p`
	margin-top: 16px;
	margin-bottom: 0px;
	text-align: center;
	width: 100%;
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 18px;
	font-weight: 700;
`;
const SmallTextWrap = styled.p`
	margin-top: 3px;
	margin-bottom: 31px;
	text-align: center;
	width: 100%;
	color: #bebebe;
	font-family: 'Noto Sans CJK KR';
	font-size: 14px;
	font-weight: 500;
`;
const LoginBtn = styled.div`
	width: 100%;
	height: 44px;
	box-shadow: 0 2px 10px rgba(176, 141, 247, 0.5);
	border-radius: 68px;
	background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	line-height: 44px;
	cursor: pointer;
	:hover {
		opacity: 0.8;
	}
`;
@inject('userStore')
@observer
class LoginRequiredModal extends Component {
	_onClickLogin = () => {
		this.props.userStore.toggleLoginRequired();
		history.push('/login');
	};

	render() {
		return (
			<Wrapper active={this.props.userStore.loginRequired}>
				<Backdrop
					open={this.props.userStore.loginRequired}
					handleClose={this.props.userStore.toggleLoginRequired}
				/>
				<Modal
					active={this.props.userStore.loginRequired}
					bottom={this.props.bottom}
				>
					<IconWrapper>
						<i>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="58"
								height="64"
								viewBox="0 0 58 64"
							>
								<defs>
									<mask id="z273b" width="2" height="2" x="-1" y="-1">
										<path fill="#fff" d="M9 7h40v46H9z" />
										<path d="M9 11c0-2 2-4 4-4h32c2 0 4 2 4 4v38c0 2-2 4-4 4H13c-2 0-4-2-4-4z" />
									</mask>
									<filter
										id="z273a"
										width="80"
										height="88"
										x="-11"
										y="-13"
										filterUnits="userSpaceOnUse"
									>
										<feOffset
											dy="2"
											in="SourceGraphic"
											result="FeOffset1032Out"
										/>
										<feGaussianBlur
											in="FeOffset1032Out"
											result="FeGaussianBlur1033Out"
											stdDeviation="4 4"
										/>
									</filter>
									<linearGradient
										id="z273c"
										x1="14.34"
										x2="43.53"
										y1="16.87"
										y2="30"
										gradientUnits="userSpaceOnUse"
									>
										<stop offset="0" stopColor="#828ff7" />
										<stop offset="1" stopColor="#b08df7" />
									</linearGradient>
								</defs>
								<g>
									<g>
										<g filter="url(#z273a)">
											<path
												fill="none"
												d="M9 11c0-2 2-4 4-4h32c2 0 4 2 4 4v38c0 2-2 4-4 4H13c-2 0-4-2-4-4z"
												mask='url("#z273b")'
											/>
											<path
												fill="#938ef7"
												fillOpacity=".5"
												d="M9 11c0-2 2-4 4-4h32c2 0 4 2 4 4v38c0 2-2 4-4 4H13c-2 0-4-2-4-4z"
											/>
										</g>
										<path
											fill="url(#z273c)"
											d="M9 11c0-2 2-4 4-4h32c2 0 4 2 4 4v38c0 2-2 4-4 4H13c-2 0-4-2-4-4z"
										/>
									</g>
									<g>
										<path
											fill="#fff"
											d="M17 27c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"
										/>
									</g>
									<g>
										<g />
										<g>
											<path
												fill="#828ff7"
												d="M34.317 30.988v.664c0 .366-.3.665-.665.665h-9.304a.667.667 0 0 1-.665-.665v-.664c0-1.768 3.543-2.659 5.317-2.659s5.317.89 5.317 2.659zm-7.975-6.646a2.658 2.658 0 1 1 5.316 0 2.658 2.658 0 1 1-5.316 0z"
											/>
										</g>
									</g>
									<g>
										<path
											fill="#fff"
											d="M19 44c0-.5.5-1 1-1h18c.5 0 1 .5 1 1s-.5 1-1 1H20c-.5 0-1-.5-1-1z"
										/>
									</g>
								</g>
							</svg>
						</i>
					</IconWrapper>
					<BigTextWrap>계정이 필요해요!</BigTextWrap>
					<SmallTextWrap>
						로그인을 해야지만
						<br />
						서비스를 이용할 수 있어요.
					</SmallTextWrap>
					<LoginBtn onClick={this._onClickLogin}>로그인하러 갈게요</LoginBtn>
				</Modal>
			</Wrapper>
		);
	}
}

export default LoginRequiredModal;
