import React from 'react';

export default props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="18"
		viewBox="0 0 20 18"
		{...props}
	>
		<path d="M5.5 2c1.515 0 2.992.959 3.544 2.295a1 1 0 0 0 1.889.065h.007C11.46 2.99 12.96 2 14.5 2c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05l-.1.1-.1-.1C5.14 11.24 2 8.39 2 5.5 2 3.5 3.5 2 5.5 2zm3.15 15.13c.379.344.86.517 1.34.517.484 0 .969-.175 1.35-.527l.11-.1C16.7 12.26 20.13 9.15 20 5.27c-.06-1.7-.94-3.32-2.34-4.28-2.64-1.8-5.9-.96-7.66 1.1C8.24.03 4.98-.82 2.34.99.94 1.95.06 3.57 0 5.28c-.14 3.88 3.3 6.99 8.55 11.76l.1.09z" />
	</svg>
);
