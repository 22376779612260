import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    background: "transparent",
    boxShadow: "none",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  imgWrapper: {
    height: "100%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "visible",
  },
  dialog: {
    background: "black",
  },
  icon: {
    color: "white",
  },
  pan: {
    height: "100vh",
  },
}));

export default function FullScreenDialog({ open, handleClose, src }) {
  const classes = useStyles();

  const onClose = () => window.history.back();
  useEffect(() => {
    if (open) {
      const { onpopstate } = window;
      window.history.pushState({}, "", "");
      window.onpopstate = handleClose;
      return () => {
        window.onpopstate = onpopstate;
      };
    }
    return;
  }, [open]);
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      classes={{ paper: classes.dialog }}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            className={classes.icon}
            edge="start"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={classes.imgWrapper}>
        <TransformWrapper limitToBounds={false} centerContent={false}>
          <TransformComponent>
            <div className={classes.pan}>
              <img className={classes.img} src={src} />
            </div>
          </TransformComponent>
        </TransformWrapper>
      </div>
    </Dialog>
  );
}
