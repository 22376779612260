import React from "react";
import styled, { css } from "styled-components";
import NoticeIcon from "../myIcons/noticeIcon";

const Layout = styled.div`
  height: 33px;
  box-shadow: 0 0 27px rgba(0, 0, 0, 0.13);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 20px;
  margin-right: 14px;
  margin-left: 14px;
`;
const Text = styled.div`
  color: #1f75fe;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  letter-spacing: -0.08px;
  line-height: 15px;
`;
export default ({ text, ...rest }) => (
  <Layout {...rest}>
    <NoticeIcon style={{ width: 24, marginLeft: 20, marginRight: 20 }} />{" "}
    <Text>{text}</Text>
  </Layout>
);
