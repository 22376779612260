import React from 'react';
import axios from 'axios';
import { observer, inject } from 'mobx-react';
import { Wrap } from 'components/style/custom-styled-components';
import ProductCategoryModal from 'components/popModal/productCategoryModal';
import MainList from 'containers/mainList';
import LoginRequiredModal from 'components/popModal/loginRequiredModal';
import Footer from 'components/footer';
import ReportModal from 'components/popModal/reportModal';
import * as userAPI from 'axios/userAPI';
import history from 'utils/history';
import PostMoreInfoModal from 'components/popModal/postMoreInfoModal';
import { v1 } from 'uuid';
import * as iosHandler from 'utils/iosHandler';
import cookie from 'js-cookie';
import { url } from '../utils/starApi';
import ProductList from 'components/ProductList';

@inject('userStore')
@inject('cacheStore')
@observer
class Home extends React.Component {
  constructor(props) {
    super(props);
    let clickedTag = undefined;
    if (
      typeof window !== 'undefined' &&
      window.location.search.includes('tag=')
    ) {
      const { search } = window.location;
      clickedTag = decodeURIComponent(search.slice('?tag='.length));
    }

    const isWorld = this.props.match.path.includes('/world');

    this.state = {
      worldId: isWorld ? this.props.match.params.id : null,
      sumId: isWorld ? null : this.props.match.params.id,
      searchVal: clickedTag || '',
      currentTab: '포스팅',
      profile: undefined,
      clickedTag,
      tagListOn: false,
      productCategoryModalOpen: false,
      postMoreInfoOpen: false,
      postNow: null,
      productsOpen: false,
      reportModalOn: false,
      ad: null,
      singlePostId:
        this.props.match &&
        this.props.match.path === '/post/:id' &&
        this.props.match.params.id,
    };
  }
  async componentDidMount() {
    if (window.location.href.includes('sum/73')) {
      // 마이피드 하드코딩
      history.replace('/');
    }
    if (this.state.clickedTag) {
      this.setState(
        {
          searchVal: this.state.clickedTag,
        },
        () => {
          this.handleSelectTag(this.state.clickedTag);
        }
      );
    }
    if (this.props.userStore.auth_token) {
      const profileReq = {
        headers: { Authorization: `Bearer ${this.props.userStore.auth_token}` },
      };
      const profile = await userAPI.getMyUserProfile(profileReq).then((res) => {
        return res.data;
      });
      // 별샵에 계정 생성한다.
      try {
        const { id, nickname } = profile;
        iosHandler.setPushKey(id);
        await axios(`${url}/get-my-info`, {
          method: 'post',
          data: { userId: id, name: nickname },
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.setState({
          profile,
        });
      }
    } else {
      iosHandler.setPushKey();
    }
  }
  handleTabChange = (tab) => {
    this.setState({
      currentTab: tab,
    });
  };
  handleSearch = (event) => {
    const { value } = event.target;
    this.setState({
      currentTab: this.state.currentTab,
      searchVal: value,
      tagListOn: value !== '',
    });
  };
  handleSubmitTag = () => {
    const newRecentSearchArr = this.props.cacheStore.myRecentSearch.concat(
      this.state.searchVal
    );
    this.props.cacheStore.updateMyRecentSearch(newRecentSearchArr);
  };
  handleSelectTag = (searchVal) => {
    this.setState(
      {
        searchVal,
        tagListOn: false,
      },
      () => {
        this.handleSubmitTag();
      }
    );
  };
  handleClickAdd = () => {
    if (this.props.userStore.getTokenOrToggleLogin())
      history.push(`/new-post?sum=${this.props.match.params.id}`);
  };
  refreshOnlyContents = () => {
    window.location.href = '/';
  };
  loadAd = () => {
    axios(`${url}/ad/${this.state.sumId}`).then(({ data }) => {
      this.setState({
        ad: data,
      });
    });
  };
  onClickAd = async () => {
    try {
      const post = this.state.postNow;
      const body = {
        userId: null,
        adId: this.state.ad.id,
        postUserId: post.profile.id,
        postUserName: post.profile.nickname,
      };
      if (this.state.profile) {
        body.userId = this.state.profile.id;
      } else {
        let userId = cookie.get('userId');
        if (!userId) {
          cookie.set('userId', v1(), { expires: 1000 });
          userId = cookie.get('userId');
        }
        body.userId = userId;
      }
      await axios(`${url}/ad`, {
        method: 'post',
        data: body,
      });
    } catch (e) {
      alert(JSON.stringify(e));
    }
    // alert(this.state.ad.url);
    window.location.href = this.state.ad.url;
    // window.open(this.state.ad.url);
  };
  render() {
    return (
      <Wrap style={{ paddingBottom: 0 }}>
        <LoginRequiredModal bottom={'0px'} />
        <MainList
          singlePostId={this.state.singlePostId}
          onLoadSinglePost={(post) =>
            this.setState({
              postNow: post,
              sumId: Number(post.id_of_sum),
            })
          }
          worldId={this.state.worldId}
          sumId={
            this.state.singlePostId || this.state.worldId
              ? null
              : this.props.match.params.id
          }
          currentTab={this.state.currentTab}
          handleSelectTag={this.handleSelectTag}
          handleTabChange={this.handleTabChange}
          searchVal={this.state.searchVal}
          tagListOn={this.state.tagListOn}
          user={this.state.profile}
          onClickBack={() => {
            window.history.back();
          }}
          onClickPostMore={(post) => {
            this.setState({
              postMoreInfoOpen: true,
              postNow: post,
              sumId: Number(post.id_of_sum),
            });
          }}
          onClickProducts={(post) => {
            if (post.item.length) {
              this.loadAd();
              this.setState({
                productsOpen: true,
                postNow: post,
              });
            }
          }}
        />
        <Footer
          refresh={this.refreshOnlyContents}
          onClickAdd={this.handleClickAdd}
        />
        <ProductCategoryModal
          open={this.state.productCategoryModalOpen}
          handleClose={() =>
            this.setState({
              productCategoryModalOpen: false,
            })
          }
          onClick={(selected) => {
            if (this.props.userStore.getTokenOrToggleLogin())
              history.push(`/new-post?selected=${selected}`);
          }}
          bottom={'0px'}
        />
        <PostMoreInfoModal
          open={this.state.postMoreInfoOpen}
          sumId={this.state.postNow && this.state.postNow.id_of_sum}
          handleClose={() =>
            this.setState({
              postMoreInfoOpen: false,
            })
          }
          post={this.state.postNow}
          bottom={'0px'}
          isMine={
            this.state.postNow &&
            this.state.profile &&
            this.state.postNow.profile.id === this.state.profile.id
          }
        />
        <div>
          <ProductList
            on={this.state.productsOpen}
            onClose={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.setState({ productsOpen: false });
            }}
            items={this.state.postNow ? this.state.postNow.item : []}
            ad={this.state.ad}
            onClickAd={this.onClickAd}
          />
        </div>
        <ReportModal
          open={this.state.reportModalOn}
          handleClose={() =>
            this.setState({
              reportModalOn: false,
            })
          }
          bottom={'0px'}
        />
      </Wrap>
    );
  }
}
export default Home;
