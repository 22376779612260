export default ({ tagged, str }) => {
  let title = str;
  console.log(tagged, title);
  tagged.forEach(({ id, nickname }) => {
    const reg = new RegExp(`@${nickname}(?!:id)`);
    console.log(reg);
    const res = reg.exec(title);
    console.log(res);
    if (res) {
      const front = title.slice(0, res.index);
      const rear = title.slice(res.index + res[0].length);
      title = `${front}@${nickname}:id=${id}${rear}`;
    }
  });
  return title;
};
