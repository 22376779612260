import { observable, action } from "mobx";
import PostStore from "stores/postStore";
import * as userAPI from "axios/userAPI";
import * as myLocalStorage from "utils/myLocalStorage";
import history from "utils/history";

class UserStore {
  @observable is_fetched_accessToken = false;
  @observable auth_token = myLocalStorage.getAuthToken();
  @observable loginRequired = false;
  @observable itemLinkRequired = false;
  @observable itemBuyRequired = false;
  @observable myFilterToggle = false;
  @observable profileMoreToggle = false;
  @observable notOnServiceToggle = false;
  @observable reportToggle = false;
  @observable.ref reportData = {
    mode: undefined, // 'profile', 'post'
    id: undefined // object_id
  };

  // 모든 토글 끄기
  @action clearAllToggle = () => {
    this.loginRequired = false;
    this.itemLinkRequired = false;
    this.itemBuyRequired = false;
    this.myFilterToggle = false;
    this.profileMoreToggle = false;
    this.notOnServiceToggle = false;
    this.reportToggle = false;
  };

  @action setAccessToken = token => {
    this.auth_token = token;
  };
  @action logout = () => {
    this.auth_token = false;
    this.is_fetched_accessToken = false;
  };
  @action getTokenOrToggleLogin = () => {
    if (this.is_fetched_accessToken) {
      return this.auth_token;
    } else {
      const token = myLocalStorage.getAuthToken();
      if (token) {
        this.is_fetched_accessToken = true;
        this.auth_token = token;
        return token;
      } else {
        this.toggleLoginRequired();
        return false;
      }
    }
  };

  @action getTokenOrGoLogin = () => {
    if (this.is_fetched_accessToken) {
      return this.auth_token;
    } else {
      const token = myLocalStorage.getAuthToken();
      if (token) {
        this.is_fetched_accessToken = true;
        this.auth_token = token;
        return token;
      } else {
        history.push("/login");
        return false;
      }
    }
  };

  // 로그인 요구 모달 토글
  @action toggleLoginRequired = () => {
    this.loginRequired = !this.loginRequired;
  };

  // 리뷰 등록 전 공지 모달 토글
  @action toggleItemLinkRequired = () => {
    this.itemLinkRequired = !this.itemLinkRequired;
  };

  // 이벤트 참여 시 공지 모달 토글
  @action toggleItemBuyRequired = () => {
    this.itemBuyRequired = !this.itemBuyRequired;
  };

  // 마이필터 모달 토글
  @action toggleMyFilter = () => {
    this.myFilterToggle = !this.myFilterToggle;
  };

  // 유저 프로필 더보기 토글
  @action toggleProfileMore = () => {
    this.profileMoreToggle = !this.profileMoreToggle;
  };

  // 서비스 준비중 토글
  @action toggleNotOnService = () => {
    this.notOnServiceToggle = !this.notOnServiceToggle;
  };

  // 신고하기 토글
  @action toggleReport = () => {
    if (this.auth_token) {
      this.reportToggle = !this.reportToggle;
    } else {
      this.loginRequired = !this.loginRequired;
    }
  };

  // 신고 대상 데이터
  @action setReportData = data => {
    this.reportData = data;
  };
}

export default UserStore;
