import React, { Fragment } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { observer, inject } from 'mobx-react';

const RightWrap = styled.div`
	position: absolute;
	width: 30%;
	height: 100vh;
	right: 0px;
`;
const LeftWrap = styled.div`
	position: absolute;
	width: 30%;
	height: 100vh;
	left: 0px;
`;
@inject('postStore')
@observer
class DetailVideo extends React.Component {
	constructor(props) {
		super(props);
		this._videoId = 0;
		this.state = {
			isPlaying: false,
			duration: null,
			loaded: false,
		};
	}
	componentWillUnmount() {
		// IOS는 비디오를 삭제 처리 해주어야 한다.
		// https://stackoverflow.com/questions/28905253/media-err-decode-on-html5-video-in-ios-uiwebview-after-many-plays
		if (this.video) {
			this.video.remove();
			this.video.src = '';
			this.video.load();
		}
	}
	componentDidUpdate(prevProps) {
		if (this.props.videoData && !prevProps.videoData) {
			this.video.load();
		}

		// duration이 있다는 말은 metadata가 로딩되었다는 것
		if (this.video && this.state.duration) {
			if (this.props.active && !this.state.isPlaying) {
				this.playVideoWithBar({ duration: this.state.duration });
			} else if (prevProps.active && !this.props.active) {
				this.video.pause();
				this.video.currentTime = 0;
				this.setState({ isPlaying: false });
			}
		}
	}
	playVideoWithBar = ({ duration }) => {
		// 영상 진행 바는 시간 딜레이를 주어야 정상적으로 작동한다.
		// 영상 진행 바 초기화
		setTimeout(() => {
			let prevIdx = 0;
			while (prevIdx < this.props.videoLen) {
				// 이미 작동 중에 100으로 올리는 것은 안된다.
				// 0으로 초기화 시킨 뒤 100으로 올려야 정상 작동한다.
				this.props.setPrgBarWidth(prevIdx, 0, '0%');

				prevIdx += 1;
			}
			// 현재는 0으로 초기화
			this.props.setPrgBarWidth(this.props.index, 0, '0%');

			setTimeout(() => {
				if (this.video) {
					try {
						this.video.play();
						this.props.setPrgBarWidth(this.props.index, duration, '100%');
					} catch (e) {
						// alert(e.message);
					}
				}
			}, 100);
		}, 10);

		// 현재 영상 진행 바 시작
		setTimeout(() => {
			let prevIdx = 0;
			while (prevIdx < this.props.index) {
				this.props.setPrgBarWidth(prevIdx, 0, '100%');
				prevIdx += 1;
			}
		}, 100);
		this.setState({ isPlaying: true });
	};
	_onEnded = e => {
		// mute 테스트 코드
		if (this.props.active) {
			if (this.props.videoLen > 1) {
				this.setState({ isPlaying: false, duration: null });
				this.props.nextVideo();
			} else {
				this.playVideoWithBar({ duration: this.state.duration });
			}
		} else {
			this.video.pause();
			this.video.currentTime = 0;
			this.setState({ isPlaying: false });
		}
	};
	_startPlay = e => {
		this.props.handlePlayFault(false);
	};
	_onLoadedMetaData = e => {
		this.setState({ duration: this.video.duration });
		this.props.setCurrentVideo(this.video);
		// this.props.setVideoStatus('loadedMetaData');
	};
	_onPlaying = () => {
		let error = this.video.error ? this.video.error.code : this.video.error;

		if (!error) {
			if (this.props.onPlay) {
				this.props.onPlay();
			}
			this.setState(
				{
					videoPlayed: true,
					isWaiting: false,
				},
				() => {
					setTimeout(() => {
						if (this.props.muteBtn) {
							this.props.muteBtn.click();
						}
					}, 2000);
				},
			);
		}
	};
	_onPause = () => {
		this.setState({
			videoPlayed: false,
		});
	};
	_onWaiting = () => {
		this.setState({
			videoPlayed: false,
			isWaiting: true,
		});
	};
	rightOnClick = () => {
		// mute 테스트 코드
		if (this.props.videoLen > 1) {
			// 초기화
			this.setState({ isPlaying: false, duration: null });
			this.props.nextVideo();
		}
	};
	leftOnClick = () => {
		if (this.props.videoLen > 1) {
			// 초기화
			this.setState({ isPlaying: false, duration: null });
			this.props.prevVideo();
		}
	};
	render() {
		if (!this.props.videoData) return null;
		return (
			<Fragment>
				<video
					ref={video => {
						this.video = video;
					}}
					src={this.props.videoData.video}
					id={`${this.props.videoPrefix || 'video'}_${this.props.data.id}`}
					preload="auto"
					autoPlay={this.props.active}
					// autoPlay
					onEnded={this._onEnded}
					controls={false}
					playsInline
					muted
					onLoadedMetadata={this._onLoadedMetaData}
					onPlay={this._startPlay}
					onPause={this._onPause}
					onPlaying={this._onPlaying}
					onWaiting={this._onWaiting}
					style={{
						backgroundColor: 'black',
						borderRadius: 0,
						minWidth: '100%',
						minHeight: '100%',
						position: 'absolute',
						maxWidth: '375px',
					}}
				/>
				<LeftWrap onClick={this.leftOnClick} />
				<RightWrap onClick={this.rightOnClick} />
			</Fragment>
		);
	}
}
export default DetailVideo;
