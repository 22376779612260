import basic from './기초.png';
import etc from './기타.png';
import nail from './네일.png';
import tool from './도구&툴.png';
import lib from './립.png';
import base from './베이스.png';
import blu from './블러셔.png';
import eye from './아이.png';
import clean from './클렌징.png';

export default {
	basic,
	etc,
	nail,
	tool,
	lib,
	base,
	blu,
	eye,
	clean,
};
