import React, { Component } from 'react';
import { createGlobalStyle } from 'styled-components';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { observer, inject } from 'mobx-react';
import { GOOGLE_KEY } from 'axios/config';
import * as userAPI from 'axios/userAPI';
import * as iosHandler from 'utils/iosHandler';
import Home from 'pages/home';
import HomeWithAProfile from 'pages/homeWithAProfile';
import _Home from 'pages/_home';
import HomeWithSingleContentFromMyProfile from 'pages/homeWithSingleContentFromMyProfile';
import Event from 'pages/event';
import EventList from 'pages/eventList';
import EventDetailRefactor from 'pages/eventDetailRefactor';
import Login from 'pages/login';
import CreatePostPage from 'pages/createPostPage';
import ProductAddPage from 'pages/productAddPage';
import PostSamplePage from 'pages/postSamplePage';
import PostEditSamplePage from 'pages/editPost/postEditSamplePage';
import PostEditPage from 'pages/editPost/postEditPage';
import MyPage from 'pages/myPage';
import MyPageFollow from 'pages/myPageFollow';
import MyPagePosts from 'pages/myPagePosts';
import MySetting from 'pages/mySetting';
import Star from 'pages/star';
import StarUsage from 'pages/star/Usage';
import MyLikePage from 'pages/myLikePage';
import MyProfileUpdate from 'pages/myProfileUpdate';
import MyNotification from 'pages/myNotification';
import Signup from 'pages/signup';
import SignupStyle from 'pages/signupStyle';
import SignupFilter from 'pages/signupFilter';
import UserFollow from 'pages/userFollow';
import LikeList from 'pages/likeList';
import Topics from 'pages/topics';
import Missions from 'pages/missions';
import Population from 'pages/population';
import VideoEditPage from 'pages/videoEditPage';
import EditVideoPage from 'pages/editPost/videoEditPage';
import Sums from 'pages/sums';
import Explore from 'pages/explore';
import AndroidAuth from 'components/AndroidAuth';

import IosCreatePostPage from 'pages/iosApp/iosCreatePostPage';
import IosPostSamplePage from 'pages/iosApp/iosPostSamplePage';
import IosPostEditPage from 'pages/iosApp/iosPostEditPage';
import Search from 'pages/search';

import PostStore from 'stores/postStore';
import CacheStore from 'stores/cacheStore';
import EventStore from 'stores/eventStore';
import UserStore from 'stores/userStore';

import * as config from 'axios/config';
import cookie from 'js-cookie';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#1f75fe' },
  },
  typography: {
    fontFamily: ['Noto Sans CJK KR'].join(','),
  },
});
const GlobalStyle = createGlobalStyle`
  html,body {
    background-color: #f5f5f5 !important;
    margin:0;
		padding:0;
    #root {
      width:100%;
      // min-height: 100vh;
    }
  }
    .am-navbar-light{
        background-color:rgb(248,248,248) !important;
    }
    .am-tab-bar{
        max-width: 1024px;
        width: 100%;
        height: 62px !important;
        background-color:white !important;
        // position: absolute;
        // bottom: 0;
    }
    .am-tab-bar-tab{
				cursor:pointer;
				height: 100%;
    }
    .am-tabs-default-bar{
        background-color: transparent !important;
    }
    .am-tabs-default-bar-top .am-tabs-default-bar-prevpage, .am-tabs-default-bar-bottom .am-tabs-default-bar-prevpage{
        background: linear-gradient(to right, rgb(248,248,248), rgba(255, 255, 255, 0)) !important;
    }
    .am-tabs-default-bar-top .am-tabs-default-bar-nextpage, .am-tabs-default-bar-bottom .am-tabs-default-bar-nextpage {
        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(248,248,248)) !important;
    }
    .am-grid .am-flexbox{
        background-color : transparent !important;
    }
    .am-tabs-tab-bar-wrap {
			padding-bottom: 12px;
			background-color: transparent  !important;
        .am-tab-bar-bar {
					margin-top: 0px;
						border-top : 0;
						height: 58px;
        }
    }
    .am-flexbox-item{
      width:100%;
    }
    .am-grid-item-content{
      padding:0 !important;
    }
   .rmc-picker-popup-header{
        z-index:4 !important;
    }
    .rmc-picker-popup {
        left: 0;
        bottom: 0;
        right: 0;
        position: fixed;
        width: 100%;
        background-color: #fff;
        max-width: 1024px;
        margin: auto;
    }
    .am-tabs-default-bar-underline{
        border: solid 1px #828ff7 !important;
    }
    .am-grid.am-grid-square .am-grid-item:before{
      content: none !important;
    }
    .vjs-control-bar{
        opacity:0;
        z-index:-1;
    }
`;

class App extends Component {
  constructor(props) {
    super(props);
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem('listState');
      window.localStorage.removeItem('listState-individual-sum');
    }
  }
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Provider cacheStore={new CacheStore()}>
          <Provider postStore={new PostStore()}>
            <Provider eventStore={new EventStore()}>
              <Provider userStore={new UserStore()}>
                <div className="App" id="App">
                  <GlobalStyle />
                  <AndroidAuth>
                    <Switch>
                      <Route exact path="/star" component={Star} />
                      <Route exact path="/star/usage" component={StarUsage} />
                      <Route exact path="/event" component={Event} />
                      <Route
                        exact
                        path="/event/:id"
                        component={EventDetailRefactor}
                      />
                      <Route exact path="/login" component={Login} />
                      <Route exact path="/login/apple" component={Login} />
                      <Route
                        exact
                        path="/my-page/follow/:follow"
                        component={MyPageFollow}
                      />
                      <Route
                        exact
                        path="/my-page/update"
                        component={MyProfileUpdate}
                      />
                      <Route path="/my-page/posts" component={MyPagePosts} />
                      <Route path="/my-page" component={MyPage} />

                      <Route exact path="/my-setting" component={MySetting} />
                      <Route path="/my-like" component={MyLikePage} />
                      <Route
                        exact
                        path="/new-post"
                        component={
                          // IosCreatePostPage
                          config.isMobile.IOSWebView()
                            ? IosCreatePostPage
                            : CreatePostPage
                        }
                      />
                      <Route
                        exact
                        path="/add-product"
                        component={ProductAddPage}
                      />
                      <Route
                        exact
                        path="/new-post/sample"
                        component={
                          config.isMobile.IOSWebView()
                            ? IosPostSamplePage
                            : PostSamplePage
                        }
                      />
                      <Route
                        exact
                        path="/new-post/:id"
                        component={VideoEditPage}
                      />
                      <Route
                        exact
                        path="/edit-post/sample"
                        component={
                          config.isMobile.IOSWebView()
                            ? IosPostSamplePage
                            : PostEditSamplePage
                        }
                      />
                      <Route
                        exact
                        path="/edit-post/video/:id"
                        component={EditVideoPage}
                      />
                      <Route
                        exact
                        path="/edit-post/:id"
                        component={
                          // IosPostEditPage
                          config.isMobile.IOSWebView()
                            ? IosPostEditPage
                            : PostEditPage
                        }
                      />
                      <Route
                        exact
                        path="/notification"
                        component={MyNotification}
                      />
                      <Route exact path="/signup" component={Signup} />
                      <Route
                        exact
                        path="/signup/style"
                        component={SignupStyle}
                      />
                      <Route
                        exact
                        path="/signup/filter"
                        component={SignupFilter}
                      />
                      <Route exact path="/topics" component={Topics} />
                      <Route exact path="/missions" component={Missions} />
                      <Route
                        exact
                        path="/profile/:id/follow/:follow"
                        component={UserFollow}
                      />
                      <Route exact path="/post/:id/like" component={LikeList} />
                      <Route
                        exact
                        path="/population/:sumId"
                        component={Population}
                      />
                      <Route exact path="/event-list" component={EventList} />
                      <Route exact path="/explore" component={Explore} />
                      <Route
                        path="/single/:id"
                        name="homeWithSingleContentFromMyProfile"
                        component={HomeWithSingleContentFromMyProfile}
                      />
                      <Route path="/search" component={Search} />
                      <Route
                        exact
                        path="/profile/:profileId"
                        component={HomeWithAProfile}
                      />
                      <Route
                        exact
                        path="/postsOfProfile/:profileId"
                        component={Home}
                      />
                      <Route exact path="/" name="Sum" component={Sums} />
                      <Route
                        exact
                        path="/post/:id"
                        name="Home"
                        component={Home}
                      />
                      <Route exact path="/sum" name="Home" component={Home} />
                      <Route exact path="/sum/:id" component={Home} />
                      <Route exact path="/world/:id" component={Home} />
                      <Route
                        exact
                        path="/following"
                        name="Home"
                        component={Home}
                      />
                      <Route path="/:id" name="Home" component={Home} />
                    </Switch>
                  </AndroidAuth>
                </div>
              </Provider>
            </Provider>
          </Provider>
        </Provider>
      </ThemeProvider>
    );
  }
}

export default App;
