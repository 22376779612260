import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import AddIcon from '@material-ui/icons/AddCircle';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles({
  input: {
    '& input::placeholder': {
      color: 'black',
    },
  },
  control: {
    background: 'white',
    padding: '8px 12px',
    width: '100%',
  },
  up: {
    borderRadius: '10px 10px 0px 0px',
    borderBottom: '1px solid #efefef',
    boxShadow: '0 2px 5px rgb(0 0 0 / 10%)',
  },
  down: {
    borderRadius: '0px 0px 10px 10px',
    boxShadow: '0 2px 5px rgb(0 0 0 / 10%)',
  },
  item: { marginBottom: 8 },
});

const InfoList = ({ items, onChange }) => {
  const classes = useStyles();
  const onTextChange = (name, i) => (e) => {
    const { value } = e.target;
    const newItems = items.slice();
    newItems[i][name] = value;
    onChange(newItems);
  };
  return (
    <div>
      {items.map(({ shop, link, id }, i) => (
        <div key={id} className={classes.item}>
          <FormControl className={clsx(classes.control, classes.up)}>
            <Input
              className={classes.input}
              placeholder="제목을 입력해주세요."
              value={shop}
              onChange={onTextChange('shop', i)}
              disableUnderline
              inputProps={{ className: classes.input }}
            />
          </FormControl>
          <FormControl className={clsx(classes.control, classes.down)}>
            <Input
              className={classes.input}
              fullWidth
              placeholder="링크를 입력해주세요."
              id="input-with-icon-adornment"
              onChange={onTextChange('link', i)}
              value={link}
              disableUnderline
              endAdornment={
                <InputAdornment
                  position="end"
                  onClick={() => {
                    onChange(items.filter((_, j) => j !== i));
                  }}
                >
                  <ClearIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
      ))}
      <div>
        <Button
          color="primary"
          fullWidth
          onClick={() =>
            onChange([
              ...items,
              { id: `${Math.random()}`, shop: '', link: '', shopName: '' },
            ])
          }
        >
          <AddIcon />
          정보 추가
        </Button>
      </div>
    </div>
  );
};
export default InfoList;
