import React from "react";
import { observer, inject } from "mobx-react";
import InfiniteScroll from "react-infinite-scroller";
import GridList from "pages/components/gridList";
import ItemGridList from "pages/components/itemGridList";
import SpinnerCurtain from "components/loadingSpinner/spinnerCurtain";

import * as postAPI from "axios/postAPI";
import * as userAPI from "axios/userAPI";

@inject("userStore")
@observer
class InfiniteList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: false,
      error: false,
      page: 0,
      next: null,
    };
  }
  requestData = async ({ page = 1 }) => {
    let reqFunc = this.props.post
      ? postAPI.getPostList
      : userAPI.getUserItemList;

    const params = {
      profile: this.props.profile.id,
      headers: this.props.userStore.auth_token
        ? { Authorization: `Bearer ${this.props.userStore.auth_token}` }
        : false,
      page,
    };
    const { data } = await reqFunc(params);
    return data;
  };
  loadMore = async () => {
    if (!this.state.loading && !this.state.error) {
      this.setState({ loading: true });
      const next = this.state.page + 1;
      try {
        const data = await this.requestData({
          page: next,
        });
        const prevData = this.state.data || [];
        this.setState({
          data: prevData.concat(
            data.data.filter(
              (o) => prevData.findIndex((j) => j.id === o.id) < 0
            )
          ),
          page: next,
          next: data.next,
          loading: false,
        });
      } catch (e) {
        this.setState({
          error: true,
          loading: false,
        });
      }
    }
  };
  render() {
    const hasMore =
      (this.state.page === 0 || Boolean(this.state.next)) &&
      !this.state.loading;
    return (
      <InfiniteScroll
        pageStart={this.state.page}
        loadMore={this.loadMore}
        hasMore={hasMore}
        loader={
          <div className="loader" style={{ textAlign: "center" }} key={0}></div>
        }
        useWindow={false}
      >
        {this.props.post ? (
          <GridList data={this.state.data} onClick={this.props.onClick} />
        ) : (
          <ItemGridList data={this.state.data} onClick={this.props.onClick} />
        )}
        {this.state.loading && <SpinnerCurtain />}
      </InfiniteScroll>
    );
  }
}

export default InfiniteList;
