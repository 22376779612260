export function switchProfileHeight(v) {
  var height;
  switch (v) {
    case "전체":
      height = 0;
      break;
    case "155":
      height = 1;
      break;
    case "160":
      height = 2;
      break;
    case "165":
      height = 3;
      break;
    case "170":
      height = 4;
      break;
    case "175":
      height = 5;
      break;
    case "180":
      height = 6;
      break;
  }
  return height;
}

export function switchProfileSize(v) {
  var size;
  switch (v) {
    case "전체":
      size = 0;
      break;
    case "XS":
      size = 1;
      break;
    case "S":
      size = 2;
      break;
    case "M":
      size = 3;
      break;
    case "L":
      size = 4;
      break;
    case "XL":
      size = 5;
      break;
  }
  return size;
}

export function switchProfileSkin(v) {
  var skin;
  switch (v) {
    case "전체":
      skin = 0;
      break;
    case "지성":
      skin = 1;
      break;
    case "건성":
      skin = 2;
      break;
    case "복합성":
      skin = 3;
      break;
    case "민감성":
      skin = 4;
      break;
  }
  return skin;
}
