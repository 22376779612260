import styled from "styled-components";

/* css모음 */
export const Wrap = styled.div`
  /*메인 wrap*/
  max-width: 1024px;
  min-height: 100vh;
  position: relative;
  margin: 0 auto;
  overflow-y: hidden;
  background: rgb(248, 248, 248);
`;
export const BlackWrap = styled.div`
  max-width: 1024px;
  min-height: 100vh;
  position: relative;
  margin: 0 auto;
  background: rgb(0, 0, 0);
`;
export const IndexWrap = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  width: 100%;
  height: 100vh;
  max-width: 1024px;
  overflow-x: hidden;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    display: none !important;
  }
  h2 {
    margin-bottom: 0;
    margin-left: 2.5%;
  }

  .slideWrap {
    // text-align: center;
    margin-top: 16px;
    margin-bottom: 16px;
    width: auto;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0px 16px;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none !important;
    }
  }

  .slideWrap .items {
    display: inline-block;
    margin-right: 10px;
    border-radius: 50px;
    font-size: 16px;
    color: #aeaeae;
    font-family: "Noto Sans CJK KR";
    p {
      padding: 6px 2px;
      margin-bottom: 0px;
    }
  }
  .slideWrap .items:first-child {
    margin-left: 36px;
  }
  .mainTabWrap {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    width: auto;
  }
  .footerWrap {
    z-index: 1000;
  }
`;

export const MainEventWrap = styled.div`
  width: 88%;
  height: 80px;
  margin: 0 auto 30px;
  border-radius: 10px;
  background: -webkit-linear-gradient(left, #8ea0fb 0%, #82d9fa 100%);
`;

export const MainDetailWrap = styled.div`
   /* 클릭X, 메인*/
    margin-bottom:15px;
    transition: all 1s ease;
    .profileImg {
      position: relative;
      cursor:pointer;
      width: 100%;
      padding: 0px;
      box-sizing: border-box;
        img{
          width: 100%;
          height:100%;
          border-radius: 10px;
          -moz-box-shadow: 1px 5px 10px 3px #ddd;
          -webkit-box-shadow: 1px 5px 10px 3px #ddd;
          box-shadow: 1px 3px 9px 2px #ddd;
        }
    }
      .slideImgWrap {
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
         -ms-overflow-style: none;
        ::-webkit-scrollbar { 
        display: none !important;
      }
    }
        .slideImgWrap .imgItems {
            display: inline-block;
            margin-left: 10px;
            border-radius: 20px;
            background: -webkit-linear-gradient(left, #827be6 0%,#b47eea 100%);
            color:#fff;
            height: 30px;
                 p{
                  padding: 5px;
                }
        }
        .slideImgWrap .imgItems:first-child{
          margin-left:0;
        }
 
    .detail{
        display: none; 
    }
    .backBtn{
        display: none;
        color:#fff;
    }
   
    /*              클릭후 Detailpage*               */
      /*&.focused { 
        transform: scale(1.6) translate(0px, 0px);
        height:100vh;
        background: white;
        z-index:99;
            
        .roundImgWrap .rImgItems {
        }
       .footerWrap{
       display: none;
       }*/
      
   }
`;

export const TopWrap = styled.div`
  top: 0px;
  left: 0px;
  height: 7%;
  right: 0px;
  overflow: hidden;
  width: 100%;
`;
