import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "components/footer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import LeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
  appBar: {
    background: "#ffffff",
  },
  toolBar: {
    textAlign: "center",
  },
  layout: {
    background: "#f5f5f5",
  },
  icon: {
    color: "black",
  },
  title: {
    flexGrow: 1,
    fontSize: 17,
  },
  bottomMargin: {
    height: 34,
  },
  paddingTop: {
    paddingTop: 60,
  },
});
const Layout = ({
  back,
  footer,
  title,
  rightIcon,
  onRightIconClick,
  children,
}) => {
  const classes = useStyles();
  const RightIcon = rightIcon;
  return (
    <div>
      <AppBar elevation={0} position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => window.history.back()}
          >
            <LeftIcon className={classes.icon} />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          {rightIcon && (
            <IconButton color="inherit" onClick={onRightIconClick}>
              <RightIcon className={classes.icon} />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <div className={classes.paddingTop}>{children}</div>

      {footer && (
        <>
          <div className={classes.bottomMargin}></div>
          <Footer />
        </>
      )}
    </div>
  );
};
export default Layout;
