import React, { Fragment } from "react";
import { Icon } from "antd";
import styled, { css } from "styled-components";
import { observer, inject } from "mobx-react";

import LikeClickedIcon from "components/myIcons/likeClickedIcon";
import LikeIcon from "components/myIcons/likeIcon";

import * as postAPI from "axios/postAPI";

const CountText = styled.p`
  display: block;
  margin-top: 0px !important;
  font-family: "Noto Sans CJK KR";
  font-weight: 500;
  line-height: 25px;
  font-size: 14px;
  font-family: "Noto Sans CJK KR";
  font-weight: 500;
  line-height: 25px;
  text-align: center;
  color: black !important;
  // color: rgb(115, 115, 115) !important;
`;

@inject("userStore")
@inject("postStore")
@observer
class LikeBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_liked: this.props.is_liked,
      option: this.props.option,
      id: this.props.id,
      count: this.props.count,
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.id != this.props.id) {
      this.setState({
        is_liked: this.props.is_liked,
        option: this.props.option,
        id: this.props.id,
        count: this.props.count,
      });
    }
  }
  handleLike = async (e) => {
    e.stopPropagation();
    const token = this.props.userStore.getTokenOrToggleLogin();
    if (token) {
      const like = !this.state.is_liked;
      if (this.props.doAfterClick) {
        this.props.doAfterClick(like);
      }
      const req = {
        id: this.props.id,
        object: this.props.option,
        headers: { Authorization: `Bearer ${token}` },
      };
      await Promise.all([
        postAPI.patchMyLike(req).then((res) => {
          return res.data;
        }),
      ]);
      if (this.state.is_liked) {
        this.setState({
          is_liked: like,
          count: parseInt(this.state.count) - 1,
        });
      } else {
        this.setState({
          is_liked: like,
          count: parseInt(this.state.count) + 1,
        });
      }
      this.props.toggleIsLike && this.props.toggleIsLike();
    } else {
      if (this.props.mode == "comment") {
        this.props.postStore.toggleComment();
      }
    }
  };

  render() {
    return (
      <Fragment>
        {this.props.main ? (
          this.state.is_liked ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="24"
              viewBox="0 0 26 24"
              onClick={this.handleLike}
            >
              <g>
                <g>
                  <path
                    fill="#1f75fe"
                    d="M12.573 23.382a.604.604 0 0 1-.308-.084c-.12-.067-2.95-1.714-5.825-4.41-1.701-1.595-3.058-3.227-4.038-4.85C1.154 11.966.522 9.9.522 7.894c0-3.815 2.988-6.92 6.66-6.92 1.243 0 2.564.484 3.718 1.363a7.139 7.139 0 0 1 1.673 1.792 7.139 7.139 0 0 1 1.673-1.792C15.401 1.46 16.721.976 17.964.976c3.673 0 6.66 3.104 6.66 6.92 0 2.004-.633 4.07-1.88 6.141-.978 1.624-2.337 3.256-4.036 4.85-2.875 2.697-5.706 4.344-5.825 4.41a.604.604 0 0 1-.309.085z"
                  />
                  <path
                    fill="none"
                    stroke="#1f75fe"
                    stroke-miterlimit="20"
                    stroke-width=".3"
                    d="M12.573 23.382a.604.604 0 0 1-.308-.084c-.12-.067-2.95-1.714-5.825-4.41-1.701-1.595-3.058-3.227-4.038-4.85C1.154 11.966.522 9.9.522 7.894c0-3.815 2.988-6.92 6.66-6.92 1.243 0 2.564.484 3.718 1.363a7.139 7.139 0 0 1 1.673 1.792 7.139 7.139 0 0 1 1.673-1.792C15.401 1.46 16.721.976 17.964.976c3.673 0 6.66 3.104 6.66 6.92 0 2.004-.633 4.07-1.88 6.141-.978 1.624-2.337 3.256-4.036 4.85-2.875 2.697-5.706 4.344-5.825 4.41a.604.604 0 0 1-.309.085z"
                  />
                </g>
              </g>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="24"
              viewBox="0 0 26 24"
              onClick={this.handleLike}
            >
              <g>
                <g>
                  <path
                    fill="#1f75fe"
                    d="M11.972 5.797c.087.27.329.45.601.45a.638.638 0 0 0 .602-.45c.53-1.657 2.628-3.504 4.789-3.504 2.973 0 5.392 2.513 5.392 5.603 0 4.006-2.98 7.648-5.481 9.997a35.163 35.163 0 0 1-5.302 4.069 35.239 35.239 0 0 1-5.301-4.069c-2.5-2.35-5.481-5.994-5.481-9.997 0-3.09 2.418-5.603 5.392-5.603 2.16 0 4.258 1.847 4.789 3.504zm.91 17.5c.028-.015 2.887-1.651 5.826-4.409 1.7-1.596 3.059-3.227 4.037-4.85 1.247-2.072 1.879-4.139 1.879-6.142 0-3.816-2.988-6.92-6.66-6.92-1.243 0-2.564.484-3.718 1.362a7.17 7.17 0 0 0-1.673 1.792A7.146 7.146 0 0 0 10.9 2.338C9.746 1.46 8.426.976 7.183.976 3.51.976.523 4.08.523 7.896c0 2.003.632 4.07 1.879 6.142.982 1.627 2.341 3.26 4.038 4.85 2.929 2.748 5.796 4.393 5.824 4.41a.601.601 0 0 0 .31.084.59.59 0 0 0 .308-.084z"
                  />
                  <path
                    fill="none"
                    stroke="#1f75fe"
                    stroke-miterlimit="20"
                    stroke-width=".3"
                    d="M11.972 5.797c.087.27.329.45.601.45a.638.638 0 0 0 .602-.45c.53-1.657 2.628-3.504 4.789-3.504 2.973 0 5.392 2.513 5.392 5.603 0 4.006-2.98 7.648-5.481 9.997a35.163 35.163 0 0 1-5.302 4.069 35.239 35.239 0 0 1-5.301-4.069c-2.5-2.35-5.481-5.994-5.481-9.997 0-3.09 2.418-5.603 5.392-5.603 2.16 0 4.258 1.847 4.789 3.504zm.91 17.5c.028-.015 2.887-1.651 5.826-4.409 1.7-1.596 3.059-3.227 4.037-4.85 1.247-2.072 1.879-4.139 1.879-6.142 0-3.816-2.988-6.92-6.66-6.92-1.243 0-2.564.484-3.718 1.362a7.17 7.17 0 0 0-1.673 1.792A7.146 7.146 0 0 0 10.9 2.338C9.746 1.46 8.426.976 7.183.976 3.51.976.523 4.08.523 7.896c0 2.003.632 4.07 1.879 6.142.982 1.627 2.341 3.26 4.038 4.85 2.929 2.748 5.796 4.393 5.824 4.41a.601.601 0 0 0 .31.084h0a.59.59 0 0 0 .308-.084z"
                  />
                </g>
              </g>
            </svg>
          )
        ) : this.state.is_liked ? (
          <LikeClickedIcon
            style={{
              margin: this.props.margin,
              cursor: "pointer",
            }}
            onClick={this.handleLike}
            marginLeft={this.props.marginLeft}
            marginBottom={this.props.marginBottom}
            marginTop={this.props.marginTop}
          />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            onClick={this.handleLike}
            style={{
              marginBottom: 5,
            }}
          >
            <path
              fill={
                this.props.noClickedColor
                  ? this.props.noClickedColor
                  : "#bebebe"
              }
              d="M5.5 2c1.515 0 2.992.959 3.544 2.295a1 1 0 0 0 1.889.065h.007C11.46 2.99 12.96 2 14.5 2c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05l-.1.1-.1-.1C5.14 11.24 2 8.39 2 5.5 2 3.5 3.5 2 5.5 2zm3.15 15.13c.379.344.86.517 1.34.517.484 0 .969-.175 1.35-.527l.11-.1C16.7 12.26 20.13 9.15 20 5.27c-.06-1.7-.94-3.32-2.34-4.28-2.64-1.8-5.9-.96-7.66 1.1C8.24.03 4.98-.82 2.34.99.94 1.95.06 3.57 0 5.28c-.14 3.88 3.3 6.99 8.55 11.76l.1.09z"
            />
          </svg>
        )}
        {this.props.indicate ? (
          <CountText
            liked={this.state.is_liked}
            style={{
              marginTop: this.props.textMarginTop
                ? this.props.textMarginTop
                : "-20px",
              ...this.props.style,
            }}
          >
            {this.state.count}
          </CountText>
        ) : null}
      </Fragment>
    );
  }
}
export default LikeBtn;
