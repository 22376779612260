import React, { Component } from "react";
import styled, { css } from "styled-components";

const IconWrap = styled.i``;

class LikeMiniIcon extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <IconWrap>
        <svg
          style={{ verticalAlign: "text-bottom" }}
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="16"
          viewBox="0 0 18 16"
        >
          <defs>
            <mask id="1b0ib" width="2" height="2" x="-1" y="-1">
              <path fill="#fff" d="M4 3h10v10H4z" />
              <path d="M12.83 3.995c-1.32-.9-2.95-.48-3.83.55-.88-1.03-2.51-1.455-3.83-.55-.7.48-1.14 1.29-1.17 2.145-.07 1.94 1.65 3.495 4.275 5.88l.05.045a.998.998 0 0 0 1.345-.005l.055-.05C12.35 9.63 14.065 8.075 14 6.135c-.03-.85-.47-1.66-1.17-2.14z" />
            </mask>
            <filter
              id="1b0ia"
              width="40"
              height="40"
              x="-11"
              y="-12"
              filterUnits="userSpaceOnUse"
            >
              <feOffset in="SourceGraphic" result="FeOffset1043Out" />
              <feGaussianBlur
                in="FeOffset1043Out"
                result="FeGaussianBlur1044Out"
                stdDeviation="2 2"
              />
            </filter>
          </defs>
          <g>
            <g>
              <g filter="url(#1b0ia)">
                <path
                  fill="none"
                  d="M12.83 3.995c-1.32-.9-2.95-.48-3.83.55-.88-1.03-2.51-1.455-3.83-.55-.7.48-1.14 1.29-1.17 2.145-.07 1.94 1.65 3.495 4.275 5.88l.05.045a.998.998 0 0 0 1.345-.005l.055-.05C12.35 9.63 14.065 8.075 14 6.135c-.03-.85-.47-1.66-1.17-2.14z"
                  mask='url("#1b0ib")'
                />
                <path
                  fillOpacity=".2"
                  d="M12.83 3.995c-1.32-.9-2.95-.48-3.83.55-.88-1.03-2.51-1.455-3.83-.55-.7.48-1.14 1.29-1.17 2.145-.07 1.94 1.65 3.495 4.275 5.88l.05.045a.998.998 0 0 0 1.345-.005l.055-.05C12.35 9.63 14.065 8.075 14 6.135c-.03-.85-.47-1.66-1.17-2.14z"
                />
              </g>
              <path
                fill="#f8f8f8"
                d="M12.83 3.995c-1.32-.9-2.95-.48-3.83.55-.88-1.03-2.51-1.455-3.83-.55-.7.48-1.14 1.29-1.17 2.145-.07 1.94 1.65 3.495 4.275 5.88l.05.045a.998.998 0 0 0 1.345-.005l.055-.05C12.35 9.63 14.065 8.075 14 6.135c-.03-.85-.47-1.66-1.17-2.14z"
              />
            </g>
          </g>
        </svg>
      </IconWrap>
    );
  }
}

export default LikeMiniIcon;
