import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    background: 'white',
    width: '100%',
    maxWidth: '90%',
    borderRadius: 8,
  },
  title: {
    fontSize: 16,
    fontWeight: 900,
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  textField: {
    paddingTop: 4,
    borderRadius: 12,
    background: 'rgba(120, 132, 158, 0.08)',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
    '& input': {
      paddingTop: 12,
      paddingBottom: 18,
    },
  },
  body: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 32,
  },
  buttonNegative: {
    color: 'black',
    background: '#efefef',
    height: 48,
    width: '50%',
    borderRadius: 0,
  },
  button: {
    color: 'white',
    background: '#1f75fe',
    height: 48,
    width: '50%',
    borderRadius: 0,
    borderRadius: 0,
    '&:hover': {
      background: '#1f75fe',
    },
  },
  days: {
    textAlign: 'center',
    fontSize: 40,
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  textField2: {
    width: 74,
    height: 84,
    paddingTop: 0,
    paddingBottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& input': {
      fontSize: 40,
    },
  },
});

const Modal = ({ open, onClose, value, onChange, onSubmit, onRemove }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.root }}>
      <div>
        <div className={classes.title}>미션</div>
        <div className={classes.days}>
          D-
          <TextField
            className={clsx(classes.textField, classes.textField2)}
            value={value.days}
            type="number"
            variant="outlined"
            placeholder="00"
            onChange={(e) =>
              e.target.value < 0 ? () => {} : onChange('days')(e)
            }
          />
        </div>
        <div className={classes.body}>
          <TextField
            className={classes.textField}
            value={value.mission}
            fullWidth
            variant="outlined"
            placeholder="D-n일. 미션명 입력 (최대 20자)"
            onChange={(e) =>
              e.target.value.length > 20 ? () => {} : onChange('mission')(e)
            }
            margin="normal"
          />
          <TextField
            className={classes.textField}
            value={value.description}
            fullWidth
            multiline
            variant="outlined"
            placeholder="미션 설명 입력"
            onChange={(e) => onChange('missionDescription')(e)}
          />
        </div>

        <Button
          className={classes.buttonNegative}
          onClick={() => {
            onRemove();
          }}
        >
          삭제하기
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() =>
            onSubmit({
              value: value.mission,
              daysValue: value.days,
              description: value.description,
            })
          }
        >
          저장하기
        </Button>
      </div>
    </Dialog>
  );
};
export default Modal;
