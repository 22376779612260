import { Grid } from 'antd-mobile';
import React from 'react';
import styled, { css } from 'styled-components';
import Item from './components/item';

const List = styled(Grid)`
	display: block;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	padding: 0px 16px;
	padding-bottom: 110px;
	${props =>
		props.mode == 'user' &&
		css`
			padding-bottom: 16px;
		`}
	div.am-flexbox-item.am-grid-item:nth-child(odd) {
		margin-right: 3%;
	}
	div.am-flexbox-item.am-grid-item:nth-child(even) {
	}
`;

class GridList extends React.Component {
	render() {
		return (
			<List
				mode={this.props.mode}
				data={this.props.data}
				columnNum={2}
				hasLine={false}
				activeStyle={false}
				itemStyle={{
					height: 232,
					marginTop: 12,
				}}
				renderItem={dataItem => {
					return (
						<Item
							data={dataItem}
							likeBtn={false}
							onClick={() => this.props.onClick(dataItem)}
						/>
					);
				}}
			/>
		);
	}
}

export default GridList;
