import React, { Component } from 'react';
import styled, { css } from 'styled-components';

class ProfileIcon extends Component {
	constructor() {
		super();
	}

	render() {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="20"
				viewBox="0 0 20 20"
				{...this.props}
			>
				<g>
					<g>
						<path d="M19.518 17.138v1.19c0 .654-.536 1.19-1.19 1.19H1.672c-.654 0-1.19-.536-1.19-1.19v-1.19c0-3.164 6.341-4.759 9.518-4.759 3.177 0 9.518 1.595 9.518 4.76zM5.24 5.241c0-2.63 2.13-4.759 4.759-4.759 2.63 0 4.759 2.13 4.759 4.76C14.759 7.87 12.629 10 10 10a4.758 4.758 0 0 1-4.759-4.759z" />
					</g>
				</g>
			</svg>
		);
	}
}

export default ProfileIcon;
