import React from 'react';
import icon from './bookmarkIcon.png';
import iconClicked from './bookmarkIcon_clicked.png';

export default ({ isClicked, ...props }) => (
	<img
		src={isClicked ? iconClicked : icon}
		{...props}
		style={{ width: 14, height: 18 }}
	/>
);
