import React, { Component } from "react";
import styled, { css } from "styled-components";
import "./style.css";

// Libs
import { ClipLoader, PropagateLoader } from "react-spinners";

let Wrapper = styled.div`
  line-height: 250px;
  width: 50px;
  margin: auto;
`;

export default class LoadingSpinner extends Component {
  componentDidMount() {}
  render() {
    if (this.props.height) {
      Wrapper = styled.div`
        line-height: ${this.props.height};
        text-align: center;
      `;
    }
    return (
      <Wrapper>
        <ClipLoader
          sizeUnit={"px"}
          size={50}
          color={"rgb(130, 143, 247)"}
          loading={true}
        />
      </Wrapper>
    );
  }
}
