/* global Swiper */
import React, { Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import styled, { css } from 'styled-components';
import DetailCommentIcon from 'components/myIcons/detailCommentIcon';
import PriceTagIcon from 'components/myIcons/priceTagIcon';
import DetailMoreIcon from 'components/myIcons/detailMoreIcon';
import { Wrap } from '../components/style/custom-styled-components';
import PostDetail from 'pages/postDetail';
import default_profile from 'images/default_profile.png';
import LikeBtn from 'components/likeBtn';
import MutedIcon from 'components/myIcons/mutedIcon';
import SpinnerCurtain from 'components/loadingSpinner/spinnerCurtain';
import PostMoreInfoModal from 'components/popModal/postMoreInfoModal';
import { CSSTransition } from 'react-transition-group';
import ProductList from 'containers/productList';
import CommentModal from 'components/popModal/commentModal';
import ItemLinkRequiredModal from 'components/popModal/itemLinkRequiredModal';
import MyFilterModal from 'components/popModal/myFilterModal';
import ReportModal from 'components/popModal/reportModal';
import LoginRequiredModal from 'components/popModal/loginRequiredModal';
import './videoSwiper.css';

const PostInfoWrap = styled.div`
  position:absolute;
  z-index: 1000;
  display:flex;
  align-items: flex-end;
  width:100%;
  padding-left:16px;
  padding-right:16px
  bottom:60px;
  left:0px;
	@media (min-width: 480px) {
		position: absolute;
	}
`;
const ProfileImage = styled.div`
	width: 42px;
	height: 42px;
	border: 1px solid #ffffff;
	flex: 0 0 auto;
	margin-right: 8px;
	border-radius: 50%;
	display: flex;
	${props =>
		props.mode == 'card' &&
		css`
			width: 36px;
			height: 36px;
		`}
	${props =>
		props.isDefaultProfileImg &&
		css`
			background-color: rgb(255, 255, 255);
		`}
  div {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-size: cover;
		background-position: center;
		${props =>
			props.isDefaultProfileImg &&
			css`
				margin: auto;
				width: 21px;
				height: 21px;
				border-radius: 0;
				${props =>
					props.mode == 'card' &&
					css`
						width: 18px;
						height: 18px;
					`}
			`}
	}
`;
const PostTags = styled.p`
	margin: 0px;
	span {
		text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
		color: #ffffff;
		font-family: 'Noto Sans CJK KR';
		font-size: 14px;
		font-weight: 500;
	}
	span.post-tag {
		cursor: pointer;
		:hover {
			opacity: 0.8;
		}
	}
`;
const PostAuthor = styled.p`
	margin: 0px;
	margin-bottom: 8px;
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 12px;
	font-weight: 400;
`;
const PostTitle = styled.p`
	margin: 0px;
	margin-bottom: 8px;
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 18px;
	font-weight: 700;
`;
const IconWrap = styled.div`
	flex: 0 0 auto;
	width: 40px;
	height: 40px;
	margin: auto;
	margin-top: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background: rgba(0, 0, 0, 0.2);
	${props =>
		props.gradient &&
		css`
			box-shadow: 0 2px 10px rgba(138, 99, 223, 0.5);
			background-image: linear-gradient(100deg, #828ff7 0%, #b08df7 100%);
		`}
`;
const IconContainer = styled.div`
	text-align: center;
`;
const IconText = styled.span`
	color: white;
`;
const Detail = styled.span`
	position: absolute;
	right: 16px;
`;
const ProgressBarWrap = styled.div`
	position: absolute;
	z-index: 10;
	display: flex;
	top: 6px;
	left: 6px;
	right: 6px;
	height: 3px;
	background-color: transparent;
	width: calc(100% - 12px);
`;
const ProgressBar = styled.div`
	height: 100%;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.2);
	position: relative;
`;
const BarSpace = styled.div`
	width: 5px;
	height: 100%;
	background-color: transparent;
`;
const ProgressFilter = styled.div`
	height: 100%;
	width: 0;
	background-color: rgba(255, 255, 255, 0.6);
	position: absolute;
	top: 0px;
	left: 0px;
	transition: width 10s linear;
`;
const SwiperWrap = styled.div`
	background: black;
	position: fixed;
	bottom: 0;
	top: 0;
	width: 100%;
	@media (min-width: 480px) {
		position: static;
	}
`;
const GradientFilter = styled.div`
	z-index: 1;
	position: absolute;
	left: 0px;
	width: 100%;
	height: 25%;

	${props =>
		props.top &&
		css`
			top: 0px;
			background-image: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0.3) 0%,
				rgba(0, 0, 0, 0) 100%
			);
		`}

	${props =>
		props.bottom &&
		css`
			bottom: 0px;
			background-image: linear-gradient(
				to top,
				rgba(0, 0, 0, 0.3) 0%,
				rgba(0, 0, 0, 0) 100%
			);
		`}
`;
const RecommendationOrFollowing = styled.div`
	position: absolute;
	z-index: 10;
	top: 22px;
	width: 100%;
	color: white;
	text-align: center;
`;
const Badge = styled.span`
	background-color: white;
	border-radius: 50%;
	width: 14px;
	height: 14px;
	display: flex;
	font-size: 10px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-left: 14px;
	margin-top: -14px;
	position: absolute;
`;
const ProfilePic = styled.div`
	// transition: background-image 0.5s ease;
`;

@inject('userStore')
@inject('postStore')
@observer
class Home extends React.Component {
	constructor(props) {
		super(props);

		// 복사된 URL 통해서 들어왔을 경우 copiedId에 id 저장
		// 1개만 로딩할 수 있도록 하기 위함
		this.state = {
			ready: false,
			postMoreInfoData: false,
			postIdForComment: false,
			postsMap: {},
			postData: {
				video: [],
				profile: {},
				comment: { count: 0 },
				like: { count: 0 },
				item: [],
				tag: [],
			},
			prgBarWidth: [
				{
					id: 0,
					width: '0%',
					currentDuration: 0,
				},
			],
			founding: false,
		};
		// this.state.post = post;

		// 임시
		this.state.clicked = false;

		// 스와이퍼
		this.state.swiper = null;

		// 렌더링할 post
		this.state.slides = [];
		this.state.virtualData = {
			slides: [],
		};

		// 현재 슬라이드 id
		this.state.nowId = null;

		// 뮤트
		this.state.muted = true;

		// post data 의 next 키
		this.state.next = null;

		// 상품창 표시
		this.state.productListOn = false;

		// 프로필 이미지 원본
		this.state.minimizedProfileImgUrl = null;

		this.state.deactivateVideo = Boolean(this.state.profileId);
	}
	async componentDidMount() {
		this.swiper = new Swiper(`.${this.props.swiperClassName}`, {
			direction: 'vertical',

			virtual: {
				slides: this.state.slides,
				renderExternal: data => {
					this.setState({
						virtualData: data,
					});
				},
				addSlidesBefore: 4,
				addSlidesAfter: 4,
			},
			on: {
				// 마지막에 도달했을 땐 새로 로딩해야한다.
				reachEnd: () => {
					if (this.swiper) {
						if (this.props.index > 1 && this.props.hasMore) {
							this.props.loadMore();
						}
					}
				},
				slideChange: () => {
					this.props.handleIndex(this.swiper.realIndex);
					setTimeout(() => {
						const slide = this.props.posts[this.swiper.realIndex];
						if (slide) {
							this.setState({
								nowId: slide.id,
								profileId: slide.profile.id,
							});
							if (this.props.onChange) {
								this.props.onChange(slide);
							}
						}
					}, 200);
				},
			},
		});
		window.swiperEle = this.swiper;

		this.setState({ swiper: this.swiper });

		// 터치 이벤트 추가
		document.body.addEventListener('touchstart', () => {
			// 터치 시작시 다음 영상 미리 소리 재생하기
			// 2가지 이슈 커버 완료
			// 1. 실제 슬라이딩 이뤄지지 않았을 때 멈추기
			// 2. 슬라이드 위로 이동 시 위에 있는 영상이 재생 되어야 한다.
			if (!this.state.muted) {
				// 슬라이드 데이터 찾기
				const prevSlide = this.state.slides[this.swiper.realIndex - 1];
				const nextSlide = this.state.slides[this.swiper.realIndex + 1];

				// id 구하기
				const prevSlideId = `${this.props.videoIdPrefix}_${prevSlide.id}`;
				const nextSlideId =
					nextSlide && `${this.props.videoIdPrefix}_${nextSlide.id}`;

				// DOM Element 찾기
				const prevSlideEle =
					prevSlideId && document.getElementById(prevSlideId);
				const nextSlideEle = document.getElementById(nextSlideId);

				// 위와 아래의 것의 muted를 푼다.
				if (prevSlideEle) {
					prevSlideEle.muted = false;
				}
				if (nextSlideEle) {
					nextSlideEle.muted = false;
				}
			}
		});
	}
	componentWillReceiveProps(nextProps) {
		if (this.props.posts !== nextProps.posts && nextProps.posts.length > 0) {
			// posts.length가 0이란 것은 슬라이드 클릭 직후라는 것
			// 클릭 직후인 상태 && 마지막 슬라이드 && hasMore => loadMore
			console.log('new videoset');
			if (
				this.props.posts.length === 0 &&
				nextProps.posts.length - 1 === nextProps.index &&
				nextProps.hasMore
			) {
				console.log('loadmore');
				this.props.loadMore();
			}

			// 기존 포스트에 없었던 포스트를 새로운 포스트로 인식한다.
			const newPosts = nextProps.posts.filter(
				(nextPost, i) =>
					!this.props.posts[i] || this.props.posts[i].id !== nextPost.id,
			);
			console.log('newPosts', newPosts);

			this.swiper.virtual.appendSlide(newPosts);
			// this.swiper.update();

			// 이 부분 리팩토링 되어야 한다.
			const postNow = nextProps.posts[nextProps.index];
			console.log('postNow', postNow);

			this.setState({
				ready: true,
				posts: nextProps.posts,
				nowId: postNow ? postNow.id : null,
			});

			this.swiper.slideTo(nextProps.index, 0);
		}
		// 포스트 삭제
		if (this.props.posts.length > 0 && nextProps.posts.length === 0) {
			setTimeout(() => {
				this.swiper.virtual.removeAllSlides();
				this.setState({
					postsMap: {},
					slides: [],
					virtualData: { slides: [] },
				});
			}, 300);
		}
		if (this.props.index !== nextProps.index) {
			this.swiper.slideTo(nextProps.index, 0);
		}
	}
	componentWillUnmount() {
		this.props.postStore.clearAllToggle();
		this.props.userStore.clearAllToggle();
	}
	componentDidUpdate(prevProps, prevState) {
		const postDataNow =
			this.state.postsMap[this.state.nowId] || this.state.postData;
		const postDataPrev =
			prevState.postsMap[prevState.nowId] || prevState.postData;
		if (postDataNow !== postDataPrev) {
			this.initPrgBarWidth(postDataNow.video.length);
			this.getMinimizedProfileImgUrl(postDataNow.profile.image)
				.then(url => {
					this.setState({ minimizedProfileImgUrl: url });
				})
				.catch(e => {
					this.setState({ minimizedProfileImgUrl: null });
				});
		}
	}
	initPrgBarWidth = length => {
		let arr = [];
		for (var i = 0; i < length; i++) {
			arr.push({
				id: i,
				width: '0%',
				currentDuration: 0,
			});
		}
		this.setState({
			prgBarWidth: arr,
		});
	};
	setPrgBarWidth = (id, currentDuration, width) => {
		this.setState({
			prgBarWidth: this.state.prgBarWidth.map(prg =>
				id == prg.id
					? { ...prg, currentDuration: currentDuration, width: width }
					: prg,
			),
		});
	};
	updatePostsMap = (id, data) => {
		this.setState({
			postsMap: { ...this.state.postsMap, [id]: data },
		});
	};
	handleMoreInfo = post => {
		this.setState({
			postMoreInfoData: post,
		});
		this.props.postStore.toggleMoreInfo();
	};
	getMinimizedProfileImgUrl = originalSrc => {
		// 64px 로 압축된 프로필 이미지가 있는지 확인한다.
		return new Promise((resolve, reject) => {
			const img = document.createElement('img');
			img.src = `${/.+\//.exec(originalSrc)[0]}64_${
				/.+\/(.+)/.exec(originalSrc)[1]
			}`;
			img.onload = () => {
				resolve(img.src);
			};
			img.onerror = e => {
				reject(e);
			};
		});
	};
	handleComment = postId => {
		this.setState({
			postIdForComment: postId,
		});
		this.props.postStore.toggleComment();
	};
	handleSound = () => {
		const nextValue = !this.state.muted;
		this.setState({
			muted: nextValue,
		});
		// 비디오 element에 전달
		// 슬라이드 데이터 찾기
		const slide = this.state.slides[this.swiper.realIndex];

		// id 구하기
		const slideId = slide && `${this.props.videoIdPrefix}_${slide.id}`;

		// DOM Element 찾기
		const slideEle = slideId && document.getElementById(slideId);

		// 위와 아래의 것의 muted를 푼다.
		if (slideEle) {
			slideEle.muted = nextValue;
		}
	};
	toProfile = async () => {
		this.state.swiper.slidePrev();
	};
	toggleSelected = isRecommendation => () => {
		const isLogin = Boolean(this.props.userStore.auth_token);
		if (!isLogin && !isRecommendation) {
			this.props.userStore.getTokenOrToggleLogin();
		} else {
			this.setState({
				isRecommendation,
			});
			window.location.href = isRecommendation ? '/' : '/following';
		}
	};
	render() {
		const postNow =
			this.state.postsMap[this.state.nowId] || this.state.postData;
		const videoLen = postNow.video.length;
		const isLoaded = postNow.title;
		const isMine =
			this.props.userStore.auth_token &&
			this.state.profile &&
			postNow.profile &&
			postNow.profile.id == this.state.profile.id;

		return (
			<Wrap>
				<PostMoreInfoModal
					data={this.state.postMoreInfoData}
					bottom={'0px'}
					isMine={isMine}
				/>
				<CommentModal postId={this.state.postIdForComment} bottom={'0px'} />
				<ItemLinkRequiredModal bottom={'0px'} />
				<MyFilterModal
					bottom={'0px'}
					is_applied={
						this.state.profile ? this.state.profile.is_applied : undefined
					}
					profile={this.state.profile}
				/>
				<ReportModal bottom={'0px'} />
				<LoginRequiredModal bottom={'0px'} />
				<SwiperWrap
					className={`swiper-container ${this.props.swiperClassName}`}
				>
					<div className="swiper-wrapper">
						{this.state.virtualData.slides.map(slide => (
							<div
								key={slide.id}
								className="swiper-slide"
								style={{
									top: `${this.state.virtualData.offset}px`,
									overflow: 'hidden',
								}}
							>
								<PostDetail
									{...this.props}
									videoPrefix={this.props.videoIdPrefix}
									params={{ id: slide.id }}
									active={
										!this.state.deactivateVideo && this.state.nowId === slide.id
									}
									updatePostsMap={this.updatePostsMap}
									setPrgBarWidth={this.setPrgBarWidth}
									muted={this.state.muted}
									thumbnail={slide.thumbnail_image}
									showThumbDiv={!isLoaded}
								/>
								{!isLoaded && <SpinnerCurtain />}
							</div>
						))}
					</div>
					<ProgressBarWrap>
						{postNow.video.map((_, index) => {
							return (
								<Fragment key={index}>
									<ProgressBar>
										<ProgressFilter
											style={{
												width: this.state.prgBarWidth[index]
													? this.state.prgBarWidth[index].width
													: 0,
												transition: `width ${
													this.state.prgBarWidth[index]
														? this.state.prgBarWidth[index].currentDuration
														: 0
												}s linear`,
											}}
										/>
									</ProgressBar>
									{index == videoLen - 1 ? <Fragment /> : <BarSpace />}
								</Fragment>
							);
						})}
					</ProgressBarWrap>

					<GradientFilter top />
					<GradientFilter bottom />
					<RecommendationOrFollowing>
						<Detail onClick={() => isLoaded && this.handleMoreInfo(postNow)}>
							<DetailMoreIcon />
						</Detail>
					</RecommendationOrFollowing>

					<CSSTransition
						in={!this.state.productListOn}
						timeout={300}
						classNames="example"
						unmountOnExit
					>
						<>
							<PostInfoWrap>
								<div style={{ flex: '1 1 auto', width: '100%' }}>
									{isLoaded && (
										<>
											<PostAuthor>
												<span style={{ fontStyle: 'italic', marginRight: 4 }}>
													by
												</span>
												{postNow.profile.nickname}
												{'·'}
												{postNow.edited_at}
											</PostAuthor>
											<PostTitle>{postNow.title}</PostTitle>
										</>
									)}

									<PostTags>
										{postNow.tag.length
											? postNow.tag.map((tag, index) => {
													return (
														<span key={index} className="post-tag">
															{' '}
															{`#${tag.name}`}{' '}
														</span>
													);
											  })
											: null}
									</PostTags>
								</div>
								{isLoaded && (
									<div
										style={{
											flex: '0 0 auto',
											width: '40px',
											marginLeft: 24,
											height: '100%',
										}}
									>
										<div>
											<ProfileImage
												isDefaultProfileImg={
													postNow.profile.image ? false : true
												}
												onClick={this.props.goBack}
											>
												<ProfilePic
													style={{
														backgroundImage: `url('${
															postNow.profile.image
																? this.state.minimizedProfileImgUrl
																	? this.state.minimizedProfileImgUrl
																	: postNow.profile.image
																: // ? postNow.profile.image
																  default_profile
														}')`,
													}}
												/>
											</ProfileImage>
											{!isMine ? (
												<IconContainer>
													<IconWrap>
														<LikeBtn
															noClickedColor="rgb(255,255,255)"
															fontSize="24px"
															margin="auto"
															height="auto"
															marginTop="0px"
															is_liked={postNow.like.is_liked}
															id={postNow.id}
															option="post"
														/>
													</IconWrap>
													<IconText>{postNow.like.count}</IconText>
												</IconContainer>
											) : null}
											<IconContainer>
												<IconWrap
													onClick={() =>
														isLoaded && this.handleComment(postNow.id)
													}
												>
													<DetailCommentIcon />
												</IconWrap>
												<IconText>{postNow.comment.count}</IconText>
												<IconWrap
													ref={ref => (this.muteBtn = ref)}
													onClick={this.handleSound}
												>
													<MutedIcon muted={this.state.muted} />
												</IconWrap>
											</IconContainer>
											<IconWrap
												gradient
												onClick={() => {
													if (postNow.item.length < 1) {
														alert('등록된 상품이 없습니다.');
													} else {
														this.setState({
															productListOn: true,
														});
													}
												}}
											>
												<PriceTagIcon />
												<Badge>{postNow.item.length}</Badge>
											</IconWrap>
										</div>
									</div>
								)}
							</PostInfoWrap>
							{this.props.show && !this.state.ready && (
								<SpinnerCurtain fullSize />
							)}
						</>
					</CSSTransition>
				</SwiperWrap>
				<ProductList
					swiperClassName={`${this.props.swiperClassName}-productList`}
					on={this.state.productListOn}
					onClose={() => this.setState({ productListOn: false })}
					items={postNow.item}
				/>
			</Wrap>
		);
	}
}
export default Home;
