import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const Layout = styled.div`
	padding-top: 60px;
	padding-bottom: 60px;
	padding-left: 16px;
	padding-right: 16px;
	width: 100%;
	max-width: 400px;
`;

export default ({ children }) => <Layout>{children}</Layout>;
