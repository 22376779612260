/* global Swiper */
import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import DetailCommentIcon from 'components/myIcons/detailCommentIcon';
import PriceTagIcon from 'components/myIcons/priceTagIcon';
import LikeBtn from 'components/likeBtnControlled';
import MutedIcon from 'components/myIcons/mutedIcon';
import ProductList from 'components/ProductList';
import default_profile from 'images/default_profile.png';
import CommentModal from 'components/popModal/commentModal';

const PostInfoWrap = styled.div`
	// pointer-events: none; =>
	// 슬라이드를 막지 않도록 존재한다.
	pointer-events: none;
  position:fixed;
  z-index: 1000;
  display:flex;
  align-items: flex-end;
  width:100%;
  padding-left:16px;
  padding-right:16px
  bottom:60px;
	left:0px;
	@media (min-width: 1024px) {
		// position: absolute;
	}
`;
const PointerEventWrap = styled.div`
	pointer-events: auto;
`;
const ProfileImage = styled.div`
	pointer-events: auto;
	width: 42px;
	height: 42px;
	border: 1px solid #ffffff;
	flex: 0 0 auto;
	margin-right: 8px;
	border-radius: 50%;
	display: flex;
	${(props) =>
		props.mode == 'card' &&
		css`
			width: 36px;
			height: 36px;
		`}
	${(props) =>
		props.isDefaultProfileImg &&
		css`
			background-color: rgb(255, 255, 255);
		`}
  div {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-size: cover;
		background-position: center;
		${(props) =>
			props.isDefaultProfileImg &&
			css`
				margin: auto;
				width: 21px;
				height: 21px;
				border-radius: 0;
				${(props) =>
					props.mode == 'card' &&
					css`
						width: 18px;
						height: 18px;
					`}
			`}
	}
`;
const PostTags = styled.p`
	pointer-events: auto;
	margin: 0px;
	span {
		text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
		color: #ffffff;
		font-family: 'Noto Sans CJK KR';
		font-size: 14px;
		font-weight: 500;
	}
	span.post-tag {
		cursor: pointer;
		:hover {
			opacity: 0.8;
		}
	}
`;
const PostAuthor = styled.p`
	margin: 0px;
	margin-bottom: 8px;
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 12px;
	font-weight: 400;
`;
const PostTitle = styled.p`
	margin: 0px;
	margin-bottom: 8px;
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 18px;
	font-weight: 700;
`;
const IconWrap = styled.div`
	pointer-events: auto;
	flex: 0 0 auto;
	width: 40px;
	height: 40px;
	margin: auto;
	margin-top: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background: rgba(0, 0, 0, 0.2);
	${(props) =>
		props.gradient &&
		css`
			box-shadow: 0 2px 10px rgba(138, 99, 223, 0.5);
			background-image: linear-gradient(100deg, #828ff7 0%, #b08df7 100%);
		`}
`;
const IconContainer = styled.div`
	text-align: center;
`;
const IconText = styled.span`
	color: white;
`;
const Badge = styled.span`
	background-color: white;
	border-radius: 50%;
	width: 14px;
	height: 14px;
	display: flex;
	font-size: 10px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-left: 14px;
	margin-top: -14px;
	position: absolute;
`;
class PostInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			minimizedProfileImgUrl: '',
			productListOn: false,
			is_liked: this.props.post.like.is_liked,
			count: parseInt(this.props.post.like.count),
			commentCount: 0,
			commentModalOpen: null,
		};
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.props.post !== prevProps.post) {
			try {
				this.getMinimizedProfileImgUrl(this.props.post.profile.image)
					.then((url) => {
						this.setState({ minimizedProfileImgUrl: url });
					})
					.catch((e) => {
						this.setState({ minimizedProfileImgUrl: null });
					});
				this.setState({
					is_liked: this.props.post.like.is_liked,
					count: parseInt(this.props.post.like.count),
				});
			} catch (e) {}
		}
	}
	getMinimizedProfileImgUrl = (originalSrc) => {
		// 64px 로 압축된 프로필 이미지가 있는지 확인한다.
		return new Promise((resolve, reject) => {
			const img = document.createElement('img');
			img.src = `${/.+\//.exec(originalSrc)[0]}64_${
				/.+\/(.+)/.exec(originalSrc)[1]
			}`;
			img.onload = () => {
				resolve(img.src);
			};
			img.onerror = (e) => {
				reject(e);
			};
		});
	};
	render() {
		const {
			post,
			muted,
			handleComment,
			handleSound,
			disableLike,
			ad,
			onClickAd,
		} = this.props;
		if (!post) return null;
		return (
			<>
				<PostInfoWrap>
					<div style={{ flex: '1 1 auto', width: '100%' }}>
						<PostAuthor>
							<span style={{ fontStyle: 'italic', marginRight: 4 }}>by</span>
							{post.profile.nickname}
							{'·'}
							{post.edited_at}
						</PostAuthor>
						<PostTitle>{post.title}</PostTitle>
						<PostTags>
							{post.tag.length
								? post.tag.map((tag, index) => (
										<span key={index} className="post-tag">
											{`#${tag.name}`}
										</span>
								  ))
								: null}
						</PostTags>
					</div>
					<PointerEventWrap
						style={{
							flex: '0 0 auto',
							width: '40px',
							marginLeft: 24,
							height: '100%',
						}}
					>
						<div>
							<ProfileImage
								isDefaultProfileImg={post.profile.image ? false : true}
								onClick={this.props.handleClickProfileImg}
							>
								<div
									style={{
										backgroundImage: `url('${
											post.profile.image
												? this.state.minimizedProfileImgUrl
													? this.state.minimizedProfileImgUrl
													: post.profile.image
												: // ? post.profile.image
												  default_profile
										}')`,
									}}
								/>
							</ProfileImage>
							{!disableLike && (
								<IconContainer>
									<IconWrap>
										<LikeBtn
											onChange={({ is_liked, count }) => {
												this.setState({ is_liked, count });
												post.like.count = count;
												post.like.is_liked = is_liked;
											}}
											noClickedColor="rgb(255,255,255)"
											fontSize="24px"
											margin="auto"
											height="auto"
											marginTop="0px"
											is_liked={this.state.is_liked}
											id={post.id}
											option="post"
											count={this.state.count}
										/>
									</IconWrap>
									<IconText>{this.state.count}</IconText>
								</IconContainer>
							)}
							<IconContainer>
								<IconWrap
									onClick={() => this.setState({ commentModalOpen: true })}
								>
									<DetailCommentIcon />
								</IconWrap>
								<IconText>{post.comment.count}</IconText>
								<IconWrap
									ref={(ref) => (this.muteBtn = ref)}
									onClick={handleSound}
								>
									<MutedIcon muted={muted} />
								</IconWrap>
							</IconContainer>
							<IconWrap
								gradient
								onClick={() => {
									if (post.item.length < 1) {
										alert('등록된 상품이 없습니다.');
									} else {
										this.setState({ productListOn: true });
									}
								}}
							>
								<PriceTagIcon />
								<Badge>{post.item.length}</Badge>
							</IconWrap>
						</div>
					</PointerEventWrap>
					<PointerEventWrap>
						<ProductList
							on={this.state.productListOn}
							onClose={(e) => {
								e.preventDefault();
								e.stopPropagation();
								this.setState({ productListOn: false });
							}}
							items={post.item}
							ad={ad}
							onClickAd={() => onClickAd(post)}
						/>
					</PointerEventWrap>
				</PostInfoWrap>
				<CommentModal
					open={this.state.commentModalOpen}
					handleClose={() =>
						this.setState({
							commentModalOpen: false,
						})
					}
					handleChange={(commentCount) => {
						this.setState({ commentCount });
						post.comment.count = commentCount;
					}}
					postId={post.id}
					bottom={'0px'}
				/>
			</>
		);
	}
}
export default PostInfo;
