import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { Icon } from "antd";

import history from "utils/history";

const Btn = styled.div`
  cursor: pointer;
  height: 40px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 22px;
  background-color: #ffffff;

  margin: auto 6px;
  margin-bottom: 16px;

  text-align: center;
  color: #bebebe;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 500;
  line-height: 44px;

  transition: 0.3s;

  ${props =>
    props.clicked &&
    css`
      color: #828ff7;
      font-weight: 700;
    `}
  ${props => props.focused && css``}
`;
const SmallBtn = styled.button`
  cursor: pointer;
  min-width: 60px;
  border: none;
  display: inline-block;
  flex: 0 0 auto;
  height: 32px;
  border-radius: 50px;
  background-color: #eeeeee;
  padding: 4px 12px;
  margin: auto;
  margin-bottom: 16px;
  margin-right: 8px;
  text-align: center;
  color: #bebebe;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 500;
  transition: 0.1s;
  span {
    vertical-align: text-top;
  }
  ${props =>
    props.clicked &&
    css`
      color: #828ff7;
      font-weight: 700;
      background-color: rgba(130, 143, 247, 0.2);
    `}
  ${props => props.focused && css``}
`;
class StyleBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
      focused: false
    };
  }
  componentDidMount() {
    if (this.props.mode == "smallBtn") {
      for (var i = 0; i < this.props.myStyle.length; i++) {
        if (this.props.data.name == this.props.myStyle[i].name) {
          this.setState({
            clicked: true
          });
          this.props.handleStyleBtn(this.props.data.id);
          break;
        }
      }
    }
  }
  onMouseEnterHandler = e => {
    this.setState({ focused: true });
  };
  onMouseLeaveHandler = e => {
    this.setState({ focused: false });
  };
  _onTouchStart = e => {
    this.setState({
      focused: true
    });
  };
  _onTouchEnd = e => {
    this.setState({
      focused: false
    });
  };

  handleClick = () => {
    this.props.handleStyleBtn(this.props.data.id);
    this.setState({
      clicked: !this.state.clicked
    });
  };
  render() {
    if (this.props.mode == "smallBtn") {
      return (
        <SmallBtn
          onClick={this.handleClick}
          clicked={this.state.clicked}
          focused={this.state.focused}
          onTouchStart={this._onTouchStart}
          onTouchEnd={this._onTouchEnd}
          onMouseEnter={this.onMouseEnterHandler}
          onMouseLeave={this.onMouseLeaveHandler}
        >
          {this.props.data.name}
        </SmallBtn>
      );
    } else {
      return (
        <Btn
          onClick={this.handleClick}
          clicked={this.state.clicked}
          focused={this.state.focused}
          onTouchStart={this._onTouchStart}
          onTouchEnd={this._onTouchEnd}
          onMouseEnter={this.onMouseEnterHandler}
          onMouseLeave={this.onMouseLeaveHandler}
        >
          {this.props.data.name}
        </Btn>
      );
    }
  }
}

export default StyleBtn;
