import React from 'react';
import * as postAPI from 'axios/postAPI';
import AddIcon from '@material-ui/icons/Add';
import sumImg from 'images/sum.png';

class Sum extends React.Component {
  state = {
    img: null,
  };
  async componentDidMount() {
    if (!this.props.noImg) {
      const props = {
        id_of_sum: this.props.sumId,
      };
      if (!this.props.sumId) {
        props.onlySelected = false;
      }
      const thumbs = (await postAPI.getFirstPost(props)).data;
      const img =
        thumbs.data.length > 0 ? thumbs.data[0].thumbnail_image : null;
      this.setState({
        img,
      });
    }
  }
  render() {
    const { spacer, name, onClick, selected, image, defaultImg } = this.props;

    return (
      <div
        style={{
          textAlign: 'center',
        }}
        role="button"
        onClick={onClick}
      >
        <div
          style={{
            background: '#e2e2e2',
            height: 70,
            borderRadius: '100%',
            marginRight: 8,
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {defaultImg ? (
            <img
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              src={sumImg}
            />
          ) : image ? (
            <img
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              src={`https://db.lay-er.me${image.url}`}
            />
          ) : this.state.img ? (
            <img
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              src={this.state.img}
            />
          ) : this.props.noImg ? (
            <AddIcon />
          ) : null}
        </div>
        <div
          style={{
            marginRight: 8,
            marginTop: 6,
            fontSize: 13,
            whiteSpace: 'break-spaces',
          }}
        >
          <strong style={{ color: selected ? '#5574f7' : '#afafaf' }}>
            {name}
          </strong>
        </div>
      </div>
    );
  }
}

export default Sum;
