import React from 'react';
import Swiper from 'react-id-swiper';
import { withStyles } from '@material-ui/core/styles';
import history from 'utils/history';

const Item = ({ style, img, title, onClick }) => (
  <div
    style={{
      textAlign: 'center',
      ...style,
      maxHeight: 150,
    }}
    role="button"
    onClick={onClick}
  >
    <div
      style={{
        boxShadow: '0 0 27px rgba(57, 57, 57, 0.06)',
        backgroundColor: '#ffffff',
        borderRadius: 10,
        margin: 8,
        overflow: 'hidden',
        position: 'relative',
        maxHeight: 140,
      }}
    >
      <img
        src={img}
        style={{
          maxWidth: 100,
          minHeight: 140,
          width: '100%',
          objectFit: 'cover',
        }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          background: 'linear-gradient(360deg, #333333, transparent)',
          paddingBottom: 4,
          textAlign: 'left',
          paddingLeft: 4,
        }}
      >
        <div>
          <strong style={{ color: 'white', marginBottom: 4 }}>
            {title.slice(0, 26)}...
          </strong>
        </div>
      </div>
    </div>
  </div>
);

export default withStyles({
  swiper: {
    '& .swiper-container': {
      overflow: 'hidden',
    },
    padding: '8px 0px 16px',

    marginBottom: 12,
    background: 'white',
    '& .swiper-wrapper': {
      marginLeft: 8,
      // paddingLeft: 8,
      // paddingLeft: 4,
      // paddingRight: 4,
    },
  },
})(({ classes, sumName, items }) => {
  return (
    <div style={{ marginTop: 8 }} className={classes.swiper}>
      <span style={{ fontSize: 16, marginLeft: 14, fontWeight: 'bold' }}>
        <span style={{ color: '#1f75fe' }}>{sumName}</span> 잔치!
      </span>
      <Swiper slidesPerView={3.2} freeMode rebuildOnUpdate>
        {items.map(({ id, postId, img, title }) => (
          <div>
            <Item
              key={id}
              img={img}
              title={title}
              onClick={() => {
                history.push(`/post/${postId}`);
              }}
            />
          </div>
        ))}
      </Swiper>
    </div>
  );
});
