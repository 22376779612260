import React, { Component } from "react";
import Switch from "react-switch";

class MySwitch extends Component {
  constructor() {
    super();
    this.state = { checked: false };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(checked) {
    this.setState({ checked });
  }

  render() {
    return (
      <label style={{ flex: "0 0 auto" }}>
        <Switch
          onChange={
            this.props.handleChange
              ? this.props.handleChange
              : this.handleChange
          }
          checked={this.props.checked ? this.props.checked : this.state.checked}
          checkedIcon={false}
          uncheckedIcon={false}
          offColor={"#ebebeb"}
          onColor={"#828ff7"}
          offHandleColor={"#fff"}
          onHandleColor={"#fff"}
          boxShadow={`0 2px 5px rgba(0, 0, 0, 0.2)`}
          activeBoxShadow={`0 0 2px 3px #828ff7`}
        />
      </label>
    );
  }
}

export default MySwitch;
