import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';
import imageCompression from 'browser-image-compression';

import { Wrap, IndexWrap } from 'components/style/custom-styled-components';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import Zoom from 'components/ZoomDialog';
import Button from '@material-ui/core/Button';

import * as userAPI from 'axios/userAPI';
import * as eventAPI from 'axios/eventAPI';
import * as mediaAPI from 'axios/mediaAPI';
import * as config from 'axios/config';

import history from 'utils/history';
import * as myVideo from 'utils/myVideo';
import * as iosHandler from 'utils/iosHandler';
import { resetOrientation } from 'utils/img';

import video_img from 'images/video.png';
import back_ico from 'images/back_ico_b.svg';
import search_ico from 'images/search_ico.svg';
import default_profile from 'images/default_profile.svg';
import imgAdd from 'images/imgAdd.svg';
import { ReactComponent as VideoPlusIco } from 'images/video_plus_ico.svg';

const MyVideoInputImg = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: 72px;
  height: 128px;
  border-radius: 6px;
  background-color: #eeeeee;
  background-size: cover;
  background-position: center;
  margin-right: 8px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  video {
    display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: black;
    object-fit: cover;
  }
  ${(props) =>
    props.added &&
    css`
      video {
        display: block;
      }
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    `}
  input {
    cursor: pointer;
  }
`;
const VideoWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
const VideoDuration = styled.span`
  position: absolute;
  bottom: 6px;
  right: 6px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  color: #f5f5f5;
  font-family: 'Noto Sans CJK KR';
  font-size: 14px;
  font-weight: 500;
`;
const MyVideoInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  opacity: 0;
  display: none;
  ${(props) =>
    props.added &&
    css`
      display: none;
    `}
`;
const AddBtnWrap = styled.div`
  position: absolute !important;
  display: flex;
  flex-direction: column;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  ${(props) =>
    props.added &&
    css`
      display: none;
    `}
`;
const AddBtn = styled.div`
  margin: auto;
  text-align: center;
  p {
    margin-bottom: 0px !important;
    color: #aeaeae;
    font-family: 'Noto Sans CJK KR';
    font-size: 14px;
    font-weight: 500;
  }
`;

@inject('userStore')
@inject('postStore')
@observer
class VideoInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      added: false,
      video: false,
      videoUrl: '',
      duration: false,
      isIOSApp: config.isMobile.IOSWebView(),
      zoomContent: {},
      zoomOpen: false,
    };
  }
  componentDidMount() {
    if (this.props.video) {
      let isImg = this.props.isImage;
      if (typeof this.props.video === 'string') {
        const last = this.props.video.lastIndexOf('.');
        const ext = this.props.video.slice(last);
        if (['jpeg', 'jpg', 'png', 'PNG', 'JPG'].some((o) => ext.includes(o))) {
          isImg = true;
        }
      } else if (typeof this.props.video === 'object') {
        if (this.props.video.type.includes('image')) {
          isImg = true;
        }
      }
      this.setState({
        added: true,
        video: this.props.video,
        videoUrl: this.props.isUrl
          ? this.props.video
          : window.URL.createObjectURL(this.props.video),
        isImg,
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.video != this.props.video) {
      if (this.props.video) {
        this.setState({
          added: true,
          video: this.props.video,
          videoUrl: this.props.isUrl
            ? this.props.video
            : window.URL.createObjectURL(this.props.video),
        });
      } else {
        this.setState({
          added: false,
          video: false,
          videoUrl: '',
          duration: false,
        });
      }
    }
  }
  afterSetState = async () => {
    this.props.addVideoInput(this.state.video);
    if (this.state.video.type.includes('video')) {
      if (this.props.mode == 'edit') {
        history.push('/edit-post/video/' + this.props.index);
      } else {
        history.push('/new-post/' + this.props.index);
      }
    } else {
      const formData = new FormData();
      formData.append('video', this.state.video);
      const req = {
        headers: this.props.userStore.auth_token
          ? {
              Authorization: `Bearer ${this.props.userStore.auth_token}`,
              'Content-Type': 'multipart/form-data',
            }
          : false,
        data: formData,
      };
      const { id } = await mediaAPI.getFramesFromMedia(req).then((res) => {
        return res.data;
      });
      const arr =
        this.props.mode == 'edit'
          ? this.props.postStore.editedVideoArr
          : this.props.postStore.addedVideoArr;
      const idx = arr.length - 1;
      arr[idx].id = id;
    }
  };
  handleFile = async (e) => {
    if (e.target.files.length > 0) {
      let file = e.target.files[0];
      let isImg = e.target.files[0].type.includes('image');
      if (isImg) {
        file = await imageCompression(file, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        });
        file = new File([file], file.name, { type: file.type });
      }
      this.setState(
        {
          added: true,
          isImg,
          video: file,
          videoUrl: this.props.isUrl
            ? this.props.video
            : window.URL.createObjectURL(file),
        },
        () => {
          this.afterSetState();
        }
      );
    } else {
      this.setState({
        profileImg: null,
        profileImgURL: null,
      });
    }
  };
  _onLoadedMetaData = () => {
    let duration,
      hasDuration = false;
    if (this.props.mode == 'edit') {
      try {
        if (
          this.props.postStore.editedVideoArr[this.props.index].currentDuration
        ) {
          hasDuration = true;
          this.setState({
            duration: this.props.postStore.editedVideoArr[this.props.index]
              .currentDuration,
          });
        }
      } catch {}
    } else {
      try {
        if (
          this.props.postStore.addedVideoArr[this.props.index].currentDuration
        ) {
          hasDuration = true;
          this.setState({
            duration: this.props.postStore.addedVideoArr[this.props.index]
              .currentDuration,
          });
        }
      } catch (e) {}
    }
    if (!hasDuration) {
      duration = this.video.duration;
      duration = myVideo.secondsToTime(duration);
      const h = myVideo.pad(duration.h, 2);
      const m = myVideo.pad(duration.m, 2);
      const s = myVideo.pad(duration.s, 2);
      this.setState({
        duration: `${m}:${s}`,
      });
    }
  };
  _onPlay = () => {
    this.video.pause();
  };
  _onClick = () => {
    if (!this.props.ios) {
      if (!this.props.isUrl) {
        if (this.props.mode == 'edit') {
          history.push('/edit-post/video/' + this.props.index);
        } else {
          history.push('/new-post/' + this.props.index);
        }
      }
    }
  };
  delVideo = () => {
    this.setState(
      {
        added: false,
        video: false,
        videoUrl: '',
        duration: false,
      },
      () => this.props.delVideo(this.props.index)
    );
  };
  _addClickOnIOSApp = () => {
    iosHandler.openImagePicker(this.props.userStore.auth_token);
  };
  afterUpload = (id, url) => {
    const res = {
      id: id,
      url: url,
    };
    this.props.addVideoInput(res);
  };

  render() {
    if (this.props.button) {
      return (
        <div
          style={{
            width: '50%',
            display: 'inline-block',
            textAlign: 'left',
          }}
        >
          <MyVideoInput
            type="file"
            id="contained-button-file"
            onChange={this.handleFile}
            added={this.state.added}
            accept="video/*"
          />
          <label htmlFor="contained-button-file">
            <Button
              component="span"
              style={{
                boxShadow: '0 4px 16px rgba(69, 91, 99, 0.08)',
                border: '1px solid rgba(0, 0, 0, 0)',
                background: 'white',
                color: '#1f75fe',
                width: '95%',
                padding: 12,
                borderRadius: 8,
                fontFamily: 'Noto Sans CJK KR',
              }}
            >
              <img style={{ width: 14, marginRight: 8 }} src={video_img} />
              영상 추가
            </Button>
          </label>
        </div>
      );
    }
    return (
      <MyVideoInputImg added={this.state.added}>
        <AddBtnWrap added={this.state.added}>
          <AddBtn onClick={this._addClickOnIOSApp}>
            <VideoPlusIco style={{ verticalAlign: 'middle' }} />
          </AddBtn>
        </AddBtnWrap>
        {this.state.isImg ||
        (this.state.video &&
          this.state.video.type &&
          this.state.video.type.includes('image')) ? (
          <img
            src={this.state.videoUrl}
            style={{
              height: 'auto',
              objectFit: 'contain',
              width: '100%',
              minHeight: '100%',
              position: 'absolute',
            }}
            onClick={() =>
              this.setState({
                zoomContent: {
                  img: this.state.videoUrl,
                },
                zoomOpen: true,
              })
            }
          />
        ) : (
          <video
            ref={(ref) => (this.video = ref)}
            src={this.state.videoUrl}
            onLoadedMetadata={this._onLoadedMetaData}
            playsInline={true}
            muted={true}
            autoPlay={true}
            style={{
              minWidth: '100%',
              minHeight: '100%',
              position: 'absolute',
            }}
            onPlay={this._onPlay}
            // onClick={this._onClick}
            onClick={() =>
              this.setState({
                zoomContent: {
                  vid: this.state.videoUrl,
                },
                zoomOpen: true,
              })
            }
          />
        )}
        {this.state.videoUrl ? (
          <svg
            onClick={this.delVideo}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 16 16"
            style={{ position: 'absolute', right: '0px', top: '8px' }}
          >
            <path
              fill="rgb(255,255,255)"
              d="M11.24.769a.828.828 0 0 0-1.173 0L6 4.827 1.933.76A.828.828 0 1 0 .76 1.933L4.827 6 .76 10.067a.828.828 0 1 0 1.173 1.173L6 7.173l4.067 4.067a.828.828 0 1 0 1.173-1.173L7.173 6l4.067-4.067a.834.834 0 0 0 0-1.164z"
            />
          </svg>
        ) : (
          <Fragment />
        )}
        <VideoDuration>
          {this.state.duration ? this.state.duration : ''}
        </VideoDuration>
        {!this.state.isIOSApp ? (
          <MyVideoInput
            type="file"
            id="file"
            onChange={this.handleFile}
            added={this.state.added}
            accept="video/*, image/*"
          />
        ) : null}
        <Zoom
          open={this.state.zoomOpen}
          onClose={() => this.setState({ zoomOpen: false })}
          img={this.state.zoomContent.img ? this.state.zoomContent.img : null}
          vid={this.state.zoomContent.vid ? this.state.zoomContent.vid : null}
        />
      </MyVideoInputImg>
    );
  }
}
export default VideoInput;
