import React, { Fragment } from "react";
import axios from "axios";
import styled, { css } from "styled-components";
import { NavBar } from "antd-mobile";
import { observer, inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import { Wrap, IndexWrap } from "components/style/custom-styled-components";
import ProductCategoryModal from "components/popModal/productCategoryModal";
import Loader from "components/loadingSpinner/spinnerCurtain";

import Footer from "components/footer";

import * as userAPI from "axios/userAPI";
import * as eventAPI from "axios/eventAPI";
import * as postAPI from "axios/postAPI";
import history from "utils/history";
import clsx from "clsx";
import UserProfilePage from "./userProfilePage";
import back_ico from "images/back_ico_b.svg";

const NavWrap = styled.div`
  z-index: 10;
  position: fixed;
  background: transparent;
  width: 100%;
  max-width: 1024px;
  // transition: 0.5s;
  ${(props) =>
    props.toggle &&
    css`
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
    `}
`;
const FilterText = styled.span`
  color: #bebebe;
  font-family: "Noto Sans CJK KR";
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  ${(props) =>
    props.name == props.clicked &&
    css`
      color: #333333;
      font-family: "Noto Sans CJK KR";
      font-size: 15px;
      font-weight: 700;
    `}
`;

const IconWrap = styled.div`
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

const base = "https://star-api.lay-er.me";

const INIT = "INIT";
@inject("cacheStore")
@inject("userStore")
@inject("postStore")
@observer
class MyPage extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      initData: this.props.cacheStore.myProfileData,
      eventBanner: this.props.cacheStore.myEventBanner,
      accessToken: this.props.userStore.auth_token,
      changed: false,
      productCategoryModalOpen: false,
      posts: [],
      next: INIT,
      page: 0,
      sum: null,
    };
  }
  componentWillMount() {
    if (!this.props.userStore.auth_token) {
      history.push("/login");
    }
  }
  async componentDidMount() {
    // 섬 정보 요청 시작
    const { search } = window.location;
    let sumId;
    let sum;
    const sumIdRegExp = /sumId=(\d+)/.exec(search);
    if (sumIdRegExp) {
      sumId = Number(sumIdRegExp[1]);
      try {
        const sumReq = await axios.get(`${base}/sums/${sumId}`);
        sum = sumReq.data;
      } catch (e) {
        console.error(e);
      }
    }
    this.setState({ sum });
    // 섬 정보 요청 종료

    const { loadedPostInMyPage } = this.props.postStore;

    const reg = /scroll=(\d+)/.exec(window.location.search);
    if (reg) {
      const scroll = Number(reg[1]);
      setTimeout(() => {
        this.scrollTo(scroll);
      }, 100);
    }

    // 뒤로가기 + 스크롤시 데이터 불러옴
    if (reg && loadedPostInMyPage.posts.length) {
      this.setState({
        posts: loadedPostInMyPage.posts,
        next: loadedPostInMyPage.next,
      });
    } else {
      this.getPostList({ renew: true });
    }

    this.scrollEvent = this.getScrollEle().addEventListener(
      "scroll",
      this.scrollHandler
    );
  }

  toSns = (url) => {
    if (url) window.open(url, "_blank");
  };
  componentWillUnmount() {
    this.getScrollEle().removeEventListener("scroll", this.scrollHandler);
    this._isMounted = false;
    this.props.cacheStore.setMyProfileData(this.state.initData);
    this.props.cacheStore.setMyEventBanner(this.state.eventBanner);
    this.props.cacheStore.setUserProfileData(this.state.initData);
  }
  scrollHandler = () => {
    const { scrollHeight, scrollTop, offsetHeight } = document.getElementById(
      "SCROLL_WRAP"
    );
    const maxScroll = scrollHeight - offsetHeight;
    if (maxScroll - scrollTop < 100) {
      this.getPostList();
    }
  };
  handleClickAdd = () => {
    this.setState({
      productCategoryModalOpen: true,
    });
  };
  getScrollEle = () => document.getElementById("SCROLL_WRAP");
  scrollTo = (number) => (this.getScrollEle().scrollTop = number);
  getScrollY = () => {
    const ele = this.getScrollEle();
    return ele.scrollTop;
  };
  getPostList = async ({ renew } = { renew: false }) => {
    // 포스트를 요청하고 상태를 업데이트 한다.
    // 프로필 Id로 필터링한다.
    if (renew || (this.state.next && !this.state.loading)) {
      const profileIdRegExp = /profileId=(\d+)/.exec(window.location.search);
      const id = profileIdRegExp[1];

      const userStore = this.props;
      this.setState({ loading: true });
      if (id) {
        try {
          const Authorization = `Bearer ${userStore.auth_token}`;
          const headers = userStore.auth_token ? { Authorization } : undefined;
          const params = {
            headers,
            next: renew || this.state.next === INIT ? null : this.state.next,
            page: renew ? 1 : this.state.page + 1, // page 1부터 시작
            pageSize: 20,
          };
          const { search } = window.location;
          if (search.includes("isLike=true")) {
            params.like = id;
          } else {
            params.profile = id;
          }
          if (search.includes("sumId=")) {
            const sumIdRegExp = /sumId=(\d+)/.exec(search);
            if (sumIdRegExp) {
              params.id_of_sum = [Number(sumIdRegExp[1])];
            }
          }
          const { data } = await postAPI.getPostList(params);
          if (data) {
            const posts = renew
              ? data.data
              : this.state.posts.concat(data.data);
            this.props.postStore.setLoadedPostInMyPage({
              posts,
              next: data.next,
            });

            this.setState({
              posts,
              next: data.next,
            });
            this.setState({ loading: false });
            return data.data;
          }
        } catch (e) {
          console.log(e);
        }
      }
      this.setState({ loading: false });
    }
  };
  render() {
    const { classes, data, isMine } = this.props;
    return (
      <Wrap
        style={{
          paddingBottom: 0,
          height: "-webkit-fill-available",
          backgroundColor: "rgb(248,248,248)",
          overflowY: "hidden",
        }}
      >
        <IndexWrap
          id="SCROLL_WRAP"
          style={{ paddingTop: 0, backgroundColor: "#f8f8f8" }}
        >
          <NavWrap>
            <NavBar
              mode="light"
              icon={[
                <IconWrap key="iconback" onClick={() => window.history.back()}>
                  <img
                    src={back_ico}
                    style={{
                      verticalAlign: "middle",
                      cursor: "pointer",
                    }}
                  />
                </IconWrap>,
              ]}
              rightContent={
                [
                  // <IconWrap
                  //   onClick={() =>
                  //     this.setState({
                  //       userProfileModalOn: true,
                  //     })
                  //   }
                  // >
                  //   <svg
                  //     style={{ verticalAlign: "middle" }}
                  //     xmlns="http://www.w3.org/2000/svg"
                  //     width="16"
                  //     height="4"
                  //     viewBox="0 0 16 4"
                  //   >
                  //     <path
                  //       fill="#333333"
                  //       d="M9.778 1.778c0 .978-.8 1.778-1.778 1.778s-1.778-.8-1.778-1.778S7.022 0 8 0s1.778.8 1.778 1.778zm6.222 0c0 .978-.8 1.778-1.778 1.778s-1.778-.8-1.778-1.778S13.244 0 14.222 0 16 .8 16 1.778zm-12.444 0c0 .978-.8 1.778-1.778 1.778S0 2.756 0 1.778.8 0 1.778 0s1.778.8 1.778 1.778z"
                  //     />
                  //   </svg>
                  // </IconWrap>,
                ]
              }
            >
              <FilterText>
                {this.state.sum ? this.state.sum.name : "섬 전체"}
              </FilterText>
            </NavBar>
          </NavWrap>
          <div className={classes.grid}>
            {this.state.posts &&
              this.state.posts.map(({ id, thumbnail_image }, i) => (
                <div
                  key={`${id}-${i}`}
                  className={clsx(classes.imgWrapper, {
                    [classes.noBorderImgWrapper]: i % 3 === 2,
                  })}
                >
                  {/* <a onClick={() => (window.location.href = `/post/${id}`)}> */}
                  <a
                    onClick={() => {
                      // history.replace(
                      // window.location.pathname +
                      //   window.location.search.includes("?")
                      //   ? window.location.search
                      //   : "?" + `scroll=${this.getScrollY()}`
                      // );
                      let prevSearch = window.location.search.replace(
                        /&scroll=\d+/g,
                        ""
                      );
                      prevSearch = prevSearch.replace(/scroll=\d+/g, "");
                      let search =
                        window.location.search === "" ? "?" : `${prevSearch}&`;
                      history.replace(
                        window.location.pathname +
                          search +
                          `scroll=${this.getScrollY()}`
                      );
                      history.push(`/post/${id}`);
                    }}
                  >
                    {/* <a href={`/post/${id}`}> */}
                    <img src={thumbnail_image} className={classes.img} />
                  </a>
                </div>
              ))}
          </div>
          {this.state.loading && <Loader />}
          <div style={{ minHeight: 100 }} />
        </IndexWrap>
        <Footer onClickAdd={this.handleClickAdd} />
      </Wrap>
    );
  }
}
export default withStyles((theme) => ({
  grid: { display: "flex", flexFlow: "row wrap", marginTop: 45 },
  imgWrapper: {
    width: "33.333333%",
    paddingTop: "33.333333%",
    position: "relative",
    borderBottom: "1px solid white",
    borderRight: "1px solid white",
  },
  noBorderImgWrapper: {
    borderRight: "none",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  intro: {
    padding: 16,
    paddingBottom: 16,
    borderBottom: "1px solid #f2f2f2",
  },
  tabWrapper: {
    flexGrow: 1,
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: theme.palette.primary.main,
    },
  },
  tabs: {
    width: "100%",
  },
  tab: {
    width: "50%",
    fontSize: 16,
    fontWeight: 700,
  },
}))(MyPage);
