import axios from 'axios';
import { ROOT_URL } from './config';

export function getTagList(req) {
	return axios.get(
		ROOT_URL + `/api/v1/tag/?name=${req.tag}&page=${req.page ? req.page : 1}`,
	);
}

export function getPostList(req) {
	const defaults = {
		ordering: '',
		tag: '',
		page: '',
		event: '',
		profile: '',
		like: '',
		key: '',
		following: false,
		page: 1,
		pageSize: 20,
	};
	for (var prop in defaults) {
		req[prop] = typeof req[prop] !== 'undefined' ? req[prop] : defaults[prop];
	}
	const filter =
		'?ordering=' +
		req.ordering +
		'&tag=' +
		req.tag +
		'&event=' +
		req.event +
		'&profile=' +
		req.profile +
		'&like=' +
		req.like +
		'&key=' +
		req.key +
		'&following=' +
		req.following +
		'&page=' +
		req.page +
		'&page_size=' +
		req.pageSize;
	if (req.headers)
		return axios.get(ROOT_URL + `/api/v1/post/` + filter, {
			headers: req.headers,
		});
	return axios.get(ROOT_URL + `/api/v1/post/` + filter);
}

export function getItemList(req) {
	const defaults = {
		ordering: 'new',
		tag: '',
		page: '',
		event: '',
		profile: '',
		like: '',
		key: '',
		name: '',
		page: 1,
		pageSize: 20,
	};
	for (var prop in defaults) {
		req[prop] = typeof req[prop] !== 'undefined' ? req[prop] : defaults[prop];
	}
	const filter =
		'?ordering=' +
		req.ordering +
		'&tag=' +
		req.tag +
		'&event=' +
		req.event +
		'&profile=' +
		req.profile +
		'&like=' +
		req.like +
		'&key=' +
		req.key +
		'&name=' +
		req.name +
		'&page=' +
		req.page +
		'&page_size=' +
		req.pageSize;
	if (req.headers)
		return axios.get(ROOT_URL + `/api/v1/item/${filter}`, {
			headers: req.headers,
		});
	return axios.get(ROOT_URL + `/api/v1/item/${filter}`);
}

export function getUserList(req) {
	if (req.headers)
		return axios.get(
			ROOT_URL +
				`/api/v1/profile/?page=${req.page ? req.page : 1}&nickname=${req.name}`,
			{ headers: req.headers },
		);
	return axios.get(
		ROOT_URL +
			`/api/v1/profile/?page=${req.page ? req.page : 1}&nickname=${req.name}`,
	);
}

export function getPopularTag() {
	return axios.get(ROOT_URL + `/api/v1/tag/?ordering=popular`);
}
