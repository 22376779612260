import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/ButtonBase';
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircle from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import history from 'utils/history';

const MissionAvailable = ({ classes, items, onClose }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  return (
    <>
      <div className={classes.root}>
        <div className={classes.background}>
          <span
            className={classes.text}
            role="button"
            onClick={() => {
              setOpen(true);
            }}
          >
            오늘 참여해야 할 <span className={classes.blue}>미션</span>이 있어요
            !
            <IconButton
              className={classes.iconButton}
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          </span>
        </div>
      </div>
      {/* ?sum=5&mission=123테스트용미션&days=10 */}
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className={classes.modal}>
          <ul className={classes.list}>
            {items.map((item, i) => {
              const isSelected = selected === i;
              return (
                <li
                  key={item.mission.id}
                  className={classes.listItem}
                  role="button"
                  onClick={() => {
                    setSelected(i);
                  }}
                >
                  <span className={isSelected ? classes.selected : undefined}>
                    {item.mission.title}
                  </span>
                  <Checkbox
                    classes={{
                      checked: isSelected ? classes.selected : undefined,
                    }}
                    checked={isSelected}
                    checkedIcon={<CheckCircle />}
                    icon={<RadioButtonUncheckedIcon />}
                  />
                </li>
              );
            })}
          </ul>
          <Button
            className={classes.button}
            onClick={() => {
              const { mission, post } = items[selected];
              window.location.href = `/missions?sumId=${
                post.id_of_sum
              }&title=${encodeURIComponent(mission.title)}`;
            }}
          >
            등록하기
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default withStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: 80,
    zIndex: 1001,
    width: '100%',
    textAlign: 'center',
  },
  background: {
    boxShadow: '0 3px 16px rgba(0, 0, 0, 0.16)',
    borderRadius: 30,
    backgroundColor: '#f1f1f1',
    display: 'inline',
    padding: '6px 6px 6px 14px',
  },
  text: {},
  blue: {
    color: '#1f75fa',
  },
  iconButton: {
    padding: 6,
  },
  icon: {
    width: 10,
  },
  modal: {
    position: 'absolute',
    width: '100%',
    borderRadius: '18px 18px 0px 0px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: ' 0 2px 20px rgba(0, 0, 0, 0.4)',
    bottom: 0,
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    width: '100%',
    padding: 12,
    color: 'white',
    boxShadow: '0 2px 10px rgba(87, 115, 255, 0.3)',
    borderRadius: 68,
    backgroundImage:
      'linear-gradient(to right, rgba(87, 115, 255, 0.99) 0%, #82a3f2 100%)',
  },
  list: {
    listStyle: 'none',
  },
  listItem: {
    width: '100%',
    textAlign: 'center',
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: 14,
    fontWeight: 'bold',
    // '&:not(&:first-child)&:not(&:last-child)': {
    //   borderTop: '1px solid #efefef',
    //   borderBottom: '1px solid #efefef',
    // },
  },
  selected: {
    color: '#5574f7 !important',
  },
}))(MissionAvailable);
