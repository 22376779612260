import axios from 'axios';
const BASE = 'https://star-api.lay-er.me';

const sendPush = ({ id, message, link, image }) =>
  axios.post(`${BASE}/push/send`, {
    userId: id,
    payload: {
      message,
      link,
      image,
    },
  });
export default sendPush;
