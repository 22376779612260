const reg = /@[^(</:)]+:id=(\d+)/;
export default (str) => {
  let t = str;

  while (reg.exec(t)) {
    let res = reg.exec(t);
    let str = res[0];
    let idx = res.index;

    let nickname = str.slice(0, str.indexOf(':'));

    let middle = `<a style="text-decoration:none;" href="http://lay-er.me/profile/${res[1]}">${nickname}</a>`;

    let front = t.slice(0, idx);
    let end = t.slice(idx + str.length);

    t = `${front}${middle}${end}`;
  }
  return t;
};
