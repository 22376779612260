import React, { Component } from "react";

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 18 18"
    {...props}
  >
    <g>
      <g>
        <path d="M4.995 8.127l2.637 2.737 5.279-5.48a.912.912 0 0 1 1.32 0 .997.997 0 0 1 0 1.372l-5.857 6.08a.913.913 0 0 1-.737.373h-.012a.908.908 0 0 1-.738-.377L3.674 9.498a.997.997 0 0 1 0-1.37.91.91 0 0 1 1.321 0zM12.05 18L18 12.05V2c0-1.103-.897-2-2-2H2C.897 0 0 .897 0 2v14c0 1.103.897 2 2 2h10.05z" />
      </g>
    </g>
  </svg>
);
