import React from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import { observer, inject } from 'mobx-react';
import Swiper from 'react-id-swiper';
import history from 'utils/history';
import Comment from '../comment';
import DetailMoreIcon from '../myIcons/detailMoreIcon';
import PollModal from 'components/PollModal';
import * as postAPI from 'axios/postAPI';
import TOPIC_IMG from 'images/action/topic.png';
import MISSION_IMG from 'images/action/mission.png';
import QNA_IMG from 'images/action/qna.png';
import POLL_IMG from 'images/action/poll.png';
import getMidnightMoment from 'utils/getMidnightMoment';
import LikeIcon from './likeIcon';

const base = 'https://star-api.lay-er.me';

const BADGES = {
  topic: {
    label: '같이해요',
    cta: '참여하기',
    color: '#854fff',
  },
  mission: {
    label: '참여좀요',
    cta: '참여하기',
    color: '#ff854f',
  },
  qna: {
    label: '물어봐요',
    cta: '알려주기',
    color: '#ffb900',
  },
  poll: {
    label: '골라봐요',
    cta: '투표하기',
    color: '#3adbe1',
  },
};

const emerge = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
const Layout = styled.div`
  padding-top: 8px;
  margin-bottom: 16px;
  position: relative;
  z-index: 0;
  cursor: pointer;
  height: 100%;
  // border-radius: 16px;
  animation: ${emerge} 0.5s;
  animation-fill-mode: forwards;
  p {
    font-family: 'Noto Sans CJK KR';
  }
`;

const Wrapper = styled.div`
  position: relative;
  z-index: 0;
  cursor: pointer;
  // height: 100%;
  // padding-top: 100%;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.16);
  background-size: cover;
  background-position: center;
  margin: 0px;
`;
const User = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 9px;
  padding-left: 16px;
  padding-right: 16px;
  > img {
    :nth-of-type(1) {
      width: 42px;
      height: 42px;
      border-radius: 30px;
    }
    :nth-of-type(2) {
      margin-left: auto;
      width: 18px !important;
      height: 24px !important;
    }
  }
  > div {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    > p {
      margin: 0px;
      // :nth-of-type(1) {
      //   color: #000;
      //   font-size: 15px;
      //   font-weight: bold;
      // }
      // :nth-of-type(2) {
      //   color: #acb1c0;
      //   font-size: 12px;
      // }
    }
  }
`;
const Media = styled.div`
  padding-bottom: 36px;
  .swiper-wrapper {
    display: flex;
    align-items: center;
  }
`;
const LikeText = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 0px;
  display: flex;
  align-items: left;
  p {
    margin: 0px;
    font-family: 'Noto Sans CJK KR';
    font-weight: 500;
    line-height: 25px;
  }
`;
const Info = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
  }
  p {
    margin: 0px;
    font-family: 'Noto Sans CJK KR';
    font-weight: 500;
    line-height: 25px;
  }
  svg {
    margin-bottom: 0px !important;
    margin-right: 6px;
  }
  span {
  }
`;
const Content = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 8px;
  text-align: left;
  span {
    font-family: 'Noto Sans CJK KR';
    font-size: 14px;
    // color: #333;
    :nth-of-type(1) {
      font-weight: 500;
    }
    :nth-of-type(2) {
      font-weight: 300;
    }
  }
`;
const QnaOrPoll = styled.div`
  background: white;
  width: 92%;
  height: 48px;
  padding: 8px;
  box-shadow: 0 2px 8px rgba(69, 79, 99, 0.4);
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: -16px;
  border-radius: 20px;
  z-index: 10;
  font-size: 14px;
  color: #4a4a4a;
  text-align: left;
  display: flex;
  align-items: center;
`;
const params = {
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
};
@inject('userStore')
@inject('postStore')
@observer
class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: JSON.parse(this.props.data),
      commentModalOpen: false,
      comments: [],
      topic: null,
      mission: null,
      qna: null,
      pollData: null,
      pollTitle: null,
      pollOptionA: '',
      pollOptionB: '',
      pollOpen: false,
      votedTo: null,
      voteData: null,
      isLiked: false,
      likeNum: 0,
      loading: this.props.onlyBasic,
    };
  }
  async componentDidMount() {
    const data = JSON.parse(this.props.data);
    try {
      const req = {
        post: data.id,
      };
      const commentData = (await postAPI.getPostComments(req)).data;
      this.setState({
        comments: commentData.data.filter((o) => !o.parent && !o.is_blocked),
      });
    } catch (e) {
      console.log(e);
    }
    if (data.like.is_liked) {
      this.setState({ isLiked: true, likeNum: data.like.count });
    } else {
      this.setState({ likeNum: data.like.count });
    }
    let hasEnjoy = false;
    try {
      const topic = await axios.get(`${base}/topics?postId=${data.id}`);
      if (topic.data && topic.data[0]) {
        hasEnjoy = true;
        const topics = await axios.get(
          `${base}/topics?title=${encodeURIComponent(topic.data[0].title)}`
        );
        const first = topics.data.find((o) => o.starter && o.starter !== '');
        this.setState({
          topic: {
            ...topic.data[0],
            uploaded: topics.data.length,
            starter: first ? first.starter : null,
          },
        });
      }
    } catch (e) {}
    if (!hasEnjoy) {
      try {
        const qna = await axios.get(`${base}/qnas?postId=${data.id}`);
        if (qna.data && qna.data[0]) {
          hasEnjoy = true;
          this.setState({ qna: qna.data[0].title });
        }
      } catch (e) {}
    }
    if (!hasEnjoy) {
      try {
        const mission = await axios.get(`${base}/missions?postId=${data.id}`);
        if (mission.data && mission.data[0]) {
          const missions = await axios.get(
            `${base}/missions?title=${encodeURIComponent(
              mission.data[0].title
            )}`
          );
          const firstMission = missions.data[0];
          let firstPost;
          try {
            firstPost = (await postAPI.getPost({ id: firstMission.postId }))
              .data;
          } catch (e) {
            console.error(e);
          }
          hasEnjoy = true;
          this.setState({
            mission: {
              ...firstMission,
              uploaded: missions.data.length,
              starter: firstPost ? firstPost.profile.nickname : null,
            },
          });
        }
      } catch (e) {}
    }

    let pollId;
    if (!hasEnjoy) {
      try {
        const poll = await axios.get(`${base}/polls?postId=${data.id}`);
        if (poll.data && poll.data[0]) {
          hasEnjoy = true;
          pollId = poll.data[0].id;
          this.setState({
            pollData: poll.data[0],
            pollTitle: poll.data[0].title,
            pollOptionA: poll.data[0].optionA,
            pollOptionB: poll.data[0].optionB,
          });
        }
      } catch (e) {}
    }
    if (pollId) {
      try {
        this.calcPoll(pollId);
      } catch (e) {}
    }
    if (this.props.onlyBasic) {
      this.setState({
        isLoading: false,
      });
    }

    if (data.video) {
      data.video.forEach((vid) => {
        let video = document.querySelector(`#vid-${vid.id}`);
        if (video) {
          let observer = new IntersectionObserver(
            (entries, observer) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio != 1 && !video.paused) {
                  video.pause();
                } else if (entry.intersectionRatio === 1) {
                  video.play();
                }
              });
            },
            { threshold: 1 }
          );
          observer.observe(video);
        }
      });
    }
  }
  async calcPoll(pollId) {
    const { data } = await axios.get(`${base}/votes?poll.id=${pollId}`);
    if (data) {
      const aUsers = data.filter((o) => o.voteTo === 'a').map(({ id }) => id);
      const bUsers = data.filter((o) => o.voteTo === 'b').map(({ id }) => id);

      const found = data.find((o) => o.userId === this.props.userId);
      const aNum = aUsers.length;
      const bNum = bUsers.length;
      const invalid = aNum + bNum < 1;
      this.setState({
        votedTo: found ? found.voteTo : null,
        voteNumber: aNum + bNum,
        voteData: invalid
          ? null
          : {
              a: Math.round((aNum * 100) / (aNum + bNum)),
              b: Math.round((bNum * 100) / (aNum + bNum)),
              aUsers,
              bUsers,
              optionA: this.state.pollOptionA,
              optionB: this.state.pollOptionB,
            },
      });
    }
  }
  handleLike = async (e) => {
    e.stopPropagation();
    const token = this.props.userStore.getTokenOrToggleLogin();
    if (token) {
      const like = !this.state.isLiked;

      const req = {
        id: this.state.data.id,
        object: 'post',
        headers: { Authorization: `Bearer ${token}` },
      };
      try {
        await postAPI.patchMyLike(req).then((res) => {
          return res.data;
        });
        this.setState({
          isLiked: like,
          likeNum: Number(this.state.likeNum) + (like ? 1 : -1),
        });
        this.props.toggleIsLike && this.props.toggleIsLike();
      } catch (e) {
        console.log(e);
      }
    }
  };
  render() {
    const data = JSON.parse(this.props.data);
    const commentCount = data.comment && data.comment.count;

    const tagStr =
      data &&
      data.tag &&
      data.tag
        .sort((a, b) => a.id - b.id)
        .reduce((acc, val) => {
          acc = acc + '#' + val.name + ' ';
          return acc;
        }, '');

    const badgeType =
      this.state.topic && this.state.topic.title !== ''
        ? 'topic'
        : this.state.mission && this.state.mission.title !== ''
        ? 'mission'
        : this.state.qna && this.state.qna !== ''
        ? 'qna'
        : this.state.pollTitle && this.state.pollTitle !== ''
        ? 'poll'
        : null;
    const badge = badgeType ? BADGES[badgeType] : null;
    const openCommentModal = () => {
      this.props.openCommentModal(true, {
        ...data,
        voteData: this.state.voteData,
      });
    };
    if (!data.video) {
      return null;
    }
    const isTopic = this.state.topic && this.state.topic.title !== '';
    const isMission = this.state.mission && this.state.mission.title !== '';
    const isQna = this.state.qna && this.state.qna !== '';
    const isPoll = this.state.pollTitle && this.state.pollTitle !== '';
    if (
      this.props.onlyBasic &&
      (this.state.isLoading || isTopic || isMission || isQna || isPoll)
    )
      return <div />;

    return (
      <>
        <Layout>
          {this.props.sum ? (
            <User>
              <img
                style={{
                  objectFit: 'cover',
                  boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
                }}
                src={data.profile.image}
                role="button"
                onClick={this.props.onClickSumName}
              />
              <div style={{ textAlign: 'left' }}>
                <p
                  style={{ fontWeight: 'bold', marginBottom: 2 }}
                  onClick={this.props.onClickSumName}
                >
                  {data.profile.nickname}
                </p>

                <p onClick={() => history.push('/profile/' + data.profile.id)}>
                  {data.edited_at}
                  {this.props.sum.name === '마이피드'
                    ? null
                    : ` ∙ ${this.props.sum.name}`}
                </p>
              </div>
              <div style={{ flex: 1, alignItems: 'flex-end' }}>
                <DetailMoreIcon
                  onClick={() => {
                    this.props.onClickPostMore(data);
                  }}
                />
              </div>
            </User>
          ) : (
            <User>
              <img
                style={{ objectFit: 'cover' }}
                src={data.profile.image}
                role="button"
                onClick={() => history.push('/profile/' + data.profile.id)}
              />
              <div style={{ textAlign: 'left' }}>
                <p
                  style={{ fontWeight: 'bold', marginBottom: 2 }}
                  onClick={() => history.push('/profile/' + data.profile.id)}
                >
                  {data.profile.nickname}
                </p>

                <p>{data.edited_at}</p>
              </div>
              <div style={{ flex: 1, alignItems: 'flex-end' }}>
                <DetailMoreIcon
                  onClick={() => {
                    this.props.onClickPostMore(data);
                  }}
                />
              </div>
            </User>
          )}
          <Media>
            <Swiper
              pagination={{
                el: `.swiper-pagination ${
                  isTopic || isMission || isQna || isPoll
                    ? '.swiper-pagination-up'
                    : ''
                } ${data.video.length === 1 ? '.swiper-pagination-no' : ''}`,
                clickable: true,
              }}
            >
              {data.video.map(({ id, image, video }) => {
                if (video) {
                  return (
                    <Wrapper>
                      <video
                        id={`vid-${id}`}
                        src={video}
                        loop
                        controls
                        playsInline
                        autoPlay={false}
                        preload="auto"
                        poster={image}
                        style={{
                          width: '100%',
                          maxHeight: '520px',
                          objectFit: 'cover',
                        }}
                        muted
                      />
                    </Wrapper>
                  );
                }
                return (
                  <Wrapper>
                    <img
                      src={image}
                      style={{
                        width: '100%',
                        maxHeight: '520px',
                        objectFit: 'cover',
                      }}
                      onClick={() => this.props.onClickImage(image)}
                    />
                  </Wrapper>
                );
              })}
            </Swiper>
            {isTopic && (
              <QnaOrPoll
                onClick={() => {
                  try {
                    const sumId = data.id_of_sum;

                    history.push(
                      `/topics?sumId=${sumId}&title=${encodeURIComponent(
                        this.state.topic.title
                      )}`
                    );
                  } catch (e) {
                    console.error(e);
                  }
                }}
              >
                <div
                  style={{
                    width: '32px',
                    height: '32px',
                    borderRadius: 20,
                    background: badge.color,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 8,
                  }}
                >
                  <img src={TOPIC_IMG} style={{ width: 18 }} />
                </div>{' '}
                <div style={{ flex: 1 }}>
                  <span
                    style={{
                      color: '#454f63',
                      fontSize: 14,
                      fontWeight: 'bold',
                    }}
                  >
                    {this.state.topic.title}
                  </span>
                  <div
                    style={{ fontSize: 12, color: '#454f63', opacity: 0.94 }}
                  >
                    {this.state.topic.uploaded}명 참여{' '}
                    {this.state.topic.starter
                      ? `by ${this.state.topic.starter}`
                      : ''}
                  </div>
                </div>
                <span
                  style={{
                    color: badge.color,
                    fontWeight: 'bold',
                    marginRight: 16,
                  }}
                >
                  {badge.cta}
                </span>
              </QnaOrPoll>
            )}
            {isMission && (
              <QnaOrPoll
                onClick={() => {
                  try {
                    const sumId = data.id_of_sum;

                    history.push(
                      `/missions?sumId=${sumId}&title=${encodeURIComponent(
                        this.state.mission.title
                      )}`
                    );
                  } catch (e) {
                    console.error(e);
                  }
                }}
              >
                <div
                  style={{
                    width: '32px',
                    height: '32px',
                    borderRadius: 20,
                    background: badge.color,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 8,
                  }}
                >
                  <img src={MISSION_IMG} style={{ width: 18, height: 18 }} />
                </div>{' '}
                <div style={{ flex: 1 }}>
                  <div>
                    <span
                      style={{
                        color: '#454f63',
                        fontSize: 14,
                        fontWeight: 'bold',
                      }}
                    >
                      {this.state.mission.title}
                    </span>
                  </div>
                  <div
                    style={{ fontSize: 12, color: '#454f63', opacity: 0.94 }}
                  >
                    {getMidnightMoment(this.state.mission.created_at)
                      .add(this.state.mission.days, 'days')
                      .isAfter(getMidnightMoment())
                      ? `D-${
                          this.state.mission.days -
                          getMidnightMoment(this.state.mission.created_at).diff(
                            getMidnightMoment(),
                            'days'
                          )
                        }`
                      : '종료'}{' '}
                    ∙ {this.state.mission.days}일 미션 by{' '}
                    {this.state.mission.starter}
                  </div>
                </div>
                <span
                  style={{
                    color: badge.color,
                    fontWeight: 'bold',
                    marginRight: 16,
                  }}
                >
                  {badge.cta}
                </span>
              </QnaOrPoll>
            )}
            {isQna && (
              <QnaOrPoll onClick={openCommentModal}>
                <div
                  style={{
                    width: '32px',
                    height: '32px',
                    borderRadius: 20,
                    background: badge.color,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 8,
                  }}
                >
                  <img src={QNA_IMG} style={{ width: 18, height: 18 }} />
                </div>{' '}
                <div style={{ flex: 1 }}>
                  <span
                    style={{
                      color: '#454f63',
                      fontSize: 14,
                      fontWeight: 'bold',
                    }}
                  >
                    {this.state.qna}
                  </span>
                  <div
                    style={{ fontSize: 12, color: '#454f63', opacity: 0.94 }}
                  >
                    {commentCount}개의 댓글
                  </div>
                </div>
                <span
                  style={{
                    color: badge.color,
                    fontWeight: 'bold',
                    marginRight: 16,
                  }}
                >
                  {badge.cta}
                </span>
              </QnaOrPoll>
            )}
            {isPoll && (
              <QnaOrPoll
                onClick={() => {
                  this.setState({ pollOpen: true });
                  openCommentModal();
                }}
              >
                <div
                  style={{
                    width: '32px',
                    height: '32px',
                    borderRadius: 20,
                    background: badge.color,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 8,
                  }}
                >
                  <img src={POLL_IMG} style={{ width: 18, height: 18 }} />
                </div>{' '}
                <span style={{ flex: 1 }}>
                  <div>
                    <span
                      style={{
                        color: '#454f63',
                        fontSize: 14,
                        fontWeight: 'bold',
                      }}
                    >
                      {this.state.pollTitle}
                    </span>
                  </div>
                  <div
                    style={{ fontSize: 12, color: '#454f63', opacity: 0.94 }}
                  >
                    {this.state.voteNumber}명 투표
                  </div>
                </span>
                <span
                  style={{
                    color: badge.color,
                    fontWeight: 'bold',
                    marginRight: 16,
                  }}
                >
                  {badge.cta}
                </span>
              </QnaOrPoll>
            )}
          </Media>
          <Info>
            <div>
              <LikeIcon
                on={this.state.isLiked}
                style={{ width: 20 }}
                onClick={this.handleLike}
              />
              <span
                role="button"
                onClick={() => {
                  history.push(`/post/${data.id}/like`);
                }}
              >
                좋아요 {this.state.likeNum}
              </span>
            </div>
            <div style={{ marginLeft: 4, marginRight: 4 }}>∙</div>
            <div onClick={openCommentModal}>댓글 {commentCount}</div>
            <div style={{ marginLeft: 4, marginRight: 4 }}>∙</div>
            <div
              style={
                data.item.length > 0
                  ? { color: '#1f75fe', flex: 1 }
                  : { flex: 1 }
              }
              role="button"
              onClick={() => this.props.onClickProducts(data)}
            >
              정보 {data.item.length}
            </div>
          </Info>
          {/* `${parseLink(data.title)} */}
          <Content>
            <div>
              <Comment text={data.title} primary maxLine={3} />
            </div>
            <div>
              {this.state.comments.slice(0, 2).map((comment) => (
                <div key={comment.id}>
                  <Comment
                    writer={comment.profile && comment.profile.nickname}
                    text={comment.content}
                    maxLine={1}
                    onClick={openCommentModal}
                  />
                </div>
              ))}
            </div>
            <p
              onClick={openCommentModal}
              style={{
                color: '#989faa',
                fontSize: 14,
                cursor: 'pointer',
                textAlign: 'right',
                marginTop: 6,
                marginBottom: 3,
                paddingRight: 8,
              }}
            >
              {data.comment && data.comment.count}개의 댓글 모두보기
            </p>
          </Content>
        </Layout>
        <PollModal
          onList
          open={this.state.pollOpen && this.props.isOpenCommentModal}
          onClose={() => {
            this.setState({ pollOpen: false });
          }}
          title={this.state.pollTitle}
          pollOptionA={this.state.pollOptionA}
          pollOptionB={this.state.pollOptionB}
          onVote={async (voteTo) => {
            if (this.props.userId) {
              if (!this.state.votedTo) {
                await axios.post(`${base}/votes`, {
                  userId: this.props.userId,
                  voteTo,
                  poll: this.state.pollData.id,
                });
                this.setState({
                  votedTo: voteTo,
                });
                this.calcPoll(this.state.pollData.id);
              }
            } else {
              this.props.toggleLogin();
            }
          }}
          votedTo={this.state.votedTo}
          result={this.state.votedTo && this.state.voteData}
        />
      </>
    );
  }
}
export default Card;
