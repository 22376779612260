import React, { Fragment } from 'react';
import styled from 'styled-components';

const RightWrap = styled.div`
	position: absolute;
	width: 30%;
	height: 100vh;
	right: 0px;
`;
const LeftWrap = styled.div`
	position: absolute;
	width: 30%;
	height: 100vh;
	left: 0px;
`;
class Video extends React.Component {
	constructor(props) {
		super(props);
		this._videoId = 0;
		this.state = {
			isPlaying: false,
			// duration이 있다는 말은 metadata가 로딩되었다는 것
			duration: null,
			loaded: false,
		};
	}
	componentWillUnmount() {
		// IOS는 비디오를 삭제 처리 해주어야 한다.
		// https://stackoverflow.com/questions/28905253/media-err-decode-on-html5-video-in-ios-uiwebview-after-many-plays
		if (this.video) {
			this.video.remove();
			this.video.src = '';
			this.video.load();
		}
	}
	componentDidMount() {
		this.video.load();
	}
	componentDidUpdate(prevProps) {
		// 이전의 비디오가 다를경우 로드한다.
		if (this.props.videoData && !prevProps.videoData) {
			this.video.load();
		}
		// 비디오가 로딩된 경우
		if (this.video && this.state.duration) {
			// 활성화 되었으나 재생 되지 않은 상태일 경우 재생한다.
			if (this.props.active && !this.state.isPlaying) {
				this.playVideo();
				// 비할성화 된 경우 정지한다.
			} else if (prevProps.active && !this.props.active) {
				this.video.pause();
				// this.video.currentTime = 0;
				this.setState({ isPlaying: false });
			}
		}

		if (!prevProps.stopByTouch && this.props.stopByTouch) {
			this.video.pause();
		} else if (prevProps.stopByTouch && !this.props.stopByTouch) {
			this.video.play();
		}
	}
	playVideo = () => {
		this.video.play();
		setTimeout(() => {
			this.props.onPlay(this.video);
		}, 100);
		this.setState({ isPlaying: true });
	};
	onEnded = () => {
		// 활성화 된 경우
		if (this.props.active) {
			// 비디오 길이가 2개 이상이면 다음 비디오를 재생한다.
			// 그렇지 않으면 다시 재생한다.
			if (this.props.autoRightClickOnEnd) {
				this.setState({ isPlaying: false, duration: null });
				this.props.rightClick();
			} else {
				this.playVideo();
			}
		} else {
			// 활성화되지 않은경우 비디오는 전부 정지한다.
			this.video.pause();
			this.video.currentTime = 0;
			this.setState({ isPlaying: false });
		}
	};
	onLoadedMetaData = () => {
		// 비디오 메타데이터가 로드된경우 duration 상태를 넣는다.
		this.setState({ duration: this.video.duration });
	};
	onClick = isRight => {
		if (this.props.rightClick && this.props.leftClick) {
			this.setState({ isPlaying: false, duration: null });
			if (isRight) {
				// 초기화
				this.props.rightClick();
			} else {
				this.props.leftClick();
			}
		}
	};
	render() {
		if (!this.props.videoData) return null;
		return (
			<Fragment>
				<video
					ref={video => {
						this.video = video;
					}}
					src={this.props.videoData.video}
					id={this.props.id}
					autoPlay={this.props.active}
					onEnded={this.onEnded}
					controls={false}
					playsInline
					muted
					onLoadedMetadata={this.onLoadedMetaData}
					style={{
						backgroundColor: 'black',
						borderRadius: 0,
						minWidth: '100%',
						minHeight: '100%',
						position: 'absolute',
						maxWidth: '375px',
					}}
					onClick={this.props.centerClick || false}
				/>
				<LeftWrap
					onClick={e => {
						e.stopPropagation();
						this.onClick(false);
					}}
				/>
				<RightWrap
					onClick={e => {
						e.stopPropagation();
						this.onClick(true);
					}}
				/>
			</Fragment>
		);
	}
}

Video.defaultProps = {};
export default Video;
