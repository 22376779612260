import React from 'react';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';
import { Wrap, IndexWrap } from 'components/style/custom-styled-components';
import ProductCategoryModal from 'components/popModal/productCategoryModal';
import SearchResult from 'containers/searchResult';
import LoginRequiredModal from 'components/popModal/loginRequiredModal';
import MyFilterModal from 'components/popModal/myFilterModal';
import Footer from 'components/footer';
import * as userAPI from 'axios/userAPI';
import history from 'utils/history';

const NavBar = styled.div`
  padding: 0px 18px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  position: fixed;
  line-height: 44px;
  height: 44px;
  background-color: #ffffff;
  width: 100%;
  max-width: 1024px;
  z-index: 3;
  top: 0;
  ${(props) =>
    props.singleBorder &&
    css`
      border-bottom: solid 1px #ececec;
      box-shadow: none;
    `}
`;
const NavItemWrap = styled.div`
  display: flex;
  align-items: center;
  span {
    color: #333333;
    font-family: 'Noto Sans CJK KR';
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
    :hover {
      opacity: 0.8;
    }
  }
`;
const SearchInput = styled.input`
  border: none;
  height: 26px;
  line-height: 26px;
  width: 100%;
  color: #333333;
  font-family: 'Noto Sans CJK KR';
  font-size: 16px;
  font-weight: 500;
`;
const ContentWrap = styled.div`
  color: #333333;
  font-family: 'Noto Sans CJK KR';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  p {
    margin-bottom: 10px;
  }
`;
const IconWrap = styled.i`
  position: fixed;
  top: 4px;
  cursor: pointer;
`;
const HashTag = styled.span`
  cursor: default !important;
  flex: 0 0 auto;
  margin-left: 29px;
  color: #333333;
  font-family: 'Noto Sans CJK KR';
  font-size: 16px;
  font-weight: 500;
  :hover {
    opacity: 1 !important;
  }
`;

@inject('userStore')
@inject('cacheStore')
@observer
class SearchPage extends React.Component {
  constructor(props) {
    super(props);
    let clickedTag = undefined;
    if (
      typeof window !== 'undefined' &&
      window.location.search.includes('tag=')
    ) {
      const { search } = window.location;
      clickedTag = decodeURIComponent(search.slice('?tag='.length));
    }
    this.state = {
      searchVal: clickedTag || '',
      currentTab: '포스팅',
      profile: undefined,
      clickedTag,
      tagListOn: false,
      productCategoryModalOpen: false,
    };
  }
  async componentDidMount() {
    if (this.state.clickedTag) {
      this.setState(
        {
          searchVal: this.state.clickedTag,
        },
        () => {
          this.handleSelectTag(this.state.clickedTag);
        }
      );
    }
    if (this.props.userStore.auth_token) {
      const profileReq = {
        headers: { Authorization: `Bearer ${this.props.userStore.auth_token}` },
      };
      const profile = await userAPI.getMyUserProfile(profileReq).then((res) => {
        return res.data;
      });
      this.setState({
        profile: profile,
      });
    }
  }
  handleTabChange = (tab) => {
    this.setState({
      currentTab: tab,
    });
  };
  handleSearch = (event) => {
    const { value } = event.target;
    this.setState({
      currentTab: this.state.currentTab,
      searchVal: value,
      tagListOn: value !== '',
    });
  };
  handleKeyPress = (e) => {
    const KeyCode = {
      ENTER: 13,
    };
    if (e.charCode == KeyCode.ENTER) {
      this.handleSubmitTag();
    }
  };
  handleSubmitTag = () => {
    const newRecentSearchArr = this.props.cacheStore.myRecentSearch.concat(
      this.state.searchVal
    );
    this.props.cacheStore.updateMyRecentSearch(newRecentSearchArr);
  };
  handleSelectTag = (searchVal) => {
    this.setState(
      {
        searchVal,
        tagListOn: false,
      },
      () => {
        this.handleSubmitTag();
      }
    );
  };
  handleClickAdd = () => {
    this.setState({
      productCategoryModalOpen: true,
    });
  };
  render() {
    return (
      <Wrap style={{ paddingBottom: 0 }}>
        <IndexWrap style={{ paddingTop: 44, backgroundColor: '#f8f8f8' }}>
          <LoginRequiredModal bottom={'0px'} />
          <MyFilterModal
            bottom={'0px'}
            is_applied={
              this.state.profile ? this.state.profile.is_applied : undefined
            }
            profile={this.state.profile}
          />
          <NavBar singleBorder>
            <NavItemWrap style={{ width: '100%' }}>
              <IconWrap
                onClick={() =>
                  this.setState({
                    searchVal: '',
                    currentTab: undefined,
                  })
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                >
                  <path
                    fill="#bebebe"
                    d="M6.5 11C4.01 11 2 8.99 2 6.5S4.01 2 6.5 2 11 4.01 11 6.5 8.99 11 6.5 11zm6 0h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34A6.505 6.505 0 0 0 .05 7.32c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49z"
                  />
                </svg>
              </IconWrap>
              <HashTag>
                {this.state.currentTab === '포스팅' ? '#' : null}
              </HashTag>
              <SearchInput
                value={
                  this.state.currentTab === '상품'
                    ? '지금은 상품 검색이 불가능합니다.'
                    : this.state.searchVal
                }
                style={{ background: 'white' }}
                disabled={this.state.currentTab === '상품'}
                onChange={this.handleSearch}
                onKeyPress={this.handleKeyPress}
              />
            </NavItemWrap>
            {this.state.searchVal !== '' && (
              <NavItemWrap
                style={{
                  width: '30px',
                  textAlign: 'center',
                  flex: '0 0 auto',
                }}
              >
                <span
                  style={{ width: '100%' }}
                  onClick={() =>
                    this.setState({ searchVal: '', tagListOn: false })
                  }
                >
                  취소
                </span>
              </NavItemWrap>
            )}
          </NavBar>
          <ContentWrap>
            <SearchResult
              currentTab={this.state.currentTab}
              handleSelectTag={this.handleSelectTag}
              handleTabChange={this.handleTabChange}
              searchVal={this.state.searchVal}
              tagListOn={this.state.tagListOn}
            />
          </ContentWrap>
        </IndexWrap>
        <Footer onClickAdd={this.handleClickAdd} />
        <ProductCategoryModal
          open={this.state.productCategoryModalOpen}
          handleClose={() =>
            this.setState({
              productCategoryModalOpen: false,
            })
          }
          onClick={(selected) => {
            if (this.props.userStore.getTokenOrToggleLogin())
              history.push(`/new-post?selected=${selected}`);
          }}
          bottom={'0px'}
        />
      </Wrap>
    );
  }
}
export default SearchPage;
