export default function getDaysInMonth(month, year) {
  // Since no month has fewer than 28 days
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  let arr = [];
  let i = 0;
  while (i < days.length) {
    arr.push(i + 1);
    i++;
  }
  return arr;
}
