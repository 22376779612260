import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { CSSTransition } from "react-transition-group";
import BookmarkBtn from "components/bookmarkBtn";
import categoryItems from "utils/categoryItems";
import LinkSvg from "components/myIcons/linkIcon";
import { url } from "../../utils/starApi";
import "./index.css";

const Layout = styled.div`
  z-index: 1000;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  font-family: "Noto Sans CJK KR";
`;
const ProductLayout = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  z-index: 1000;
`;
const ProductList = styled.div`
  background-color: rgba(255, 255, 255, 1);
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 10px;
  margin-bottom: 20px;
`;
const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
const ProductNum = styled.div`
  color: white;
  margin-bottom: 8px;
  font-size: 16px;
  margin-left: 16px;
`;
const ProductItem = styled.div`
  height: 84px;
  padding: 16px;
  display: flex;
  border-bottom: 1px solid #efefef;
  ${(props) =>
    props.noBorder &&
    css`
      border-bottom: none;
    `}
`;
const ProductImg = styled.img`
  width: 52px !important;
  height: 52px;
  border-radius: 8px;
  object-fit: cover;
  margin-right: 12px;
  display: inline-block;
  flex-shrink: 0;
`;
const ProductContent = styled.div`
  display: flex;
  width: 100%;
`;
const ProductDesc = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ProductCategory = styled.p`
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  margin-bottom: 0px;
`;

const ProductStore = styled.p`
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
`;

const BookmarkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export default ({ on, onClose, items, ad, onClickAd }) => {
  // 기존, 크롤링 완료
  const isUpdated = (item) =>
    item.shopLink !== "" && item.store !== "업데이트중";
  // 신규
  const isNewlyUploaded = (item) => item.shopName;
  const getProductImage = (item) => {
    const isNew = isNewlyUploaded(item);
    const emptyImage = categoryItems["fashion"].find((o) => o.label === "기타")
      .src;
    if (isNew) {
      const found = categoryItems[item.category].find(
        (o) => o.label === item.label
      );
      if (found) return found.src;
      else return emptyImage;
    } else return emptyImage;
  };
  const noLink = (item) =>
    (!item.shopLink || item.shopLink === "") &&
    (!item.link || item.link === "");
  const validLink = (item) =>
    !item.shopLink || item.shopLink === "" ? item.link : item.shopLink;

  return (
    <CSSTransition
      in={on}
      timeout={200}
      classNames="product-list"
      mountOnEnter
      unmountOnExit
    >
      <Layout>
        <Backdrop onClick={onClose} role="button" />
        <ProductLayout>
          <ProductNum>{items.length}개의 정보</ProductNum>
          <ProductList>
            {items.map((item, i) => (
              <ProductItem
                key={`${item.link}${i}${item.shopLink}`}
                noBorder={i === items.length - 1}
                role="button"
                onClick={() => !noLink(item) && window.open(validLink(item))}
              >
                {/* <ProductImg
									src={
										isUpdated(item) ? item.image.image : getProductImage(item)
									}
								/> */}
                <ProductContent>
                  <ProductDesc>
                    <ProductCategory>
                      {isNewlyUploaded(item) ? item.label : "기타"}
                    </ProductCategory>
                    <ProductStore>
                      {isNewlyUploaded(item) ? item.shopName : item.store}
                      {!noLink(item) && (
                        <LinkSvg
                          style={{
                            width: 20,
                            height: 20,
                            fill: "#828ff7",
                          }}
                        />
                      )}
                    </ProductStore>
                  </ProductDesc>
                </ProductContent>
              </ProductItem>
            ))}
            {ad && (
              <ProductItem
                role="button"
                onClick={() => {
                  onClickAd();
                }}
                noBorder
              >
                <ProductImg src={ad.imageUrl} />
                <ProductContent>
                  <ProductDesc>
                    <ProductCategory>{ad.title}</ProductCategory>
                    <ProductStore>
                      {ad.shopName}
                      <LinkSvg
                        style={{
                          width: 20,
                          height: 20,
                          fill: "#828ff7",
                        }}
                      />
                    </ProductStore>
                  </ProductDesc>
                </ProductContent>
              </ProductItem>
            )}
          </ProductList>
        </ProductLayout>
      </Layout>
    </CSSTransition>
  );
};
