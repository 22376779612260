import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';

import item_modal_img from 'images/item_modal_ico.png';
import history from 'utils/history';

const Wrapper = styled.div`
	width: 100%;
	max-width: 480px;
	height: 100vh;
	top: 0px;
	z-index: -1;
	position: fixed;
	transition: 0.2s background-color ease, z-index 0.2s ease;

	${props =>
		props.active &&
		css`
			z-index: 1002;
			background-color: rgba(0, 0, 0, 0.5);
			transition: 0.2s background-color ease;
		`}
`;
const Modal = styled.div`
	max-width: 480px;
	padding: 16px;
	position: fixed;
	bottom: -400px;
	width: 100%;
	height: 323px;
	background: #ffffff;
	transition: 0.2s ease;
	z-index: 1002;
	${props =>
		props.active &&
		css`
			bottom: ${props.bottom ? props.bottom : '48px'};
			box-shadow: 0 2px 20px rgba(0, 0, 0, 0.4);
			border-radius: 20px 20px 0 0;
		`}
`;
const OutSideWrap = styled.div`
	width: 100%;
	height: 100%;
	z-index: 1;
	top: 0px;
	position: fixed;
	background: transparent;
`;
const IconWrapper = styled.div`
	width: 104px;
	height: 104px;
	display: flex;
	align-items: center;
	margin: auto;
	margin-top: 24px;
	background-color: rgba(130, 143, 247, 0.1);
	border-radius: 50%;
	text-align: center;
	i {
		vertical-align: middle;
		width: 100%;
		height: 64px;
	}
	svg {
		margin: auto;
		vertical-align: text-top;
	}
`;
const BigTextWrap = styled.p`
	margin-top: 16px;
	margin-bottom: 0px;
	text-align: center;
	width: 100%;
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 18px;
	font-weight: 700;
`;
const SmallTextWrap = styled.p`
	margin-top: 3px;
	margin-bottom: 31px;
	text-align: center;
	width: 100%;
	color: #bebebe;
	font-family: 'Noto Sans CJK KR';
	font-size: 14px;
	font-weight: 500;
`;
const Btn = styled.div`
	width: 100%;
	height: 44px;
	box-shadow: 0 2px 10px rgba(176, 141, 247, 0.5);
	border-radius: 68px;
	background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	line-height: 44px;
	cursor: pointer;
	:hover {
		opacity: 0.8;
	}
`;
const IconImg = styled.div`
	background-size: cover;
	background-position: center;
	width: 63px;
	height: 58px;
	margin: auto;
`;

@inject('userStore')
@observer
class ItemLinkRequiredModal extends Component {
	constructor() {
		super();
		this.state = {};
	}

	_onClick = () => {
		this.props.userStore.toggleItemLinkRequired();
		history.push('/new-post');
	};

	render() {
		return (
			<Wrapper active={this.props.userStore.itemLinkRequired}>
				<OutSideWrap
					className="123123123"
					onClick={this.props.userStore.toggleItemLinkRequired}
				/>
				<Modal
					active={this.props.userStore.itemLinkRequired}
					bottom={this.props.bottom}
				>
					<IconWrapper>
						<IconImg style={{ backgroundImage: `url('${item_modal_img}')` }} />
					</IconWrapper>
					<BigTextWrap>상품의 링크가 필요해요!</BigTextWrap>
					<SmallTextWrap>
						하나 이상의 링크가 존재해야지만
						<br />
						게시글을 올릴 수 있어요.
					</SmallTextWrap>
					<Btn onClick={this._onClick}>확인했어요</Btn>
				</Modal>
			</Wrapper>
		);
	}
}

export default ItemLinkRequiredModal;
