import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    width: "calc(50% - 12px)",
    margin: 6,
    marginBottom: 14,
  },
  box: {
    display: "flex",
    flexFlow: "wrap",
    borderRadius: 8,
    overflow: "hidden",
    marginBottom: 6,
  },
  outerWrapper: {
    width: "50%",
    borderRight: "1px solid white",
    borderBottom: "1px solid white",
  },
  wrapper: {
    position: "relative",
    paddingTop: "100%",
    width: "100%",
  },
  innerWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: "#dedede",
  },
  block: {
    width: 70,
    height: 70,
    borderRight: "1px solid white",
    borderBottom: "1px solid white",
  },
  img: { width: "100%", height: "100%", objectFit: "cover" },
  null: { width: "100%", height: "100%" },
};
export default withStyles(styles)(
  ({ classes, name, count, imgs = [], onClick }) => {
    const arrs = [...imgs];
    while (arrs.length < 4) {
      arrs.push(null);
    }
    return (
      <div className={classes.root} role="button" onClick={onClick}>
        <div className={classes.box}>
          {[0, 1, 2, 3].map((idx) => (
            <div className={classes.outerWrapper}>
              <div key={idx} className={classes.wrapper}>
                <div className={classes.innerWrapper}>
                  {imgs[idx] && <img className={classes.img} src={imgs[idx]} />}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
          {name} <strong>{count}</strong>
        </div>
      </div>
    );
  }
);
