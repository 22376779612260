import etc from './기타.png';
import shop from './샵.png';
import style from './스타일링.png';
import essence from './에센스.png';
import color from './염색&펌.png';
import cair from './케어.png';
import hair from './헤어기기.png';

export default {
	etc,
	shop,
	style,
	essence,
	color,
	cair,
	hair,
};
