import axios from "axios";
const BASE = "https://star-api.lay-er.me";

export default ({ ids, nickname, postId, prefix = "내용" }) =>
  Promise.all(
    ids.map((id) =>
      axios.post(`${BASE}/push/send`, {
        userId: id,
        payload: {
          message: `${nickname}님이 ${prefix}에 회원님을 언급하였습니다.`,
          link: `/post/${postId}`,
        },
      })
    )
  );
