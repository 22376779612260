import React from "react";

const Sum = () => (
  <svg
    id="구성_요소_11_1"
    data-name="구성 요소 11 – 1"
    xmlns="http://www.w3.org/2000/svg"
    width="127.792"
    height="52.942"
    viewBox="0 0 127.792 52.942"
    style={{
      fill: "url(#linear-gradient)",
    }}
  >
    <defs>
      <style>.cls-1</style>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#1a55bc" />
        <stop offset="1" stop-color="#68e5bf" />
      </linearGradient>
    </defs>
    <path
      id="패스_1075"
      data-name="패스 1075"
      d="M127.792,47.749a10.282,10.282,0,0,0-3.654-1.418c-5.741-2.884-16.6-10.5-27.848-29.712C87.955,2.373,79.64-.268,74.156.03,65.8.475,57.878,7.743,51.711,20.556c-2.736-1.756-8.369-4.579-14.954-3.478-6.3,1.06-11.786,5.424-16.3,12.968C14.184,40.495,6.66,45.175,4.3,46.459A11.855,11.855,0,0,0,0,48.58l1.966,2.749a7.68,7.68,0,0,1,5.045-1.81,7.68,7.68,0,0,1,5.045,1.81,6.848,6.848,0,0,0,4.566,1.621,6.882,6.882,0,0,0,4.566-1.621,7.937,7.937,0,0,1,10.091,0,7.241,7.241,0,0,0,9.132,0,7.937,7.937,0,0,1,10.091,0,7.25,7.25,0,0,0,9.139,0,7.477,7.477,0,0,1,9.74,0,7.241,7.241,0,0,0,9.132,0,7.937,7.937,0,0,1,10.091,0,7.241,7.241,0,0,0,9.132,0,7.937,7.937,0,0,1,10.091,0,7.241,7.241,0,0,0,9.132,0A7.68,7.68,0,0,1,122,49.519a8.089,8.089,0,0,1,1.108.074.007.007,0,0,1,.007.007h0a6.918,6.918,0,0,1,2.9,1.033Z"
      transform="translate(0 -0.008)"
    />
    <path
      id="패스_1076"
      data-name="패스 1076"
      class="cls-1"
      d="M119.916,50.195a3.919,3.919,0,0,1-5.214,0,11.289,11.289,0,0,0-14.015,0,3.919,3.919,0,0,1-5.214,0,11.289,11.289,0,0,0-14.015,0,3.919,3.919,0,0,1-5.214,0,10.949,10.949,0,0,0-6.66-2.432l-.351-.007v.007a10.949,10.949,0,0,0-6.66,2.432,3.919,3.919,0,0,1-5.214,0,11.289,11.289,0,0,0-14.015,0,3.919,3.919,0,0,1-5.214,0,11.289,11.289,0,0,0-14.015,0,3.61,3.61,0,0,1-2.607.993,3.62,3.62,0,0,1-2.607-.993,12.263,12.263,0,0,0-3.749-1.979A51.253,51.253,0,0,0,28.24,33.4c3.985-6.646,8.673-10.469,13.941-11.368,7.558-1.29,13.948,3.938,14.015,3.992L57.9,27.453l.912-2.033c5.647-12.57,13.09-20.006,20.412-20.4,6.511-.351,13.117,4.809,19.034,14.92,9.064,15.494,17.926,23.9,24.829,28.456A13.54,13.54,0,0,0,119.916,50.195Z"
      transform="translate(-4.917 -1.63)"
    />
  </svg>
);

export default ({ style, img, name, onClick, isAll }) => (
  <div
    style={{
      textAlign: "center",
      height: 160,
      ...style,
    }}
    role="button"
    onClick={onClick}
  >
    {isAll ? (
      <div
        style={{
          height: "100%",
          boxShadow: "0 0 27px rgba(57, 57, 57, 0.06)",
          backgroundColor: "#ffffff",
          borderRadius: 8,
          margin: 6,
          paddingTop: 12,
          paddingBottom: 16,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ marginBottom: 8, flex: 1 }}>
          {img ? (
            <img src={img} style={{ maxWidth: 140, width: "100%" }} />
          ) : (
            <Sum />
          )}
        </div>
        <div>
          <div style={{ fontSize: 16 }}>
            <strong>{name}</strong>
          </div>
          <div style={{ fontSize: 16 }}>
            <strong>HOT</strong>
          </div>
        </div>
      </div>
    ) : (
      <div
        style={{
          height: "100%",
          boxShadow: "0 0 27px rgba(57, 57, 57, 0.06)",
          backgroundColor: "#ffffff",
          borderRadius: 8,
          margin: 6,
          overflow: "hidden",
          position: "relative",
        }}
      >
        {img ? (
          <img
            src={img}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        ) : (
          <div style={{ width: "100%", height: "100%", objectFit: "cover" }} />
        )}
        <div
          style={{
            fontSize: 16,
            position: "absolute",
            width: "100%",
            color: "white",
            top: 0,
            height: "100%",
            background: "rgba(0,0,0,0.3)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <strong>{name}</strong>
        </div>
      </div>
    )}
  </div>
);
