import React, { Component } from 'react';

class DetailCommentIcon extends Component {
	constructor() {
		super();
	}

	render() {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="28"
				height="27"
				viewBox="0 0 28 27"
			>
				<defs>
					<mask id="m04nb" width="2" height="2" x="-1" y="-1">
						<path fill="#fff" d="M2 2h24v24H2z" />
						<path d="M2 2h24v24H2z" />
					</mask>
					<filter
						id="m04na"
						width="54"
						height="54"
						x="-13"
						y="-13"
						filterUnits="userSpaceOnUse"
					>
						<feOffset in="SourceGraphic" result="FeOffset1079Out" />
					</filter>
					<mask id="m04nd" width="2" height="2" x="-1" y="-1">
						<path fill="#fff" d="M4 4h20v20H4z" />
						<path d="M21 18H8l-2 2V7c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v10c0 .55-.45 1-1 1zm1-14H6c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2z" />
					</mask>
					<filter
						id="m04nc"
						width="50"
						height="50"
						x="-11"
						y="-11"
						filterUnits="userSpaceOnUse"
					>
						<feOffset in="SourceGraphic" result="FeOffset1094Out" />
					</filter>
					<mask id="m04nf" width="2" height="2" x="-1" y="-1">
						<path fill="#fff" d="M9 9h11v2H9z" />
						<path d="M10 9h8.458a1 1 0 0 1 0 2H10a1 1 0 0 1 0-2z" />
					</mask>
					<filter
						id="m04ne"
						width="41"
						height="32"
						x="-6"
						y="-6"
						filterUnits="userSpaceOnUse"
					>
						<feOffset in="SourceGraphic" result="FeOffset1110Out" />
					</filter>
					<mask id="m04nh" width="2" height="2" x="-1" y="-1">
						<path fill="#fff" d="M9 13h11v2H9z" />
						<path d="M10 13h8.458a1 1 0 0 1 0 2H10a1 1 0 0 1 0-2z" />
					</mask>
					<filter
						id="m04ng"
						width="41"
						height="32"
						x="-6"
						y="-2"
						filterUnits="userSpaceOnUse"
					>
						<feOffset in="SourceGraphic" result="FeOffset1126Out" />
					</filter>
				</defs>
				<g>
					<g>
						<g>
							<g filter="url(#m04na)">
								<path fill="none" d="M2 2h24v24H2z" mask='url("#m04nb")' />
							</g>
						</g>
						<g>
							<g filter="url(#m04nc)">
								<path
									fill="none"
									d="M21 18H8l-2 2V7c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v10c0 .55-.45 1-1 1zm1-14H6c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2z"
									mask='url("#m04nd")'
								/>
								<path
									fillOpacity=".2"
									d="M21 18H8l-2 2V7c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v10c0 .55-.45 1-1 1zm1-14H6c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2z"
								/>
							</g>
							<path
								fill="#fff"
								d="M21 18H8l-2 2V7c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v10c0 .55-.45 1-1 1zm1-14H6c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2z"
							/>
						</g>
						<g>
							<g filter="url(#m04ne)">
								<path
									fill="none"
									d="M10 9h8.458a1 1 0 0 1 0 2H10a1 1 0 0 1 0-2z"
									mask='url("#m04nf")'
								/>
								<path
									fillOpacity=".2"
									d="M10 9h8.458a1 1 0 0 1 0 2H10a1 1 0 0 1 0-2z"
								/>
							</g>
							<path
								fill="#fff"
								d="M10 9h8.458a1 1 0 0 1 0 2H10a1 1 0 0 1 0-2z"
							/>
						</g>
						<g>
							<g filter="url(#m04ng)">
								<path
									fill="none"
									d="M10 13h8.458a1 1 0 0 1 0 2H10a1 1 0 0 1 0-2z"
									mask='url("#m04nh")'
								/>
								<path
									fillOpacity=".2"
									d="M10 13h8.458a1 1 0 0 1 0 2H10a1 1 0 0 1 0-2z"
								/>
							</g>
							<path
								fill="#fff"
								d="M10 13h8.458a1 1 0 0 1 0 2H10a1 1 0 0 1 0-2z"
							/>
						</g>
					</g>
				</g>
			</svg>
		);
	}
}

export default DetailCommentIcon;
