import axios from 'axios';
import { ROOT_URL } from './config';

export function getEventBanner(req) {
	return axios.get(ROOT_URL + `/api/v1/article/?count=4&mode=${req.mode}`);
}

export function getEvents(req) {
	if (req.headers) {
		return axios.get(ROOT_URL + '/api/v1/event/?ordering=' + req.filter, {
			headers: req.headers,
		});
	}
	return axios.get(ROOT_URL + '/api/v1/event/?ordering=' + req.filter);
}

export function getEvent(req) {
	if (req.headers) {
		return axios.get(ROOT_URL + `/api/v1/event/${req.id}/`, {
			headers: req.headers,
		});
	}
	return axios.get(ROOT_URL + `/api/v1/event/${req.id}/`);
}
