import { Tabs } from 'antd-mobile';
import React from 'react';
import './index.css';

class _Tabs extends React.Component {
	render() {
		return (
			<Tabs
				tabs={this.props.tabs}
				initialPage={this.props.initialPage}
				swipeable={false}
				animated={false}
				useOnPan={false}
				tabBarTextStyle={{
					fontWeight: 700,
					fontFamily: 'Noto Sans CJK KR',
					cursor: 'pointer',
				}}
				tabBarActiveTextColor={'#828ff7'}
				tabBarInactiveTextColor={'#bebebe'}
				onChange={this.props.onTabChange}
			>
				{this.props.children}
			</Tabs>
		);
	}
}

export default _Tabs;
