import React from 'react';
import styled, { css } from 'styled-components';
import default_profile from 'images/default_profile.png';
import star from 'images/star.png';
import right from 'images/right.png';

const ProfileWrap = styled.div`
	margin: auto;

	padding-bottom: 12px;
	display: flex;
	flex-direction: row;
	align-items: center;
	p {
		margin: 0px !important;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	p.profile-name {
		color: #333333;
		font-family: 'Noto Sans CJK KR';
		font-size: 16px;
		font-weight: 700;
	}
	p.profile-intro {
		color: #999999;
		font-family: 'Noto Sans CJK KR';
		font-size: 14px;
		font-weight: 500;
	}
	span.profile-btn {
		display: inline-block;
		text-align: center;
		width: 64px;
		height: 28px;
		border-radius: 20px;
		vertical-align: middle;
		font-family: 'Noto Sans CJK KR';
		font-size: 13px;
		font-weight: 700;
		line-height: 28px;
		cursor: pointer;
		hover: {
			opacity: 0.8;
		}
	}
	span.profile-follow-btn {
		background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
		color: #ffffff;
	}
	span.profile-following-btn {
		background-color: rgba(130, 143, 247, 0.2);
		color: #828ff7;
	}
`;
const ProfileImage = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-size: cover;
	background-position: center;
	display: flex;
	margin-right: 12px;
	cursor: pointer;
	:hover {
		opacity: 0.8;
	}
	${(props) =>
		props.isDefaultProfileImg &&
		css`
			background-color: white;
		`}
	div {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-size: cover;
		background-position: center;
		${(props) =>
			props.isDefaultProfileImg &&
			css`
				margin: auto;
				width: 32px;
				height: 32px;
				border-radius: 0;
			`}
	}
`;
const StarWrapper = styled.div`
	display: flex;
	align-items: center;
`;
const StarNum = styled.span`
	color: #4b4b4b;
	font-family: 'Noto Sans CJK KR';
	font-size: 17px;
	font-weight: 500;
	line-height: 28px;
	margin-right: 12px;
	margin-left: 8px;
`;
const Star = styled.img`
	width: 20px;
	height: 20px;
`;
const Right = styled.img`
	width: 8px;
	height: 13px;
`;

export default ({ user, amount }) => (
	<ProfileWrap>
		<ProfileImage isDefaultProfileImg={user.image ? false : true}>
			<div
				style={{
					backgroundImage: `url('${
						user.image ? user.image : default_profile
					}')`,
				}}
			/>
		</ProfileImage>
		<div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
			<p className="profile-name"> {user.nickname} </p>
			<p className="profile-intro"> {user.introduction} </p>
		</div>
		<StarWrapper
			role="button"
			onClick={() => (window.location.href = '/star/usage')}
		>
			<Star src={star} />
			<StarNum>{amount}</StarNum>
			<Right src={right} />
		</StarWrapper>
	</ProfileWrap>
);
