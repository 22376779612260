import React, { Component } from "react";

class InstaIcon extends Component {
  constructor() {
    super();
  }

  render() {
    if (!this.props.mode) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="11"
          viewBox="0 0 11 11"
        >
          <defs>
            <linearGradient
              id="rf3ca"
              x1="5.5"
              x2="5.5"
              y1=".09"
              y2="10.97"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#e09b3d" />
              <stop offset=".3" stopColor="#c74c4d" />
              <stop offset=".6" stopColor="#c21975" />
              <stop offset="1" stopColor="#7024c4" />
            </linearGradient>
            <linearGradient
              id="rf3cb"
              x1="5.5"
              x2="5.5"
              y1=".2"
              y2="10.87"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#e09b3d" />
              <stop offset=".3" stopColor="#c74c4d" />
              <stop offset=".6" stopColor="#c21975" />
              <stop offset="1" stopColor="#7024c4" />
            </linearGradient>
            <linearGradient
              id="rf3cc"
              x1="8.34"
              x2="8.34"
              y1=".05"
              y2="11.14"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#e09b3d" />
              <stop offset=".3" stopColor="#c74c4d" />
              <stop offset=".6" stopColor="#c21975" />
              <stop offset="1" stopColor="#7024c4" />
            </linearGradient>
          </defs>
          <g>
            <g>
              <g>
                <path
                  fill="url(#rf3ca)"
                  d="M9.893 7.723a2.17 2.17 0 0 1-2.17 2.17H3.277a2.17 2.17 0 0 1-2.17-2.17V3.277a2.17 2.17 0 0 1 2.17-2.17h4.446a2.17 2.17 0 0 1 2.17 2.17zM7.723 0H3.277A3.28 3.28 0 0 0 0 3.277v4.446A3.28 3.28 0 0 0 3.277 11h4.446A3.28 3.28 0 0 0 11 7.723V3.277A3.28 3.28 0 0 0 7.723 0z"
                />
              </g>
              <g>
                <path
                  fill="url(#rf3cb)"
                  d="M5.5 7.207a1.707 1.707 0 1 1 0-3.414 1.707 1.707 0 0 1 0 3.414zm0-4.5A2.797 2.797 0 0 0 2.706 5.5 2.797 2.797 0 0 0 5.5 8.294 2.797 2.797 0 0 0 8.294 5.5 2.797 2.797 0 0 0 5.5 2.706z"
                />
              </g>
              <g>
                <path
                  fill="url(#rf3cc)"
                  d="M7.648 2.684a.695.695 0 1 1 1.39 0 .695.695 0 0 1-1.39 0z"
                />
              </g>
            </g>
          </g>
        </svg>
      );
    } else if (this.props.mode == "userProfile") {
      return (
        <svg
          style={{ verticalAlign: "text-top" }}
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
        >
          <defs>
            <linearGradient
              id="oea0a"
              x1="6.9"
              x2="6.9"
              y1=".33"
              y2="13.54"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#e09b3d" />
              <stop offset=".3" stopColor="#c74c4d" />
              <stop offset=".6" stopColor="#c21975" />
              <stop offset="1" stopColor="#7024c4" />
            </linearGradient>
            <linearGradient
              id="oea0b"
              x1="6.9"
              x2="6.9"
              y1=".65"
              y2="13.22"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#e09b3d" />
              <stop offset=".3" stopColor="#c74c4d" />
              <stop offset=".6" stopColor="#c21975" />
              <stop offset="1" stopColor="#7024c4" />
            </linearGradient>
            <linearGradient
              id="oea0c"
              x1="10.33"
              x2="10.33"
              y1=".19"
              y2="14.08"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#e09b3d" />
              <stop offset=".3" stopColor="#c74c4d" />
              <stop offset=".6" stopColor="#c21975" />
              <stop offset="1" stopColor="#7024c4" />
            </linearGradient>
          </defs>
          <g>
            <g>
              <g>
                <path
                  fill="url(#oea0a)"
                  d="M12.229 9.595a2.633 2.633 0 0 1-2.633 2.634H4.2a2.633 2.633 0 0 1-2.634-2.634V4.201a2.633 2.633 0 0 1 2.634-2.634h5.395a2.633 2.633 0 0 1 2.633 2.634zM9.596.225H4.2A3.98 3.98 0 0 0 .225 4.2v5.394A3.98 3.98 0 0 0 4.2 13.572h5.395a3.98 3.98 0 0 0 3.976-3.977V4.201A3.98 3.98 0 0 0 9.596.225z"
                />
              </g>
              <g>
                <path
                  fill="url(#oea0b)"
                  d="M6.898 8.906a2.008 2.008 0 1 1 0-4.016 2.008 2.008 0 0 1 0 4.016zm0-5.295a3.29 3.29 0 0 0-3.287 3.287 3.29 3.29 0 0 0 3.287 3.287 3.29 3.29 0 0 0 3.287-3.287 3.29 3.29 0 0 0-3.287-3.287z"
                />
              </g>
              <g>
                <path
                  fill="url(#oea0c)"
                  d="M9.462 3.497a.87.87 0 1 1 1.74 0 .87.87 0 0 1-1.74 0z"
                />
              </g>
            </g>
          </g>
        </svg>
      );
    } else if (this.props.mode == "myProfile") {
      return (
        <svg
          style={{ verticalAlign: "text-bottom", marginRight: 25 }}
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <defs>
            <linearGradient
              id="diexa"
              x1="9"
              x2="9"
              y1=".15"
              y2="17.96"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#e09b3d" />
              <stop offset=".3" stopColor="#c74c4d" />
              <stop offset=".6" stopColor="#c21975" />
              <stop offset="1" stopColor="#7024c4" />
            </linearGradient>
            <linearGradient
              id="diexb"
              x1="9"
              x2="9"
              y1=".58"
              y2="17.52"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#e09b3d" />
              <stop offset=".3" stopColor="#c74c4d" />
              <stop offset=".6" stopColor="#c21975" />
              <stop offset="1" stopColor="#7024c4" />
            </linearGradient>
            <linearGradient
              id="diexc"
              x1="13.63"
              x2="13.63"
              y1="-.04"
              y2="18.72"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#e09b3d" />
              <stop offset=".3" stopColor="#c74c4d" />
              <stop offset=".6" stopColor="#c21975" />
              <stop offset="1" stopColor="#7024c4" />
            </linearGradient>
          </defs>
          <g>
            <g>
              <g>
                <path
                  fill="url(#diexa)"
                  d="M16.19 12.638a3.552 3.552 0 0 1-3.552 3.551H5.362a3.552 3.552 0 0 1-3.551-3.551V5.362a3.552 3.552 0 0 1 3.551-3.551h7.276a3.551 3.551 0 0 1 3.551 3.551zM12.637 0H5.362A5.368 5.368 0 0 0 0 5.362v7.276A5.368 5.368 0 0 0 5.362 18h7.276A5.368 5.368 0 0 0 18 12.638V5.362A5.368 5.368 0 0 0 12.638 0z"
                />
              </g>
              <g>
                <path
                  fill="url(#diexb)"
                  d="M9 11.708a2.708 2.708 0 1 1 0-5.416 2.708 2.708 0 0 1 0 5.416zm0-7.14A4.437 4.437 0 0 0 4.568 9 4.438 4.438 0 0 0 9 13.432 4.437 4.437 0 0 0 13.432 9 4.437 4.437 0 0 0 9 4.568z"
                />
              </g>
              <g>
                <path
                  fill="url(#diexc)"
                  d="M12.458 4.413a1.173 1.173 0 1 1 2.345 0 1.173 1.173 0 0 1-2.345 0z"
                />
              </g>
            </g>
          </g>
        </svg>
      );
    }
  }
}

export default InstaIcon;
