import React, { Fragment } from 'react';
import axios from 'axios';
import styled, { css, keyframes } from 'styled-components';
import { observer, inject } from 'mobx-react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import history from 'utils/history';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from 'components/loadingSpinner/spinnerCurtain';
import PostGrid from 'components/PostGrid';
import SquarePosts from 'components/SquarePosts';

import FollowBtn from 'components/followBtn';
import InstaIcon from 'components/myIcons/instaIcon';
import YoutubeIcon from 'components/myIcons/youtubeIcon';

// import history from "utils/history";
import * as userAPI from 'axios/userAPI';
import * as postAPI from 'axios/postAPI';

import default_profile from 'images/default_profile.png';
import { sum } from 'lodash';

const base = 'https://star-api.lay-er.me';
const IMAGE =
  'https://movedot.s3.amazonaws.com/media/thumbnail/2020-05-31/GQg5iMwc5g_thumbnail.jpeg';

const emerge = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
const ProfileImagePhoto = styled.div`
  animation: ${emerge} 0.5s;
  animation-fill-mode: forwards;
`;
const ProfileWrap = styled.div`
  padding: 16px;
  margin-top: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  p.profile-name {
    color: #333333;
    font-family: 'Noto Sans CJK KR';
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 4px;
    flex: 1;
  }
  p.profile-sns {
    color: #999999;
    font-family: 'Noto Sans CJK KR';
    font-size: 12px;
    font-weight: 500;
  }

  div.profile-sns {
    display: flex;
    flex-direction: row;
    align-items: center;
    .profile-sns-connect {
      color: #999999;
      font-family: 'Noto Sans CJK KR';
      font-size: 14px;
      font-weight: 500;
    }
  }
  span.profile-edit-btn {
    display: inline-block;
    text-align: center;
    width: 94px;
    height: 28px;
    border-radius: 14px;
    background-color: rgba(130, 143, 247, 0.2);

    color: #828ff7;
    font-family: 'Noto Sans CJK KR';
    font-size: 13px;
    font-weight: 700;
    line-height: 28px;
    cursor: pointer;
    hover: {
      opacity: 0.8;
    }
  }
  span.sns-link {
    cursor: pointer;
    :hover {
      opacity: 0.8;
    }
  }
`;
const ProfileImage = styled.div`
  width: 64px;
  height: 64px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  flex: 0 0 auto;
  margin-right: 16px;
  border-radius: 50%;
  display: flex;
  div {
    animation: ${emerge} 0.5s;
    animation-fill-mode: forwards;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    ${(props) =>
      props.isDefaultProfileImg &&
      css`
        margin: auto;
        width: 32px;
        height: 32px;
        border-radius: 0;
      `}
  }
`;
const InfoCard = styled.div`
  width: 100%;
  height: 92px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;

  p {
    width: 100%;
    height: 47px;
    padding: 10px 16px;
    border-bottom: solid 1px #eeeeee;
    margin: 0;
    span.profile-grade {
      color: #bebebe;
      font-family: 'Noto Sans CJK KR';
      font-size: 14px;
      font-weight: 500;
      line-height: 30px;
    }
    span.profile-grade-data {
      margin-left: 16px;
      color: #828ff7;
      font-family: 'Noto Sans CJK KR';
      font-size: 14px;
      font-weight: 700;
      line-height: 30px;
      text-transform: uppercase;
    }
    span.profile-grade-icon {
      float: right;
    }
  }
`;
const InfoCardInnerWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding: 16px 0px;
  div {
    text-align: center;
    width: 25%;
    span {
      display: block;
    }
    span.profile-info-title {
      color: #bebebe;
      font-family: 'Noto Sans CJK KR';
      font-size: 14px;
      font-weight: 500;
    }
    span.profile-info-data {
      color: #828ff7;
      font-family: 'Noto Sans CJK KR';
      color: #828ff7;
      font-family: 'Noto Sans CJK KR';
      font-size: 24px;
      font-weight: 700;
    }
  }
`;

const ProfileIntro = styled.p`
  padding-left: 16px;
  margin-bottom: 8px;
  color: #999999;
  font-family: 'Noto Sans CJK KR';
  font-size: 14px;
  font-weight: 500;
`;
const SocialIconWrap = styled.i`
  cursor: pointer;
  font-family: 'Noto Sans CJK KR';
  text-decoration: none;
  font-style: normal;
  color: #999999;
  font-weight: bold;
  font-size: 12px;
  :hover {
    opacity: 0.8;
  }
  .icon {
    margin-right: 4px;
  }
  svg {
    margin-right: 6px !important;
    margin-bottom: 0px;
  }
`;
const INIT = 'INIT';
@inject('userStore')
@inject('postStore')
@observer
class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {},
      profileId: this.props.id,
      userProfileModalOn: false,
      notOnServiceModalOn: false,
      reportModalOn: false,
      loginRequiredModalOn: false,
      tabIdx: null,
      posts: [],
      next: INIT,
      page: 0,
      squares: [],

      numOfPosts: 0,
      numOfLikes: 0,
    };
    this.scrollEvent = null;
  }
  async componentDidMount() {
    // if (id) {
    //   this.getPostList(this.props);
    // }
    const { loadedPostInMyPage } = this.props.postStore;
    let type;
    if (window.location.search.includes('like=true')) {
      this.setState({ tabIdx: 1 });
      type = 'like';
    } else {
      this.setState({ tabIdx: 0 });
      type = 'post';
    }

    const reg = /scroll=(\d+)/.exec(window.location.search);

    // 뒤로가기 + 스크롤시 데이터 불러옴
    if (reg && loadedPostInMyPage.posts.length) {
      this.setState({
        posts: loadedPostInMyPage.posts,
        next: loadedPostInMyPage.next,
      });
    } else {
      this.getPostList({ renew: true, type });
    }

    // 포스팅과 좋아요 개수 설정
    this.setNumOfLikesAndPosts();
  }
  toSns = (url) => {
    if (url) window.open(url, '_blank');
  };
  setNumOfLikesAndPosts = async () => {
    const userStore = this.props;
    const Authorization = `Bearer ${this.props.userStore.auth_token}`;
    const headers = userStore.auth_token ? { Authorization } : undefined;
    const params = {
      headers,
      page: 1, // page 1부터 시작
      pageSize: 1,
    };

    const postsData = await postAPI.getPostList({
      ...params,
      profile: this.state.profileId,
    });
    const likesData = await postAPI.getPostList({
      ...params,
      like: this.state.profileId,
    });
    this.setState({
      numOfPosts: Number(postsData.data.count),
      numOfLikes: Number(likesData.data.count),
    });
  };
  getPostList = async ({ renew, type } = { renew: false, type: 'post' }) => {
    // 포스트를 요청하고 상태를 업데이트 한다.
    // 프로필 Id로 필터링한다.
    if (renew || (this.state.next && !this.state.loading)) {
      const userStore = this.props;
      const id = this.state.profileId;
      this.setState({ loading: true });
      if (id) {
        try {
          const sums = await axios.get(`${base}/sums`);

          let squares;

          const Authorization = `Bearer ${userStore.auth_token}`;
          const headers = userStore.auth_token ? { Authorization } : undefined;
          const params = {
            headers,
            page: 1, // page 1부터 시작
            pageSize: 4,
          };
          if (type === 'post') {
            params.profile = id;
          } else if (type === 'like') {
            params.like = id;
          }
          squares = (
            await Promise.all(
              [
                { id: null, name: '섬 전체' },
                { id: 73, name: '마이피드' },
                ...sums.data,
              ]
                .map(({ id, name }) => ({ id, name }))
                .map(async ({ id, name }) => {
                  const result = {
                    sumId: id,
                    sumName: name,
                    count: 0,
                    imgs: [],
                  };
                  const getPostListParams = {
                    ...params,
                  };
                  if (id) {
                    getPostListParams.id_of_sum = [id];
                  }
                  const { data } = await postAPI.getPostList(getPostListParams);
                  result.count = Number(data.count);
                  data.data.forEach((post) => {
                    result.imgs.push(post.thumbnail_image);
                  });
                  return result;
                })
            )
          ).filter((o) => o.count);

          this.setState({ loading: false, squares });
        } catch (e) {
          console.log(e);
        }
      }
      this.setState({ loading: false });
    }
  };
  render() {
    const { classes, data, isMine } = this.props;
    const toFollowing = `/profile/${data.id}/follow/following`;
    const toFollower = `/profile/${data.id}/follow/follower`;

    const instagram = data && data.instagram;
    const instaId =
      instagram && instagram.slice('http://instagram.com/'.length);
    const youtube = data && data.youtube;

    return (
      <div>
        <ProfileWrap>
          <ProfileImage isDefaultProfileImg={data.image ? false : true}>
            <ProfileImagePhoto
              style={{
                backgroundImage: `url('${
                  data.image ? data.image : default_profile
                }')`,
              }}
            />
          </ProfileImage>
          <div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p className="profile-name"> {data.nickname} </p>

              {isMine ? (
                <span
                  className="profile-edit-btn"
                  onClick={() => {
                    history.push('/my-page/update');
                  }}
                >
                  내 정보 수정
                </span>
              ) : (
                <div style={{ flex: '0 0 auto' }}>
                  <FollowBtn profile={data} is_followed={data.is_followed} />
                </div>
              )}
            </div>
            <div className="profile-sns">
              <>
                {instagram && (
                  <>
                    <SocialIconWrap
                      style={{ paddingTop: 6 }}
                      onClick={() => {
                        this.toSns(instagram);
                      }}
                    >
                      <InstaIcon className="icon" mode="myProfile" />{' '}
                      <span>{instaId}</span>
                    </SocialIconWrap>
                  </>
                )}
                {youtube && (
                  <>
                    <SocialIconWrap
                      style={{
                        paddingTop: 6,
                        marginLeft: instagram ? 20 : 0,
                      }}
                      onClick={() => {
                        this.toSns(youtube);
                      }}
                    >
                      <YoutubeIcon className="icon" mode="myProfile" />
                      <span>youtube</span>
                    </SocialIconWrap>
                  </>
                )}
              </>
            </div>
          </div>
        </ProfileWrap>
        <ProfileIntro> {data.introduction} </ProfileIntro>
        <div
          style={{
            padding: 16,
            paddingTop: 0,
            paddingBottom: 16,
            borderBottom: '1px solid #f2f2f2',
          }}
        >
          <InfoCard>
            <InfoCardInnerWrap>
              <div
                role="button"
                onClick={() => (window.location.href = toFollowing)}
              >
                <span className="profile-info-data">
                  {' '}
                  {this.state.numOfPosts}{' '}
                </span>
                <span className="profile-info-title"> 포스팅 </span>
              </div>
              <div>
                <span className="profile-info-data">
                  {' '}
                  {this.state.numOfLikes}{' '}
                </span>
                <span className="profile-info-title"> 좋아요 </span>
              </div>
              <div
                style={{ cursor: 'pointer' }}
                role="button"
                onClick={() => (window.location.href = toFollowing)}
              >
                <span className="profile-info-data">{data.following}</span>
                <span className="profile-info-title"> 팔로잉 </span>
              </div>
              <div
                style={{ cursor: 'pointer' }}
                role="button"
                onClick={() => (window.location.href = toFollower)}
              >
                <span className="profile-info-data">{data.follower}</span>
                <span className="profile-info-title"> 팔로워 </span>
              </div>
            </InfoCardInnerWrap>
          </InfoCard>
        </div>
        <div className={classes.tabWrapper}>
          {isMine && (
            <Tabs
              className={classes.tabs}
              classes={{ indicator: classes.indicator }}
              value={this.state.tabIdx}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_, newValue) => {
                const [isPost, isLike] = [0, 1].map((o) => newValue === o);
                history.replace({
                  pathname: window.location.pathname,
                  search: isLike ? '?like=true' : '',
                });
                this.setState(
                  {
                    tabIdx: newValue,
                  },
                  () => {
                    this.getPostList({
                      renew: true,
                      type: isPost ? 'post' : isLike ? 'like' : null,
                    });
                  }
                );
              }}
              aria-label="disabled tabs example"
              centered
              TabIndicatorProps={{ children: <span /> }}
            >
              <Tab className={classes.tab} label="포스팅" />
              <Tab className={classes.tab} label="좋아요" />
            </Tabs>
          )}
          <div className={classes.squares}>
            {this.state.squares.map(({ sumId, sumName, count, imgs }) => (
              <SquarePosts
                key={`${sumId}${sumName}${count}`}
                imgs={imgs}
                name={sumName}
                count={count}
                onClick={() => {
                  const queries = [];
                  if (sumId) {
                    queries.push(`sumId=${sumId}`);
                  }
                  if (this.state.tabIdx === 1) {
                    queries.push(`isLike=true`);
                  }
                  queries.push(`profileId=${this.state.profileId}`);
                  queries.push('scroll=0');
                  window.location.href = `/my-page/posts?${queries.join('&')}`;
                }}
              />
            ))}
          </div>
          {/* 하단 여백 */}
          <div style={{ height: 100 }} />
        </div>
        {this.state.loading && <Loader />}
      </div>
    );
  }
}
export default withStyles((theme) => ({
  grid: { display: 'flex', flexFlow: 'row wrap' },
  imgWrapper: {
    width: '33.333333%',
    paddingTop: '33.333333%',
    position: 'relative',
    borderBottom: '1px solid white',
    borderRight: '1px solid white',
  },
  noBorderImgWrapper: {
    borderRight: 'none',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  intro: {
    padding: 16,
    paddingBottom: 16,
    borderBottom: '1px solid #f2f2f2',
  },
  tabWrapper: {
    flexGrow: 1,
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
  tabs: {
    width: '100%',
  },
  tab: {
    width: '50%',
    fontSize: 16,
    fontWeight: 700,
  },
  squares: {
    display: 'flex',
    flexFlow: 'wrap',
    marginRight: 14,
    marginLeft: 14,
    marginTop: 12,
  },
}))(Profile);
