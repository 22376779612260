import React, { Fragment } from 'react';
import { NavBar } from 'antd-mobile';
import styled, { css } from 'styled-components';
import back_ico from 'images/back_ico_b.svg';
import LikeIcon from '../Icon/likeHeader';
import LikeOnIcon from '../Icon/likeOn';

const NavWrap = styled.div`
	z-index: 10;
	position: fixed;
	background: transparent;
	width: 100%;
	max-width: 1024px;
	// transition: 0.5s;
	${(props) =>
		props.toggle &&
		css`
			height: 100%;
			background: rgba(0, 0, 0, 0.5);
		`}
`;

const FilterText = styled.span`
	color: #bebebe;
	font-family: 'Noto Sans CJK KR';
	font-size: 15px;
	font-weight: 500;
	cursor: pointer;
	:hover {
		opacity: 0.8;
	}
	${(props) =>
		props.name == props.clicked &&
		css`
			color: #333333;
			font-family: 'Noto Sans CJK KR';
			font-size: 15px;
			font-weight: 700;
		`}
`;
const IconWrap = styled.i`
	cursor: pointer;
	:hover {
		opacity: 0.8;
	}
`;
export default ({ onlyLike, onClickLike, title = '별샵', noRightContent }) => {
	return (
		<NavWrap>
			<NavBar
				mode="light"
				icon={[
					<IconWrap key="iconback" onClick={() => window.history.back()}>
						<img
							src={back_ico}
							style={{
								verticalAlign: 'middle',
								cursor: 'pointer',
							}}
						/>
					</IconWrap>,
				]}
				rightContent={
					!noRightContent && [
						<IconWrap key={1} style={{ paddingRight: 5 }} onClick={onClickLike}>
							{onlyLike ? (
								<LikeOnIcon style={{ verticalAlign: 'middle' }} />
							) : (
								<LikeIcon style={{ verticalAlign: 'middle' }} />
							)}
						</IconWrap>,
					]
				}
			>
				<FilterText>{title}</FilterText>
			</NavBar>
		</NavWrap>
	);
};
