import React from 'react';
import styled, { css } from 'styled-components';
import src from 'images/likeBorder.png';
import srcOn from 'images/likeBorderOn.png';

const Img = styled.img`
	width: 30px;
	height: 30px;
`;
export default props => <Img src={props.on ? srcOn : src} {...props} />;
