import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { observer, inject } from 'mobx-react';
import * as userAPI from 'axios/userAPI';
import Loader from '../../../components/loadingSpinner/spinnerCurtain';
import Header from './../Header';
import Profile from './../Profile';
import Layout from './../Layout';
import Category from '../Category';
import List from './list';
import { url } from '../../../utils/starApi';

import {
	Wrap,
	IndexWrap,
	MainEventWrap,
} from '../../../components/style/custom-styled-components';

const list = ['전체 내역', '사용 내역'];

@inject('userStore')
@observer
class MyPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			products: [],
			user: null,
			onlyLike: false,
			listSelectedIdx: 0,
		};
	}
	async componentDidMount() {
		// get users
		try {
			const user = await userAPI
				.getMyUserProfile({
					headers: {
						Authorization: `Bearer ${this.props.userStore.auth_token}`,
					},
				})
				.then((res) => res.data);
			const { id, nickname } = user;

			// get products
			const { data } = await axios(`${url}/get-my-info`, {
				method: 'post',
				data: { userId: id, name: nickname },
			});
			this.setState({ user: data });
		} catch (e) {
			console.error(e);
			alert('로그인이 필요합니다.');
			window.location.href = '/';
		}
	}
	render() {
		const { user, listSelectedIdx } = this.state;
		if (!user) {
			return (
				<Wrap>
					<Loader />
				</Wrap>
			);
		}
		return (
			<Wrap>
				<Header title="사용 내역" noRightContent />
				<Layout>
					<Category
						list={list}
						selected={listSelectedIdx}
						onClick={(listSelectedIdx) => this.setState({ listSelectedIdx })}
						center
					/>
					<List
						list={
							listSelectedIdx === 1
								? user.histories.filter((o) => o.amount < 0)
								: user.histories.slice()
						}
					/>
				</Layout>
			</Wrap>
		);
	}
}

export default MyPage;
