import { observable, action } from "mobx";
import * as eventAPI from "axios/eventAPI";

export default class EventStore {
  @action getEvents = async req => {
    const eventData = await Promise.all([
      eventAPI
        .getEvents(req)
        .then(res => {
          return res.data;
        })
        .catch(e => {
          alert(e);
          return false;
        })
    ]);
    if (eventData[0]) {
      return eventData[0].data;
    } else {
      return false;
    }
  };
}
