import React, { Fragment } from 'react';
import { Icon } from 'antd';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';

import LikeClickedIcon from 'components/myIcons/bookmarkIcon';
import BookmarkIcon from 'components/myIcons/bookmarkIcon';
import LikeIcon from 'components/myIcons/bookmarkBorderIcon';
import BookmarkClicked from './myIcons/bookmarkIcon_clicked.png';

import * as postAPI from 'axios/postAPI';

const CountText = styled.span`
	display: block;
	margin-top: -20px;
	height: 25px;
	color: #828ff7;
	font-family: 'Noto Sans CJK KR';
	font-size: 12px;
	font-weight: 500;
	line-height: 25px;
	text-align: center;
`;

@inject('userStore')
@inject('postStore')
@observer
class LikeBtn extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			is_liked: this.props.is_liked,
			option: this.props.option,
			id: this.props.id,
			count: this.props.count,
		};
	}
	componentDidUpdate(prevProps) {
		if (prevProps != this.props) {
			this.setState({
				is_liked: this.props.is_liked,
				option: this.props.option,
				id: this.props.id,
				count: this.props.count,
			});
		}
	}
	handleLike = async e => {
		e.stopPropagation();
		const token = this.props.userStore.getTokenOrToggleLogin();
		if (token) {
			const like = !this.state.is_liked;
			if (this.props.doAfterClick) {
				this.props.doAfterClick(like);
			}
			const req = {
				id: this.props.id,
				object: this.props.option,
				headers: { Authorization: `Bearer ${token}` },
			};
			await Promise.all([
				postAPI.patchMyLike(req).then(res => {
					return res.data;
				}),
			]);
			if (this.state.is_liked) {
				this.setState({
					is_liked: like,
					count: parseInt(this.state.count) - 1,
				});
			} else {
				this.setState({
					is_liked: like,
					count: parseInt(this.state.count) + 1,
				});
			}
			this.props.toggleIsLike && this.props.toggleIsLike();
		} else {
			if (this.props.mode == 'comment') {
				this.props.postStore.toggleComment();
			}
		}
	};

	render() {
		return (
			<Fragment>
				{this.state.is_liked ? (
					<BookmarkIcon
						role="button"
						isClicked={this.state.is_liked}
						onClick={this.handleLike}
					/>
				) : (
					<BookmarkIcon
						role="button"
						isClicked={this.state.is_liked}
						onClick={this.handleLike}
					/>
				)}
				{this.props.indicate ? (
					<CountText
						style={{
							marginTop: this.props.textMarginTop
								? this.props.textMarginTop
								: '-20px',
							color: this.state.is_liked ? '#828ff7' : '#bebebe',
						}}
					>
						{this.state.count}
					</CountText>
				) : null}
			</Fragment>
		);
	}
}
export default LikeBtn;
