import { observable, action } from 'mobx';
import * as myLocalStorage from 'utils/myLocalStorage';

export default class CacheStore {
	// 나의 좋아요 캐시
	@observable.ref myLikeData = undefined;

	@action setMyLikeData = data => {
		this.myLikeData = data;
	};

	// 검색 캐시 - 검색 페이지 상태 캐시
	@observable.ref searchPageState = {
		searchVal: '',
		beforSelectTag: true,
		currentTab: undefined,
	};
	@action setSearchPageState = dict => {
		this.searchPageState = dict;
	};
	@action initSearchPageState = () => {
		this.searchPageState = {
			searchVal: '',
			beforSelectTag: true,
			currentTab: undefined,
		};
	};
	// 검색 캐시 - 최근 검색어 캐시
	@observable.ref myRecentSearch = myLocalStorage.getRecentSearch();

	@action resetMyRecentSearch = () => {
		this.myRecentSearch = [];
		myLocalStorage.resetRecentSearch();
	};
	@action updateMyRecentSearch = search => {
		this.myRecentSearch = search;
		myLocalStorage.updateRecentSearch(search);
	};

	// 검색 캐시 - 포스팅 데이터 캐시
	@observable.ref searchPostData = undefined;
	@observable searchPostCount = '';

	@action initSearchPostData = () => {
		this.searchPostData = undefined;
		this.searchPostCount = '';
	};
	@action setSearchPostData = (data, count) => {
		this.searchPostData = data;
		this.searchPostCount = count;
	};

	// 검색 캐시 - 아이템 데이터 캐시
	@observable.ref searchItemData = undefined;

	@action initSearchItemData = () => {
		this.searchItemData = undefined;
	};
	@action setSearchItemData = data => {
		this.searchItemData = data;
	};

	// 검색 캐시 - 사용자 데이터 캐시
	@observable.ref searchUserData = undefined;

	@action initSearchUserData = () => {
		this.searchUserData = undefined;
	};
	@action setSearchUserData = data => {
		this.searchUserData = data;
	};

	// 유저 프로필 캐시 - 유저 정보
	@observable.ref userProfileData = {};
	@observable.ref userMyId = undefined;
	@action initUserProfileData = () => {
		this.userProfileData = {};
	};
	@action setUserProfileData = data => {
		this.userProfileData = data;
	};
	@action setUserMyId = id => {
		this.userMyId = id;
	};

	// 마이 페이지 캐시 - 마이페이지 init 여부
	@observable.ref myProfileData = undefined;
	@observable.ref myEventBanner = undefined;
	@action setMyProfileData = data => {
		this.myProfileData = data;
	};
	@action setMyEventBanner = data => {
		this.myEventBanner = data;
	};

	// 유저 프로필 캐시 - 유저 포스팅 정보
	@observable.ref userPostData = [{}];
	@action initUserPostData = () => {
		this.userPostData = [{}];
	};
	@action setUserPostData = data => {
		this.userPostData = data;
	};

	// 유저 프로필 캐시 - 유저 상품 정보
	@observable.ref userItemData = [{}];
	@action initUserItemData = () => {
		this.userItemData = [{}];
	};
	@action setUserItemData = data => {
		this.userItemData = data;
	};

	// 메인 페이지 캐시 - 추천/팔로잉 필터
	@observable.ref mainFilter = '추천';
	@action initMainFilter = () => {
		this.mainFilter = '추천';
	};
	@action setMainFilter = name => {
		this.mainFilter = name;
	};
	// 메인 페이지 캐시 - 메인 페이지 상태 전체
	@observable.ref mainState = undefined;
	@action setMainState = data => {
		this.mainState = data;
	};
	// 메인 페이지 캐시 - 메인 데이터 (추천 리뷰, 이벤트, 크리에이티브)
	@observable.ref mainData = undefined;
	@observable.ref mainCrtFilter = undefined;
	@observable.ref mainCrtData = undefined;
	@action setMainData = data => {
		this.mainData = data;
	};
	// 메인 페이지 캐시 - 팔로잉 리뷰 데이터
	@observable.ref mainFollowingPost = undefined;
	@action initMainFollowingPost = () => {
		this.mainFollowingPost = undefined;
	};
	@action setMainFollowingPost = data => {
		this.mainFollowingPost = data;
	};

	// 리뷰 상세 페이지 캐시 - 리뷰 데이터 (리뷰, 상품, 좋아할만한 사람, 좋아할만한 상품)
	@observable.ref detailData = undefined;
	@action setDetailData = data => {
		this.detailData = data;
	};

	// 이벤트 페이지 캐시 - 이벤트 데이터
	@observable.ref eventData = undefined;
	@action setEventData = data => {
		this.eventData = data;
	};

	// 이벤트 디테일 페이지 캐시 - 이벤트 상세 데이터, 이벤트 관련 포스팅 데이터
	@observable.ref eventDetailData = undefined;
	@action setEventDetailData = data => {
		this.eventDetailData = data;
	};

	// 리뷰 수정 페이지 캐시
	@observable.ref postEditData = undefined;
	@action setPostEditData = data => {
		this.postEditData = data;
	};
}
