import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { observer, inject } from "mobx-react";

import DetailCommentIcon from "components/myIcons/detailCommentIcon";
import DetailLikeIcon from "components/myIcons/detailLikeIcon";
import DetailMoreIcon from "components/myIcons/detailMoreIcon";
import CommentMiniIcon from "components/myIcons/commentMiniIcon";
import LikeMiniIcon from "components/myIcons/likeMiniIcon";
import SpinnerCurtain from "components/loadingSpinner/spinnerCurtain";

import default_profile from "images/default_profile.png";

const RightWrap = styled.div`
  position: absolute;
  width: 50%;
  height: 100vh;
  right: 0px;
`;
const LeftWrap = styled.div`
  position: absolute;
  width: 50%;
  height: 100vh;
  left: 0px;
`;
const ProfileWrap = styled.div`
  position: absolute;
  display: flex;
  padding-top: 18px;
  padding-left: 16px;
  top: 0px;
  left: 0px;
  z-index: 1000;

  div {
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    color: #ffffff;
    font-family: "Noto Sans CJK KR";
    font-size: 16px;
    font-weight: 700;
    p {
      margin-bottom: 0px;
    }
    span {
      display: inline-block;
      color: #f8f8f8;
      font-family: "Noto Sans CJK KR";
      font-size: 12px;
      font-weight: 500;
    }
  }
`;
const ProfileImage = styled.div`
  width: 42px;
  height: 42px;
  border: 1px solid #ffffff;
  flex: 0 0 auto;
  margin-right: 8px;
  border-radius: 50%;
  display: flex;
  ${props =>
    props.isDefaultProfileImg &&
    css`
      background-color: rgb(255, 255, 255);
    `}
  div {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    ${props =>
      props.isDefaultProfileImg &&
      css`
        margin: auto;
        width: 21px;
        height: 21px;
        border-radius: 0;
      `}
  }
`;
const MyIcon = styled.div`
  background-position: center;
  background-size: cover;
`;
const PostInfoWrap = styled.div`
  position:absolute;
  z-index: 1000;
  display:flex;
  align-items: flex-end;
  width:100%;
  padding-bottom:36px;
  padding-left:16px;
  padding-right:16px
  bottom:0px;
  left:0px;
`;
const PostTitle = styled.p`
  margin: 0px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  font-family: "Noto Sans CJK KR";
  font-size: 18px;
  font-weight: 700;
`;
const PostTags = styled.p`
  margin: 0px;
  span {
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    color: #ffffff;
    font-family: "Noto Sans CJK KR";
    font-size: 14px;
    font-weight: 500;
  }
`;
const IconWrap = styled.div`
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  margin: auto;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
`;

@inject("cacheStore")
@inject("postStore")
@observer
class SampleVideoOnlyPoster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoUrl: this.props.videoUrl,
      onLoaded: false
    };
  }
  componentDidMount() {}
  componentWillUnmount() {
    clearTimeout(this.state.playTimeOut);
    if (this.video) {
      this.video.currentTime = 0;
      this.video.pause();
      this.video.setAttribute("src", null);
      this.video.load();
      this.video = null;
    }
  }
  componentWillUpdate(nextProps) {
    if (this.props.itsMe && !nextProps.itsMe) {
      if (this.video) {
        clearTimeout(this.state.playTimeOut);
        this.video.currentTime = 0;
        this.video.pause();
        this.video.setAttribute("src", null);
        this.video.load();
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.itsMe && this.props.itsMe) {
      this.setState(
        {
          onLoaded: false
        },
        () => {
          this.video.setAttribute("src", this.props.videoUrl);
          this.video.load();
        }
      );
    }
  }
  _onEnded = e => {
    if (this.props.videos.length == this.props.index + 1) {
      return;
    } else {
      this.props.setVideoIndex(this.props.index + 1);
    }
  };
  _onPlaying = () => {
    let error = this.video.error ? this.video.error.code : this.video.error;
    // console.log(`id : ${this.props.index} , onPlaying , ERROR : ${this.video.error}`)
    if (!error) {
      this.setState(
        {
          videoPlayed: true
        },
        () => {
          this.props.setPrgBarWidth(this.video.duration);
        }
      );
    }
    if (error == 3) {
      this.video.pause();
      this.video.currentTime = 0;
      this.video.load();
      this.video.play();
    }
  };
  _onLoadedMetaData = () => {
    this.setState({
      onLoaded: true
    });
  };
  _onWaiting = () => {};
  rightOnClick = () => {
    this.props.setVideoIndex((this.props.index + 1) % this.props.videos.length);
  };
  leftOnClick = () => {
    this.props.setVideoIndex(
      Math.abs(this.props.index - 1) % this.props.videos.length
    );
  };
  render() {
    if (this.props.itsMe) {
      const post =
        this.props.mode == "edit"
          ? this.props.cacheStore.postEditData
          : this.props.postStore.createPost;
      const postTags = post.tags ? post.tags.replace(/ /gi, "").split(",") : [];
      const profile = post.profile;
      return (
        <Fragment>
          <video
            ref={ref => {
              this.video = ref;
            }}
            src={this.state.videoUrl}
            autoPlay={false}
            onEnded={this._onEnded}
            controls={false}
            muted={true}
            playsInline={true}
            onLoadedMetadata={this._onLoadedMetaData}
            onPlaying={this._onPlaying}
            onWaiting={this._onWaiting}
            style={{
              borderRadius: 0,
              minWidth: "100%",
              minHeight: "100%",
              position: "absolute",
              maxWidth: "375px",
              objectFit: "cover"
            }}
          ></video>
          {!this.state.onLoaded ? <SpinnerCurtain /> : null}
          <ProfileWrap>
            <ProfileImage isDefaultProfileImg={profile.image ? false : true}>
              <div
                style={{
                  backgroundImage: `url('${
                    profile.image ? profile.image : default_profile
                  }')`
                }}
              />
            </ProfileImage>
            <div style={{ flex: "1 1 auto", overflow: "hidden" }}>
              <p className="profile-name"> {profile.nickname} </p>
              <p className="profile-data" style={{ marginTop: -6 }}>
                <span> 방금 전 </span>
                <span style={{ marginLeft: 9 }}>
                  <LikeMiniIcon />
                  <span>0</span>
                </span>
                <span style={{ marginLeft: 9 }}>
                  <CommentMiniIcon />
                  <span>0</span>
                </span>
              </p>
            </div>
          </ProfileWrap>
          <PostInfoWrap>
            <div style={{ flex: "1 1 auto", width: "100%" }}>
              {post.title ? <PostTitle>{post.title}</PostTitle> : <Fragment />}
              <PostTags>
                {postTags.map((tag, index) => {
                  return <span> {`#${tag}`} </span>;
                })}
              </PostTags>
            </div>
            <div
              style={{
                flex: "0 0 auto",
                width: "40px",
                marginLeft: 24,
                height: "100%"
              }}
            >
              <IconWrap>
                <DetailMoreIcon />
              </IconWrap>
              <IconWrap>
                <DetailCommentIcon />
              </IconWrap>
              <IconWrap>
                <DetailLikeIcon />
              </IconWrap>
            </div>
          </PostInfoWrap>
          <LeftWrap onClick={this.leftOnClick} />
          <RightWrap onClick={this.rightOnClick} />
        </Fragment>
      );
    } else {
      return <Fragment />;
    }
  }
}
export default SampleVideoOnlyPoster;
