import React from 'react';
import styled from 'styled-components';
import Video from 'components/Video';
import Layout from '../Layout';

// const ThumbDiv = styled.div`
// 	background: black;
// 	position: absolute;
// 	height: 100vh;
// 	width: 100%;
// 	z-index: 10000;
// 	top: 0;
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: center;
// 	align-items: center;
// 	img {
// 		width: 100%;
// 	}
// `;
class MultiVideo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// 비디오 인덱스 (다중 비디오일 때)
			play: false,
			vi: 0,
		};
	}
	changeVideo = isNext => () => {
		let vi;
		const length = this.props.post.video.length;
		if (isNext) {
			vi = (this.state.vi + 1) % length;
		} else {
			vi = this.state.vi - 1;
			if (vi < 0) vi = 0;
		}
		this.setState({
			vi,
		});
	};
	render() {
		const { post } = this.props;
		const videoLen = post.video.length;
		return (
			<Layout black>
				<Video
					id={this.props.id}
					videoLen={videoLen}
					videoData={post.video[this.state.vi]}
					// 비디오 변환은 길이가 1 이상일 때만 가능
					autoRightClickOnEnd={videoLen > 1}
					rightClick={videoLen > 1 && this.changeVideo(true)}
					leftClick={videoLen > 1 && this.changeVideo(false)}
					centerClick={this.props.onClick}
					active={this.props.active}
					onPlay={video => {
						this.props.onPlay({ index: this.state.vi, video });
						this.setState({ play: true });
					}}
				/>
			</Layout>
		);
	}
}
MultiVideo.defaultProps = {
	id: 'id',
};
export default MultiVideo;
