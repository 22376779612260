import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from 'antd';
import { observer, inject } from 'mobx-react';

import {
	Wrap,
	IndexWrap,
	MainEventWrap,
} from 'components/style/custom-styled-components';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import SignupFilterBtn from 'components/signup/signupFilterBtn';

import * as userAPI from 'axios/userAPI';
import history from 'utils/history';
import * as mySwitch from 'utils/switchForServer';

import back_ico from 'images/back_ico_b.svg';

const NavBar = styled.div`
	display: flex;
	flex-direction: row;
	line-height: 56px;
	height: 44px;
	line-height: 44px;
	width: 100%;
	max-width: 1024px;
	z-index: 3;
	background: rgb(248, 248, 248);
`;
const FormWrap = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0px 16px;
	p {
		color: #333333;
		font-family: 'Noto Sans CJK KR';
		font-size: 16px;
		font-weight: 700;
		line-height: 28px;
		margin-bottom: 6px;
	}
`;
const SubmitWrap = styled.div`
	width: 100%;
	max-width: 1024px;
	margin: auto;
	padding: 16px;
	position: fixed !important;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background-image: linear-gradient(
		to top,
		rgba(248, 248, 248, 1) 0%,
		rgba(255, 255, 255, 0) 100%
	);
`;
const SubmitBtn = styled.div`
	width: 100%;
	height: 44px;
	margin: auto;
	box-shadow: 0 2px 10px rgba(176, 141, 247, 0.5);
	border-radius: 68px;
	background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
	text-align: center;
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 14px;
	font-weight: 500;
	line-height: 44px;
	cursor: pointer;
`;
const CancelBtn = styled.div`
	width: 100%;
	height: 44px;
	margin: auto;
	box-shadow: 0 2px 20px rgba(176, 141, 247, 0.2);
	border-radius: 68px;
	border: 2px solid rgba(130, 143, 247, 0.2);
	background-color: rgba(255, 255, 255, 0);

	color: #828ff7;
	font-family: 'Noto Sans CJK KR';
	font-size: 14px;
	font-weight: 500;
	line-height: 44px;
	text-align: center;
`;
const ToCancelBtn = styled.button`
	padding: 0px;
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 15px;
	font-weight: 700;
	border: none;
	background-color: transparent;
	cursor: pointer;
	:hover {
		opacity: 0.8;
	}
`;
@inject('userStore')
@observer
class SignupFilter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			accessToken: this.props.userStore.auth_token,
			initData: true,
			stature: '',
			size: '',
			skin: '',
		};
	}
	componentDidMount() {}

	formSubmit = async () => {
		const req = {
			headers: {
				Authorization: `Bearer ${this.state.accessToken}`,
			},
			data: {
				height: mySwitch.switchProfileHeight(String(this.state.stature)),
				size: mySwitch.switchProfileSize(this.state.size),
				bottomSize: mySwitch.switchProfileSize(this.state.bottomSize),
				skintype: mySwitch.switchProfileSkin(this.state.skin),
			},
		};
		const result = await Promise.all([
			userAPI
				.patchUserProfile(req)
				.then(res => {
					return res.data;
				})
				.catch(e => {
					alert(e);
					return false;
				}),
		]);
		if (result[0]) {
			history.push('/');
		}
	};
	handleStatureBtn = val => {
		if (this.state.stature != val) {
			this.setState({
				stature: val,
			});
		} else {
			this.setState({
				stature: '전체',
			});
		}
	};
	handleSizeBtn = val => {
		if (this.state.size != val) {
			this.setState({
				size: val,
			});
		} else {
			this.setState({
				size: '전체',
			});
		}
	};
	handleBottomSizeBtn = val => {
		if (this.state.bottomSize != val) {
			this.setState({
				bottomSize: val,
			});
		} else {
			this.setState({
				bottomSize: '전체',
			});
		}
	};
	handleSkinBtn = val => {
		if (this.state.skin != val) {
			this.setState({
				skin: val,
			});
		} else {
			this.setState({
				skin: '전체',
			});
		}
	};
	_onCancel = () => {
		history.push('/');
	};
	render() {
		if (this.state.accessToken) {
			if (this.state.initData) {
				return (
					<Wrap
						style={{
							paddingBottom: 90,
							backgroundColor: '#f8f8f8',
							minHeight: '100vh',
						}}
					>
						<NavBar>
							<div style={{ width: '50%' }}>
								<img
									src={back_ico}
									onClick={() => {
										history.goBack();
									}}
									style={{
										marginLeft: 16,
										fontSize: 19,
										verticalAlign: 'middle',
										cursor: 'pointer',
									}}
								/>
							</div>
							<div
								style={{ width: '50%', textAlign: 'right', paddingRight: 15 }}
							>
								<ToCancelBtn onClick={this._onCancel}>다음에 하기</ToCancelBtn>
							</div>
						</NavBar>
						<p
							style={{
								margin: 0,
								padding: '4px 16px',
								color: '#333333',
								fontFamily: 'Noto Sans CJK KR',
								fontSize: '24px',
								fontWeight: 700,
								lineHeight: '32px',
							}}
						>
							나만의 필터를 <br /> 설정해주세요
						</p>
						<p
							style={{
								marginBottom: 28,
								padding: '2px 16px',
								color: '#999999',
								fontFamily: 'Noto Sans CJK KR',
								fontSize: '14px',
								fontWeight: 500,
								lineHeight: '24px',
							}}
						>
							회원님에게 딱 맞는 컨텐츠를 추천해드릴게요
						</p>
						<FormWrap>
							<p> 신장 </p>
							<div style={{ display: 'block', marginBottom: 16 }}>
								<SignupFilterBtn
									clicked={this.state.stature == 155 ? true : false}
									handleBtn={this.handleStatureBtn}
									data={155}
									text={'~155'}
								/>
								<SignupFilterBtn
									clicked={this.state.stature == 160 ? true : false}
									handleBtn={this.handleStatureBtn}
									data={160}
									text={'~160'}
								/>
								<SignupFilterBtn
									clicked={this.state.stature == 165 ? true : false}
									handleBtn={this.handleStatureBtn}
									data={165}
									text={'~165'}
								/>
								<SignupFilterBtn
									clicked={this.state.stature == 170 ? true : false}
									handleBtn={this.handleStatureBtn}
									data={170}
									text={'~170'}
								/>
								<SignupFilterBtn
									clicked={this.state.stature == 175 ? true : false}
									handleBtn={this.handleStatureBtn}
									data={175}
									text={'~175'}
								/>
								<SignupFilterBtn
									clicked={this.state.stature == 180 ? true : false}
									handleBtn={this.handleStatureBtn}
									data={180}
									text={'175 이상'}
								/>
							</div>
							<p> 상의 사이즈 </p>
							<div style={{ display: 'block', marginBottom: 16 }}>
								<SignupFilterBtn
									clicked={this.state.size == 'XS' ? true : false}
									handleBtn={this.handleSizeBtn}
									data={'XS'}
									text={'XS'}
								/>
								<SignupFilterBtn
									clicked={this.state.size == 'S' ? true : false}
									handleBtn={this.handleSizeBtn}
									data={'S'}
									text={'S'}
								/>
								<SignupFilterBtn
									clicked={this.state.size == 'M' ? true : false}
									handleBtn={this.handleSizeBtn}
									data={'M'}
									text={'M'}
								/>
								<SignupFilterBtn
									clicked={this.state.size == 'L' ? true : false}
									handleBtn={this.handleSizeBtn}
									data={'L'}
									text={'L'}
								/>
								<SignupFilterBtn
									clicked={this.state.size == 'XL' ? true : false}
									handleBtn={this.handleSizeBtn}
									data={'XL'}
									text={'XL'}
								/>
							</div>
							<p> 하의 사이즈 </p>
							<div style={{ display: 'block', marginBottom: 16 }}>
								<SignupFilterBtn
									clicked={this.state.bottomSize == 'XS' ? true : false}
									handleBtn={this.handleBottomSizeBtn}
									data={'XS'}
									text={'XS'}
								/>
								<SignupFilterBtn
									clicked={this.state.bottomSize == 'S' ? true : false}
									handleBtn={this.handleBottomSizeBtn}
									data={'S'}
									text={'S'}
								/>
								<SignupFilterBtn
									clicked={this.state.bottomSize == 'M' ? true : false}
									handleBtn={this.handleBottomSizeBtn}
									data={'M'}
									text={'M'}
								/>
								<SignupFilterBtn
									clicked={this.state.bottomSize == 'L' ? true : false}
									handleBtn={this.handleBottomSizeBtn}
									data={'L'}
									text={'L'}
								/>
								<SignupFilterBtn
									clicked={this.state.bottomSize == 'XL' ? true : false}
									handleBtn={this.handleBottomSizeBtn}
									data={'XL'}
									text={'XL'}
								/>
							</div>
							<p> 피부타입 </p>
							<div style={{ display: 'block', marginBottom: 16 }}>
								<SignupFilterBtn
									clicked={this.state.skin == '건성' ? true : false}
									handleBtn={this.handleSkinBtn}
									data={'건성'}
									text={'건성'}
								/>
								<SignupFilterBtn
									clicked={this.state.skin == '지성' ? true : false}
									handleBtn={this.handleSkinBtn}
									data={'지성'}
									text={'지성'}
								/>
								<SignupFilterBtn
									clicked={this.state.skin == '복합성' ? true : false}
									handleBtn={this.handleSkinBtn}
									data={'복합성'}
									text={'복합성'}
								/>
								<SignupFilterBtn
									clicked={this.state.skin == '민감성' ? true : false}
									handleBtn={this.handleSkinBtn}
									data={'민감성'}
									text={'민감성'}
								/>
							</div>
							<SubmitWrap>
								<SubmitBtn onClick={this.formSubmit}> 시작하기 </SubmitBtn>
							</SubmitWrap>
						</FormWrap>
					</Wrap>
				);
			} else {
				return <LoadingSpinner />;
			}
		} else {
			history.push('/login');
			return <Fragment />;
		}
	}
}
export default SignupFilter;
