import React, { Fragment } from 'react';
import 'antd-mobile/dist/antd-mobile.css';
import { Icon } from 'antd';
import { NavBar } from 'antd-mobile';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';

import * as userAPI from 'axios/userAPI';

import history from 'utils/history';

import { ReactComponent as SearchIco } from 'images/search_ico.svg';
import { ReactComponent as ShopIcon } from 'images/shop.svg';

const NavWrap = styled.div`
  z-index: 10;
  position: fixed;
  background: transparent;
  width: 100%;
  max-width: 1024px;
  // transition: 0.5s;
  ${(props) =>
    props.toggle &&
    css`
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
    `}
`;

const Dot = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  left: 29px;
  border-radius: 50%;
  top: 15px;
  border: 1px solid #f8f8f8;
  background-color: #828ff7;
`;
const MainFilter = styled.div`
  span:first-child {
    margin-right: 8px;
  }
`;
const FilterText = styled.span`
  color: #bebebe;
  font-family: 'Noto Sans CJK KR';
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  ${(props) =>
    props.name == props.clicked &&
    css`
      color: #333333;
      font-family: 'Noto Sans CJK KR';
      font-size: 15px;
      font-weight: 700;
    `}
`;
const IconWrap = styled.i`
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;
const Divider = styled.span`
  display: inline-block;
  vertical-align: middle;
  width: 3px;
  height: 3px;
  background: #bebebe;
  border-radius: 50%;
  margin-right: 8px;
`;

@inject('userStore')
@observer
class TopNavBar extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      login: true,
      dropDownClicked: false,
      allType: true,
      typeChanged: false,
      newNoti: false,
    };
  }
  async componentDidMount() {
    this._isMounted = true;
    const token = this.props.userStore.auth_token;
    if (token) {
      const req = {
        headers: { Authorization: `Bearer ${this.props.userStore.auth_token}` },
      };
      const res = await userAPI.getIsNewNoti(req).then((res) => {
        return res;
      });
      if (res.data && this._isMounted) {
        this.setState({
          newNoti: res.data.is_new,
        });
      }
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  togglePostType = () => {
    this.setState({
      allType: !this.state.allType,
      typeChanged: true,
    });
  };
  toggleDropDown = () => {
    if (this.props.userStore.getTokenOrToggleLogin()) {
      this.setState({
        dropDownClicked: !this.state.dropDownClicked,
        typeChanged: false,
      });
    }
  };
  _outsideClick = () => {
    if (this.state.dropDownClicked) {
      this.setState({
        dropDownClicked: false,
      });
    }
  };
  toNotify = () => {
    if (this.props.userStore.getTokenOrToggleLogin()) {
      history.push('/notification');
    }
  };
  toSearch = () => {
    history.push('/search');
  };
  toSns = (url) => {
    if (url) window.open(url, '_blank');
  };
  render() {
    return (
      <NavWrap
        toggle={this.state.dropDownClicked}
        onClick={this._outsideClick}
        style={this.props.style}
      >
        <NavBar
          mode="light"
          rightContent={
            this.props.myPage
              ? [
                  <Fragment>
                    <IconWrap
                      style={{ paddingTop: 2, marginRight: 10 }}
                      onClick={() => history.push('/star')}
                    >
                      <ShopIcon style={{ verticalAlign: 'middle' }} />
                    </IconWrap>
                  </Fragment>,
                  <Fragment>
                    <IconWrap
                      style={{ paddingTop: 6 }}
                      onClick={() => {
                        history.push('/my-setting');
                      }}
                    >
                      <Icon
                        type="setting"
                        style={{
                          color: 'black',
                          fontSize: 20,
                          cursor: 'pointer',
                        }}
                      />
                    </IconWrap>
                  </Fragment>,
                ]
              : [
                  <IconWrap key={1} style={{ paddingRight: 5 }}>
                    <SearchIco
                      width="17px"
                      style={{ verticalAlign: 'middle' }}
                      onClick={
                        this.props.myLike
                          ? this.props.myLikeSearch
                          : this.toSearch
                      }
                    />
                  </IconWrap>,
                ]
          }
        >
          {this.props.home ? (
            //<DropDown onClick={this.toggleDropDown} toggle={this.state.dropDownClicked}> {this.state.allType ? '전체' : '팔로잉'} <Icon type="down" style={{fontSize: '11px', verticalAlign: 'baseline'}}/> </DropDown>
            <MainFilter>
              <FilterText
                name="추천"
                clicked={this.props.mainFilter}
                onClick={() => {
                  if (this.props.mainFilter === '추천') {
                    window.location.href = '/';
                  } else {
                    this.props.handleMainFilter('추천');
                  }
                }}
              >
                추천
              </FilterText>
              <Divider />
              <FilterText
                name="팔로잉"
                clicked={this.props.mainFilter}
                onClick={() => {
                  if (this.props.mainFilter === '팔로잉') {
                    window.location.href = '/';
                  } else {
                    this.props.handleMainFilter('팔로잉');
                  }
                }}
              >
                팔로잉
              </FilterText>
            </MainFilter>
          ) : (
            <Fragment />
          )}
          {this.props.myLike ? (
            //<DropDown onClick={this.toggleDropDown} toggle={this.state.dropDownClicked}> {this.state.allType ? '전체' : '팔로잉'} <Icon type="down" style={{fontSize: '11px', verticalAlign: 'baseline'}}/> </DropDown>
            <MainFilter>
              <FilterText>좋아요</FilterText>
            </MainFilter>
          ) : (
            <Fragment />
          )}
        </NavBar>
      </NavWrap>
    );
  }
}
export default TopNavBar;
