import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Tabs } from 'antd-mobile';
import { observer, inject } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroller';
import * as searchAPI from 'axios/searchAPI';
import PostResultList from 'components/searchResult/postResultList';
import ItemResultList from 'components/searchResult/itemResultList';
import UserResultList from 'components/searchResult/userResultList';
import SearchTagList from 'components/searchResult/searchTagList';
import SpinnerCurtain from 'components/loadingSpinner/spinnerCurtain';

const Wrap = styled.div`
  height: 100vh;
  overflow-y: auto;
  .am-tabs-tab-bar-wrap {
    position: fixed;
    width: 100%;
    max-width: 1024px;
    z-index: 2;
    margin-bottom: 16px !important;
  }
  .am-tabs-default-bar {
    border-bottom: solid 1px #ececec;
    box-shadow: none;
    background: rgb(255, 255, 255) !important;
    margin-bottom: 0px !important;
  }
`;

const TabContentWrap = styled.div`
  width: 100%;
  max-width: 1024px;
  padding: 0px 16px;
  padding-bottom: 24px;
  padding-top: 60px;
`;

const TABS = ['포스팅', '상품', '사용자'];
@inject('userStore')
@observer
class SearchResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: this.props.currentTab ? this.props.currentTab : '포스팅',
      filter: 'new',
      filterText: '최신순',
      filterDownToggle: false,
      data: null,
      page: 0,
      next: null,
      loading: false,
    };
    this.scrollParent = React.createRef();
  }
  componentDidMount() {
    this.props.handleTabChange(this.state.currentTab);
  }
  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.searchVal !== '' &&
        this.props.tagListOn &&
        !nextProps.tagListOn) ||
      (nextProps.searchVal === '' &&
        !nextProps.tagListOn &&
        this.props.searchVal !== '')
    ) {
      this.setState({ data: null, page: 0, next: null });
    }
  }

  async componentDidUpdate(prevProps) {
    // user만 검색
    if (prevProps.searchVal !== this.props.searchVal) {
      if (this.state.currentTab === '사용자' && this.props.searchVal) {
        this.setState({
          data: [],
          count: 0,
          page: 0,
          next: null,
        });
      }
    }
  }
  handleMyFilter = () => {
    const token = this.props.userStore.getTokenOrToggleLogin();
    if (token) {
      this.props.userStore.toggleMyFilter();
    }
  };
  handleFilter = (filter) => {
    const dict = {
      popular: '인기순',
      new: '최신순',
    };
    this.setState({
      filter,
      filterText: dict[filter],
      filterDropDown: false,
      data: null,
      page: 0,
      next: null,
    });
  };
  toggleFilter = () => {
    this.setState({
      filterDownToggle: !this.state.filterDownToggle,
    });
  };
  _onChange = (tab, _) => {
    this.setState({
      currentTab: tab.title,
      data: null,
      page: 0,
      next: null,
    });
    this.props.handleTabChange(tab.title);
  };
  loadData = async ({ page, tab }) => {
    // this.setState({ loading: true });
    const headers = this.props.userStore.auth_token
      ? {
          Authorization: `Bearer ${this.props.userStore.auth_token}`,
        }
      : undefined;
    let result = {};

    const { searchVal } = this.props;
    if (tab === 0) {
      result = await searchAPI
        .getPostList({
          tag: searchVal,
          ordering: this.state.filter,
          headers,
          page,
        })
        .then((res) => {
          return res.data;
        });
    } else if (tab === 1) {
      result = await searchAPI
        .getPostList({
          ordering: 'new',
          headers,
          page,
        })
        .then((res) => {
          return res.data;
        });
    } else if (tab === 2) {
      result = await searchAPI
        .getUserList({ name: searchVal, page, headers })
        .then((res) => {
          return res.data;
        });
    }
    let data = (this.state.data || []).concat(result.data);

    // DELETE DUPLICATE
    data = data.reduce((acc, val) => {
      if (!acc.find((o) => o.id === val.id)) acc.push(val);
      return acc;
    }, []);

    // 탭을 빠르게 변환하여 탭 변환 이후에 리스트 데이터가 변화할 경우
    if (TABS[tab] === this.state.currentTab) {
      this.setState({
        data,
        count: result.count,
        page,
        next: result.next,
        // loading: false,
      });
    }
    return data;
  };
  render() {
    const tabs = [{ title: '포스팅' }, { title: '사용자' }];
    let initialPage;
    if (this.state.currentTab == '포스팅') initialPage = 0;
    else if (this.state.currentTab == '상품') initialPage = 1;
    else if (this.state.currentTab == '사용자') initialPage = 2;

    const SearchTags = (
      <SearchTagList
        handleSelectTag={this.props.handleSelectTag}
        searchVal={this.props.searchVal}
      />
    );
    const SearchTagLogic = this.props.tagListOn ? SearchTags : null;

    const loadMore = async () => {
      const data = await this.loadData({
        page: this.state.page + 1,
        tab: initialPage,
      });
      return data;
    };
    const hasMore = this.state.page === 0 || Boolean(this.state.next);
    return (
      <Wrap>
        <InfiniteScroll
          pageStart={this.state.page}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            <div className="loader" style={{ textAlign: 'center' }} key={0}>
              {/* 불러오는 중 ... */}
            </div>
          }
          useWindow={false}
        >
          <Tabs
            tabs={tabs}
            initialPage={initialPage}
            swipeable={false}
            animated={false}
            useOnPan={false}
            tabBarTextStyle={{
              fontWeight: 700,
              fontFamily: 'Noto Sans CJK KR',
              cursor: 'pointer',
            }}
            tabBarActiveTextColor={'#828ff7'}
            tabBarInactiveTextColor={'#bebebe'}
            onChange={this._onChange}
            styles={{
              inkBar: {
                left: 0,
              },
            }}
          >
            <TabContentWrap>
              <Fragment>
                {SearchTagLogic}
                {this.props.searchVal !== '' &&
                this.state.currentTab === '포스팅' ? (
                  <PostResultList
                    displayNone={this.props.tagListOn}
                    count={this.state.count}
                    postData={this.state.data}
                    searchVal={this.props.searchVal}
                    handleMyFilter={this.handleMyFilter}
                    toggleFilter={this.toggleFilter}
                    filterDownToggle={this.state.filterDownToggle}
                    handleFilter={this.handleFilter}
                    filterText={this.state.filterText}
                    loadMore={loadMore}
                    hasMore={hasMore}
                  />
                ) : null}
              </Fragment>
            </TabContentWrap>
            {
              // 상품
            }
            {/* <TabContentWrap>
							{this.state.currentTab === '상품' ? (
								<ItemResultList
									itemData={this.state.data}
									searchVal={this.props.searchVal}
								/>
							) : null}
						</TabContentWrap> */}
            {
              // 사용자
            }
            <TabContentWrap>
              {this.props.searchVal !== '' &&
              this.state.currentTab === '사용자' ? (
                <UserResultList
                  itemData={this.state.data}
                  searchVal={this.props.searchVal}
                />
              ) : null}
            </TabContentWrap>
          </Tabs>
        </InfiniteScroll>
      </Wrap>
    );
  }
}
export default SearchResult;
