import React, { Fragment } from "react";
import styled, { css } from "styled-components";

export default class ItemLinkInputContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <div>{this.props.children}</div>;
  }
}
