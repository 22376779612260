import React, { Component } from 'react';
import styled, { css } from 'styled-components';

class LikeClickedIcon extends Component {
	constructor() {
		super();
	}

	render() {
		return (
			<svg
				style={{}}
				xmlns="http://www.w3.org/2000/svg"
				width="25"
				height="24"
				viewBox="0 0 25 24"
				style={{ marginTop: 12 }}
			>
				<defs>
					<filter id="3trra" filterUnits="userSpaceOnUse">
						<feOffset dy="2" in="SourceGraphic" result="FeOffset1023Out" />
						<feGaussianBlur
							in="FeOffset1023Out"
							result="FeGaussianBlur1024Out"
							stdDeviation="2 2"
						/>
					</filter>
					<linearGradient
						id="3trrc"
						x1="4"
						x2="24"
						y1="10.82"
						y2="10.82"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0" stopColor="#828ff7" />
						<stop offset="1" stopColor="#b08df7" />
					</linearGradient>
				</defs>
				<g>
					<g>
						<g filter="url(#3trra)">
							<path
								fill="none"
								d="M21.66 2.99c-2.64-1.8-5.9-.96-7.66 1.1-1.76-2.06-5.02-2.91-7.66-1.1-1.4.96-2.28 2.58-2.34 4.29-.14 3.88 3.3 6.99 8.55 11.76l.1.09c.76.69 1.93.69 2.69-.01l.11-.1c5.25-4.76 8.68-7.87 8.55-11.75-.06-1.7-.94-3.32-2.34-4.28z"
								mask='url("#3trrb")'
							/>
							<path
								fill="#9b8ef7"
								fillOpacity=".5"
								d="M21.66 2.99c-2.64-1.8-5.9-.96-7.66 1.1-1.76-2.06-5.02-2.91-7.66-1.1-1.4.96-2.28 2.58-2.34 4.29-.14 3.88 3.3 6.99 8.55 11.76l.1.09c.76.69 1.93.69 2.69-.01l.11-.1c5.25-4.76 8.68-7.87 8.55-11.75-.06-1.7-.94-3.32-2.34-4.28z"
							/>
						</g>
						<path
							fill="url(#3trrc)"
							d="M21.66 2.99c-2.64-1.8-5.9-.96-7.66 1.1-1.76-2.06-5.02-2.91-7.66-1.1-1.4.96-2.28 2.58-2.34 4.29-.14 3.88 3.3 6.99 8.55 11.76l.1.09c.76.69 1.93.69 2.69-.01l.11-.1c5.25-4.76 8.68-7.87 8.55-11.75-.06-1.7-.94-3.32-2.34-4.28z"
						/>
					</g>
				</g>
			</svg>
		);
	}
}

export default LikeClickedIcon;
