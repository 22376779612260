const reg = /@[^@]+:id=(\d+)/;
export default (str) => {
  let t = str;
  const tagged = [];
  while (reg.exec(t)) {
    let res = reg.exec(t);
    let str = res[0];
    let idx = res.index;

    // '@는 뺸다'
    let nickname = str.slice(0, str.indexOf(":"));

    let middle = nickname;

    let front = t.slice(0, idx);
    let end = t.slice(idx + str.length);

    if (!tagged.find((o) => o.id === res[1])) {
      tagged.push({
        id: Number(res[1]),
        // @제거
        nickname: nickname.slice(1),
      });
    }

    t = `${front}${middle}${end}`;
  }
  return {
    str: t,
    tagged,
  };
};
