import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { Icon } from "antd";
import { Grid, Flex, Tabs, WhiteSpace } from "antd-mobile";
import { observer, inject } from "mobx-react";

import LikeBtn from "components/likeBtn";
import comment_post_small from "images/comment_post_small.png";
import like_post_small from "images/like_post_small.png";

import * as postAPI from "axios/postAPI";
import history from "utils/history";

const WrapTitle = styled.h3`
  width: 163px;
  height: 27px;
  border: 1px solid rgba(0, 0, 0, 0);
  color: #333333;
  font-family: "Noto Sans CJK KR";
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 0px;
`;
const ItemCard = styled.div`
  position: relative;
  z-index: 0;
  cursor: pointer;
  height: 240px;
  border-radius: 10px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  :after {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
    content: "";
    z-index: -1;
  }
`;
const ItemWrap = styled(Grid)`
  display: block;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: rgb(248, 248, 248);

  div.am-flexbox-item.am-grid-item:nth-child(odd) {
    margin-right: 3%;
  }
`;
const LikeBtnWrap = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;
const ExampleTag = styled.div`
  position: absolute;
  text-align: center;
  top: 10px;
  left: 10px;
  width: 36px;
  height: 20px;
  line-height: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0);
  background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
  letter-spacing: -0.24px;
  color: #ffffff;
  font-family: "Noto Sans CJK KR";
  font-size: 12px;
  font-weight: 500;
`;
const Title = styled.div`
  position: absolute;
  line-height: normal !important;
  bottom: 17px;
  left: 0px;
  padding: 10px;
  width: 100%;
  text-align: left;
  color: #ffffff;
  font-family: "Noto Sans CJK KR";
  font-size: 13px;
  font-weight: 400;

  padding-bottom: 0px;
  margin-bottom: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;
const IconWrap = styled.div`
  position: absolute;
  line-height: normal !important;
  bottom: -4px;
  left: 0px;
  padding: 10px;
  width: 100%;
  text-align: left;
  color: #ffffff;
  font-family: "Noto Sans CJK KR";
  font-size: 13px;
  font-weight: 400;
`;
const MiniIcon = styled.div`
  display: inline-block;
  width: 10px;
  height: 9px;
  margin-right: 5px;
  vertical-align: super;
  background-size: cover;
  background-position: center;
`;
const CountText = styled.div`
  display: inline-block;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  color: #f8f8f8;
  font-family: "Noto Sans CJK KR";
  font-size: 12px;
  font-weight: 500;
  vertical-align: super;
`;
@inject("cacheStore")
@inject("userStore")
@observer
class EventDetailPostList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toDetail = async id => {
    const req = {
      id: id,
      headers: this.props.userStore.auth_token
        ? { Authorization: `Bearer ${this.props.userStore.auth_token}` }
        : undefined
    };
    const postData = await postAPI.getPost(req).then(res => {
      return res.data;
    });
    this.props.cacheStore.setDetailData(postData);

    history.push(`/post/${id}`);
  };

  render() {
    return (
      <ItemWrap
        data={this.props.data}
        columnNum={2}
        hasLine={false}
        activeStyle={false}
        itemStyle={{
          height: 240,
          marginTop: 12
        }}
        renderItem={(data, index) => {
          return (
            <Fragment>
              <ItemCard
                style={{ backgroundImage: `url('${data.thumbnail_image}')` }}
                onClick={() => {
                  this.toDetail(data.id);
                }}
              >
                {data.type == "예시" ? (
                  <ExampleTag>예시</ExampleTag>
                ) : (
                  <Fragment />
                )}
                <Title>{data.title}</Title>
                <IconWrap>
                  <MiniIcon
                    style={{ backgroundImage: `url('${like_post_small}')` }}
                  />{" "}
                  <CountText style={{ marginLeft: -5, marginRight: 7 }}>
                    {data.like.count}
                  </CountText>
                  <MiniIcon
                    style={{ backgroundImage: `url('${comment_post_small}')` }}
                  />{" "}
                  <CountText style={{ marginLeft: -3 }}>
                    {data.comment.count}
                  </CountText>
                </IconWrap>
              </ItemCard>
            </Fragment>
          );
        }}
      ></ItemWrap>
    );
  }
}
export default EventDetailPostList;
