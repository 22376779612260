import React, { Fragment } from 'react';
import Beforeunload from 'react-beforeunload';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';
import { animateScroll as scroll } from 'react-scroll';
import mime from 'mime';
import ProductAddPage from '../productAddPage';
import { Wrap, IndexWrap } from 'components/style/custom-styled-components';
import VideoInput from './iosVideoInput';
import qs from 'querystring';
import * as postAPI from 'axios/postAPI';
import * as userAPI from 'axios/userAPI';
import history from 'utils/history';
import closeIcon from 'images/baseline_close_black_18dp.png';
import LinkSvg from 'components/myIcons/linkIcon';
import Button from '@material-ui/core/Button';
import * as mediaAPI from 'axios/mediaAPI';
import imageCompression from 'browser-image-compression';
import axios from 'axios';
import categoryItems from 'utils/categoryItems';
import back_ico from 'images/back_ico_b.svg';
import photo_img from 'images/photo.png';
import DeleteModal from 'components/popModal/deleteModal';
import * as iosHandler from 'utils/iosHandler';
import { ClipLoader, PropagateLoader } from 'react-spinners';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/CheckCircle';
import sendPush from 'utils/sendPush';
import QnaModal from 'components/QnaModal';
import PollModal from 'components/PollModal';
import MissionModal from 'components/MissionModal';
import TopicModal from 'components/TopicModal';
import makeLink from 'utils/makeLink';
import getCaret from 'utils/getCaret';
import PostTypeSelect from 'containers/postTypeSelect';
import sendTagPush from 'utils/sendTagPush';
import UserSelectorModal from 'components/popModal/userSelectorModal';
import { ReactSortable } from 'react-sortablejs';
import InfoList from 'containers/infoList';
import WorldAndSum from 'components/worldAndSum';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = {
  buttonWrapper: {
    marginBottom: 16,
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonNegative: {
    color: 'black',
    background: '#efefef',
    height: 48,
    width: '48%',
    borderRadius: 10,
    boxShadow: 'none',
  },
  buttonActive: {
    background: '#E2EAFA',
    color: '#1f75fe',
  },
  buttonCenter: {
    marginLeft: 8,
    marginRight: 8,
  },
};
const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  line-height: 56px;
  height: 44px;
  line-height: 44px;
  width: 100%;
  max-width: 1024px;
  z-index: 3;
  top: 0;
  background: rgb(248, 248, 248);
`;
const ContentWrap = styled.div`
  padding: 10px 16px;
  padding-bottom: 70px;

  color: #333333;
  font-family: 'Noto Sans CJK KR';
  font-size: 16px;
  font-weight: 700;
  p {
    margin-bottom: 10px;
  }
  span.sub-btn {
    float: right;
    cursor: pointer;
    :hover {
      opacity: 0.8;
    }
  }
  div {
    position: relative;
  }
  input::placeholder {
    color: #bebebe;
    font-family: 'Noto Sans CJK KR';
    font-size: 14px;
    font-weight: 500;
  }
`;
const VideoInputWrap = styled.div`
  width: 100%;
  max-width: 1024px;
  border-radius: 6px;
  padding-bottom: 36px;
  display: flex;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none !important;
  }
  justify-content: center;
`;

const UpdateTextArea = styled.textarea`
  width: 100%;
  min-height: 150px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
  border: none;
  margin-bottom: 16px;
  padding: 14px 16px;
  color: #333333;
  font-family: 'Noto Sans CJK KR';
  font-size: 14px;
  font-weight: 500;
  ::placeholder {
    color: #9fa4af;
    opacity: 0.5;
  }
`;
const UpdateInput = styled.input`
  width: 100%;
  height: 44px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
  border: none;
  margin-bottom: 36px;
  padding: 0px 16px;
  color: #333333;
  font-family: 'Noto Sans CJK KR';
  font-size: 14px;
  font-weight: 500;
`;
const SubmitWrap = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: auto;
  padding: 16px;
  position: fixed !important;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-image: linear-gradient(
    to top,
    rgba(248, 248, 248, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
`;
const SubmitBtn = styled.button`
  border: none;
  width: 100%;
  height: 44px;
  box-shadow: 0 10px 20px rgba(31, 117, 254, 0.3);
  background: #1f75fe;
  border-radius: 68px;
  color: #ffffff;
  font-family: 'Noto Sans CJK KR';
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  ${(props) =>
    props.disabled &&
    css`
      background-image: none;
      background-color: rgb(150, 150, 150);
    `}
`;
const ProductAddPageLayout = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
`;
const AddButton = styled.button`
  background: none;
  border: none;
  font-family: 'Noto Sans CJK KR';
  font-size: 15px;
  font-weight: 700;
  margin-top: 8px;
  color: #828ff7;
  width: 100%;
  text-align: center;
  cursor: pointer;
`;
const base = 'https://star-api.lay-er.me';

@inject('postStore')
@inject('userStore')
@observer
class IosCreatePostPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoInputArr: [],
      videoLen: this.props.postStore.videoLen,
      videoFiles: this.props.postStore.iosAddedVideoArr,
      linkArr: this.props.postStore.createPost.links
        ? this.props.postStore.createPost.links
        : [],
      linkNum: this.props.postStore.createPost.linkNum
        ? this.props.postStore.createPost.linkNum
        : 1,
      tags: this.props.postStore.createPost.tags
        ? this.props.postStore.createPost.tags
        : undefined,
      title: this.props.postStore.createPost.title
        ? this.props.postStore.createPost.title
        : undefined,
      profile: this.props.postStore.createPost.profile
        ? this.props.postStore.createPost.profile
        : undefined,
      deleteMode: false,
      productAddPageOn: false,
      productAdded: [
        { id: `${Math.random()}`, shop: '', link: '', shopName: '' },
      ],
      selectedProduct: null,

      loading: false,
      deleteIndex: -1,

      topicDescription: '',
      topicOpen: false,
      topic: null,

      missionDescription: '',
      missionOpen: false,
      mission: null,

      qnaOpen: false,
      qna: null,

      pollOpen: false,
      pollTitle: '',
      pollOptionA: '',
      pollOptionB: '',

      drawerOpen: false,
      page: 0,
      cursor: -1,
      tagged: [],

      days: '',
      changing: false,

      texts: [],
      onlySelected: false,
    };
    if (typeof window !== 'undefined') {
      const selected = /selected=(\d)/.exec(window.location);
      if (selected) {
        let selectedCode = selected[1];
        this.categorySelected =
          selectedCode === '0'
            ? 'fashion'
            : selectedCode === '1'
            ? 'beauty'
            : 'hair';
      }
    }
    if (!this.categorySelected) {
      this.categorySelected = 'fashion';
    }
  }
  handleDeleteProduct = (index) => {
    let newProductAdded = this.state.productAdded.slice();
    newProductAdded.splice(index, 1);
    this.setState({
      productAdded: newProductAdded,
      deleteIndex: -1,
    });
  };
  getImgOfProduct = (label, category) => {
    const src = categoryItems[category].find((o) => o.label === label).src;
    return src;
  };
  async componentWillMount() {
    if (!this.props.userStore.auth_token) history.push('/login');
    else if (!this.props.postStore.createPost.profile) {
      const req = {
        headers: { Authorization: `Bearer ${this.props.userStore.auth_token}` },
      };
      const profile = await userAPI.getMyUserProfile(req).then((res) => {
        return res.data;
      });
      this.setState({
        profile: profile,
      });
    }
  }
  setMissionAndTopicState = () => {
    const parsed = qs.parse(window.location.search);

    if (parsed.topic) {
      this.setState({ topic: parsed.topic, topicDescription: '' });
    }
    if (parsed.mission && parsed.days) {
      this.setState({
        mission: parsed.mission,
        missionDays: Number(parsed.days),
        missionDescription: '',
      });
    }
  };
  getSumId = () => {
    const parsed = qs.parse(window.location.search);
    let sumId = parsed.sum;
    if (!sumId) {
      sumId = parsed['?sum'];
    }
    return sumId ? Number(sumId) : null;
  };
  // async componentDidUpdate() {
  //   window.alert(JSON.stringify(this.props.postStore.iosAddedVideoArr));
  // }
  async componentDidMount() {
    const sums = await axios.get(`${base}/sums`);
    const sumId = this.getSumId();
    this.setMissionAndTopicState();
    const sum = sums.data.find(({ id }) => id === sumId);
    this.setState({ sum });

    let videoInputArr = [];
    for (var i = 0; i < this.state.videoLen; i++) {
      const video = this.props.postStore.iosAddedVideoArr[i]
        ? this.props.postStore.iosAddedVideoArr[i].videoUrl
        : undefined;
      const item = this.props.postStore.iosAddedVideoArr[i];
      const key = item ? item.id || i : i;
      const isImage = item ? !item.video : false;

      videoInputArr.push(
        <VideoInput
          key={video}
          index={i}
          isImage={isImage}
          onChange={this.handleInput}
          addVideoInput={this.addVideoInput}
          video={video}
          delVideo={this.delVideo}
        />
      );
    }
    this.setState({
      videoInputArr: videoInputArr,
    });
  }
  componentWillUnmount() {
    this.props.postStore.createPost = {
      title: this.state.title,
      tags: this.state.tags,
      links: this.state.linkArr,
      linkNum: this.state.linkNum,
      profile: this.state.profile,
    };
  }
  handleInput = (event) => {
    const eName = event.target.name;
    if (eName == 'tags') {
      var special_pattern = /[`~!@#$%^&*|\\\'\";:\/?]/gi;
      if (special_pattern.test(event.target.value) == true) {
        alert('태그에 특수문자는 입력할 수 없습니다.');
        this.setState({
          [eName]: this.state.tags ? this.state.tags : '',
        });
      } else {
        this.setState({ [eName]: event.target.value });
      }
    } else {
      this.setState({ [eName]: event.target.value });
    }
  };
  addVideoInput = (res, isImage) => {
    // res.url = this.state.videoUrl;
    // alert(res.url);
    if (isImage) {
      res.videoUrl = this.state.videoUrl;
    }
    this.props.postStore.iosAddVideo(res, isImage);
    if (!res.url) {
      res.url = this.state.videoUrl;
    }
    let videoInputArr = [];

    for (var i = 0; i < this.props.postStore.videoLen; i++) {
      const video = this.props.postStore.iosAddedVideoArr[i]
        ? this.props.postStore.iosAddedVideoArr[i].videoUrl
        : undefined;
      const isImage = this.props.postStore.iosAddedVideoArr[i]
        ? this.props.postStore.iosAddedVideoArr[i].isImage
        : false;
      videoInputArr.push(
        <VideoInput
          key={video}
          index={i}
          isImage={isImage}
          onChange={this.handleInput}
          addVideoInput={this.addVideoInput}
          video={video}
          delVideo={this.delVideo}
        />
      );
    }
    this.setState({
      videoInputArr: videoInputArr,
      videoFiles: this.props.postStore.iosAddedVideoArr,
      videoLen: this.props.postStore.videoLen,
    });
  };
  afterSetState = async () => {
    const isImage = !this.state.video.type.includes('video');
    if (!isImage) {
      this.addVideoInput(this.state.video, isImage);
    } else {
      const formData = new FormData();
      formData.append('video', this.state.video);
      const req = {
        headers: this.props.userStore.auth_token
          ? {
              Authorization: `Bearer ${this.props.userStore.auth_token}`,
              'Content-Type': 'multipart/form-data',
            }
          : false,
        data: formData,
      };
      const data = await mediaAPI.getFramesFromMedia(req).then((res) => {
        return res.data;
      });
      this.addVideoInput(data, true);
    }
  };
  handleFile = async (e) => {
    if (e.target.files.length > 0) {
      let file = e.target.files[0];
      const isImg = file.type.includes('image');
      if (isImg) {
        file = await imageCompression(file, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        });
        file = new File([file], file.name, { type: file.type });
      }
      this.setState(
        {
          added: true,
          isImg,
          video: file,
          videoUrl: this.props.isUrl
            ? this.props.video
            : window.URL.createObjectURL(file),
        },
        () => {
          this.afterSetState();
        }
      );
    } else {
      this.setState({
        profileImg: null,
        profileImgURL: null,
      });
    }
  };
  delVideo = (index) => {
    this.props.postStore.iosDelVideo(index);
    let videoInputArr = [];
    for (var i = 0; i < this.props.postStore.videoLen; i++) {
      const video = this.props.postStore.iosAddedVideoArr[i]
        ? this.props.postStore.iosAddedVideoArr[i].videoUrl
        : undefined;
      videoInputArr.push(
        <VideoInput
          key={video}
          index={i}
          onChange={this.handleInput}
          addVideoInput={this.addVideoInput}
          video={video}
          delVideo={this.delVideo}
        />
      );
    }
    this.setState({
      videoInputArr: videoInputArr,
      videoFiles: this.props.postStore.iosAddedVideoArr,
      videoLen: this.props.postStore.videoLen,
    });
  };
  handleAddLink = () => {
    this.setState(
      {
        linkNum: this.state.linkNum + 1,
      },
      () => {
        scroll.scrollToBottom({
          duration: 500,
          delay: 0,
          smooth: true,
        });
      }
    );
  };
  toggleLinkDelete = () => {
    this.setState({
      deleteMode: !this.state.deleteMode,
    });
  };
  handleAddLinkArr = (id, data) => {
    const { linkArr } = this.state;
    let isNew = true;
    for (var i = 0; i < this.state.linkArr.length; i++) {
      if (this.state.linkArr[i].id == id) {
        isNew = false;
        break;
      }
    }
    if (isNew) {
      const { linkArr } = this.state;
      this.setState({
        linkArr: linkArr.concat({ id: id, data }),
      });
    } else {
      this.setState({
        linkArr: linkArr.map(
          (arr) =>
            id === arr.id
              ? { ...arr, data } // 새 객체를 만들어서 기존의 값과 전달받은 data 을 덮어씀
              : arr // 기존의 값을 그대로 유지
        ),
      });
    }
  };
  handleRemoveLinkArr = (id) => {
    const { linkArr } = this.state;
    this.setState({
      linkArr: linkArr.filter((arr) => arr.id !== id),
    });
  };
  handleTagArr = (arr) => {
    this.setState({
      tagArr: arr,
    });
  };
  formSubmit = async () => {
    const sumId = this.getSumId();

    try {
      if (!sumId) {
        alert('섬을 선택하여 주십시요.');
        return;
      }
      this.props.postStore.setLinkLoading(true);
      if (this.props.postStore.iosAddedVideoArr.length == 0) {
        alert('영상을 하나 이상 업로드해야 합니다');
      } else if (!this.state.title) {
        alert('내용을 입력해 주세요');
      } else {
        this.setState({ loading: true });
        const tagRegExp = /#\S+/g;
        const tags = [];
        let regExpResult;
        while ((regExpResult = tagRegExp.exec(this.state.title))) {
          tags.push(regExpResult[0].slice(1));
        }

        let links = [];
        for (var i = 0; i < this.state.linkArr.length; i++) {
          links.push(this.state.linkArr[i].data);
        }

        const order = this.state.videoInputArr
          .map(({ props: { video } }) =>
            // ios create는 video Url 유념
            this.props.postStore.iosAddedVideoArr.find(
              (o) => o.videoUrl === video
            )
          )
          .map(({ id }) => id)
          .join(',');
        const req = {
          data: {
            title: this.state.title,
            id_of_sum: String(sumId),
            tags: tags,
            links: links.filter((o) => o !== '' || o.link !== ''),
            video: this.props.postStore.iosAddedVideoArr,
            category: this.state.productAdded.length
              ? this.state.productAdded[0].category
              : null,
            order,
            onlySelected: sumId ? this.state.onlySelected : false,
          },
          headers: {
            Authorization: `Bearer ${this.props.userStore.auth_token}`,
          },
        };

        if (this.state.tagged.length) {
          let title = req.data.title;
          req.data.title = makeLink({ str: title, tagged: this.state.tagged });
        }

        if (this.state.productAdded.length) {
          req.data.links = this.state.productAdded
            .filter(
              (o) =>
                !(
                  o.shop === '' ||
                  (o.link !== '' && o.link.indexOf('http') === -1)
                )
            )
            .map((o) => {
              return {
                shopName: o.shop,
                shopLink: o.link,
                link: o.link,
                category: o.category,
                label: o.label,
              };
            });
        }

        // IOS는 업로드가 되어있어 파일 원본이 없고 URL만 남아있다.
        // 파일을 수동으로 생성을 해주어야 한다.
        try {
          const url = req.data.video[0].videoUrl;
          const fetched = await fetch(url);
          const blob = await fetched.blob();
          const filename = url.slice(url.lastIndexOf('/') + 1, url.length);
          const file = new File([blob], filename, {
            type: mime.getType(filename),
          });
          const formData = new FormData();
          formData.append('video', file);
        } catch (e) {
          console.error(e);
        }
        await postAPI.createPostApp(req).then(async ({ data }) => {
          await sendTagPush({
            ids: this.state.tagged.map(({ id }) => id),
            nickname: this.state.profile.nickname,
            postId: data.id,
          });

          if (this.state.qna && this.state.qna !== '') {
            await axios.post(`${base}/qnas`, {
              title: this.state.qna,
              postId: data.id,
            });
          } else if (this.state.mission && this.state.mission !== '') {
            const prev = await axios.get(
              `${base}/missions?title=${encodeURIComponent(this.state.mission)}`
            );
            try {
              if (prev.data && prev.data.length > 0) {
                // 이름 가져오기
                const starterPost =
                  prev.data.find(({ starter }) => starter && starter !== '') ||
                  prev.data[0];
                if (starterPost) {
                  const post = (
                    await postAPI.getPost({
                      id: starterPost.postId,
                      headers: undefined,
                    })
                  ).data;
                  const starter = post.profile && post.profile.id;

                  if (starter) {
                    // 푸시 보내기
                    await sendPush({
                      id: starter,
                      message: `${this.state.profile.nickname}님이 회원님의 미션에 참여했습니다.`,
                      link: `/post/${data.id}`,
                    });
                  }
                }
              }
            } catch (e) {
              console.error(e);
            }
            await axios.post(`${base}/missions`, {
              title: this.state.mission,
              description: this.state.missionDescription,
              postId: data.id,
              days: this.state.missionDays,
            });
          } else if (this.state.pollTitle && this.state.pollTitle !== '') {
            await axios.post(`${base}/polls`, {
              title: this.state.pollTitle,
              postId: data.id,
              optionA: this.state.pollOptionA,
              optionB: this.state.pollOptionB,
            });
          } else if (this.state.topic && this.state.topic !== '') {
            const prev = await axios.get(
              `${base}/topics?title=${encodeURIComponent(this.state.topic)}`
            );
            let first = true;
            try {
              if (prev.data && prev.data.length > 0) {
                first = false;
                // 이름 가져오기
                const starterPost = prev.data.find(
                  ({ starter }) => starter && starter !== ''
                );
                if (starterPost) {
                  const starter = starterPost.starter;
                  const user = await axios.get(
                    `${base}/users?name=${encodeURIComponent(starter)}`
                  );
                  if (user.data && user.data[0]) {
                    const { userId } = user.data[0];

                    // 푸시 보내기
                    await sendPush({
                      id: userId,
                      message: `${this.state.profile.nickname}님이 회원님의 토픽에 참여했습니다.`,
                      link: `/post/${data.id}`,
                    });
                  }
                }
              }
            } catch (e) {
              console.error(e);
            }
            await axios.post(`${base}/topics`, {
              title: this.state.topic,
              first,
              starter: first ? this.state.profile.nickname : '',
              postId: data.id,
              description: this.state.topicDescription,
            });
          }
          await axios.put(`${base}/sums/${sumId}`, {
            lastUploaded: new Date(),
          });
        });
        this.setState(
          {
            linkArr: [],
            linkNum: 1,
            tags: undefined,
            title: undefined,
          },
          this.props.postStore.clearNewPost
        );
        // history.push(`/sum/${sumId}`);
        window.location.href = `/sum/${sumId}`;
      }
      this.props.postStore.setLinkLoading(false);
      this.setState({ loading: false });
    } catch (e) {
      console.log(e);
      // alert(`오류 : ${e.message}`);
      // history.push(`/sum/${sumId}`);
      window.location.href = `/sum/${sumId}`;
      this.props.postStore.setLinkLoading(false);
      this.setState({ loading: false });
    }
  };
  render() {
    const { classes } = this.props;
    let videoInputArr = [];
    console.log(this.props.postStore.iosAddedVideoArr[i]);

    console.log(this.props, this.state);
    for (var i = 0; i < this.state.videoLen; i++) {
      const video = this.props.postStore.iosAddedVideoArr[i]
        ? this.props.postStore.iosAddedVideoArr[i].videoUrl
        : undefined;
      const item = this.props.postStore.iosAddedVideoArr[i];
      const key = item ? item.id || i : i;
      const isImage = item ? !item.video : false;

      videoInputArr.push(
        <VideoInput
          key={video + '' + Math.random()}
          index={i}
          isImage={isImage}
          onChange={this.handleInput}
          addVideoInput={this.addVideoInput}
          video={video}
          delVideo={this.delVideo}
        />
      );
    }

    const pollOn = this.state.pollTitle !== '';
    const qnaOn = this.state.qna && this.state.qna !== '';
    const missionOn = this.state.mission && this.state.mission !== '';
    const topicOn = this.state.topic && this.state.topic !== '';
    return (
      <Beforeunload onBeforeunload={() => '변경 사항이 저장되지 않습니다.'}>
        <Wrap style={{ paddingBottom: 0, backgroundColor: 'rgb(248,248,248)' }}>
          <IndexWrap style={{ paddingTop: 56, backgroundColor: '#f8f8f8' }}>
            <NavBar>
              <div style={{ width: '50%' }}>
                <img
                  src={back_ico}
                  onClick={() => {
                    window.history.back();
                  }}
                  style={{
                    marginLeft: 19,
                    width: 19,
                    verticalAlign: 'middle',
                    cursor: 'pointer',
                  }}
                />
              </div>
              {/* IOS 웹뷰 미리보기 페이지 버그로 인해 기능 OFF
              <div style={{width:'50%', textAlign:'right', paddingRight:15}}>
                <ToSampleBtn onClick={()=>{history.push('/new-post/sample')}}>미리보기</ToSampleBtn>
              </div>
              */}
            </NavBar>
            <ContentWrap>
              <WorldAndSum />
              {this.getSumId() ? (
                <FormControlLabel
                  style={{ marginLeft: 2, marginBottom: 6 }}
                  control={
                    <Switch
                      checked={this.state.onlySelected}
                      onChange={({ target: { checked } }) =>
                        this.setState({ onlySelected: checked })
                      }
                      color="primary"
                    />
                  }
                  label="선택한 섬에서만 보이기"
                />
              ) : null}
              <PostTypeSelect
                selected={
                  pollOn ? 1 : qnaOn ? 2 : missionOn ? 3 : topicOn ? 4 : 0
                }
                handleSelect={(index) => {
                  if (index === 0) {
                    if (window.location.href.includes('mission=')) {
                      alert('미션 수정은 불가능합니다.');
                    } else {
                      this.setState({
                        pollTitle: '',
                        pollOptionA: '',
                        pollOptionB: '',
                        pollOpen: false,
                        mission: null,
                        missionDays: '',
                        missionDescription: '',
                        missionOpen: false,
                        qna: null,
                        qnaOpen: false,
                        topic: null,
                        topicOpen: false,
                        topicDescription: '',
                      });
                    }
                  } else if (index === 1) {
                    if (qnaOn || missionOn || topicOn) {
                      alert('다른 등록을 제거해주세요.');
                    } else {
                      this.setState({ pollOpen: true });
                    }
                  } else if (index === 2) {
                    if (pollOn || missionOn || topicOn) {
                      alert('다른 등록을 제거해주세요.');
                    } else {
                      this.setState({ qnaOpen: true });
                    }
                  } else if (index === 3) {
                    if (window.location.href.includes('mission=')) {
                      alert('미션 수정은 불가능합니다.');
                    } else {
                      if (pollOn || qnaOn || topicOn) {
                        alert('다른 등록을 제거해주세요.');
                      } else {
                        this.setState({ missionOpen: true });
                      }
                    }
                  } else if (index === 4) {
                    if (pollOn || qnaOn || missionOn) {
                      alert('다른 등록을 제거해주세요.');
                    } else {
                      this.setState({ topicOpen: true });
                    }
                  }
                }}
              />
              {this.state.videoInputArr.length > 0 && (
                <VideoInputWrap>
                  {this.state.changing ? (
                    <ReactSortable
                      list={this.state.videoInputArr}
                      setList={(videoInputArr) => {
                        this.setState({
                          videoInputArr,
                        });
                      }}
                    >
                      {this.state.videoInputArr.map((item) => (
                        <div key={item.key} style={{ display: 'inline-block' }}>
                          {item}
                        </div>
                      ))}
                    </ReactSortable>
                  ) : (
                    this.state.videoInputArr
                  )}
                </VideoInputWrap>
              )}
              {this.state.videoInputArr.length > 1 && (
                <div style={{ textAlign: 'right' }}>
                  <Button
                    onClick={() =>
                      this.setState({ changing: !this.state.changing })
                    }
                    color="primary"
                  >
                    {this.state.changing ? '변경 완료' : '순서 변경'}
                  </Button>
                </div>
              )}
              <div style={{ marginBottom: 28 }}>
                <VideoInput button addVideoInput={this.addVideoInput} />
                <div
                  style={{
                    width: '50%',
                    display: 'inline-block',
                    textAlign: 'right',
                  }}
                >
                  <input
                    type="file"
                    id="file-image"
                    style={{ display: 'none' }}
                    onChange={this.handleFile}
                    accept="image/*"
                  />
                  <label htmlFor="file-image">
                    <Button
                      component="span"
                      style={{
                        boxShadow: '0 4px 16px rgba(69, 91, 99, 0.08)',
                        border: '1px solid rgba(0, 0, 0, 0)',
                        background: 'white',
                        color: '#1f75fe',
                        width: '95%',
                        borderRadius: 8,
                        padding: 12,
                        fontFamily: 'Noto Sans CJK KR',
                      }}
                    >
                      <img
                        style={{ width: 14, marginRight: 8 }}
                        src={photo_img}
                      />
                      사진 추가
                    </Button>
                  </label>
                </div>
              </div>
              <div>
                <UpdateTextArea
                  ref={(ref) => this.titleInput}
                  name="title"
                  value={this.state.title}
                  onChange={this.handleInput}
                  placeholder="내용을 입력해주세요. (해시태그는 #으로 입력)"
                  onFocus={(e) => {
                    e.preventDefault();
                    this.setState({
                      cursor: e.selectionEnd,
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === '@') {
                      setTimeout(() => {
                        this.setState({ drawerOpen: true });
                      }, 300);
                    }
                    this.setState({ cursor: getCaret(e.target) });
                  }}
                />
              </div>
              <p>정보 등록</p>
              <InfoList
                items={this.state.productAdded.map((product) => ({
                  shop: product.shop,
                  link: product.link,
                  ...product,
                }))}
                onChange={(productAdded) => {
                  this.setState({
                    productAdded: productAdded.map((item) => ({
                      ...item,
                      category: 'fashion',
                      shop: item.shop,
                      name: '클릭시 상품 링크로 이동합니다.',
                      label: null,
                    })),
                  });
                }}
              />
              <SubmitWrap>
                <SubmitBtn
                  onClick={this.formSubmit}
                  disabled={this.props.postStore.linkLoading}
                >
                  {' '}
                  게시하기{' '}
                </SubmitBtn>
              </SubmitWrap>
            </ContentWrap>
          </IndexWrap>
        </Wrap>
        {this.state.productAddPageOn && (
          <ProductAddPageLayout>
            <ProductAddPage
              onBack={() => this.setState({ productAddPageOn: false })}
              onSubmit={(submitted) => {
                if (this.state.selectedProduct) {
                  const productAddedSliced = this.state.productAdded.slice();
                  const idx = productAddedSliced.findIndex(
                    (o) => o.id === this.state.selectedProduct.id
                  );
                  productAddedSliced[idx] = {
                    ...submitted,
                    id: this.state.selectedProduct.id,
                    category: this.state.selectedProduct.category,
                  };
                  this.setState({
                    productAdded: productAddedSliced,
                    productAddPageOn: false,
                  });
                } else {
                  this.setState({
                    productAdded: this.state.productAdded.concat({
                      ...submitted,
                      id: `${Math.random()}`,
                      category: this.categorySelected,
                    }),
                    productAddPageOn: false,
                  });
                }
              }}
              categoryItems={
                this.state.sum
                  ? this.state.sum.categories.map((o) => o.name)
                  : []
              }
              selectedProduct={this.state.selectedProduct}
            />
          </ProductAddPageLayout>
        )}
        {this.state.loading && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ClipLoader
              sizeUnit={'px'}
              size={36}
              color={'rgb(130, 143, 247)'}
              loading={true}
            />
          </div>
        )}
        <DeleteModal
          active={this.state.deleteIndex > -1}
          onClick={() => this.handleDeleteProduct(this.state.deleteIndex)}
          handleClose={() => this.setState({ deleteIndex: -1 })}
        />
        <MissionModal
          open={this.state.missionOpen}
          onClose={() => this.setState({ missionOpen: false })}
          value={{
            days: this.state.days,
            mission: this.state.mission,
            description: this.state.missionDescription,
          }}
          onChange={(name) => (e) => this.setState({ [name]: e.target.value })}
          onSubmit={({ value, description, daysValue }) =>
            this.setState({
              mission: value === '' ? null : value,
              missionDays: daysValue === '' ? null : daysValue,
              missionDescription: description === '' ? null : description,
              missionOpen: false,
            })
          }
          onRemove={() =>
            this.setState({
              mission: null,
              missionDays: '',
              missionDescription: '',
              missionOpen: false,
            })
          }
        />
        <QnaModal
          open={this.state.qnaOpen}
          onClose={() => this.setState({ qnaOpen: false })}
          value={this.state.qna}
          onChange={(e) => this.setState({ qna: e.target.value })}
          onSubmit={(value) =>
            this.setState({
              qna: value === '' ? null : value,
              qnaOpen: false,
            })
          }
          onRemove={() => this.setState({ qna: null, qnaOpen: false })}
        />
        <PollModal
          input
          onVote={() => {}}
          open={this.state.pollOpen}
          onClose={() => this.setState({ pollOpen: false })}
          title={this.state.pollTitle}
          onTitleChange={(e) => this.setState({ pollTitle: e.target.value })}
          pollOptionA={this.state.pollOptionA}
          onPollOptionAChange={(e) =>
            this.setState({ pollOptionA: e.target.value })
          }
          pollOptionB={this.state.pollOptionB}
          onPollOptionBChange={(e) =>
            this.setState({ pollOptionB: e.target.value })
          }
          onSubmit={() => {
            if (this.state.pollTitle === '') {
              window.alert('타이틀을 입력해주십시요.');
            } else if (
              this.state.pollOptionA === '' ||
              this.state.pollOptionB === ''
            ) {
              window.alert('투표안을 입력해주십시요.');
            } else {
              this.setState({ pollOpen: false });
            }
          }}
          onRemove={() =>
            this.setState({
              pollTitle: '',
              pollOptionA: '',
              pollOptionB: '',
              pollOpen: false,
            })
          }
        />
        <TopicModal
          open={this.state.topicOpen}
          onClose={() => this.setState({ topicOpen: false })}
          value={{
            topic: this.state.topic,
            topicDescription: this.state.topicDescription,
          }}
          onChange={(name) => (e) => this.setState({ [name]: e.target.value })}
          onSubmit={(value) =>
            this.setState({
              topic: value.topic === '' ? null : value.topic,
              topicOpen: false,
              topicDescription: value.topicDescription,
            })
          }
          onRemove={() =>
            this.setState({
              topic: null,
              topicOpen: false,
              topicDescription: '',
            })
          }
        />
        <UserSelectorModal
          open={this.state.drawerOpen}
          onClose={() => this.setState({ drawerOpen: false })}
          onSelect={(data) => {
            const { cursor } = this.state;
            const nextTitle = [
              this.state.title.slice(0, cursor),
              `@${data.nickname} `,
              this.state.title.slice(cursor + 1),
            ].join('');

            this.setState({
              drawerOpen: false,
              title: nextTitle,

              tagged: this.state.tagged.find((p) => p.id === data.id)
                ? this.state.tagged
                : this.state.tagged.concat(data),
            });
          }}
        />
      </Beforeunload>
    );
  }
}
export default withStyles(styles)(IosCreatePostPage);
