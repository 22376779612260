import React, { Fragment } from "react";
import "antd/dist/antd.css";
import { Button, WhiteSpace, WingBlank } from "antd-mobile";
import { Icon } from "antd";
import styled, { css } from "styled-components";
import { observer, inject } from "mobx-react";

import EventCard from "components/eventCard";
import LoadingSpinner from "components/loadingSpinner/loadingSpinner";

import history from "utils/history";
import getRemainTime from "utils/myCountDown";

const Wrapper = styled.div`
  padding: 32px 16px;
  padding-bottom: 0px;
  position: relative;
`;

const Title = styled.div`
  width: 100%;
  height: 28px;
  color: #333333;
  font-family: Lato;
  font-size: 23px;
  font-weight: 900;
  line-height: 26px;
  margin-bottom: 16px;
`;
const FilterBtn = styled.div`
  position: absolute;
  right: 16px;
  vertical-align: text-bottom;
  display: inline-block;
  width: 96px;
  height: 25px;
  border-radius: 50px;
  text-align: center;
  line-height: 21px;
  cursor: pointer;
  border: 2px solid rgba(130, 143, 247, 0.2);
  :hover,
  focus {
    opacity: 0.8;
  }
`;

const FilterText = styled.span`
  color: #828ff7;
  font-family: "Noto Sans CJK KR";
  font-size: 13px;
  font-weight: 700;
  vertical-align: text-top;
`;
const FilterDown = styled.div`
  position: absolute;
  text-align: center;
  display: none;
  right: 16px;
  top: 57px;
  width: 97px;
  height: 117px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #ffffff;
  ${props =>
    props.toggle &&
    css`
      display: block;
      z-index: 2;
    `}
`;
const FilterDownText = styled.div`
  color: #828ff7;
  font-family: "Noto Sans CJK KR";
  font-size: 13px;
  font-weight: 700;
  padding: 7px 0px;
  cursor: pointer;
  :hover,
  focus {
    opacity: 0.8;
  }
`;
const TitleWrap = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
`;

const StoreImg = styled.div`
  flex: 0 0 auto;
  width: 48px;
  height: 48px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  background-size: cover;
  background-position: center;
`;
const StoreInfo = styled.div`
  margin-left: 12px;
  flex: 0 1 auto;
  overflow: hidden;
  white-space: nowrap;

  p.event-title {
    margin: 0px;
    color: #333333;
    font-family: "Noto Sans CJK KR";
    font-size: 17px;
    font-weight: 700;
    line-height: 25px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  p.store-name {
    margin: 0px;
    color: #828ff7;
    font-family: "Noto Sans CJK KR";
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
const EventPostWrap = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  height: 130px;
  overflow-x: auto;
`;
const EventPostCard = styled.div`
  flex: 0 0 auto;
  position: relative;
  margin-right: 8px;
  width: 96px;
  height: 128px;
  background-color: red;
  border-radius: 8px;
`;
const ExampleTag = styled.div`
  position: absolute;
  text-align: center;
  top: 8px;
  left: 8px;
  width: 30px;
  height: 18px;
  line-height: 18px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0);
  background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
  letter-spacing: -0.24px;
  color: #ffffff;
  font-family: "Noto Sans CJK KR";
  font-size: 12px;
  font-weight: 500;
`;
const FooterWrap = styled.div`
  padding: 0px 16px;
  height: 45px;
  margin-left: -16px;
  margin-top: 16px;
  border-top: solid 1px #eeeeee;
  line-height: 50px;
  color: #999999;
  font-family: "Noto Sans CJK KR";
  font-size: 13px;
  font-weight: 700;
  letter-spacing: -0.26px;
  span {
    margin: 0px;
  }
  span.remain-time {
  }
  span.participant-count {
    float: right;
  }
`;

@inject("userStore")
@inject("eventStore")
@observer
class EventList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventData: this.props.events,
      filter: "deadline",
      filterText: "마감임박순",
      filterDropDown: false
    };
  }
  componentDidMount() {}
  componentDidUpdate(prevProps) {
    if (prevProps.events != this.props.events) {
      this.setState({
        eventData: this.props.events
      });
    }
  }
  toggleFilterDropDown = () => {
    this.setState({
      filterDropDown: !this.state.filterDropDown
    });
  };
  toggleFilter = async filter => {
    const { eventStore } = this.props;
    const dict = {
      deadline: "마감임박순",
      popular: "인기순",
      new: "최신순"
    };
    const req = {
      filter: filter,
      headers: this.props.userStore.auth_token
        ? { Authorization: `Bearer ${this.props.userStore.auth_token}` }
        : false
    };
    const events = await eventStore.getEvents(req).then(res => {
      return res;
    });
    this.setState({
      eventData: events,
      filter: filter,
      filterText: dict[filter],
      filterDropDown: false
    });
  };
  render() {
    return (
      <Wrapper>
        <Title>
          <span>Challenge</span>
          <FilterBtn onClick={this.toggleFilterDropDown}>
            <FilterText>
              {" "}
              {this.state.filterText}{" "}
              <Icon
                type="down"
                style={{ fontSize: "11px", verticalAlign: "middle" }}
              />{" "}
            </FilterText>
          </FilterBtn>
          <FilterDown toggle={this.state.filterDropDown}>
            <FilterDownText onClick={() => this.toggleFilter("deadline")}>
              마감임박순
            </FilterDownText>
            <FilterDownText onClick={() => this.toggleFilter("popular")}>
              인기순
            </FilterDownText>
            <FilterDownText onClick={() => this.toggleFilter("new")}>
              최신순
            </FilterDownText>
          </FilterDown>
        </Title>

        {this.state.eventData.map((event, index) => {
          return (
            <EventCard
              key={index}
              onClick={() => {
                history.push(`/event/${event.id}`);
              }}
              data={event}
            ></EventCard>
          );
        })}
      </Wrapper>
    );
  }
}
export default EventList;
