import { Grid, Tabs } from 'antd-mobile';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import SpinnerCurtain from 'components/loadingSpinner/spinnerCurtain';
import MiniPostCard from 'components/mainCrtPost/miniPostCard';
import LikableItem from 'components/likableItem';
import SearchBar from 'components/likePageSearchBar';
import InfiniteScroll from 'react-infinite-scroller';
import Modal from 'components/modal';
import Home from 'pages/home_Prev';
import Posts from '../pages/posts';
import * as postAPI from 'axios/postAPI';
import * as userAPI from 'axios/userAPI';

const CrtPostCardList = styled(Grid)`
	display: block;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	padding: 0px 16px;
	padding-bottom: 110px;

	div.am-flexbox-item.am-grid-item:nth-child(odd) {
		margin-right: 3%;
	}
	div.am-flexbox-item.am-grid-item:nth-child(even) {
	}
`;

@inject('userStore')
@observer
class LikeTabs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			postData: false,
			postDataNext: null,
			postDataPage: 0,
			postResults: [],
			itemData: false,
			itemDataNext: null,
			itemResults: [],
			currentIndex: 0,
			modalOpen: false,
			modalIndex: -1,
			modalThumbnail: null,
		};
	}
	async componentDidMount() {
		await this.loadData();
		window.onpopstate = this.closeModal;
	}
	loadData = async () => {
		const headers = this.props.userStore.auth_token
			? { Authorization: `Bearer ${this.props.userStore.auth_token}` }
			: false;
		const myProfile = await userAPI
			.getMyUserProfile({ headers: headers })
			.then(res => {
				return res.data;
			});
		const req = {
			like: myProfile.id,
			headers: headers,
		};
		let postData = await postAPI
			.getPostList({ ...req, next: this.state.postDataNext })
			.then(res => {
				return res.data;
			});
		const itemData = await userAPI.getUserLikeItemList(req).then(res => {
			return res.data;
		});

		postData = (this.state.postData || [])
			.concat(postData.data)
			.filter((o, i, arr) => i === arr.findIndex(j => j.id === o.id));
		const postResults = postData;
		this.setState({
			// postData: postData.data,
			// 고유한 것만 보여주기
			postData,
			postResults,
			postDataNext: postData.next,
			itemData: itemData.data,
			itemDataNext: itemData.next,
			itemResults: itemData.data,
		});
		return this.state.currentIndex === 0 ? postData.data : itemData.data;
	};
	_onChange = (tab, index) => {
		this.setState({
			currentIndex: index,
		});
	};

	_setPostData = results => {
		this.setState({
			postResults: results,
		});
	};

	_setItemResults = results => {
		this.setState({
			itemResults: results,
		});
	};

	closeModal = () => {
		this.setState({
			modalOpen: false,
		});
	};
	openModal = ({ index, thumbnail }) => {
		window.history.pushState(null, null, '/my-like/modal');

		this.setState({
			modalOpen: true,
			modalIndex: index,
			modalThumbnail: thumbnail,
		});
	};
	render() {
		const {
			postData,
			itemData,
			modalIndex,
			postResults,
			itemResults,
			currentIndex,
			modalOpen,
		} = this.state;
		const tabs = [{ title: '포스팅' }, { title: '상품' }];
		let clickedPost;


		if (postData) clickedPost = postData[modalIndex];
		if (itemData) {
			return (
				<>
					<Tabs
						ref={ref => (this.tabs = ref)}
						tabs={tabs}
						initialPage={0}
						swipeable={false}
						animated={false}
						useOnPan={false}
						tabBarTextStyle={{
							fontWeight: 700,
							fontFamily: 'Noto Sans CJK KR',
							cursor: 'pointer',
						}}
						tabBarActiveTextColor={'#828ff7'}
						tabBarInactiveTextColor={'#bebebe'}
						onChange={this._onChange}
					>
						<div>
							{this.props.search_mode && currentIndex == 0 ? (
								<SearchBar
									mode="posts"
									data={postData}
									setPostData={this._setPostData}
									toggle={this.props.toggle}
								/>
							) : (
								<Fragment />
							)}

							{postData ? (
								<InfiniteScroll
									pageStart={this.state.postDataPage}
									loadMore={this.loadData}
									hasMore={Boolean(this.state.postDataNext)}
									loader={
										<div
											className="loader"
											style={{ textAlign: 'center' }}
											key={0}
										>
											{/* 불러오는 중 ... */}
										</div>
									}
									useWindow={false}
								>
									<CrtPostCardList
										data={postResults}
										columnNum={2}
										hasLine={false}
										activeStyle={false}
										itemStyle={{
											height: 232,
											marginTop: 12,
										}}
										renderItem={(post, index) => (
											<MiniPostCard
												data={JSON.stringify(post)}
												likeBtn={true}
												onClick={() =>
													this.openModal({
														index,
														thumbnail: post.thumbnail_image,
													})
												}
											/>
										)}
									/>
								</InfiniteScroll>
							) : (
								<LoadingSpinner height={'250px'} />
								// <div />
							)}
						</div>
						<div>
							{this.props.search_mode && currentIndex == 1 ? (
								<SearchBar
									mode="items"
									data={itemData}
									setItemData={this._setItemResults}
									toggle={this.props.toggle}
								/>
							) : (
								<Fragment />
							)}
							{itemData ? (
								<div style={{ padding: '0px 16px' }}>
									<LikableItem data={itemResults} />
								</div>
							) : (
								<LoadingSpinner height={'250px'} />
								// <div />
							)}
						</div>
					</Tabs>

					<Modal
						open={modalOpen}
						onClose={this.closeModal}
						afterClose={() => this.setState({ modalOpen: null })}
					>
						{/* {postData && clickedPost && (
							<Posts
								swiperClassName={'myLike-swiper-home'}
								id={clickedPost.id}
								handleClickProfileImg={this.closeModal}
								thumbnail={clickedPost.thumbnail_image}
								onClose={this.closeModal}
								onClick={this.closeModal}
								disableFooter
								disableRecommendationAndFollowing
							/>
						)} */}
						{postData && clickedPost && (
							<div style={{ height: '100%', position: 'fixed', width: '100%' }}>
								<Home
									thumbnail={clickedPost.thumbnail_image}
									onpopstate={this.closeModal}
									onClose={this.closeModal}
									postId={clickedPost.id}
									swiperClassName="myLike-swiper-home"
									userProfileSwiperClass="myLike-userprofile-swiper"
									disableDefaultTransitionEvent
									postProps={{
										disableFooter: true,
										disableRecommendationAndFollowing: true,
										onClose: this.closeModal,
										posts: postData,
										id: clickedPost.id,
										loadMore: this.loadData,
									}}
								/>
							</div>
						)}
					</Modal>
				</>
			);
		} else {
			return <div />;
			// return <SpinnerCurtain fullSize={true} />;
		}
	}
}

export default LikeTabs;
