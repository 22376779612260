import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { Icon } from "antd";

import history from "utils/history";

const Btn = styled.button`
  min-width: 60px;
  border: none;
  display: inline-block;
  flex: 0 0 auto;
  height: 32px;
  border-radius: 50px;
  background-color: #eeeeee;
  padding: 4px 12px;
  margin: auto;
  margin-bottom: 16px;
  margin-right: 8px;
  text-align: center;
  color: #bebebe;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 500;
  transition: 0.1s;
  span {
    vertical-align: text-top;
  }
  ${props =>
    props.clicked &&
    css`
      color: #828ff7;
      font-weight: 700;
      background-color: rgba(130, 143, 247, 0.2);
    `}
  ${props => props.focused && css``}
`;

class SignupFilterBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
      focused: false
    };
  }

  onMouseEnterHandler = e => {
    this.setState({ focused: true });
  };
  onMouseLeaveHandler = e => {
    this.setState({ focused: false });
  };
  _onTouchStart = e => {
    this.setState({
      focused: true
    });
  };
  _onTouchEnd = e => {
    this.setState({
      focused: false
    });
  };

  handleClick = () => {
    this.props.handleBtn(this.props.data);
  };
  render() {
    return (
      <Fragment>
        {!this.props.disabled ? (
          <Btn
            onClick={this.handleClick}
            clicked={this.props.clicked}
            focused={this.state.focused}
            onTouchStart={this._onTouchStart}
            onTouchEnd={this._onTouchEnd}
            onMouseEnter={this.onMouseEnterHandler}
            onMouseLeave={this.onMouseLeaveHandler}
          >
            <span> {this.props.text} </span>
          </Btn>
        ) : (
          <Btn clicked={this.props.clicked} focused={this.state.focused}>
            <span> {this.props.text} </span>
          </Btn>
        )}
      </Fragment>
    );
  }
}

export default SignupFilterBtn;
