import React, { Fragment } from 'react';
import clsx from 'clsx';
import styled, { css } from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { animateScroll as scroll } from 'react-scroll';
import { Wrap, IndexWrap } from 'components/style/custom-styled-components';
import VideoInput from 'components/videoInput';
import Button from '@material-ui/core/Button';
import ProductAddPage from './productAddPage';
import qs from 'querystring';
import * as postAPI from 'axios/postAPI';
import axios from 'axios';
import * as userAPI from 'axios/userAPI';
import * as mediaAPI from 'axios/mediaAPI';
import history from 'utils/history';
import closeIcon from 'images/baseline_close_black_18dp.png';
import LinkSvg from 'components/myIcons/linkIcon';
import back_ico from 'images/back_ico_b.svg';
import categoryItems from 'utils/categoryItems';
import DeleteModal from 'components/popModal/deleteModal';
import QnaModal from 'components/QnaModal';
import MissionModal from 'components/MissionModal';
import PollModal from 'components/PollModal';
import TopicModal from 'components/TopicModal';
import { ClipLoader, PropagateLoader } from 'react-spinners';
import CheckIcon from '@material-ui/icons/CheckCircle';
import imageCompression from 'browser-image-compression';
import makeLink from 'utils/makeLink';
import sendPush from 'utils/sendPush';
import getCaret from 'utils/getCaret';
import PostTypeSelect from 'containers/postTypeSelect';
import sendTagPush from 'utils/sendTagPush';
import photo_img from 'images/photo.png';
import { ReactSortable } from 'react-sortablejs';
import UserSelectorModal from '../components/popModal/userSelectorModal';
import InfoList from 'containers/infoList';
import WorldAndSum from 'components/worldAndSum';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = {
  buttonWrapper: {
    marginBottom: 16,
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonNegative: {
    color: 'black',
    background: '#efefef',
    height: 36,
    width: '48%',
    borderRadius: 10,
    boxShadow: 'none',
  },
  buttonCenter: {
    marginLeft: 8,
    marginRight: 8,
  },
  buttonActive: {
    background: '#E2EAFA',
    color: '#1f75fe',
  },
  input: {
    display: 'none',
  },
  imageMulti: {
    textAlign: 'center',
    marginBottom: 24,
  },
};
const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  line-height: 56px;
  height: 44px;
  line-height: 44px;
  width: 100%;
  max-width: 1024px;
  z-index: 3;
  top: 0;
  background: rgb(248, 248, 248);
`;
const ContentWrap = styled.div`
  padding: 10px 16px;
  padding-bottom: 70px;
  color: #333333;
  font-family: 'Noto Sans CJK KR';
  font-size: 16px;
  font-weight: 700;
  p {
    margin-bottom: 10px;
  }
  span.sub-btn {
    float: right;
    cursor: pointer;
    :hover {
      opacity: 0.8;
    }
  }
  div {
    position: relative;
  }
  input::placeholder {
    color: #bebebe;
    font-family: 'Noto Sans CJK KR';
    font-size: 14px;
    font-weight: 500;
  }
`;
const VideoInputWrap = styled.div`
  width: 100%;
  max-width: 1024px;
  border-radius: 6px;
  padding-bottom: 36px;
  display: flex;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none !important;
  }
  justify-content: center;
`;
const UpdateTextArea = styled.textarea`
  width: 100%;
  min-height: 150px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
  border: none;
  margin-bottom: 16px;
  padding: 14px 16px;
  color: #333333;
  font-family: 'Noto Sans CJK KR';
  font-size: 14px;
  font-weight: 500;
  ::placeholder {
    color: #9fa4af;
    opacity: 0.5;
  }
`;
const UpdateInput = styled.input`
  width: 100%;
  height: 44px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
  border: none;
  margin-bottom: 16px;
  padding: 0px 16px;
  color: #333333;
  font-family: 'Noto Sans CJK KR';
  font-size: 14px;
  font-weight: 500;
  ::placeholder {
    color: #9fa4af;
    opacity: 0.8;
  }
`;
const SubmitWrap = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: auto;
  padding: 10px 16px;
  position: fixed !important;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-image: linear-gradient(
    to top,
    rgba(248, 248, 248, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
`;
const SubmitBtn = styled.button`
  border: none;
  width: 100%;
  height: 44px;
  box-shadow: 0 10px 20px rgba(31, 117, 254, 0.3);
  background: #1f75fe;
  border-radius: 68px;
  color: #ffffff;
  font-family: 'Noto Sans CJK KR';
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  ${(props) =>
    props.disabled &&
    css`
      background-image: none;
      background-color: rgb(150, 150, 150);
    `}
`;
const AddButton = styled.button`
  background: none;
  border: none;
  font-family: 'Noto Sans CJK KR';
  font-size: 15px;
  font-weight: 700;
  margin-top: 8px;
  color: #828ff7;
  width: 100%;
  text-align: center;
  cursor: pointer;
`;
const Title = styled.button`
  color: #333333;
  font-family: 'Noto Sans CJK KR';
  font-size: 15px;
  font-weight: 700;
  border: none;
  background-color: transparent;
  :hover {
    opacity: 0.8;
  }
`;
const ProductAddPageLayout = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
`;
// mount whatever plugins you'd like to. These are the only current options.
const base = 'https://star-api.lay-er.me';
@inject('postStore')
@inject('userStore')
@observer
class CreatePostPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoInputArr: [],
      order: [],
      videoLen: this.props.postStore.videoLen,
      videoFiles: this.props.postStore.addedVideoArr,
      linkArr: this.props.postStore.createPost.links
        ? this.props.postStore.createPost.links
        : [],
      linkNum: this.props.postStore.createPost.linkNum
        ? this.props.postStore.createPost.linkNum
        : 1,
      tags: this.props.postStore.createPost.tags
        ? this.props.postStore.createPost.tags
        : undefined,
      title: this.props.postStore.createPost.title
        ? this.props.postStore.createPost.title
        : '',
      profile: this.props.postStore.createPost.profile
        ? this.props.postStore.createPost.profile
        : undefined,
      sum: null,
      deleteMode: false,
      productAddPageOn: false,
      productAdded: [
        { id: `${Math.random()}`, shop: '', link: '', shopName: '' },
      ],
      selectedProduct: null,

      loading: false,
      deleteIndex: -1,

      topicDescription: '',
      topicOpen: false,
      topic: null,

      missionDescription: '',
      missionOpen: false,
      mission: null,

      qnaOpen: false,
      qna: null,

      pollOpen: false,
      pollTitle: '',
      pollOptionA: '',
      pollOptionB: '',
      days: 0,
      drawerOpen: false,
      page: 0,
      cursor: -1,
      tagged: [],

      changing: false,
      onlySelected: false,
    };
    if (typeof window !== 'undefined') {
      const selected = /selected=(\d)/.exec(window.location);
      if (selected) {
        let selectedCode = selected[1];
        this.categorySelected =
          selectedCode === '0'
            ? 'fashion'
            : selectedCode === '1'
            ? 'beauty'
            : 'hair';
      }
    }
    if (!this.categorySelected) {
      this.categorySelected = 'fashion';
    }
  }
  handleDeleteProduct = (index) => {
    let newProductAdded = this.state.productAdded.slice();
    newProductAdded.splice(index, 1);
    this.setState({
      productAdded: newProductAdded,
      deleteIndex: -1,
    });
  };
  getImgOfProduct = (label, category) => {
    const src = categoryItems[category].find((o) => o.label === label).src;
    return src;
  };
  async componentWillMount() {
    if (!this.props.userStore.auth_token) history.push('/login');
    else if (!this.props.postStore.createPost.profile) {
      const req = {
        headers: { Authorization: `Bearer ${this.props.userStore.auth_token}` },
      };
      const profile = await userAPI.getMyUserProfile(req).then((res) => {
        return res.data;
      });
      this.setState({
        profile: profile,
      });
    }
  }
  setTopicAndMissionState = () => {
    const parsed = qs.parse(window.location.search);

    if (parsed.topic) {
      this.setState({ topic: parsed.topic, topicDescription: '' });
    }
    if (parsed.mission) {
      this.setState({
        mission: parsed.mission,
        missionDays: Number(parsed.days),
        missionDescription: '',
      });
    }
  };
  getSumId = () => {
    const parsed = qs.parse(window.location.search);
    console.log('parsed', parsed);
    let sumId = parsed.sum;
    if (!sumId) {
      sumId = parsed['?sum'];
    }

    return sumId ? Number(sumId) : null;
  };
  async componentDidMount() {
    const sums = await axios.get(`${base}/sums`);
    const sumId = this.getSumId();
    this.setTopicAndMissionState();
    const sum = sums.data.find(({ id }) => id === sumId);
    this.setState({ sum });
    let videoInputArr = [];
    let order = [];
    for (var i = 0; i < this.state.videoLen; i++) {
      const video = this.props.postStore.addedVideoArr[i]
        ? this.props.postStore.addedVideoArr[i].video
        : undefined;
      const item = this.props.postStore.addedVideoArr[i];
      const key = item ? item.id || i : i;
      const isImage = item ? !item.video : false;
      videoInputArr.push(
        <VideoInput
          key={key}
          index={i}
          isImage={item && item.isImage}
          onChange={this.handleInput}
          addVideoInput={this.addVideoInput}
          video={video}
          delVideo={this.delVideo}
        />
      );
      order.push(i);
    }
    this.setState({
      videoInputArr: videoInputArr,
      order,
    });
  }
  componentWillUnmount() {
    this.props.postStore.createPost = {
      title: this.state.title,
      tags: this.state.tags,
      links: this.state.linkArr,
      linkNum: this.state.linkNum,
      profile: this.state.profile,
    };
  }
  handleInput = (event) => {
    const eName = event.target.name;
    if (eName == 'tags') {
      var special_pattern = /[`~!@#$%^&*|\\\'\";:\/?]/gi;
      if (special_pattern.test(event.target.value) == true) {
        alert('태그에 특수문자는 입력할 수 없습니다.');
        this.setState({
          [eName]: this.state.tags ? this.state.tags : '',
        });
      } else {
        this.setState({ [eName]: event.target.value });
      }
    } else {
      this.setState({ [eName]: event.target.value });
    }
  };
  addVideoInput = (video) => {
    const isImage = video.type.includes('image');
    this.props.postStore.addVideo(video, isImage);

    let videoInputArr = [];
    let order = [...this.state.order];
    for (var i = 0; i < this.props.postStore.videoLen; i++) {
      const video = this.props.postStore.addedVideoArr[i]
        ? this.props.postStore.addedVideoArr[i].video
        : undefined;
      videoInputArr.push(
        <VideoInput
          key={i}
          index={i}
          onChange={this.handleInput}
          addVideoInput={this.addVideoInput}
          video={video}
          delVideo={this.delVideo}
        />
      );
      if (this.props.postStore.videoLen - 1) {
        order.push(order.length);
      }
    }
    this.setState({
      videoInputArr: videoInputArr,
      videoFiles: this.props.postStore.addedVideoArr,
      videoLen: this.props.postStore.videoLen,
      order,
    });
  };
  delVideo = (index) => {
    this.props.postStore.delVideo(index);
    let videoInputArr = [];
    for (var i = 0; i < this.props.postStore.videoLen; i++) {
      const video = this.props.postStore.addedVideoArr[i]
        ? this.props.postStore.addedVideoArr[i].video
        : undefined;
      const item = this.props.postStore.addedVideoArr[i];
      const key = item ? item.id || i : i;
      const isImage = item && item.isImage;
      videoInputArr.push(
        <VideoInput
          key={key}
          index={i}
          onChange={this.handleInput}
          isImage={isImage}
          addVideoInput={this.addVideoInput}
          video={video}
          delVideo={this.delVideo}
        />
      );
    }
    this.setState({
      videoInputArr: videoInputArr,
      videoFiles: this.props.postStore.addedVideoArr,
      videoLen: this.props.postStore.videoLen,
    });
  };
  handleAddLink = () => {
    this.setState(
      {
        linkNum: this.state.linkNum + 1,
      },
      () => {
        scroll.scrollToBottom({
          duration: 500,
          delay: 0,
          smooth: true,
        });
      }
    );
  };
  toggleLinkDelete = () => {
    this.setState({
      deleteMode: !this.state.deleteMode,
    });
  };
  handleAddLinkArr = (id, data) => {
    const { linkArr } = this.state;
    let isNew = true;
    for (var i = 0; i < this.state.linkArr.length; i++) {
      if (this.state.linkArr[i].id == id) {
        isNew = false;
        break;
      }
    }
    if (isNew) {
      const { linkArr } = this.state;
      this.setState({
        linkArr: linkArr.concat({ id: id, data }),
      });
    } else {
      this.setState({
        linkArr: linkArr.map(
          (arr) =>
            id === arr.id
              ? { ...arr, data } // 새 객체를 만들어서 기존의 값과 전달받은 data 을 덮어씀
              : arr // 기존의 값을 그대로 유지
        ),
      });
    }
  };
  handleRemoveLinkArr = (id) => {
    const { linkArr } = this.state;
    this.setState({
      linkArr: linkArr.filter((arr) => arr.id !== id),
    });
  };
  formSubmit = async () => {
    try {
      const sumId = this.getSumId();
      if (!sumId) {
        alert('섬을 선택하여 주십시요.');
      } else if (this.props.postStore.addedVideoArr.length == 0) {
        alert('영상을 하나 이상 업로드해야 합니다');
      } else if (this.state.title === '') {
        alert('내용을 입력해 주세요');
      } else {
        this.setState({ loading: true });
        this.props.postStore.setLinkLoading(true);

        const tagRegExp = /#\S+/g;
        const tags = [];
        let regExpResult;
        while ((regExpResult = tagRegExp.exec(this.state.title))) {
          tags.push(regExpResult[0].slice(1));
        }

        let links = [];
        for (var i = 0; i < this.state.linkArr.length; i++) {
          links.push(this.state.linkArr[i].data);
        }
        const order = this.state.videoInputArr
          .map(({ props: { video } }) =>
            this.props.postStore.addedVideoArr.find((o) => o.video === video)
          )
          .map(({ id }) => id)
          .join(',');
        const req = {
          data: {
            title: this.state.title,
            id_of_sum: String(sumId),
            tags: tags,
            links: links.filter((o) => o !== '' || o.link !== ''),
            video: this.props.postStore.addedVideoArr,
            category: this.state.productAdded.length
              ? this.state.productAdded[0].category
              : null,
            order,
            onlySelected: sumId ? this.state.onlySelected : false,
          },
          headers: {
            Authorization: `Bearer ${this.props.userStore.auth_token}`,
          },
        };
        // return;
        if (this.state.productAdded.length) {
          req.data.links = this.state.productAdded
            .filter(
              (o) =>
                !(
                  o.shop === '' ||
                  (o.link !== '' && o.link.indexOf('http') === -1)
                )
            )
            .map((o) => ({
              shopName: o.shop,
              shopLink: o.link,
              link: o.link,
              category: o.category,
              label: o.label,
            }));
        }

        if (this.state.tagged.length) {
          let title = req.data.title;

          req.data.title = makeLink({ str: title, tagged: this.state.tagged });
        }
        await postAPI
          .createPost(req)
          .then(async ({ data }) => {
            if (this.state.profile) {
              await sendTagPush({
                ids: this.state.tagged.map(({ id }) => id),
                nickname: this.state.profile.nickname,
                postId: data.id,
              });
            }

            if (this.state.qna && this.state.qna !== '') {
              await axios.post(`${base}/qnas`, {
                title: this.state.qna,
                postId: data.id,
              });
            } else if (this.state.mission && this.state.mission !== '') {
              const prev = await axios.get(
                `${base}/missions?title=${encodeURIComponent(
                  this.state.mission
                )}`
              );
              try {
                if (prev.data && prev.data.length > 0) {
                  // 이름 가져오기
                  const starterPost =
                    prev.data.find(
                      ({ starter }) => starter && starter !== ''
                    ) || prev.data[0];
                  if (starterPost) {
                    const post = (
                      await postAPI.getPost({
                        id: starterPost.postId,
                        headers: undefined,
                      })
                    ).data;
                    const starter = post.profile && post.profile.id;

                    if (starter) {
                      // 푸시 보내기
                      await sendPush({
                        id: starter,
                        message: `${this.state.profile.nickname}님이 회원님의 미션에 참여했습니다.`,
                        link: `/post/${data.id}`,
                      });
                    }
                  }
                }
              } catch (e) {
                console.error(e);
              }
              await axios.post(`${base}/missions`, {
                title: this.state.mission,
                description: this.state.missionDescription,
                postId: data.id,
                days: +this.state.missionDays,
              });
            } else if (this.state.pollTitle && this.state.pollTitle !== '') {
              await axios.post(`${base}/polls`, {
                title: this.state.pollTitle,
                postId: data.id,
                optionA: this.state.pollOptionA,
                optionB: this.state.pollOptionB,
              });
            } else if (this.state.topic && this.state.topic !== '') {
              const prev = await axios.get(
                `${base}/topics?title=${encodeURIComponent(this.state.topic)}`
              );
              let first = true;
              try {
                if (prev.data && prev.data.length > 0) {
                  first = false;
                  // 이름 가져오기
                  const starterPost = prev.data.find(
                    ({ starter }) => starter && starter !== ''
                  );
                  if (starterPost) {
                    const starter = starterPost.starter;
                    const user = await axios.get(
                      `${base}/users?name=${encodeURIComponent(starter)}`
                    );
                    if (user.data && user.data[0]) {
                      const { userId } = user.data[0];

                      // 푸시 보내기
                      await sendPush({
                        id: userId,
                        message: `${this.state.profile.nickname}님이 회원님의 토픽에 참여했습니다.`,
                        link: `/post/${data.id}`,
                      });
                    }
                  }
                }
              } catch (e) {
                console.error(e);
              }
              await axios.post(`${base}/topics`, {
                title: this.state.topic,
                first,
                starter: first ? this.state.profile.nickname : '',
                postId: data.id,
                description: this.state.topicDescription,
              });
            }
            await axios.put(`${base}/sums/${sumId}`, {
              lastUploaded: new Date(),
            });

            this.props.postStore.setLinkLoading(false);
            window.location.href = `/sum/${sumId}`;
          })
          .catch((e) => {
            alert(e);
            console.log(e);
            this.props.postStore.setLinkLoading(false);
            window.location.href = `/sum/${sumId}`;
          });
      }
      this.props.postStore.setLinkLoading(false);
      this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.props.postStore.setLinkLoading(false);
      this.setState({ loading: false });
    }
  };
  handleFile = async (e) => {
    const isEdit = false;
    const { files } = e.target;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i += 1) {
        let file = files[i];
        file = await imageCompression(file, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        });
        file = new File([file], file.name, { type: file.type });

        this.addVideoInput(file);
        const formData = new FormData();
        formData.append('video', file);
        const req = {
          headers: this.props.userStore.auth_token
            ? {
                Authorization: `Bearer ${this.props.userStore.auth_token}`,
                'Content-Type': 'multipart/form-data',
              }
            : false,
          data: formData,
        };
        const { id } = await mediaAPI.getFramesFromMedia(req).then((res) => {
          return res.data;
        });
        const arr = isEdit
          ? this.props.postStore.editedVideoArr
          : this.props.postStore.addedVideoArr;
        const idx = arr.length - 1;
        arr[idx].id = id;
      }
    }
  };

  render() {
    const { classes } = this.props;
    let videoInputArr = [];
    for (var i = 0; i < this.state.videoLen; i++) {
      const video = this.props.postStore.addedVideoArr[i]
        ? this.props.postStore.addedVideoArr[i].video
        : undefined;
      videoInputArr.push(
        <VideoInput
          key={i}
          index={i}
          onChange={this.handleInput}
          addVideoInput={this.addVideoInput}
          video={video}
          delVideo={this.delVideo}
        />
      );
    }

    const pollOn = this.state.pollTitle !== '';
    const qnaOn = this.state.qna && this.state.qna !== '';
    const missionOn = this.state.mission && this.state.mission !== '';
    const topicOn = this.state.topic && this.state.topic !== '';

    const videos = this.state.order.map((i) => videoInputArr[i]);
    return (
      <>
        <Wrap style={{ paddingBottom: 0, backgroundColor: 'rgb(248,248,248)' }}>
          <IndexWrap style={{ paddingTop: 44, backgroundColor: '#f8f8f8' }}>
            <NavBar>
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  position: 'absolute',
                  margin: 'auto',
                }}
              >
                <Title>포스팅</Title>
              </div>

              <div
                style={{
                  width: '100%',
                  textAlign: 'left',
                  position: 'absolute',
                }}
              >
                <img
                  src={back_ico}
                  onClick={() => window.history.back()}
                  style={{
                    marginLeft: 19,
                    width: 19,
                    verticalAlign: 'middle',
                    cursor: 'pointer',
                  }}
                />
              </div>
            </NavBar>

            <ContentWrap>
              <WorldAndSum />
              {this.getSumId() ? (
                <FormControlLabel
                  style={{ marginLeft: 2, marginBottom: 6 }}
                  control={
                    <Switch
                      checked={this.state.onlySelected}
                      onChange={({ target: { checked } }) =>
                        this.setState({ onlySelected: checked })
                      }
                      color="primary"
                    />
                  }
                  label="선택한 섬에서만 보이기"
                />
              ) : null}
              <PostTypeSelect
                selected={
                  pollOn ? 1 : qnaOn ? 2 : missionOn ? 3 : topicOn ? 4 : 0
                }
                handleSelect={(index) => {
                  if (index === 0) {
                    if (window.location.href.includes('mission=')) {
                      alert('미션 수정은 불가능합니다.');
                    } else {
                      this.setState({
                        pollTitle: '',
                        pollOptionA: '',
                        pollOptionB: '',
                        pollOpen: false,
                        mission: null,
                        missionDays: '',
                        missionDescription: '',
                        missionOpen: false,
                        qna: null,
                        qnaOpen: false,
                        topic: null,
                        topicOpen: false,
                        topicDescription: '',
                      });
                    }
                  } else if (index === 1) {
                    if (qnaOn || missionOn || topicOn) {
                      alert('다른 등록을 제거해주세요.');
                    } else {
                      this.setState({ pollOpen: true });
                    }
                  } else if (index === 2) {
                    if (pollOn || missionOn || topicOn) {
                      alert('다른 등록을 제거해주세요.');
                    } else {
                      this.setState({ qnaOpen: true });
                    }
                  } else if (index === 3) {
                    if (window.location.href.includes('mission=')) {
                      alert('미션 수정은 불가능합니다.');
                    } else {
                      if (pollOn || qnaOn || topicOn) {
                        alert('다른 등록을 제거해주세요.');
                      } else {
                        this.setState({ missionOpen: true });
                      }
                    }
                  } else if (index === 4) {
                    if (pollOn || qnaOn || missionOn) {
                      alert('다른 등록을 제거해주세요.');
                    } else {
                      this.setState({ topicOpen: true });
                    }
                  }
                }}
              />

              {this.state.videoInputArr.length > 0 && (
                <VideoInputWrap>
                  {this.state.changing ? (
                    <ReactSortable
                      list={this.state.videoInputArr}
                      setList={(videoInputArr) => {
                        this.setState({
                          videoInputArr,
                        });
                      }}
                    >
                      {this.state.videoInputArr.map((item) => (
                        <div key={item.key} style={{ display: 'inline-block' }}>
                          {item}
                        </div>
                      ))}
                    </ReactSortable>
                  ) : (
                    this.state.videoInputArr
                  )}
                </VideoInputWrap>
              )}
              {this.state.videoInputArr.length > 1 && (
                <div style={{ textAlign: 'right' }}>
                  <Button
                    onClick={() =>
                      this.setState({ changing: !this.state.changing })
                    }
                    color="primary"
                  >
                    {this.state.changing ? '변경 완료' : '순서 변경'}
                  </Button>
                </div>
              )}
              <div style={{ marginBottom: 28 }}>
                <VideoInput
                  button
                  addVideoInput={this.addVideoInput}
                  index={videoInputArr.length}
                />
                <div
                  style={{
                    width: '50%',
                    display: 'inline-block',
                    textAlign: 'right',
                  }}
                >
                  <input
                    type="file"
                    id="file-image"
                    style={{ display: 'none' }}
                    onChange={this.handleFile}
                    accept="image/*"
                  />
                  <label htmlFor="file-image">
                    <Button
                      component="span"
                      style={{
                        boxShadow: '0 4px 16px rgba(69, 91, 99, 0.08)',
                        border: '1px solid rgba(0, 0, 0, 0)',
                        background: 'white',
                        color: '#1f75fe',
                        width: '95%',
                        borderRadius: 8,
                        padding: 12,
                        fontFamily: 'Noto Sans CJK KR',
                      }}
                    >
                      <img
                        style={{ width: 14, marginRight: 8 }}
                        src={photo_img}
                      />
                      사진 추가
                    </Button>
                  </label>
                </div>
              </div>
              {/* <div className={classes.imageMulti}>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={this.handleFile}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span">
                    사진 여러장 업로드
                  </Button>
                </label>
              </div> */}
              <div>
                <UpdateTextArea
                  name="title"
                  value={this.state.title}
                  onChange={this.handleInput}
                  placeholder="내용을 입력해주세요. (해시태그는 #으로 입력)"
                  onFocus={(e) => {
                    e.preventDefault();
                    this.setState({
                      cursor: e.selectionEnd,
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === '@') {
                      setTimeout(() => {
                        this.setState({ drawerOpen: true });
                      }, 300);
                    }
                    this.setState({ cursor: getCaret(e.target) });
                  }}
                />
              </div>
              <p>정보 등록</p>
              <InfoList
                items={this.state.productAdded.map((product) => ({
                  shop: product.shop,
                  link: product.link,
                  ...product,
                }))}
                onChange={(productAdded) => {
                  this.setState({
                    productAdded: productAdded.map((item) => ({
                      ...item,
                      category: 'fashion',
                      shop: item.shop,
                      name: '클릭시 상품 링크로 이동합니다.',
                      label: null,
                    })),
                  });
                }}
              />
              <SubmitWrap>
                <SubmitBtn
                  onClick={this.formSubmit}
                  disabled={this.props.postStore.linkLoading}
                >
                  게시하기
                </SubmitBtn>
              </SubmitWrap>
            </ContentWrap>
          </IndexWrap>
        </Wrap>

        {this.state.loading && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ClipLoader
              sizeUnit={'px'}
              size={36}
              color={'rgb(130, 143, 247)'}
              loading={true}
            />
          </div>
        )}
        <DeleteModal
          active={this.state.deleteIndex > -1}
          onClick={() => this.handleDeleteProduct(this.state.deleteIndex)}
          handleClose={() => this.setState({ deleteIndex: -1 })}
        />
        <MissionModal
          open={this.state.missionOpen}
          onClose={() => this.setState({ missionOpen: false })}
          value={{
            days: this.state.days,
            mission: this.state.mission,
            description: this.state.missionDescription,
          }}
          onChange={(name) => (e) => this.setState({ [name]: e.target.value })}
          onSubmit={({ value, description, daysValue }) =>
            this.setState({
              mission: value === '' ? null : value,
              missionDays: daysValue === '' ? null : daysValue,
              missionDescription: description === '' ? null : description,
              missionOpen: false,
            })
          }
          onRemove={() =>
            this.setState({
              mission: null,
              missionDays: '',
              missionDescription: '',
              missionOpen: false,
            })
          }
        />
        <QnaModal
          open={this.state.qnaOpen}
          onClose={() => this.setState({ qnaOpen: false })}
          value={this.state.qna}
          onChange={(e) => this.setState({ qna: e.target.value })}
          onSubmit={(value) =>
            this.setState({
              qna: value === '' ? null : value,
              qnaOpen: false,
            })
          }
          onRemove={() => this.setState({ qna: null, qnaOpen: false })}
        />
        <PollModal
          input
          onVote={() => {}}
          open={this.state.pollOpen}
          onClose={() => this.setState({ pollOpen: false })}
          title={this.state.pollTitle}
          onTitleChange={(e) => this.setState({ pollTitle: e.target.value })}
          pollOptionA={this.state.pollOptionA}
          onPollOptionAChange={(e) =>
            this.setState({ pollOptionA: e.target.value })
          }
          pollOptionB={this.state.pollOptionB}
          onPollOptionBChange={(e) =>
            this.setState({ pollOptionB: e.target.value })
          }
          onSubmit={() => {
            if (this.state.pollTitle === '') {
              window.alert('타이틀을 입력해주십시요.');
            } else if (
              this.state.pollOptionA === '' ||
              this.state.pollOptionB === ''
            ) {
              window.alert('투표안을 입력해주십시요.');
            } else {
              this.setState({ pollOpen: false });
            }
          }}
          onRemove={() =>
            this.setState({
              pollTitle: '',
              pollOptionA: '',
              pollOptionB: '',
              pollOpen: false,
            })
          }
        />
        <TopicModal
          open={this.state.topicOpen}
          onClose={() => this.setState({ topicOpen: false })}
          value={{
            topic: this.state.topic,
            topicDescription: this.state.topicDescription,
          }}
          onChange={(name) => (e) => this.setState({ [name]: e.target.value })}
          onSubmit={(value) =>
            this.setState({
              topic: value.topic === '' ? null : value.topic,
              topicOpen: false,
              topicDescription: value.topicDescription,
            })
          }
          onRemove={() =>
            this.setState({
              topic: null,
              topicOpen: false,
              topicDescription: '',
            })
          }
        />
        <UserSelectorModal
          open={this.state.drawerOpen}
          onClose={() => this.setState({ drawerOpen: false })}
          onSelect={(data) => {
            const { cursor } = this.state;
            const nextTitle = [
              this.state.title.slice(0, cursor),
              `@${data.nickname} `,
              this.state.title.slice(cursor + 1),
            ].join('');

            this.setState({
              drawerOpen: false,
              title: nextTitle,

              tagged: this.state.tagged.find((p) => p.id === data.id)
                ? this.state.tagged
                : this.state.tagged.concat(data),
            });
          }}
        />
      </>
    );
  }
}
export default withStyles(styles)(CreatePostPage);
