import React, { Component } from "react";
import styled, { css } from "styled-components";

const IconWrap = styled.i``;

class CommentMiniIcon extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <IconWrap>
        <svg
          style={{ verticalAlign: "text-bottom" }}
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="17"
          viewBox="0 0 18 17"
        >
          <defs>
            <mask id="i4e9b" width="2" height="2" x="-1" y="-1">
              <path fill="#fff" d="M4 4h10v10H4z" />
              <path d="M11.5 6.359a.5.5 0 0 1 0 1h-5a.501.501 0 0 1 0-1zm0 2.283a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1zm-7.498 5.356L6 12h7c.552 0 1-.448 1-1V5c0-.552-.448-1-1-1H5c-.551 0-1 .448-1 1v9z" />
            </mask>
            <filter
              id="i4e9a"
              width="40"
              height="40"
              x="-11"
              y="-11"
              filterUnits="userSpaceOnUse"
            >
              <feOffset in="SourceGraphic" result="FeOffset1023Out" />
              <feGaussianBlur
                in="FeOffset1023Out"
                result="FeGaussianBlur1024Out"
                stdDeviation="2 2"
              />
            </filter>
          </defs>
          <g>
            <g>
              <g filter="url(#i4e9a)">
                <path
                  fill="none"
                  d="M11.5 6.359a.5.5 0 0 1 0 1h-5a.501.501 0 0 1 0-1zm0 2.283a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1zm-7.498 5.356L6 12h7c.552 0 1-.448 1-1V5c0-.552-.448-1-1-1H5c-.551 0-1 .448-1 1v9z"
                  mask='url("#i4e9b")'
                />
                <path
                  fillOpacity=".2"
                  d="M11.5 6.359a.5.5 0 0 1 0 1h-5a.501.501 0 0 1 0-1zm0 2.283a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1zm-7.498 5.356L6 12h7c.552 0 1-.448 1-1V5c0-.552-.448-1-1-1H5c-.551 0-1 .448-1 1v9z"
                />
              </g>
              <path
                fill="#f8f8f8"
                d="M11.5 6.359a.5.5 0 0 1 0 1h-5a.501.501 0 0 1 0-1zm0 2.283a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1zm-7.498 5.356L6 12h7c.552 0 1-.448 1-1V5c0-.552-.448-1-1-1H5c-.551 0-1 .448-1 1v9z"
              />
            </g>
          </g>
        </svg>
      </IconWrap>
    );
  }
}

export default CommentMiniIcon;
