import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Grid } from 'antd-mobile';
import { observer, inject } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroller';
import axios from 'axios';

import qs from 'querystring';
import CommentModal from 'components/popModal/commentModal';
import MainCard from 'components/mainCrtPost/mainCard';
import Modal from 'components/modal';
import Home from 'pages/home_Prev';
import NoFollowers from 'components/noFollowers';
import PostHeader from 'components/PostHeader';
import * as commentAPI from 'axios/commentAPI';
import * as postAPI from 'axios/postAPI';
import * as userAPI from 'axios/userAPI';
import history from 'utils/history';
import Filter from '../components/filter';
import LoadingSpinner from '../components/loadingSpinner/loadingSpinner';
import UpperDeco from 'components/upperDeco';
import Notice from 'components/notice';
import ArrowBack from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import ImageViewer from '../components/ImageViewer';
import { url } from 'utils/starApi';
import getUserOrCreate from 'utils/getUserOrCreate';
import PostTypeSelect from 'containers/postTypeSelectFromMain';
import Topics from '../pages/topics';
import Picks from 'components/Picks';

const Wrap = styled.div`
  ${(props) => (props.header ? `padding-top: 40px;` : '')}
`;

const TabContentWrap = styled.div`
  width: 100%;
  max-width: 1024px;
  padding: 0px 16px;
  padding-bottom: 24px;
  padding-top: 60px;
`;
const Header = styled.div`
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 2000;
  background: rgba(248, 248, 248);
  height: 50px;
`;
const MyGrid = styled(Grid)`
  display: block;
  // overflow: hidden;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
`;
const Nickname = styled.div`
  position: absolute;
  top: 14px;
  left: 0;
  right: 0;
  text-align: center;
`;

const TABS = ['포스팅', '상품', '사용자'];

const base = 'https://star-api.lay-er.me';
@inject('userStore')
@observer
class SearchResult extends React.Component {
  constructor(props) {
    super(props);
    let isFollowing = false;

    let filterBy = '전체';

    if (typeof window !== 'undefined') {
      isFollowing = window.location.href.includes('following=true');
      if (!isFollowing) {
        isFollowing = true;

        const url = new URL(window.location.href);
        url.searchParams.set('following', 'true');
        history.replace(`${window.location.pathname}${url.search}`);
      }
      const searchParams = new URLSearchParams(window.location.search);
      filterBy = searchParams.get('filterBy') || filterBy;
    }

    this.state = {
      isFollowing,
      currentTab: this.props.currentTab ? this.props.currentTab : '포스팅',
      filter: 'new',
      filterText: '최신순',
      filterDownToggle: false,
      data: null,
      page: 0,
      next: null,
      loading: false,
      modalOpen: false,
      newNoti: false,
      filterSelected: null,
      commentModalOpen: false,
      commentModalPost: null,
      world: null,
      sum: null,
      user: null,
      error: false,
      viewerOpen: false,
      viewerImage: null,
      postType: filterBy,
      picks: [],
    };

    const prevState = window.localStorage.getItem('listState');
    if (prevState) {
      this.state = JSON.parse(prevState);
    }
    this.scrollParent = React.createRef();
  }

  loadWorld = async (worldId) => {
    return await axios.get(`${base}/worlds/${worldId}`);
  };
  loadSum = async () => {
    if (this.props.sumId) {
      const sums = await axios.get(`${base}/sums?_sort=priority:ASC`);
      const sumId = String(this.props.sumId);
      const sum = sums.data.find(({ id }) => String(id) === String(sumId));
      return sum;
    }
    return null;
  };
  setPicks = async ({ sumId }) => {
    try {
      const picks = await axios.get(
        `${base}/picks?_sort=created_at:DESC&hidden=false${
          sumId ? `&sum.id=${sumId}` : '&main=true'
        }` //?sum.id=${sum.id}&hidden=false`
      );
      if (picks && picks.data) {
        const headers = this.props.userStore.auth_token
          ? {
              Authorization: `Bearer ${this.props.userStore.auth_token}`,
            }
          : undefined;
        const posts = (
          await Promise.all(
            picks.data.map(({ postId }) =>
              postAPI
                .getPost({
                  id: postId,
                  headers,
                })
                .then(
                  ({ data } = { data: null }) => ({ data }),
                  (error) => error
                )
            )
          )
        )
          .map(({ data }) => data)
          .filter((o) => o);
        this.setState({
          picks: posts.map((post, i) => ({
            post,
            ...picks.data[i],
          })),
        });
      }
    } catch (e) {
      console.log('섬 이벤트 없음');
    }
  };
  async componentDidMount() {
    console.log('componentDidMOunt');
    // 유저 정보 획득하고 없으면 생성
    const user = await getUserOrCreate(this.props.userStore.auth_token);
    this.setState({ user });

    // 섬 정보 획득
    const sum = await this.loadSum();
    this.setState({ sum });

    // 이벤트
    try {
      const allSumEvent = await axios.get(
        `${base}/events?main=true&hidden=false`
      );
      if (allSumEvent && allSumEvent.data && allSumEvent.data.length) {
        this.setState({ allSumEvent: allSumEvent.data[0] });
      }
      let event = await axios.get(
        `${base}/events?sum.id=${sum.id}&hidden=false`
      );
      if (event && event.data && event.data.length) {
        this.setState({ event: event.data[0] });
      } else {
        this.setState({
          event: null,
        });
      }
    } catch (e) {}
    // 픽
    await this.setPicks({ sumId: sum && sum.id });

    window.onpopstate = this.closeModal;
    // noti
    const token = this.props.userStore.auth_token;
    if (token) {
      const req = {
        headers: { Authorization: `Bearer ${this.props.userStore.auth_token}` },
      };
      const res = await userAPI.getIsNewNoti(req).then((res) => {
        return res;
      });
      if (res.data) {
        this.setState({
          newNoti: res.data.is_new,
        });
      }
    }
    // 사파리에서 최소화시 비디오 자동재생이 종료된다.
    // 이를 해결한다.
    document.addEventListener('visibilitychange', (e) => {
      if (!document.hidden) {
        setTimeout(() => {
          const vids = document.querySelectorAll('.VIDEOS');
          vids.forEach((o) => o.play());
        }, 1000);
      }
    });

    if (!this.state.data) {
      console.log('noData', this.state);
      this.loadMore();
    }
  }
  async componentWillReceiveProps(nextProps) {
    if (nextProps.sumId !== this.props.sumId) {
      const sum = await this.loadSum();
      console.log('!@!@#!@#!@#!@#!@', nextProps.sumId, this.props.sumId);
      this.setState(
        {
          postType: '전체',
          data: null,
          page: 0,
          next: null,
          sum,
        },
        () => {
          this.loadMore({ renew: 1, sumId: nextProps.sumId });
        }
      );
      window.localStorage.removeItem('listState');
      if (Array.isArray(nextProps.sumId) && Array.isArray(this.props.sumId)) {
      } else {
        // this.setState({ data: null, page: 0, next: null });

        try {
          const sumId = nextProps.sumId;
          const event = await axios.get(
            `${base}/events?sum.id=${sumId}&hidden=false`
          );
          if (event && event.data && event.data.length) {
            this.setState({ event: event.data[0] });
          } else {
            this.setState({
              event: null,
            });
          }
        } catch (e) {}
        // 픽
        await this.setPicks({ sumId: nextProps.sumId });
      }
    }
  }

  async componentDidUpdate(prevProps) {
    // user만 검색
    if (prevProps.searchVal !== this.props.searchVal) {
      if (this.state.currentTab === '사용자' && this.props.searchVal) {
        this.setState({
          data: [],
          count: 0,
          page: 0,
          next: null,
        });
      }
    }
    console.log('componentDidUpdate and save state', this.state);
    window.localStorage.setItem('listState', JSON.stringify(this.state));
    console.log('componentDidUpdate and save state [end]');
  }
  handleMyFilter = () => {
    const token = this.props.userStore.getTokenOrToggleLogin();
    if (token) {
      this.props.userStore.toggleMyFilter();
    }
  };
  handleFilter = (filter) => {
    const dict = {
      popular: '인기순',
      new: '최신순',
    };
    this.setState({
      filter,
      filterText: dict[filter],
      filterDropDown: false,
      data: null,
      page: 0,
      next: null,
    });
  };
  toggleFilter = () => {
    this.setState({
      filterDownToggle: !this.state.filterDownToggle,
    });
  };
  _onChange = (tab, _) => {
    this.setState({
      currentTab: tab.title,
      data: null,
      page: 0,
      next: null,
    });
    this.props.handleTabChange(tab.title);
  };
  loadByType = (postType, page) => {
    const map = {
      투표: 'polls',
      'Q&A': 'qnas',
      미션: 'missions',
      토픽: 'topics',
    };
    const type = map[postType];
    return axios.get(
      `https://db.lay-er.me/${type}?_sort=id:DESC&_start=${
        (page - 1) * 5
      }&_limit=5`
    );
  };
  loadData = async ({ page, tab, sumId }) => {
    try {
      console.log('loadData', page, tab, sumId);
      this.setState({ loading: true });

      const headers = this.props.userStore.auth_token
        ? {
            Authorization: `Bearer ${this.props.userStore.auth_token}`,
          }
        : undefined;

      const props = {
        following: this.state.isFollowing,
        // 메인에서는 추천으로 필터
        recommended: !this.state.isFollowing,
        category: this.state.filterSelected,
        // profile: this.state.profileId,
        headers,
        next: this.state.next,
      };

      // 섬을 선택한 상태면 추천 필터 제거
      if (this.props.sumId) delete props.recommended;

      let result;
      if (!['전체', '일반'].includes(this.state.postType)) {
        const { data } = await this.loadByType(this.state.postType, page);
        const postIds = data.map(({ postId }) => postId);
        const posts = (
          await Promise.all(
            postIds.map((id) =>
              postAPI
                .getPost({
                  id,
                  headers,
                })
                .then(
                  ({ data } = { data: null }) => ({ data }),
                  (error) => error
                )
            )
          )
        )

          .map(({ data }) => data)
          .filter((o) => o);

        console.log('posts, po', posts);
        result = { count: 0, data: posts, next: posts.length > 0 };
        // result = { count: 0, data: [], next: false };
      } else {
        console.log('this.props', this.props);
        if (sumId) {
          props.id_of_sum = Array.isArray(sumId) ? sumId : String(sumId);
        } else {
          props.onlySelected = false;
        }
        console.log('props', props);
        result = await postAPI.getPostList(props).then((res) => res.data);
      }
      console.log('loadData result', result);
      if (result) {
        let data = (this.state.data || []).concat(result.data);

        // DELETE DUPLICATE
        data = data.reduce((acc, val) => {
          if (!acc.find((o) => o.id === val.id)) acc.push(val);
          return acc;
        }, []);

        // 탭을 빠르게 변환하여 탭 변환 이후에 리스트 데이터가 변화할 경우
        if (TABS[tab] === this.state.currentTab) {
          const state = {
            data,
            count: result.count,
            page,
            next: result.next,
          };
          this.setState(state);
        }
        this.setState({ loading: false });
        return data;
      }
    } catch (e) {
      console.error(e);
      // window.alert(e.message);
      this.setState({ loading: false });
    }
  };
  closeModal = () => {
    this.setState({
      modalOpen: false,
    });
  };

  handleClickPostHeader = (name) => {
    switch (name) {
      case 'search': {
        history.push('/search');
        break;
      }
      case 'noti': {
        if (this.props.userStore.getTokenOrToggleLogin()) {
          history.push('/notification');
        }
        break;
      }
      case 'recommendation': {
        window.location.href = '/';
        break;
      }
      case 'following': {
        const token = this.props.userStore.getTokenOrToggleLogin();
        if (token)
          if (!window.location.search.includes('following')) {
            if (window.location.search === '') {
              window.location.href = '/?following=true';
            } else {
              window.location.href = '&&following=true';
            }
          }
        break;
      }
      case 'detailMore': {
        this.setState({
          postMoreInfoOpen: true,
        });
        break;
      }
    }
  };
  loadMore = async ({ renew, sumId } = { renew: false, sumId: null }) => {
    let initialPage;
    if (this.state.currentTab == '포스팅') initialPage = 0;
    else if (this.state.currentTab == '상품') initialPage = 1;
    else if (this.state.currentTab == '사용자') initialPage = 2;
    console.log('loadData ', this.state.page, sumId, renew);
    const data = await this.loadData({
      sumId: sumId || this.props.sumId,
      page: renew ? 1 : this.state.page + 1,
      tab: initialPage,
    });
    return data;
  };
  updateFollow = async ({ user, sumId, isFollow }) => {
    if (!user) {
      alert('로그인이 필요합니다.');
    } else {
      const { sums } = user;
      let newSums = sums.slice();
      if (isFollow) {
        newSums.push({ id: sumId });
      } else {
        newSums = newSums.filter((o) => o.id !== sumId);
      }
      await axios.put(`${base}/users/${user.id}`, {
        sums: newSums,
      });
      // 갱신
      const renew = await getUserOrCreate(this.props.userStore.auth_token);
      this.setState({ user: renew });

      let followers = this.state.sum.followers;
      if (isFollow) {
        followers += 1;
      } else {
        followers -= 1;
      }
      await axios.put(`${base}/sums/${sumId}`, {
        followers,
      });
      // 섬 정보 갱신
      const sumsRenew = await this.loadSum();
      this.setState({ sum: sumsRenew });
    }
  };
  render() {
    const hasMore = this.state.page === 0 || Boolean(this.state.next);

    let clickedPost;
    if (this.state.data) clickedPost = this.state.data[this.state.modalIndex];

    const event = this.props.sumId ? this.state.event : this.state.allSumEvent;

    return (
      <>
        <Wrap header={this.props.singlePostId}>
          {this.state.error && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                position: 'absolute',
              }}
            >
              <h1>삭제된 게시글입니다.</h1>
            </div>
          )}
          {event && (
            <Notice
              text={event.name}
              onClick={() => (window.location.href = event.link)}
            />
          )}
          {this.state.picks.length > 0 && (
            <Picks
              sumName={this.state.sum ? this.state.sum.name : '모든섬'}
              items={this.state.picks.map((pick) => ({
                id: pick.id,
                postId: pick.postId,
                title: pick.name,
                img: pick.post && pick.post.thumbnail_image,
              }))}
            />
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {this.props.sumId ? (
              <div style={{ marginLeft: 14 }}>
                <span
                  onClick={() => {
                    const url = new URL(window.location.href);
                    url.searchParams.delete('following', 'true');
                    history.replace(`${window.location.pathname}${url.search}`);
                    this.setState(
                      {
                        postType: '전체',
                        isFollowing: false,
                        data: null,
                        page: 0,
                        next: null,
                      },
                      () => {
                        this.loadMore({ renew: 1 });
                      }
                    );
                  }}
                  style={{
                    color:
                      this.state.user && !this.state.isFollowing
                        ? 'black'
                        : '#cfcfcf',
                    marginRight: 4,
                  }}
                >
                  신규
                </span>
                |
                <span
                  onClick={() => {
                    if (this.state.user) {
                      const url = new URL(window.location.href);
                      url.searchParams.set('following', 'true');
                      url.searchParams.delete('filterBy');
                      history.replace(
                        `${window.location.pathname}${url.search}`
                      );
                      this.setState(
                        {
                          postType: '전체',
                          isFollowing: true,
                          data: null,
                          page: 0,
                          next: null,
                        },
                        () => {
                          this.loadMore({ renew: 1 });
                        }
                      );
                    } else {
                      this.props.userStore.getTokenOrToggleLogin();
                    }
                  }}
                  style={{
                    color:
                      !this.state.user || this.state.isFollowing
                        ? 'black'
                        : '#cfcfcf',
                    marginLeft: 4,
                  }}
                >
                  팔로잉
                </span>
              </div>
            ) : (
              <div style={{ marginLeft: 14 }}>
                <span
                  onClick={() => {
                    const url = new URL(window.location.href);
                    url.searchParams.delete('following', 'true');
                    history.replace(`${window.location.pathname}${url.search}`);
                    this.setState(
                      {
                        postType: '전체',
                        isFollowing: false,
                        data: null,
                        page: 0,
                        next: null,
                      },
                      () => {
                        this.loadMore({ renew: 1 });
                      }
                    );
                  }}
                  style={{
                    color:
                      !this.state.user || !this.state.isFollowing
                        ? 'black'
                        : '#cfcfcf',
                    marginRight: 4,
                  }}
                >
                  추천
                </span>
                |
                <span
                  onClick={() => {
                    if (this.state.user) {
                      const url = new URL(window.location.href);
                      url.searchParams.set('following', 'true');
                      url.searchParams.delete('filterBy');
                      history.replace(
                        `${window.location.pathname}${url.search}`
                      );
                      this.setState(
                        {
                          postType: '전체',
                          isFollowing: true,
                          data: null,
                          page: 0,
                          next: null,
                        },
                        () => {
                          this.loadMore({ renew: 1 });
                        }
                      );
                    } else {
                      this.props.userStore.getTokenOrToggleLogin();
                    }
                  }}
                  style={{
                    color:
                      this.state.user && this.state.isFollowing
                        ? 'black'
                        : '#cfcfcf',
                    marginLeft: 4,
                  }}
                >
                  팔로잉
                </span>
              </div>
            )}
            {this.props.isAllSum && (
              <PostTypeSelect
                type={this.state.postType}
                handleSelect={(postType) => {
                  const setFilterBy = (filterBy) => {
                    const url = new URL(window.location.href);
                    url.searchParams.delete('following');
                    url.searchParams.set('filterBy', filterBy);
                    history.replace(`${window.location.pathname}${url.search}`);
                  };
                  if (this.state.postType === postType) {
                    this.setState(
                      {
                        postType: '전체',
                        data: null,
                        page: 0,
                        next: null,
                        isFollowing: false,
                      },
                      () => {
                        this.loadMore({ renew: 1 });
                      }
                    );
                    setFilterBy('전체');
                  } else {
                    this.setState(
                      {
                        postType,
                        data: null,
                        page: 0,
                        next: null,
                        isFollowing: false,
                      },
                      () => {
                        this.loadMore({ renew: 1 });
                      }
                    );
                    setFilterBy(postType);
                  }
                }}
              />
            )}
          </div>
          {this.state.user &&
          !this.state.loading &&
          this.state.data &&
          this.state.data.length === 0 ? (
            <NoFollowers
              text="아직 팔로우 하고 있는 이웃이 없네요.
다양한 섬들을 둘러보고 이웃을 추가해 보세요."
              maxLine={3}
            />
          ) : null}
          <InfiniteScroll
            initialLoad={false}
            pageStart={this.state.page}
            loadMore={this.loadMore}
            hasMore={!this.state.loading && hasMore && !this.state.error}
            useWindow={true}
          >
            <MyGrid
              data={this.state.data}
              columnNum={1}
              hasLine={false}
              activeStyle={false}
              itemStyle={{
                position: 'relative',
                width: '100%',
              }}
              renderItem={(post, index) => (
                <MainCard
                  key={post.id}
                  onlyBasic={this.state.postType === '일반'}
                  sum={
                    this.props.sums
                      ? this.props.sums.find(
                          (sum) => String(sum.id) === post.id_of_sum
                        )
                      : null
                  }
                  onClickSumName={() => {
                    window.localStorage.removeItem('listState-individual-sum');
                    history.push(`/sum/${post.id_of_sum}`);
                  }}
                  userId={this.state.user && this.state.user.id}
                  stop={this.state.modalOpen}
                  data={JSON.stringify(post)}
                  isOpenCommentModal={this.state.commentModalOpen}
                  openCommentModal={(state, post) => {
                    this.setState({
                      commentModalOpen: state,
                      commentModalPost: post,
                    });
                  }}
                  isMain
                  onClickPostMore={this.props.onClickPostMore}
                  onClickProducts={this.props.onClickProducts}
                  toggleLogin={this.props.userStore.getTokenOrToggleLogin}
                  onClickImage={(image) =>
                    this.setState({
                      viewerOpen: true,
                      viewerImage: image,
                    })
                  }
                />
              )}
            />
            {this.state.loading && (
              <div
                className="loader"
                style={{ textAlign: 'center', height: 400 }}
              >
                <LoadingSpinner />
              </div>
            )}
            <div style={{ height: 100 }} />
          </InfiniteScroll>
          <CommentModal
            open={this.state.commentModalOpen}
            handleClose={() =>
              this.setState({
                commentModalOpen: false,
              })
            }
            voteData={
              this.state.commentModalPost &&
              this.state.commentModalPost.voteData
            }
            // 코멘트 카운트
            handleChange={(commentCount) => {
              const post = this.state.data.find(
                (post) => post.id === this.state.commentModalPost.id
              );
              post.comment.count = commentCount;
              const id = this.state.commentModalPost.id;
              this.setState({ commentCount });
            }}
            postId={
              this.state.commentModalPost && this.state.commentModalPost.id
            }
            bottom={'0px'}
          />
          <ImageViewer
            open={this.state.viewerOpen}
            handleClose={() => this.setState({ viewerOpen: false })}
            src={this.state.viewerImage}
          />
        </Wrap>
      </>
    );
  }
}
export default SearchResult;
